<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Email Log</h5>
      </div>
      <div class="col-2">
        <button @click="copyContent" style="margin-right: 10px" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        @on-per-page-change="onPerPageChange"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [10, 15, 20, 25, 30, 35, 40],
          position: 'top'
        }"
        :line-numbers="false"
        @on-row-click="rowClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'timeSent', type: 'desc' }
        }"
        style-class="vgt-table musical bordered condensed"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  import moment from 'moment'
  export default {
    name: 'EmailLog',
    mixins: [copyTable],
    data() {
      return {
        serverParams: {
          perPage: 20
        },
        columns: [
          {
            label: 'Date sent',
            field: 'timeSent',
            width: '10%',
            type: 'number',
            tdClass: 'text-right',
            thClass: 'text-right',
            formatFn: this.dateFormat,
            sortable: true
          },
          {
            label: 'Template Name',
            field: 'template',
            width: '12%',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: true,
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by subject code' // placeholder for filter input
            }
          },
          {
            label: 'Sent to',
            field: 'email',
            width: '15%',
            tdClass: 'text-right',
            thClass: 'text-right',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by email' // placeholder for filter input
            }
          },
          {
            label: 'Purchaser name',
            field: 'purchaserName',
            width: '15%',
            tdClass: 'text-right',
            thClass: 'text-right',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by name' // placeholder for filter input
            }
          },
          {
            label: 'Plan',
            field: 'planId',
            width: '10%',
            tdClass: 'text-right',
            thClass: 'text-right',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by plan' // placeholder for filter input
            }
          },
          {
            label: 'Type',
            field: 'type',
            width: '10%',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: false,
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by type' // placeholder for filter input
            }
          },
          {
            label: 'CC to admin',
            field: 'ccedToAdmin',
            width: '10%',
            type: 'number',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: true,
            formatFn: this.boolFormat,
            sortFn: this.boolSort
          },
          {
            label: 'Expiration',
            field: 'expirationDate',
            width: '10%',
            type: 'number',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: false,
            formatFn: this.dateFormat
          },
          {
            label: 'Days difference',
            field: 'daysValid',
            width: '15%',
            type: 'number',
            tdClass: 'text-right',
            thClass: 'text-right',
            sortable: false,
            formatFn: this.roundFn
          }
        ],
        rows: [],
        apiError: '',
        attemptSubmit: false
      }
    },
    mounted() {
      console.log('Email Log component mounted')
      this.$store.commit('general/setMenuHighlight', 'Email Log')
      this.$store
        .dispatch('general/getRowsPerPage', 'emailsPP')
        .then((value) => {
          this.serverParams.perPage = value
        })
        .catch((ex) => {
          console.error('Exception in mounted - getRowsPerPage', ex.message)
        })
      this.$store
        .dispatch('general/getEmailLog')
        .then(() => {
          this.rows = this.emailLog
        })
        .catch((ex) => {
          console.error('Exception in mounted - getEmailLog', ex.message)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: (state) => state.general.loadingMsg,
        data: (state) => state.general.data,
        error: (state) => state.general.error,
        loading: (state) => state.general.loading,
        loggedinUser: (state) => state.general.loggedinUser,
        emailLog: (state) => state.general.emailLog
      })
    },
    methods: {
      onPerPageChange(params) {
        this.$log.info('onPerPageChange', params)
        this.$store.dispatch('general/saveRowsPerPage', { name: 'emailsPP', value: params.currentPerPage })
      },
      rowClick(params) {
        this.$store
          .dispatch('subscriptions/getSubscription', { subscriptionId: params.row.subscriptionId })
          .then(() => {
            console.log(`[EmailLog] back from getSubscription`)
            eventbus.$emit('modal', { command: 'open', name: 'subscription-edit' })
          })
          .catch((ex) => {
            console.error('Exception in rowClick', ex.message)
          })
      },
      dateFormat(val) {
        return moment(val).format('MMM-DD-YYYY').toString()
      },
      roundFn(val) {
        if (val) {
          return val.toFixed(0)
        }
        return '-'
      },
      boolFormat(val) {
        return val ? 'yes' : 'no'
      },
      boolSort(x, y) {
        return x === y ? 0 : x ? -1 : 1
      }
    }
  }
</script>
