<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <div class="row fixed-top page-header">
      <div class="col-9">
        <h5>Comment Templates</h5>
      </div>
      <div class="col-3">
        <button @click="newTemplate" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add Comment Template
        </button>
         <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data" class="table-block">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        @on-row-click="rowClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: initialSortField, type: initialSortType }
        }"
        style-class="vgt-table musical bordered condensed"
      >
        <!--  v-if="$can('delete', 'faq')" -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'delete'">
            <a href="#" v-if="$can('delete', 'comment')" @click="deleteQuestion(props.row._id)">delete comment</a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <br />
    <hr />

    <div class="show-form">
      <h5 v-if="currentComment._id">
        Edit Comment
      </h5>
      <h5 v-else>
        New Comment
      </h5>
      <div class="col-12 form-block">
        <!-- Edit part of the comment -->
        <div class="row form-row">
          <div class="col-4 label">
            <label class="label required" for="comment">Comment</label>
          </div>
          <div class="col-6">
            <input
              ref="comment-input"
              type="text"
              class="form-control"
              v-model.trim="currentComment.text"
              data-vv-as="Enter a comment"
              id="comment"
              :class="{ 'is-invalid': attemptSubmit && missingComment }"
              name="comment"
            />
            <div :class="[attemptSubmit && missingComment ? 'is-not-valid' : 'is-valid']">
              You need to enter a comment.
            </div>
          </div>
        </div>

        <!-- The Buttons -->
        <div class="row">
          <div class="offset-4 col-6">
            <span class="text-danger" v-if="formErrorText">{{ formErrorText }}<br /></span>
            <span class="text-danger" v-if="error">{{ error }}<br /></span>
            <button
              @click="save"
              :disabled="formsHasErrors"
              style="margin-left: 5px;margin-right: -5px;"
              class="btn btn-outline-success btn-xs float-right"
            >
              Save
            </button>
            <button @click="cancel" class="btn btn-outline-secondary btn-xs float-right">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'CommentTemplates',
    mixins: [copyTable],
    data() {
      return {
        // comment: {text: ''},
        initialSortField: 'text',
        initialSortType: 'asc',
        attemptSubmit: false,
        columns: [
          {
            label: 'Comment',
            field: 'text',
            width: '90%',
            filterable: true
          },
          {
            label: '',
            field: 'delete',
            width: '10%'
          }
        ],
        rows: []
      }
    },
    mounted() {
      this.$store.commit('general/setMenuHighlight', 'Comment Templates')
      console.log('Comment Templates component mounted')
      this.$store
        .dispatch('general/getComments')
        .then(() => {
          this.rows = this.commentsList
        })
        .catch(ex => {
          console.error('Exception in mounted - getComments', ex.message)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        commentsList: state => state.general.commentsList,
        currentComment: state => state.general.currentComment
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingComment
        } else {
          return false
        }
      },
      missingComment() {
        return this.currentComment.text === ''
      }
    },
    methods: {
      newTemplate() {
        this.$store.commit('general/setCurrentComment', { text: '' })
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
        this.$refs['comment-input'].focus()
      },
      cancel() {
        this.$store.commit('general/setCurrentComment', { text: '' })
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
      },
      save() {
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          this.$store.dispatch('general/newComment', { _id: this.currentComment._id, text: this.currentComment.text })
          this.attemptSubmit = false
        }
      },
      deleteQuestion(id) {
        this.$store.dispatch('general/delComment', id)
        // Use timeout because rowClick is called first
        // setTimeout(function () {
        console.log('Deleting question', id)
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
        // }, 1000)
      },
      rowClick(params) {
        console.log('Row clicked', params)
        this.$store.commit('general/setCurrentComment', params.row)
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
          if (params.pageIndex === rowIndex) {
            a.classList.add('clicked')
          }
        })
      }
    }
  }
</script>
