<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Lyricists</h5>
      </div>
      <div class="col-2">
        <button @click="newLyricist" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add Lyricist
        </button>
        <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        @on-page-change="pageChanged"
        :line-numbers="false"
        @on-cell-click="cellClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: initialSortField, type: initialSortType }
        }"
        style-class="vgt-table musical bordered condensed"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'isBIPOC'">
            <input
              type="checkbox"
              v-model="props.row.isBIPOC"
              @click.ctrl="copyToComposers = false"
              @change="changeIsBipoc($event, props.row._id)"
            />
          </span>
          <span v-if="props.column.field === 'isFemale'">
            <input
              type="checkbox"
              v-model="props.row.isFemale"
              @click.ctrl="copyToComposers = false"
              @change="changeIsfemale($event, props.row._id)"
            />
          </span>
          <span v-if="props.column.field !== 'isBIPOC' && props.column.field !== 'isFemale'">
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Lyricists',
    mixins: [copyTable],
    data() {
      return {
        error: '',
        loading: true,
        loadingMsg: 'Loading Lyricists',
        copyToComposers: true,
        initialSortField: 'lastName',
        initialSortType: 'asc',
        columns: [
          {
            label: 'First Name',
            field: 'firstName',
            width: '20%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by first name' // placeholder for filter input
            }
          },
          {
            label: 'Last Name',
            field: 'lastName',
            width: '20%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by last name' // placeholder for filter input
            }
          },
          {
            label: 'Info URL',
            field: 'infoUrl',
            width: '30%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by words in URL' // placeholder for filter input
            }
          },
          {
            field: 'isFemale',
            label: 'Women/Nonbinary',
            width: '10%',
            type: 'boolean'
          },
          {
            field: 'isBIPOC',
            label: 'Global Majority',
            width: '10%',
            type: 'boolean'
          },
          {
            label: 'Last Edited',
            field: 'date',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            width: '10%',
            filterOptions: {
              enabled: false // enable filter for this column
            }
          }
        ],
        rows: [],
        apiError: '',
        attemptSubmit: false
      }
    },
    mounted() {
      console.log('Lyricists component mounted')
      this.$store.commit('general/setMenuHighlight', 'Lyricists')
      this.$store.dispatch('creators/getComposers')
      this.$store
        .dispatch('creators/getLyricists')
        .then(() => {
          this.rows = this.lyricists
          this.loading = false
        })
        .catch(ex => {
          console.error('Exception in mounted - getLyricists', ex.message)
          this.loading = false
          this.error = `Exception in mounted - getLyricists: ${ex.message}`
        })
    },
    computed: {
      ...mapState({
        // loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        // error: state => state.general.error,
        // loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        lyricists: state => state.creators.lyricistsList,
        currentLyricist: state => state.creators.currentLyricist,
        composers: state => state.creators.composersList,
        currentComposer: state => state.creators.currentComposer
      })
    },
    methods: {
      changeIsfemale(event, id) {
        console.log('isFemale changed', event.target.checked)
        const lyricist = this.lyricists.find(l => l._id === id)
        lyricist.isFemale = event.target.checked
        this.$store.dispatch('creators/updateLyricist', lyricist)
        if (this.copyToComposers) {
          console.log(`Also looking for composer with name ${lyricist.firstName} ${lyricist.lastName}`)
          const composer = this.composers.find(c => c.firstName === lyricist.firstName && c.lastName === lyricist.lastName)
          if (composer) {
            console.log(`Also found composer with name ${composer.firstName} ${composer.lastName}`)
            composer.isFemale = event.target.checked
            this.$store.dispatch('creators/updateComposer', composer)
          }
        }
        this.copyToComposers = true
      },
      changeIsBipoc(event, id) {
        console.log('isBIPOC changed', event.target.checked, id)
        const lyricist = this.lyricists.find(l => l._id === id)
        lyricist.isBIPOC = event.target.checked
        this.$store.dispatch('creators/updateLyricist', lyricist)
        if (this.copyToComposers) {
          console.log(`Also looking for composer with name ${lyricist.firstName} ${lyricist.lastName}`)
          const composer = this.composers.find(c => c.firstName === lyricist.firstName && c.lastName === lyricist.lastName)
          if (composer) {
            console.log(`Also found composer with name ${composer.firstName} ${composer.lastName}`)
            composer.isBIPOC = event.target.checked
            this.$store.dispatch('creators/updateComposer', composer)
          }
        }
        this.copyToComposers = true
      },
      rowStyleClassFn(row) {
        if (row.isFemale && !row.isBIPOC) {
          return 'isFemale'
        } else if (!row.isFemale && row.isBIPOC) {
          return 'isBIPOC'
        } else if (row.isFemale && row.isBIPOC) {
          return 'isBoth'
        } else {
          return ''
        }
      },
      pageChanged(one, two) {
        console.log('Page changed', one, two)
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
        this.$store.commit('creators/setNewLyricist')
      },
      cellClick(params) {
        if (params.column.label !== 'Women/Nonbinary' && params.column.label !== 'Global Majority') {
          this.attemptSubmit = false
          console.log('Lyricists: getting lyricist with rowid', params.row._id)
          this.$store.dispatch('creators/getLyricist', params.row._id).then(result => {
            console.debug('Got lyricist', this.currentLyricist)
            eventbus.$emit('modal', { command: 'open', name: 'lyricist-edit' })
          })
          const rows = document.querySelectorAll('.clickable')
          rows.forEach((row, rowIndex) => {
            const a = rows[rowIndex]
            a.classList.remove('clicked')
            if (params.pageIndex === rowIndex) {
              a.classList.add('clicked')
            }
          })
        }
      },
      newLyricist() {
        this.$store.commit('creators/setNewLyricist')
        this.attemptSubmit = false
        eventbus.$emit('modal', { command: 'open', name: 'lyricist-edit' })
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
      }
    }
  }
</script>
