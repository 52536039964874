// See https://github.com/sagalbot/vue-select/pull/394 for why I included
// the Select component in the project instead of getting it via npm

export const compLyrc = {
  methods: {
    createComposer(newComposer) {
      console.log('New Composer', newComposer)
      if (window.confirm(`${newComposer} is not in the list.\n\nDo you want to add this composer/lyricist?.`)) {
        return { label: newComposer }
      } else {
        return null
      }
    },
    checkForNewComposersLyricists(compLyrc) {
      for (var composer of compLyrc.comp) {
        if (!composer._id) {
          return true
        }
      }
      for (var lyricist of compLyrc.lyrc) {
        if (!lyricist._id) {
          return true
        }
      }
      return false
    },
    addCompsLyrcsToLocalList(composers, lyricists) {
      for (var composerId in composers) {
        const cmp = this.composers.find(c => c._id === composerId)
        if (!cmp) {
          const tmpComp = composers[composerId]
          this.composers.push({ _id: tmpComp._id, label: tmpComp.lastName + ', ' + tmpComp.firstName })
        }
      }
      for (var lyricistId in lyricists) {
        const lrc = this.lyricists.find(l => l._id === lyricistId)
        if (!lrc) {
          this.lyricists.push(lyricists[lyricistId])
        }
      }
    }
  }
}
