import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    tableKey: 0,
    modulesList: [],
    logsList: [],
    filteredLogsList: []
  },
  mutations: {
    tableUpdate: state => {
      state.tableKey += 1
    },
    setModulesList: (state, payload) => {
      state.modulesList = payload
    },
    setLogsList: (state, payload) => {
      state.logsList = payload
    },
    filterLogList: (state, payload) => {
      if (payload) {
        var filtered = state.logsList.filter(c => {
          return c.module === payload[0]
        })
        state.filteredLogsList = filtered
      }
    }
  },
  actions: {
    getModules: async ({ commit, rootState }) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .get(rootState.general.apiRoot + '/api/modules', {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting modules from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting modules from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          commit('setModulesList', result.data)
          resolve()
        } else {
          reject()
        }
      })
    },
    getLogs: async ({ state, commit, rootState }, serverParams) => {
      console.log(`ServerParams: ${JSON.stringify(serverParams)}`)
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .post(rootState.general.apiRoot + '/api/logs', serverParams, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting logs from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting logs from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          Vue.$log.info(`Total received: ${result.data.count}`)
          commit('setLogsList', result.data.logs)
          resolve({ totalRecords: result.data.count })
        } else {
          resolve({ totalRecords: 0, rows: [] })
        }
      })
    }
  }
}
