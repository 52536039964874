var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("song-edit-common", {
        attrs: {
          name: "song-edit-partial",
          songs: _vm.songsForEdit,
          "songs-titles": _vm.songsTitlesForEdit,
          "show-title": _vm.currentShow.title,
          "on-closed": _vm.onSongsCommonClosed,
          "all-songs": _vm.allSongsSelected,
          "show-id": _vm.currentShow._id
        }
      }),
      _c(
        "modal",
        {
          attrs: {
            name: "show-edit",
            height: "auto",
            width: "90%",
            scrollable: true,
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _vm.loading
            ? _c("div", { staticClass: "loading" }, [
                _c("img", { attrs: { src: "/loading.gif" } }),
                _c("div", { staticClass: "loading" }, [
                  _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "show-form" }, [
            _vm.currentShow._id
              ? _c("h4", [_vm._v(" Edit Show ")])
              : _c("h4", [_vm._v(" New Show ")]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-8 form-block" },
                [
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "showTitle" }
                        },
                        [_vm._v("Show Title")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.currentShow.title,
                              expression: "currentShow.title",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.attemptSubmit && _vm.missingTitle
                          },
                          attrs: { id: "title", name: "title", type: "text" },
                          domProps: { value: _vm.currentShow.title },
                          on: {
                            blur: [
                              _vm.titleEntered,
                              function($event) {
                                return _vm.$forceUpdate()
                              }
                            ],
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentShow,
                                "title",
                                $event.target.value.trim()
                              )
                            }
                          }
                        }),
                        _vm.titleCheck !== "ok"
                          ? _c("div", { staticClass: "is-not-valid" }, [
                              _vm._v(" " + _vm._s(_vm.titleCheck) + " ")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.missingTitle
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [_vm._v(" Title is required. ")]
                        )
                      ]),
                      _c("div", { staticClass: "checkbox-block" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentShow.isCompilation,
                              expression: "currentShow.isCompilation"
                            }
                          ],
                          staticClass: "css-checkbox",
                          attrs: { type: "checkbox", id: "checkbox1" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentShow.isCompilation
                            )
                              ? _vm._i(_vm.currentShow.isCompilation, null) > -1
                              : _vm.currentShow.isCompilation
                          },
                          on: {
                            click: _vm.checkClicked,
                            change: function($event) {
                              var $$a = _vm.currentShow.isCompilation,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentShow,
                                      "isCompilation",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentShow,
                                      "isCompilation",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.currentShow, "isCompilation", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "css-label lite-red-check",
                            attrs: { for: "checkbox1", name: "checkbox1_lbl" }
                          },
                          [_vm._v("This is a compilation or jukebox show")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentShow.songsAreStandalone,
                              expression: "currentShow.songsAreStandalone"
                            }
                          ],
                          staticClass: "css-checkbox",
                          attrs: { type: "checkbox", id: "checkbox2" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentShow.songsAreStandalone
                            )
                              ? _vm._i(
                                  _vm.currentShow.songsAreStandalone,
                                  null
                                ) > -1
                              : _vm.currentShow.songsAreStandalone
                          },
                          on: {
                            click: _vm.checkClicked,
                            change: function($event) {
                              var $$a = _vm.currentShow.songsAreStandalone,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentShow,
                                      "songsAreStandalone",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentShow,
                                      "songsAreStandalone",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.currentShow,
                                  "songsAreStandalone",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "css-label lite-red-check",
                            attrs: { for: "checkbox2", name: "checkbox2_lbl" }
                          },
                          [
                            _vm._v(
                              "This is a container for stand-alone songs, not a real show"
                            )
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "note",
                            class: [
                              _vm.currentShow.songsAreStandalone
                                ? "show"
                                : "hide"
                            ]
                          },
                          [
                            _c("b", [_vm._v("Note:")]),
                            _vm._v(
                              ' If you are entering stand-alone songs, the "Show Title" above is inconsequential. It may be useful to enter the composer\'s name as the title. '
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "showId" } },
                        [_vm._v("Show ID")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentShow._id,
                            expression: "currentShow._id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "showId", disabled: "" },
                        domProps: { value: _vm.currentShow._id },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentShow,
                              "_id",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      class: [_vm.currentShow.isCompilation ? "remove" : "add"]
                    },
                    [
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "label required",
                              attrs: { for: "composers" }
                            },
                            [_vm._v("Composer(s)")]
                          ),
                          _c("div", [
                            _vm.currentShow.hasFemaleComposer
                              ? _c("span", [_vm._v("👩🏻 ")])
                              : _vm._e(),
                            _vm.currentShow.hasBIPOCComposer
                              ? _c("span", [_vm._v("👨🏾")])
                              : _vm._e()
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("v-select", {
                              staticClass: "tooltip-target is-invalid",
                              attrs: {
                                multiple: "",
                                "close-on-select": false,
                                id: "composers",
                                name: "composers",
                                options: _vm.composers
                              },
                              on: { input: _vm.composerEntered },
                              model: {
                                value: _vm.currentShow.composers,
                                callback: function($$v) {
                                  _vm.$set(_vm.currentShow, "composers", $$v)
                                },
                                expression: "currentShow.composers"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-2" }, [
                          _c(
                            "div",
                            {
                              staticClass: "btn btn-sm btn-outline-primary",
                              on: { click: _vm.newComposer }
                            },
                            [_vm._v("New")]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "row form-row" }, [
                        _c(
                          "div",
                          { staticClass: "offset-4 col-8" },
                          [
                            _c("span", { staticClass: "note" }, [
                              _vm._v("Format: Last name, First name")
                            ]),
                            _c("br"),
                            _vm._l(_vm.currentShow.composers, function(
                              composer
                            ) {
                              return _c("div", { key: composer._id }, [
                                composer.infoUrl
                                  ? _c("span", [
                                      _c("b", [
                                        _c("i", [
                                          _vm._v(
                                            _vm._s(composer.lastName) + ":"
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: composer.infoUrl,
                                            target: "__blank"
                                          }
                                        },
                                        [_vm._v(_vm._s(composer.infoUrl))]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            }),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.attemptSubmit && _vm.missingComposer
                                    ? "is-not-valid"
                                    : "is-valid"
                                ]
                              },
                              [_vm._v(" At least one composer is required. ")]
                            )
                          ],
                          2
                        )
                      ]),
                      !_vm.currentShow.isCompilation
                        ? _c("div", { staticClass: "row form-row" }, [
                            _c("div", { staticClass: "col-4 label" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "label required",
                                  attrs: { for: "lyricists" }
                                },
                                [_vm._v("Lyricist(s)")]
                              ),
                              _c("div", [
                                _vm.currentShow.hasFemaleLyricist
                                  ? _c("span", [_vm._v("👩🏻 ")])
                                  : _vm._e(),
                                _vm.currentShow.hasBIPOCLyricist
                                  ? _c("span", [_vm._v("👨🏾")])
                                  : _vm._e()
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("v-select", {
                                  attrs: {
                                    multiple: "",
                                    "close-on-select": false,
                                    id: "lyricists",
                                    name: "lyricists",
                                    options: _vm.lyricists
                                  },
                                  on: { input: _vm.lyricistEntered },
                                  model: {
                                    value: _vm.currentShow.lyricists,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.currentShow,
                                        "lyricists",
                                        $$v
                                      )
                                    },
                                    expression: "currentShow.lyricists"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-2" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-sm btn-outline-primary",
                                  on: { click: _vm.newLyricist }
                                },
                                [_vm._v("New")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "row form-row" }, [
                        _c(
                          "div",
                          { staticClass: "offset-4 col-8" },
                          [
                            _c("span", { staticClass: "note" }, [
                              _vm._v("Format: Last name, First name")
                            ]),
                            _c("br"),
                            _vm._l(_vm.currentShow.lyricists, function(
                              lyricist
                            ) {
                              return _c("div", { key: lyricist._id }, [
                                lyricist.infoUrl
                                  ? _c("span", [
                                      _c("b", [
                                        _c("i", [
                                          _vm._v(
                                            _vm._s(lyricist.lastName) + ":"
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: lyricist.infoUrl,
                                            target: "__blank"
                                          }
                                        },
                                        [_vm._v(_vm._s(lyricist.infoUrl))]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            }),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.attemptSubmit && _vm.missingLyricist
                                    ? "is-not-valid"
                                    : "is-valid"
                                ]
                              },
                              [_vm._v(" At least one lyricist is required. ")]
                            )
                          ],
                          2
                        )
                      ])
                    ]
                  ),
                  !_vm.currentShow.songsAreStandalone
                    ? _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "label required",
                              attrs: { for: "showyearWritten" }
                            },
                            [_vm._v("Year Written")]
                          )
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.currentShow.yearWritten,
                                expression: "currentShow.yearWritten",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.attemptSubmit && _vm.missingYear
                            },
                            attrs: {
                              type: "text",
                              "data-vv-as": "year a show was written",
                              id: "showyearWritten",
                              name: "showyearWritten"
                            },
                            domProps: { value: _vm.currentShow.yearWritten },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentShow,
                                  "yearWritten",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.missingYear
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" A correct year is required. ")]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "showWiki" } },
                        [_vm._v("Info / Wikipedia Link")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentShow.wikipediaUrl,
                            expression: "currentShow.wikipediaUrl"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "showWiki" },
                        domProps: { value: _vm.currentShow.wikipediaUrl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentShow,
                              "wikipediaUrl",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.currentShow.wikipediaUrl
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.currentShow.wikipediaUrl,
                                target: "blank"
                              }
                            },
                            [_vm._v("Test link")]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm.currentShow.songs.length > 0 ? _c("world-cat") : _vm._e(),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "scorePDF" } },
                        [_vm._v("Uploaded Score PDF")]
                      )
                    ]),
                    _vm.currentShow.hasFreeScore
                      ? _c("div", { staticClass: "col-6" }, [
                          _c("span", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.pdfLink + _vm.currentShow._id + ".pdf",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("View Uploaded PDF")]
                            )
                          ])
                        ])
                      : _vm._e(),
                    !_vm.currentShow.hasFreeScore &&
                    _vm.fileUploadSuccess === true
                      ? _c("div", [
                          _c("i", [
                            _vm._v("Please save show to display uploaded PDF")
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Upload Score "),
                        _c("br"),
                        _c("span", { staticClass: "no-bold" }, [
                          _vm._v("(if show is public domain)")
                        ])
                      ])
                    ]),
                    !_vm.fileUploadSuccess
                      ? _c("div", { staticClass: "col-4" }, [
                          _c("input", {
                            ref: "fileInput",
                            staticStyle: { display: "none" },
                            attrs: {
                              type: "file",
                              name: "score",
                              accept: ".pdf"
                            },
                            on: { change: _vm.fileSelected }
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-success btn-sm",
                              on: {
                                click: function($event) {
                                  return _vm.$refs.fileInput.click()
                                }
                              }
                            },
                            [_vm._v(" Select File ")]
                          ),
                          _vm.selectedFile
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.selectedFile.name))
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    !_vm.fileUploadSuccess
                      ? _c("div", { staticClass: "col-4" }, [
                          _vm.selectedFile && !_vm.fileUploadErrorText
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn btn-outline-primary btn-sm",
                                  on: { click: _vm.upload }
                                },
                                [_vm._v(" Upload File ")]
                              )
                            : _vm._e(),
                          _vm.fileSizeWarning
                            ? _c("div", { staticStyle: { color: "red" } }, [
                                _c("b", [_vm._v(_vm._s(_vm.fileSizeWarning))])
                              ])
                            : _vm._e(),
                          _vm.fileUploadErrorText
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(_vm._s(_vm.fileUploadErrorText))
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { for: "showDateEntered" }
                        },
                        [_vm._v("Date Entered")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(_vm.currentShow.dateCreated)
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { for: "showLastUpdated" }
                        },
                        [_vm._v("Last Updated")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDate")(_vm.currentShow.lastUpdated)
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { for: "showLastUpdatedBy" }
                        },
                        [_vm._v("Last Updated By")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyVal")(
                              _vm.currentShow.lastUpdatedByName
                            )
                          )
                        )
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _vm.apiError
                    ? _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "offset-sm-4 col-7" }, [
                          _c("span", { staticClass: "api-error" }, [
                            _vm._v(_vm._s(_vm.apiError))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "offset-sm-4 col-7" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-secondary btn-sm btn-margin",
                          on: { click: _vm.cancel }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-success btn-sm btn-margin",
                          attrs: { disabled: _vm.formsHasErrors },
                          on: { click: _vm.save }
                        },
                        [_vm._v(" Save ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-primary btn-sm btn-margin",
                          attrs: { disabled: _vm.formsHasErrors },
                          on: { click: _vm.saveNew }
                        },
                        [_vm._v(" Save and New ")]
                      ),
                      _vm.$can("delete", "shows") && _vm.currentShow._id
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline-danger btn-sm btn-margin",
                              on: { click: _vm.delShow }
                            },
                            [_vm._v(" Delete ")]
                          )
                        : _vm._e()
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c("h4", [_vm._v("Songs")]),
                  _vm.currentShow._id
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-success btn-sm song-button",
                            on: { click: _vm.newSong }
                          },
                          [_vm._v(" Add New Song ")]
                        ),
                        _c("hr")
                      ])
                    : _c("div", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "You can only add new songs after show is saved"
                          )
                        ])
                      ]),
                  _vm._l(_vm.currentShow.songs, function(song) {
                    return _c("div", { key: song.title }, [
                      _c("div", { staticClass: "row songs-in-edit" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: song.selectedForEdit,
                              expression: "song.selectedForEdit"
                            }
                          ],
                          staticClass: "css-checkbox",
                          attrs: { type: "checkbox", id: song.title },
                          domProps: {
                            checked: Array.isArray(song.selectedForEdit)
                              ? _vm._i(song.selectedForEdit, null) > -1
                              : song.selectedForEdit
                          },
                          on: {
                            change: function($event) {
                              var $$a = song.selectedForEdit,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      song,
                                      "selectedForEdit",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      song,
                                      "selectedForEdit",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(song, "selectedForEdit", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "css-label lite-red-check",
                            attrs: { for: song.title, name: song.title }
                          },
                          [_vm._v(" " + _vm._s(song.title) + " ")]
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.editSong(song._id)
                              }
                            }
                          },
                          [_vm._v("[edit]")]
                        ),
                        _vm.$can("delete", "songs")
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteSong(song._id, song.title)
                                  }
                                }
                              },
                              [_vm._v("[delete]")]
                            )
                          : _vm._e()
                      ])
                    ])
                  }),
                  _c("div", { staticClass: "songs-edit-all" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: { click: _vm.editSelectedSongs }
                      },
                      [_vm._v("Edit selected")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: "#" }, on: { click: _vm.editAllSongs } },
                      [_vm._v("Edit all")]
                    )
                  ])
                ],
                2
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }