<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Glossary</h5>
      </div>
      <div class="col-2">
        <button @click="newTag" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add Glossary
        </button>
         <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        :line-numbers="false"
        @on-row-click="rowClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'term', type: 'asc' }
        }"
        style-class="vgt-table musical bordered condensed"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Glossary',
    mixins: [copyTable],
    data() {
      return {
        editorOption: {
          // some quill options
          modules: {
            toolbar: [
              'bold',
              'italic',
              'underline',
              { color: [] },
              { indent: '-1' },
              { indent: '+1' },
              { list: 'ordered' },
              { list: 'bullet' },
              'link',
              'clean',
              { header: [1, 2, 3, 4, 5, 6, false] },
              { size: ['small', false, 'large', 'huge'] }
            ]
          }
        },
        editor: {},
        columns: [
          {
            label: 'Term',
            field: 'term',
            width: '20%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by term' // placeholder for filter input
            }
          },
          {
            label: 'Definition',
            field: 'definition',
            html: true,
            width: '80%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by definition' // placeholder for filter input
            }
          }
        ],
        rows: [],
        apiError: '',
        attemptSubmit: false
      }
    },
    created() {
      console.log('Glossary component created')
      this.$store.commit('general/setMenuHighlight', 'Glossary')
      this.tagToRename = 'Select a Tag'
    },
    mounted() {
      console.log('Glossary component mounted')
      this.$store
        .dispatch('glossary/getTags')
        .then(() => {
          this.rows = this.tagList
        })
        .catch(ex => {
          console.error('Exception in mounted - getTags', ex.message)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        currentTag: state => state.glossary.currentTag,
        tagList: state => state.glossary.tagsList
      })
    },
    methods: {
      newTag() {
        this.$store.commit('glossary/setNewTag')
        eventbus.$emit('modal', { command: 'open', name: 'tag-edit' })
      },
      rowClick(params) {
        this.attemptSubmit = false
        const tag = JSON.parse(JSON.stringify(params.row))
        this.$store.commit('glossary/setCurrentTag', tag)
        eventbus.$emit('modal', { command: 'open', name: 'tag-edit' })
        // this.tagToRename = 'Select a Tag'
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
          if (params.pageIndex === rowIndex) {
            a.classList.add('clicked')
          }
        })
      }
    }
  }
</script>
