<template>
  <div class="below-menu">
    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Stripe Messages</h5>
      </div>
      <div class="col-2">
         <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-row-click="rowClick"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
        position: 'top',
        mode: 'records'
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{ field: 'time', type: 'desc' }]
      }"
      :rows="rows"
      :columns="columns"
      style-class="vgt-table musical bordered condensed"
    >
      <div class="loading" slot="loadingContent">
        <div v-if="loadingErrorMsg" class="loading server-error">
          {{ loadingErrorMsg }}
        </div>
        <div v-if="isLoading">
          <img src="/loading.gif" />
          {{ isLoadingMsg }}
        </div>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'StripeMsgs',
    mixins: [copyTable],
    data() {
      return {
        isLoading: false,
        logFilter: [],
        logLevel: ['warn', 'error'],
        isLoadingMsg: '',
        loadingErrorMsg: '',
        totalRecords: 0,
        tableFilter: { columnFilters: { levels: ['warn', 'error'] } },
        serverParams: {
          columnFilters: {},
          page: 1,
          perPage: 20
        },
        rows: [],
        columns: [
          {
            label: 'Date',
            field: 'received',
            sortable: false,
            width: '10%',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy hh:mm'
          },
          {
            label: 'Event Type',
            field: 'type',
            sortable: false,
            width: '10%'
          },
          {
            label: 'Customer',
            field: 'custEmail',
            sortable: false,
            width: '10%'
          },
          {
            label: 'Amount',
            field: 'amount',
            sortable: false,
            width: '10%',
            type: 'number',
            formatFn: function(value) {
              if (typeof value === 'number') {
                return `$ ${Number.parseFloat(value).toFixed(2)}`
              } else {
                return null
              }
            }
          },
          {
            label: 'Processed',
            field: 'processed',
            sortable: false,
            width: '10%'
          },
          {
            label: 'Action',
            field: 'action',
            sortable: false,
            width: '10%'
          },
          {
            label: 'Event ID',
            field: 'eventId',
            sortable: false,
            width: '10%'
          },
          {
            label: 'Remarks',
            field: 'remarks',
            sortable: false,
            width: '10%'
          }
        ]
      }
    },
    mounted() {
      this.$log.info('mounted called')
      this.$store.commit('general/setMenuHighlight', 'Stripe Messages')
      this.$store
        .dispatch('general/getRowsPerPage', 'StripeMsgsPP')
        .then(value => {
          this.serverParams.perPage = value
        })
        .catch(ex => {
          this.$log.error(` Error getting Stripe messages from store: ${ex.message}`)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        messageList: state => state.stripe.messageList
      })
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign(this.serverParams, newProps)
        this.serverParams.columnFilters = Object.assign(this.serverParams.columnFilters, this.tableFilter.columnFilters)
        Object.keys(this.serverParams).forEach(
          key => (this.serverParams[key] === null || this.serverParams[key] === '') && delete this.serverParams[key]
        )
        if (this.serverParams.columnFilters) {
          Object.keys(this.serverParams.columnFilters).forEach(
            key =>
              (this.serverParams.columnFilters[key] === null || this.serverParams.columnFilters[key] === '') &&
              delete this.serverParams.columnFilters[key]
          )
        }

        this.$log.info(`updateParams serverParams: ${JSON.stringify(this.serverParams)}`)
      },
      onPageChange(params) {
        this.$log.info('onPageChange called', params)
        this.updateParams({ page: params.currentPage })
        this.loadItems()
      },
      onPerPageChange(params) {
        this.$log.info('onPerPageChange called', params)
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
        this.$store.dispatch('general/saveRowsPerPage', { name: 'StripeMsgsPP', value: params.currentPerPage })
      },
      onSortChange(params) {
        this.$log.info('onSortChange called', params)
        this.updateParams({
          sort: [
            {
              type: params[0].type,
              field: params[0].field
            }
          ]
        })
        this.loadItems()
      },
      onColumnFilter(params) {
        this.$log.info('onColumnFilter called', params)
        this.updateParams(params)
        this.updateParams({ page: 1 })
        this.loadItems()
      },
      loadItems() {
        // load items is what brings back the rows from server
        this.isLoading = true
        this.isLoadingMsg = 'Loading Stripe Messages'
        this.$store
          .dispatch('stripe/getStripeMsgs', this.serverParams)
          .then(response => {
            this.totalRecords = response.totalRecords
            this.rows = this.messageList
            this.isLoading = false
          })
          .catch(err => {
            this.$log.info('Error in loading stripe-msgs', err)
            this.loadingErrorMsg = err
          })
      },
      rowClick() {
        // This is only needed to get the row highlight
      }
    }
  }
</script>
