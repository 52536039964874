<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <!-- <div v-if="error" class="server-error">
      {{ error }}
    </div> -->

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Songs</h5>
      </div>
      <div class="col-2">
        <button @click="copyContent" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <vue-good-table
      mode="remote"
      @on-row-click="rowClick"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :key="tableKey"
      :totalRows="totalRecords"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
        position: 'top',
        mode: 'records'
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{ field: 'showTitle', type: 'asc' }]
      }"
      :rows="rows"
      :columns="columns"
      style-class="vgt-table musical bordered condensed"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Songs',
    mixins: [copyTable],
    data() {
      return {
        isMounting: true,
        loading: false,
        loadingMsg: '',
        loadingErrorMsg: '',
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          page: 1,
          perPage: 10
        },
        rows: [],
        columns: [
          {
            label: 'Song Title',
            field: 'title',
            type: 'string',
            width: '23%',
            sortable: true,
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by title' // placeholder for filter input
            }
          },
          {
            label: 'Show Title',
            field: 'showTitle',
            type: 'string',
            width: '23%',
            sortable: true,
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by show title' // placeholder for filter input
            }
          },
          {
            label: 'Composer',
            field: 'composerNames',
            type: 'string',
            width: '22%',
            sortable: true,
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by composer' // placeholder for filter input
            }
          },
          {
            label: 'Lyricist',
            field: 'lyricistNames',
            type: 'string',
            width: '22%',
            sortable: true,
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by Lyricist' // placeholder for filter input
            }
          },
          {
            label: 'Last Edited',
            field: 'date',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            width: '10%',
            sortable: true
          }
        ]
      }
    },
    created() {
      console.log('[Songs.vue] Songs component created')
      eventbus.$on('updateColumns', songListItem => {
        console.log('[Songs.vue] Got event updateColumns')
        this.updateColumns(songListItem)
      })
    },
    beforeDestroy() {
      eventbus.$off('updateColumns')
    },
    mounted() {
      this.$store.commit('general/setMenuHighlight', 'Songs')
      this.$store
        .dispatch('general/getRowsPerPage', 'songsPP')
        .then(value => {
          this.$log.info(`Created. Rows per page: ${value}`)
          this.serverParams.perPage = value
          this.updateParams({ page: 1, perPage: value })
          this.loadItems()
          this.isMounting = false
        })
        .catch(ex => {
          this.$log.info(`Exception in getRowsPerPage ${ex.message}`)
        })
    },
    computed: {
      ...mapState({
        loggedinUser: state => state.general.loggedinUser,
        tableKey: state => state.songs.tableKey,
        songsList: state => state.songs.songsList,
        composers: state => state.creators.composersList,
        lyricists: state => state.creators.lyricistsList,
        tagsList: state => state.glossary.tagsList
      })
    },
    methods: {
      updateColumns(song) {
        console.log('[Songs.vue] Adding icons to songs list')
        for (const listItem of this.songsList) {
          const hasFemaleComposer = listItem.hasFemaleComposer ? '👩🏻 ' : ''
          const hasBIPOCComposer = listItem.hasBIPOCComposer ? '👨🏾 ' : ''
          const hasFemaleLyricist = listItem.hasFemaleLyricist ? '👩🏻 ' : ''
          const hasBIPOCLyricist = listItem.hasBIPOCLyricist ? '👨🏾 ' : ''
          if (listItem.composers && Object.keys(listItem.composers).length > 0) {
            let composerNames = ''
            for (const c of Object.values(listItem.composers)) {
              // console.log(`Composer: ${c.firstName} ${c.lastName}`)
              composerNames += `${c.firstName} ${c.lastName}, `
            }
            listItem.composerNames =
              hasFemaleComposer + hasBIPOCComposer + composerNames.trim().substr(0, composerNames.length - 2)
          } else {
            listItem.composerNames = 'Various Composers'
          }

          if (listItem.lyricists && Object.keys(listItem.lyricists).length > 0) {
            let lyricistNames = ''
            for (const l of Object.values(listItem.lyricists)) {
              lyricistNames += `${l.firstName} ${l.lastName}, `
            }
            listItem.lyricistNames =
              hasFemaleLyricist + hasBIPOCLyricist + lyricistNames.trim().substr(0, lyricistNames.length - 2)
          } else {
            listItem.lyricistNames = 'Various Lyricists'
          }
        }
      },
      updateParams(newProps) {
        this.$log.info('updateParams', newProps)
        this.serverParams = Object.assign({}, this.serverParams, newProps)
        this.$log.info(`updateParams - newProps: ${JSON.stringify(newProps)}, serverParams: ${JSON.stringify(this.serverParams)}`)
      },
      onPageChange(params) {
        this.$log.info('onPageChange', params)
        this.updateParams({ page: params.currentPage })
        this.loadItems()
      },
      onPerPageChange(params) {
        this.$log.info('onPerPageChange', params)
        this.updateParams({ perPage: params.currentPerPage })
        // onPageChange is triggered from mounted() but we don't want to load songs here
        if (!this.isMounting) {
          this.loadItems()
        }
        this.$store.dispatch('general/saveRowsPerPage', { name: 'songsPP', value: params.currentPerPage })
      },
      onSortChange(params) {
        this.$log.info('onSortChange', params)
        this.updateParams({
          sort: [
            {
              type: params[0].type,
              field: params[0].field
            }
          ]
        })
        this.loadItems()
      },
      onColumnFilter(params) {
        this.$log.info('onColumnFilter', params)
        this.updateParams(params)
        this.updateParams({ page: 1 })
        this.loadItems()
        // this.$store.commit('songs/setTableKey')
      },
      loadItems() {
        this.loading = true
        this.loadingMsg = 'Loading Songs'
        this.$log.info('loadItems')
        this.rows = []
        // load items is what brings back the rows from server
        this.$log.info('Getting songs from store')
        this.$store
          .dispatch('songs/getFromServer', this.serverParams)
          .then(response => {
            this.totalRecords = response.totalRecords
            for (const listItem of this.songsList) {
              const hasFemaleComposer = listItem.hasFemaleComposer ? '👩🏻 ' : ''
              const hasBIPOCComposer = listItem.hasBIPOCComposer ? '👨🏾 ' : ''
              const hasFemaleLyricist = listItem.hasFemaleLyricist ? '👩🏻 ' : ''
              const hasBIPOCLyricist = listItem.hasBIPOCLyricist ? '👨🏾 ' : ''
              if (listItem.composers && Object.keys(listItem.composers).length > 0) {
                let composerNames = ''
                for (const c of Object.values(listItem.composers)) {
                  // console.log(`Composer: ${c.firstName} ${c.lastName}`)
                  composerNames += `${c.firstName} ${c.lastName}, `
                }
                listItem.composerNames =
                  hasFemaleComposer + hasBIPOCComposer + composerNames.trim().substr(0, composerNames.length - 2)
              } else {
                listItem.composerNames = 'Various Composers'
              }

              if (listItem.lyricists && Object.keys(listItem.lyricists).length > 0) {
                let lyricistNames = ''
                for (const l of Object.values(listItem.lyricists)) {
                  lyricistNames += `${l.firstName} ${l.lastName}, `
                }
                listItem.lyricistNames =
                  hasFemaleLyricist + hasBIPOCLyricist + lyricistNames.trim().substr(0, lyricistNames.length - 2)
              } else {
                listItem.lyricistNames = 'Various Lyricists'
              }
            }
            this.$emit('updateColumns')
            this.rows = this.songsList
            this.loading = false
            this.loadingMsg = ''
          })
          .catch(err => {
            this.$log.info('Error in loadItems', err)
            this.loadingErrorMsg = err
            this.loading = false
            this.loadingMsg = ''
          })
      },
      editClosed() {
        this.$modal.hide('new-edit-song')
      },
      rowClick(params) {
        this.$log.debug('Songs: getting song with rowid', params.row._id)
        // this.$modal.show('new-edit-song')
        this.$store.dispatch('songs/getSong', params.row._id)
        eventbus.$emit('modal', { command: 'open', name: 'new-edit-song' })
      }
    }
  }
</script>
