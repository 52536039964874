<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Composers</h5>
      </div>
      <div class="col-2">
        <button @click="newComposer" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add Composer
        </button>
        <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :row-style-class="rowStyleClassFn"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        :line-numbers="false"
        @on-cell-click="cellClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: initialSortField, type: initialSortType }
        }"
        style-class="vgt-table musical bordered condensed"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'isBIPOC'">
            <input
              type="checkbox"
              v-model="props.row.isBIPOC"
              @click.ctrl="copyToLyricists = false"
              @change="changeIsBipoc($event, props.row._id)"
            />
          </span>
          <span v-if="props.column.field === 'isFemale'">
            <input
              type="checkbox"
              v-model="props.row.isFemale"
              @click.ctrl="copyToLyricists = false"
              @change="changeIsfemale($event, props.row._id)"
            />
          </span>
          <span v-if="props.column.field !== 'isBIPOC' && props.column.field !== 'isFemale'">
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <div slot="table-actions">
          <input type="checkbox" v-model="showOnlyFeatured" @change="onlyFeatured" />
          <i style="padding-left:5px;">Featured Composers (check this box to see only featured composers)</i>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Composers',
    mixins: [copyTable],
    data() {
      return {
        error: '',
        loading: true,
        loadingMsg: 'Loading Composers',
        copyToLyricists: true,
        initialSortField: 'lastName',
        initialSortType: 'asc',
        showOnlyFeatured: false,
        columns: [
          {
            label: 'First Name',
            field: 'firstName',
            width: '20%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by first name' // placeholder for filter input
            }
          },
          {
            label: 'Last Name',
            field: 'lastName',
            width: '20%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by last name' // placeholder for filter input
            }
          },
          {
            label: 'Info URL',
            field: 'infoUrl',
            width: '30%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by words in URL' // placeholder for filter input
            }
          },
          {
            field: 'isFemale',
            label: 'Women/Nonbinary',
            width: '10%',
            type: 'boolean'
          },
          {
            field: 'isBIPOC',
            label: 'Global Majority',
            width: '10%',
            type: 'boolean'
          },
          {
            label: 'Last Edited',
            field: 'date',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            width: '10%',
            filterOptions: {
              enabled: false // enable filter for this column
            },
            sortable: true
          }
        ],
        rows: this.composers || [],
        apiError: '',
        attemptSubmit: false
      }
    },
    mounted() {
      console.log('Composers component mounted')
      this.$store.commit('general/setMenuHighlight', 'Composers')
      this.$store.dispatch('creators/getLyricists')
      this.$store
        .dispatch('creators/getComposers')
        .then(() => {
          this.rows = this.composers
          this.$store.dispatch('creators/getFeaturedComposers')
          this.loading = false
        })
        .catch(ex => {
          console.error('Exception in mounted - getComposers', ex.message)
          this.error = `Exception in mounted - getComposers: ${ex.message}`
        })
    },
    computed: {
      ...mapState({
        // loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        // error: state => state.general.error,
        // loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        filteredComposers: state => state.creators.filteredComposersList,
        composers: state => state.creators.composersList,
        currentComposer: state => state.creators.currentComposer,
        currentLyricist: state => state.creators.currentLyricist,
        lyricists: state => state.creators.lyricistsList
      })
    },
    methods: {
      changeIsfemale(event, id) {
        console.log('isFemale changed', event.target.checked)
        const composer = this.composers.find(c => c._id === id)
        composer.isFemale = event.target.checked
        this.$store.dispatch('creators/updateComposer', composer)
        if (this.copyToLyricists) {
          console.log(`Also looking for lyricist with name ${composer.firstName} ${composer.lastName}`)
          const lyricist = this.lyricists.find(l => l.firstName === composer.firstName && l.lastName === composer.lastName)
          if (lyricist) {
            console.log(`Also found lyricist with name ${lyricist.firstName} ${lyricist.lastName}`)
            lyricist.isFemale = event.target.checked
            this.$store.dispatch('creators/updateLyricist', lyricist)
          }
        }
        this.copyToLyricists = true
      },
      changeIsBipoc(event, id) {
        console.log('isBIPOC changed', event.target.checked)
        const composer = this.composers.find(c => c._id === id)
        composer.isBIPOC = event.target.checked
        this.$store.dispatch('creators/updateComposer', composer)
        if (this.copyToLyricists) {
          console.log(`Also looking for lyricist with name ${composer.firstName} ${composer.lastName}`)
          const lyricist = this.lyricists.find(l => l.firstName === composer.firstName && l.lastName === composer.lastName)
          if (lyricist) {
            console.log(`Also found lyricist with name ${lyricist.firstName} ${lyricist.lastName}`)
            lyricist.isBIPOC = event.target.checked
            this.$store.dispatch('creators/updateLyricist', lyricist)
          }
        }
        this.copyToLyricists = true
      },
      rowStyleClassFn(row) {
        if (row.isFemale && !row.isBIPOC) {
          return 'isFemale'
        } else if (!row.isFemale && row.isBIPOC) {
          return 'isBIPOC'
        } else if (row.isFemale && row.isBIPOC) {
          return 'isBoth'
        } else {
          return ''
        }
      },
      cellClick(params) {
        if (params.column.label !== 'Women/Nonbinary' && params.column.label !== 'Global Majority') {
          this.attemptSubmit = false
          console.log('Composers: getting composer with rowid', params.row._id)
          this.$store.dispatch('creators/getComposer', params.row._id)
          .then(result => {
            console.debug('Got composer', this.currentComposer)
            eventbus.$emit('modal', { command: 'open', name: 'composer-edit' })
          })
          const rows = document.querySelectorAll('.clickable')
          rows.forEach((row, rowIndex) => {
            const a = rows[rowIndex]
            a.classList.remove('clicked')
            if (params.pageIndex === rowIndex) {
              a.classList.add('clicked')
            }
          })
        }
      },
      onlyFeatured() {
        if (this.showOnlyFeatured) {
          this.$store.commit('creators/setFilteredComposersList')
          this.rows = this.filteredComposers
        } else {
          this.rows = this.composers
        }
      },
      newComposer() {
        this.$store.commit('creators/setNewComposer')
        this.attemptSubmit = false
        eventbus.$emit('modal', { command: 'open', name: 'composer-edit' })
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
      }
    }
  }
</script>

<style>
  .isFemale {
    background-color: pink;
  }
  .isBIPOC {
    background-color: burlywood;
  }
  .isBoth {
    background-color: gold;
  }
</style>
