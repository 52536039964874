<template>
  <div class="container">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ '' }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <!-- <div v-if="error" class="error">
      {{ error }}
    </div> -->

    <modal
      @opened="checkErrors()"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="song-edit-partial"
      height="auto"
      width="1200"
      :scrollable="true"
      transition="pop-out"
      :click-to-close="false"
    >
      <div class="show-form" style="margin:30px;">
        <h4>Edit Song common properties for show "{{ showTitle }}"</h4>
        <div class="important">
          <p>
            Any changes made on this form will permanently overwrite the data for the selected songs. Leave fields blank if you do
            not want to update them for all of the selected songs.
          </p>
        </div>
        <div class="songs-to-edit">
          Editing the following songs:
        </div>
        <p style="margin-left:10px;">
          {{ songsTitles }}
        </p>

        <hr />

        <div class="row" style="margin-top:30px;">
          <div class="offset-sm-1 col-10">
            <!-- Popularity -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="composers">Popularity</label>
              </div>
              <div class="col-6">
                <v-select
                  id="composers"
                  name="composers"
                  v-model="popularity"
                  :options="[
                    { label: 'Very popular', value: 1 },
                    { label: 'Somewhat popular', value: 2 },
                    { label: 'Heard occassionally', value: 3 },
                    { label: 'Not heard a lot', value: 4 },
                    { label: 'Rare find', value: 5 }
                  ]"
                />
              </div>
            </div>

            <!-- Composers -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="composers">Composer(s)</label>
              </div>
              <div class="col-6">
                <v-select
                  multiple
                  id="composers"
                  name="composers"
                  v-model="selectedComposers"
                  :options="composers"
                />
                <p class="note-song-edit">
                  Format: Last name, First name
                </p>
              </div>
            </div>

            <!-- Lyricists -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="lyricists">Lyricist(s)</label>
              </div>
              <div class="col-6">
                <v-select
                  multiple
                  id="lyricists"
                  name="lyricists"
                  v-model="selectedLyricists"
                  :options="lyricists"
                />
                <p class="note-song-edit">
                  Format: Last name, First name
                </p>
              </div>
            </div>

            <!-- Year Written -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="showyearWritten">Year written</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control" id="showyearWritten" v-model="yearWritten" name="showyearWritten" />
              </div>
            </div>

            <!-- Founders Pick -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="foundersPick">Founders pick</label>
              </div>
              <div class="col-6">
                <input type="radio" name="foundersPick" v-model="foudersPick" value="true" checked="false" />
                <label for="radio1">Yes </label>
                <input type="radio" name="foundersPick" v-model="foudersPick" value="false" checked="false" />
                <label for="radio1">No </label>
              </div>
            </div>
          </div>
        </div>

        <h6 class="song-edit">
          External links
        </h6>
        <hr />
        <p>
          <b>NOTE:</b> This form cannot be used to disable external links, only to change them. Links for which you leave the
          checkbox unchecked will simply be left as they are.
        </p>
        <div>
          <div class="row">
            <h6>Scores</h6>
          </div>
          <external-links
            :label="'MusicNotes.com'"
            :link-name="'MusicNotes.com'"
            :base-url="'https://www.musicnotes.com/search/go?w='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelScoresMusicNotes"
          />
          <external-links
            :label="'SheetMusicPlus.com'"
            :link-name="'SheetMusicPlus'"
            :base-url="'https://www.sheetmusicplus.com/search?Ntt='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelScoresSheetMusic"
          />
          <external-links
            :label="'NewMusicalTheatre.com'"
            :link-name="'NewMusicalTheatre'"
            :base-url="'http://newmusicaltheatre.com/catalogsearch/result/?q='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelScoresNewMusicalTheatre"
          />
          <external-links
            :label="'Amazon.com'"
            :link-name="'Amazon'"
            :base-url="'https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=%22sheet+music%22+'"
            :songs="songs"
            :show-title="showTitle"
            :model="modelScoresAmazon"
          />
          <external-links
            :label="'WorldCat (library holdings)'"
            :link-name="'WorldCat'"
            :base-url="'http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amsscr&q=ti%3A'"
            :songs="songs"
            :show-title="showTitle"
            :model="modelScoresWorldCat"
          />
          <external-links
            :label="'ContemporaryMusicalTheatre.com'"
            :link-name="'ContemporaryMusicalTheatre'"
            :base-url="'https://www.contemporarymusicaltheatre.com/'"
            :songs="songs"
            :show-title="showTitle"
            :model="modelScoresContemporaryMusicalTheatre"
          />
          <custom-link :label="'Custom Link'" :link-name="'Custom'" :songs="songs" :model="modelScoresCustom" />
        </div>
        <div>
          <div class="row">
            <h6>Recordings</h6>
          </div>
          <external-links
            :label="'Amazon.com'"
            :link-name="'Amazon-rec'"
            :base-url="'https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=music+'"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsAmazon"
          />
          <external-links
            :label="'iTunes'"
            :link-name="'itunes-rec'"
            :base-url="'https://fnd.io/#/us/search?mediaType=music&term='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsiTunes"
          />
          <external-links
            :label="'Spotify'"
            :link-name="'spotify-rec'"
            :base-url="'https://open.spotify.com/search/'"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsSpotify"
          />
          <external-links
            :label="'Soundcloud'"
            :link-name="'soundcloud-rec'"
            :base-url="'https://soundcloud.com/search?q='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsSoundcLoud"
          />
          <external-links
            :label="'NewMusicalTheatre.com'"
            :link-name="'NewMusicalTheatre-rec'"
            :base-url="'http://newmusicaltheatre.com/catalogsearch/result/?q='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsNwMusicalTheatre"
          />
          <external-links
            :label="'WorldCat (library holdings)'"
            :link-name="'WorldCat-rec'"
            :base-url="'http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amusic&q='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsWorldcat"
          />
          <external-links
            :label="'ContemporaryMusicalTheatre.com'"
            :link-name="'ContemporaryMusicalTheatre-rec'"
            :base-url="'https://www.contemporarymusicaltheatre.com/'"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsContemporaryMusicalTheatre"
          />
          <external-links
            :label="'Gilbert and Sullivan Archive'"
            :link-name="'GilbertandSullivanArchive-rec'"
            :base-url="'http://search.freefind.com/find.html?id=2743017&pid=r&mode=ALL&n=0&submit=+Find%21+&query='"
            :songs="songs"
            :show-title="showTitle"
            :model="modelRecordingsGilbert"
          />
          <custom-link :label="'Custom Link'" :link-name="'Custom-rec'" :songs="songs" :model="modelRecordingsCustom" />
        </div>
        <hr />
        <!-- The Buttons -->
        <div v-if="apiError" class="row form-row">
          <div class="offset-sm-4 col-7">
            <span class="api-error">{{ apiError }}</span>
          </div>
        </div>
        <div class="row form-row">
          <div class="offset-sm-4 col-7">
            <button @click="closeForm" class="btn btn-outline-secondary btn-sm">
              Cancel
            </button>
            <button @click="save" class="btn btn-outline-success btn-sm">
              Save
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  // Add websockets
  import ExternalLinks from '../components/ExternalLinks.vue'
  import CustomLink from '../components/CustomLink.vue'
  import { mapState } from 'vuex'
  import { compLyrc } from '../mixins/newCompLyrc.js'
  export default {
    mixins: [compLyrc],
    data() {
      return {
        apiError: '',
        foudersPick: undefined,
        popularity: undefined,
        selectedComposers: [],
        selectedLyricists: [],
        yearWritten: null,
        modelScoresMusicNotes: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelScoresSheetMusic: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelScoresNewMusicalTheatre: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelScoresAmazon: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelScoresWorldCat: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelScoresContemporaryMusicalTheatre: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelScoresCustom: { active: false, manualLink: '', siteName: '' },
        modelRecordingsAmazon: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsiTunes: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsSpotify: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsSoundcLoud: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsNwMusicalTheatre: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsWorldcat: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsContemporaryMusicalTheatre: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsGilbert: { active: false, type: 'automatic', includeTitle: false, manualLink: '' },
        modelRecordingsCustom: { active: false, manualLink: '', siteName: '' }
      }
    },
    components: { ExternalLinks, CustomLink },
    props: ['songs', 'songsTitles', 'showTitle', 'onClosed', 'allSongs', 'showId'],
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        composers: state => state.creators.composersList,
        lyricists: state => state.creators.lyricistsList
      })
    },
    methods: {
      beforeOpen() {
        console.log('[Song Edit Common] beforeOpen called')
        document.addEventListener('keyup', this.close)
      },
      beforeClose() {
        console.log('[Song Edit Common] beforeClose called')
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          console.log('[Song Edit Common] escape pressed')
          // this.$modal.hide('song-edit-partial')
          // eventbus.$emit('edit-song-closed')
          this.onClosed()
        }
      },
      save() {
        const editedProperties = {}
        if (this.foudersPick) {
          editedProperties.isRecommended = this.foudersPick === 'true'
        }
        if (this.popularity) {
          editedProperties.popularity = this.popularity.value
        }
        if (this.selectedComposers.length > 0) {
          editedProperties.selectedComposers = this.selectedComposers
          for (const comp of this.selectedComposers){
            if (comp.isFemale) {
              editedProperties.hasFemaleComposer = true
            }
            if (comp.isBIPOC) {
              editedProperties.hasBIPOCComposer = true
            }
          }
        }
        if (this.selectedLyricists.length > 0) {
          editedProperties.selectedLyricists = this.selectedLyricists
          for (const lyrc of this.selectedLyricists){
            if (lyrc.isFemale) {
              editedProperties.hasFemaleLyricist = true
            }
            if (lyrc.isBIPOC) {
              editedProperties.hasBIPOCLyricist = true
            }
          }
        }
        if (this.yearWritten) {
          editedProperties.yearWritten = this.yearWritten
        }
        if (this.modelScoresMusicNotes.active) {
          editedProperties['externalLinks.scores.musicNotes'] = {
            id: 'musicNotes',
            siteName: 'MusicNotes.com',
            enabled: true,
            includeSongTitle: this.modelScoresMusicNotes.includeTitle,
            automatic: this.modelScoresMusicNotes.type === 'automatic',
            url: this.modelScoresMusicNotes.manualLink
          }
          if (!this.modelScoresMusicNotes.manualLink) {
            delete editedProperties['externalLinks.scores.musicNotes'].url
          }
        }
        if (this.modelScoresSheetMusic.active) {
          editedProperties['externalLinks.scores.sheetMusicPlus'] = {
            id: 'sheetMusicPlus',
            siteName: 'SheetMusicPlus.com',
            enabled: true,
            includeSongTitle: this.modelScoresSheetMusic.includeTitle,
            automatic: this.modelScoresSheetMusic.type === 'automatic',
            url: this.modelScoresSheetMusic.manualLink
          }
          if (!this.modelScoresSheetMusic.manualLink) {
            delete editedProperties['externalLinks.scores.sheetMusicPlus'].url
          }
        }
        if (this.modelScoresNewMusicalTheatre.active) {
          editedProperties['externalLinks.scores.newMusicalTheatre'] = {
            id: 'newMusicalTheatre',
            siteName: 'NewMusicalTheatre.com',
            enabled: true,
            includeSongTitle: this.modelScoresNewMusicalTheatre.includeTitle,
            automatic: this.modelScoresNewMusicalTheatre.type === 'automatic',
            url: this.modelScoresNewMusicalTheatre.manualLink
          }
          if (!this.modelScoresNewMusicalTheatre.manualLink) {
            delete editedProperties['externalLinks.scores.newMusicalTheatre'].url
          }
        }
        if (this.modelScoresAmazon.active) {
          editedProperties['externalLinks.scores.amazon'] = {
            id: 'amazon-scores',
            siteName: 'Amazon.com',
            enabled: true,
            includeSongTitle: this.modelScoresAmazon.includeTitle,
            automatic: this.modelScoresAmazon.type === 'automatic',
            url: this.modelScoresAmazon.manualLink
          }
          if (!this.modelScoresAmazon.manualLink) {
            delete editedProperties['externalLinks.scores.amazon'].url
          }
        }
        if (this.modelScoresWorldCat.active) {
          editedProperties['externalLinks.scores.worldcat'] = {
            id: 'worldcat',
            siteName: 'WorldCat (library holdings)',
            enabled: true,
            includeSongTitle: this.modelScoresWorldCat.includeTitle,
            automatic: this.modelScoresWorldCat.type === 'automatic',
            url: this.modelScoresWorldCat.manualLink
          }
          if (!this.modelScoresWorldCat.manualLink) {
            delete editedProperties['externalLinks.scores.worldcat'].url
          }
        }
        if (this.modelScoresContemporaryMusicalTheatre.active) {
          editedProperties['externalLinks.scores.contemporaryMusicalTheatre'] = {
            id: 'contemporaryMusicalTheatre',
            siteName: 'ContemporaryMusicalTheatre.com',
            enabled: true,
            includeSongTitle: this.modelScoresContemporaryMusicalTheatre.includeTitle,
            automatic: this.modelScoresContemporaryMusicalTheatre.type === 'automatic',
            url: this.modelScoresContemporaryMusicalTheatre.manualLink
          }
          if (!this.modelScoresContemporaryMusicalTheatre.manualLink) {
            delete editedProperties['externalLinks.scores.contemporaryMusicalTheatre'].url
          }
        }
        if (this.modelScoresCustom.active) {
          editedProperties['externalLinks.scores.custom'] = {
            id: 'custom',
            siteName: this.modelScoresCustom.siteName,
            enabled: true,
            includeSongTitle: this.modelScoresCustom.includeTitle,
            automatic: this.modelScoresCustom.type === 'automatic',
            url: this.modelScoresCustom.manualLink
          }
          if (!this.modelScoresCustom.manualLink) {
            delete editedProperties['externalLinks.scores.custom'].url
          }
        }
        if (this.modelRecordingsAmazon.active) {
          editedProperties['externalLinks.recordings.amazon'] = {
            id: 'amazon-recordings',
            siteName: 'Amazon.com',
            enabled: true,
            includeSongTitle: this.modelRecordingsAmazon.includeTitle,
            automatic: this.modelRecordingsAmazon.type === 'automatic',
            url: this.modelRecordingsAmazon.manualLink
          }
          if (!this.modelRecordingsAmazon.manualLink) {
            delete editedProperties['externalLinks.recordings.amazon'].url
          }
        }
        if (this.modelRecordingsiTunes.active) {
          editedProperties['externalLinks.recordings.iTunes'] = {
            id: 'iTunes',
            siteName: 'iTunes',
            enabled: true,
            includeSongTitle: this.modelRecordingsiTunes.includeTitle,
            automatic: this.modelRecordingsiTunes.type === 'automatic',
            url: this.modelRecordingsiTunes.manualLink
          }
          if (!this.modelRecordingsiTunes.manualLink) {
            delete editedProperties['externalLinks.recordings.iTunes'].url
          }
        }
        if (this.modelRecordingsSpotify.active) {
          editedProperties['externalLinks.recordings.spotify'] = {
            id: 'spotify',
            siteName: 'Spotify',
            enabled: true,
            includeSongTitle: this.modelRecordingsSpotify.includeTitle,
            automatic: this.modelRecordingsSpotify.type === 'automatic',
            url: this.modelRecordingsSpotify.manualLink
          }
          if (!this.modelRecordingsSpotify.manualLink) {
            delete editedProperties['externalLinks.recordings.spotify'].url
          }
        }
        if (this.modelRecordingsSoundcLoud.active) {
          editedProperties['externalLinks.recordings.soundCloud'] = {
            id: 'soundCloud',
            siteName: 'SoundCloud',
            enabled: true,
            includeSongTitle: this.modelRecordingsSoundcLoud.includeTitle,
            automatic: this.modelRecordingsSoundcLoud.type === 'automatic',
            url: this.modelRecordingsSoundcLoud.manualLink
          }
          if (!this.modelRecordingsSoundcLoud.manualLink) {
            delete editedProperties['externalLinks.recordings.soundCloud'].url
          }
        }
        if (this.modelRecordingsWorldcat.active) {
          editedProperties['externalLinks.recordings.worldcat'] = {
            id: 'worldcat',
            siteName: 'WorldCat (library holdings)',
            enabled: true,
            includeSongTitle: this.modelRecordingsWorldcat.includeTitle,
            automatic: this.modelRecordingsWorldcat.type === 'automatic',
            url: this.modelRecordingsWorldcat.manualLink
          }
          if (!this.modelRecordingsWorldcat.manualLink) {
            delete editedProperties['externalLinks.recordings.worldcat'].url
          }
        }
        if (this.modelRecordingsContemporaryMusicalTheatre.active) {
          editedProperties['externalLinks.recordings.contemporaryMusicalTheatre'] = {
            id: 'contemporaryMusicalTheatre',
            siteName: 'Contemporarymusicaltheatre.com',
            enabled: true,
            includeSongTitle: this.modelRecordingsContemporaryMusicalTheatre.includeTitle,
            automatic: this.modelRecordingsContemporaryMusicalTheatre.type === 'automatic',
            url: this.modelRecordingsContemporaryMusicalTheatre.manualLink
          }
          if (!this.modelRecordingsContemporaryMusicalTheatre.manualLink) {
            delete editedProperties['externalLinks.recordings.contemporaryMusicalTheatre'].url
          }
        }
        if (this.modelRecordingsGilbert.active) {
          editedProperties['externalLinks.recordings.gsarchive'] = {
            id: 'gsarchive',
            siteName: 'The Gilbert & Sullivan Archive',
            enabled: true,
            includeSongTitle: this.modelRecordingsGilbert.includeTitle,
            automatic: this.modelRecordingsGilbert.type === 'automatic',
            url: this.modelRecordingsGilbert.manualLink
          }
          if (!this.modelRecordingsGilbert.manualLink) {
            delete editedProperties['externalLinks.recordings.gsarchive'].url
          }
        }
        if (this.modelRecordingsCustom.active) {
          editedProperties['externalLinks.recordings.custom'] = {
            id: 'custom',
            siteName: this.modelRecordingsCustom.siteName,
            enabled: true,
            includeSongTitle: this.modelRecordingsCustom.includeTitle,
            automatic: this.modelRecordingsCustom.type === 'automatic',
            url: this.modelRecordingsCustom.manualLink
          }
          if (!this.modelRecordingsCustom.manualLink) {
            delete editedProperties['externalLinks.recordings.custom'].url
          }
        }
        // Set property for All Songs edited or not
        editedProperties.allSongs = this.allSongs
        if (this.allSongs) {
          // Set property for showId, for bulk update in API
          editedProperties.showId = this.showId
        } else {
          const songIds = []
          for (var song of this.songs) {
            songIds.push(song._id)
          }
          editedProperties.songIds = songIds
        }
        // No need to save if number of properties are less than 2, meaning nothing changed
        if (Object.keys(editedProperties).length > 2) {
          this.$store.dispatch('songs/saveCommon', editedProperties)
        }
        this.onClosed()
      },
      closeForm() {
        // Call onClosed on parent component, onClosed function is passed as prop
        this.onClosed()
      },
      checkErrors() {
        // Reset models on opening of modal dialog page
        this.foudersPick = undefined
        this.popularity = undefined
        this.selectedComposers = []
        this.selectedLyricists = []
        this.yearWritten = undefined
        this.modelScoresMusicNotes = { scores: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelScoresSheetMusic = { scores: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelScoresNewMusicalTheatre = { scores: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelScoresAmazon = { scores: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelScoresWorldCat = { scores: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelScoresContemporaryMusicalTheatre = { scores: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelScoresCustom = { scores: false, manualLink: '', siteName: '' }
        this.modelRecordingsAmazon = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsiTunes = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsSpotify = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsSoundcLoud = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsNwMusicalTheatre = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsWorldcat = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsContemporaryMusicalTheatre = {
          recordings: false,
          type: 'automatic',
          includeTitle: false,
          manualLink: ''
        }
        this.modelRecordingsGilbert = { recordings: false, type: 'automatic', includeTitle: false, manualLink: '' }
        this.modelRecordingsCustom = { recordings: false, manualLink: '', siteName: '' }
      }
    }
  }
</script>
