export const copyTable = {
         methods: {
           // copyContent() {
           //   const urlField = document.querySelector('#vgt-table')
           //   const range = document.createRange()
           //   range.selectNode(urlField)
           //   window.getSelection().addRange(range)
           //   document.execCommand('copy')
           // }
           copyContent() {
             const selection = window.getSelection()
             const urlField = document.querySelector('#vgt-table')
             const range = document.createRange()
             range.selectNodeContents(urlField)
             selection.removeAllRanges()
             selection.addRange(range)
             document.execCommand('copy')
           }
         }
       }
