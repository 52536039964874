<template>
  <div>
    <div class="row">
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age1" v-model="currentSong.u13" />
        <label for="age1" class="css-label lite-red-check">Under 13</label>
      </div>
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age2" v-model="currentSong.a2532" />
        <label for="age2" class="css-label lite-red-check">25-32</label>
      </div>
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age3" v-model="currentSong.a60" />
        <label for="age3" class="css-label lite-red-check">60+</label>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age4" v-model="currentSong.a1417" />
        <label for="age4" class="css-label lite-red-check">14-17</label>
      </div>
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age5" v-model="currentSong.a3339" />
        <label for="age5" class="css-label lite-red-check">33-39</label>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age6" v-model="currentSong.a1824" />
        <label for="age6" class="css-label lite-red-check">18-24</label>
      </div>
      <div class="col-4">
        <input type="checkbox" name="age" @change="ageClicked" class="css-checkbox" id="age7" v-model="currentSong.a4059" />
        <label for="age7" class="css-label lite-red-check">40-59</label>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        newAges: []
      }
    },
    props: ['currentSong'],
    methods: {
      ageClicked() {
        this.newAges = []
        if (this.currentSong.u13) this.newAges.push('Under 13')
        if (this.currentSong.a1417) this.newAges.push('14-17')
        if (this.currentSong.a1824) this.newAges.push('18-24')
        if (this.currentSong.a2532) this.newAges.push('25-32')
        if (this.currentSong.a3339) this.newAges.push('33-39')
        if (this.currentSong.a4059) this.newAges.push('40-59')
        if (this.currentSong.a60) this.newAges.push('60+')
        this.currentSong.ageRanges = this.newAges
      }
    }
  }
</script>
