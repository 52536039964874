var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.enabled,
              expression: "enabled"
            }
          ],
          staticClass: "css-checkbox",
          attrs: {
            type: "checkbox",
            name: _vm.linkName + _vm.type,
            id: _vm.linkName + _vm.type
          },
          domProps: {
            checked: Array.isArray(_vm.enabled)
              ? _vm._i(_vm.enabled, null) > -1
              : _vm.enabled
          },
          on: {
            change: function($event) {
              var $$a = _vm.enabled,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.enabled = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.enabled = $$c
              }
            }
          }
        }),
        _c(
          "label",
          {
            staticClass: "css-label lite-red-check",
            attrs: { for: _vm.linkName + _vm.type }
          },
          [_vm._v("Custom")]
        )
      ])
    ]),
    _vm.enabled
      ? _c("div", { staticClass: "custom-link" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "label",
                { attrs: { for: _vm.linkName + _vm.type + "radio" } },
                [_vm._v("Site Name ")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.linkName,
                    expression: "linkName"
                  }
                ],
                attrs: { type: "text", placeholder: "" },
                domProps: { value: _vm.linkName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.linkName = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "label",
                { attrs: { for: _vm.linkName + _vm.type + "radio" } },
                [_vm._v("URL")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.url,
                    expression: "url"
                  }
                ],
                attrs: { type: "text", placeholder: "http://" },
                domProps: { value: _vm.url },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.url = $event.target.value
                  }
                }
              }),
              _vm.url
                ? _c(
                    "span",
                    { staticClass: "link", on: { click: _vm.testManualScore } },
                    [_vm._v("Test Link")]
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }