import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    messageList: []
  },
  mutations: {
    setMessageList: (state, payload) => {
      state.messageList = payload
    }
  },
  actions: {
    getStripeMsgs: async ({ state, commit, rootState }, serverParams) => {
      console.log(`ServerParams: ${JSON.stringify(serverParams)}`)
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .post(rootState.general.apiRoot + '/api/stripemsgs', serverParams, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting Stripe messages from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting Stripe messages from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          Vue.$log.info(`Total received: ${result.data.count}`)
          commit('setMessageList', result.data.msgs)
          resolve({ totalRecords: result.data.count })
        } else {
          resolve({ totalRecords: 0, rows: [] })
        }
      })
    }
  }
}
