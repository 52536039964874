import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    tagsList: [],
    currentTag: {}
  },
  mutations: {
    setCurrentTag: (state, payload) => {
      state.currentTag = payload
    },
    setNewTag: state => {
      state.currentTag = {
        _id: undefined,
        term: '',
        definition: ''
      }
    },
    setTagsList: (state, payload) => {
      state.tagsList = payload
    },
    setRenameTag: (state, payload) => {
      state.tagToRename = payload
    }
  },
  actions: {
    getTags: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.tagsList.length === 0) {
          rootState.general.error = ''
          rootState.general.loading = true
          rootState.general.loadingMsg = 'Loading Tags/Glossary'
          console.log('Loading Tags/Glossary')
          Vue.http.get('api/tags').then(
            response => {
              commit('setTagsList', response.body)
              rootState.general.loading = false
              rootState.general.loadingMsg = ''
              resolve('tags/glossary')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('tags/glossary')
        }
      })
    },
    saveTag: ({ commit, rootState }, tag) => {
      tag.definition = tag.definition.replace('<p>', '').replace('</p>', '')
      console.log(`Tag definition: ${tag.definition}`)
      Vue.http.post('api/tags', { tag }).then(
        response => {
          console.log('Got reply from saving tag', response)
          // Set current creator to empty item
          // because when on any other page then first, this creator has been moved to
          // 1st record in table when date field is the sort field (default)
          commit('setNewTag')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    deleteTag: ({ commit, rootState }, tagId) => {
      Vue.http.delete('api/tags/' + tagId).then(
        response => {
          console.log('Got reply from deleting tag', response)
          commit('setNewTag')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    renameTag: ({ commit, rootState }, params) => {
      Vue.http.post('api/tags/rename', { params }).then(
        response => {
          console.log('Got reply from renaming tag', response)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    }
  }
}
