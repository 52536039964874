import { defineAbility } from '@casl/ability'

export default function(role) {
  if (role === 'admin') {
    return defineAbility(can => {
      can('view', ['all'])
      can('create', ['all'])
      can('read', ['all'])
      can('update', ['all'])
      can('delete', ['all'])
    })
  }
  if (role === 'groupadmin') {
    return defineAbility(can => {
      can('view', ['groupusers', 'login'])
      can('create', ['groupusers'])
      can('read', ['groupusers'])
      can('update', ['groupusers'])
      can('delete', ['groupusers'])
    })
  }
  if (role === 'creator') {
    return defineAbility(can => {
      can('view', ['shows', 'songs'])
      can('create', ['shows', 'songs', 'composers', 'lyricists'])
      can('read', ['shows', 'songs', 'settings', 'composers', 'lyricists', 'settings', 'tags', 'faq', 'comments'])
      can('update', ['shows', 'songs'])
      can('delete', ['shows', 'songs'])
    })
  }
  if (role === 'subscriber') {
    return defineAbility(can => {
      can('view', ['nothing'])
      can('create', ['nothing'])
      can('read', ['nothing'])
      can('update', ['nothing'])
      can('delete', ['nothing'])
    })
  }
  if (role === 'marketingAssistant') {
    return defineAbility(can => {
      can('view', ['subscriptions', 'users', 'emails'])
      can('create', ['subscriptions', 'users'])
      can('read', ['subscriptions', 'users', 'settings', 'emails'])
      can('update', ['subscriptions', 'users', 'emails'])
      can('delete', ['subscriptions'])
    })
  }
  if (role === 'dataAssistant') {
    return defineAbility(can => {
      can('view', ['shows', 'songs', 'glossary', 'composers', 'lyricists', 'tags', 'faq', 'comments'])
      can('create', ['shows', 'songs', 'glossary', 'composers', 'lyricists', 'tags', 'faq', 'comments'])
      can('read', ['shows', 'songs', 'composers', 'glossary', 'lyricists', 'settings', 'tags', 'faq', 'comments'])
      can('update', ['shows', 'songs', 'composers', 'glossary', 'lyricists', 'tags', 'faq', 'comments'])
      can('delete', ['nothing'])
    })
  }
  return defineAbility(can => {
    can('view', ['nothing'])
    can('create', ['nothing'])
    can('read', ['nothing'])
    can('update', ['nothing'])
    can('delete', ['nothing'])
  })
}
