var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "below-menu" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [
          _c("img", { attrs: { src: "/loading.gif" } }),
          _c("div", { staticClass: "loading" }, [
            _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
          ])
        ])
      : _vm._e(),
    _vm.error
      ? _c("div", { staticClass: "server-error" }, [
          _vm._v(" " + _vm._s(_vm.error) + " ")
        ])
      : _vm._e(),
    _c("div", { staticClass: "row fixed-top page-header" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-8" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            staticStyle: { "margin-left": "10px" },
            on: { click: _vm.copyContent }
          },
          [_vm._v(" Copy ")]
        ),
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            on: { click: _vm.newCoupon }
          },
          [_vm._v(" Add Coupon ")]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newCouponText,
              expression: "newCouponText"
            }
          ],
          staticClass: "float-right",
          staticStyle: { "margin-right": "5px" },
          attrs: { type: "text" },
          domProps: { value: _vm.newCouponText },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.newCouponText = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm.data
      ? _c(
          "div",
          [
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.rows,
                "pagination-options": { enabled: false },
                "search-options": { enabled: false },
                "sort-options": {
                  enabled: true,
                  initialSortBy: { field: "code", type: "asc" }
                },
                "line-numbers": false,
                "style-class": "vgt-table musical bordered condensed"
              },
              on: { "on-row-click": _vm.rowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field === "delete"
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteCoupon(props.row)
                                    }
                                  }
                                },
                                [_vm._v("Delete Coupon")]
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ],
                null,
                false,
                85534064
              )
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4" }, [
      _c("h5", [_vm._v("Institutional Coupon Codes")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }