<template>
  <div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" :name="linkName + type" :id="linkName + type" class="css-checkbox" v-model="enabled" />
        <label :for="linkName + type" class="css-label lite-red-check">Custom</label>
      </div>
    </div>
    <div class="custom-link" v-if="enabled">
      <div class="row">
        <div class="col-12">
          <label :for="linkName + type + 'radio'">Site Name </label>
          <input type="text" v-model="linkName" placeholder="" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label :for="linkName + type + 'radio'">URL</label>
          <input type="text" v-model="url" placeholder="http://" />
          <span @click="testManualScore" v-if="url" class="link">Test Link</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['model', 'type'],
    computed: {
      linkName: function() {
        const name = this.model.externalLinks[this.type].custom.siteName
        return name
      },
      enabled: {
        get: function() {
          return this.model.externalLinks[this.type].custom.enabled
        },
        set: function(val) {
          this.model.externalLinks[this.type].custom.enabled = val
        }
      },
      url: {
        get: function() {
          return this.model.externalLinks[this.type].custom.url
        },
        set: function(val) {
          this.model.externalLinks[this.type].custom.url = val
        }
      }
    },
    methods: {
      testManualScore() {
        window.open(this.model.externalLinks[this.type].custom.url, '_blank')
      }
    }
  }
</script>
