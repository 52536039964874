var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { staticClass: "content-class" },
      [
        _c("main-menu"),
        _c("user-edit"),
        _c("subscription-edit"),
        _c("composer-edit"),
        _c("lyricist-edit"),
        _c("tag-edit"),
        _c("show-edit"),
        _c("song-edit"),
        _c(
          "modal",
          {
            attrs: {
              name: "socket-warning",
              height: "auto",
              width: "500",
              transition: "pop-out",
              "click-to-close": false
            }
          },
          [
            _vm._v(
              " Connection with server is interrupted, please wait or refresh page. "
            )
          ]
        ),
        _c("router-view", {
          key: _vm.$route.fullPath,
          staticClass: "below-menu"
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }