<template>
  <div class="row form-row">
    <div class="col-4 label">
      <label class="label" for="showWorldCat">WorldCat</label>
    </div>
    <!-- Scores -->
    <div class="col-6">
      <!-- The Scores warning when the urls for the songs are not the same -->
      <div v-if="checkWorldCatScoresWarning()">
        <b
          ><i
            >The WorldCat settings for this show are not the same for each song. If you change the settings here, they will
            overwrite the settings for individual songs.</i
          ></b
        ><br />
        <input type="checkbox" id="cbScoresWarningOverride" v-model="currentShow.worldcat.scoresOverride" />
        <label for="cbScoresWarningOverride" name="ScoresWarningOverrideLbl" class="override"
          >Yes, change the settings for ALL songs in this show.</label
        >
      </div>
      <div v-if="!checkWorldCatScoresWarning() || currentShow.worldcat.scoresOverride">
        <!-- No warning, show the Automatic and Manual Radio buttons -->
        <div>
          <input
            type="checkbox"
            class="css-checkbox"
            id="checkbox3"
            @click="currentShow.worldcat.scoresAutomatic = true"
            v-model="currentShow.worldcat.scoresEnabled"
          />
          <label for="checkbox3" name="checkbox3_lbl" class="css-label lite-red-check">Link to Score</label>
        </div>
        <div v-if="currentShow.worldcat.scoresEnabled" style="margin-left:25px;margin-bottom:20px;">
          <div>
            <input type="radio" id="radio1" name="score" :value="true" v-model="currentShow.worldcat.scoresAutomatic" />
            <label for="radio1"
              >Automatic Link
              <span @click="testAutoScore" v-if="currentShow.worldcat.scoresAutomatic" class="link">Test Link</span>
            </label>
          </div>
          <div>
            <input type="radio" id="radio2" name="score" :value="false" v-model="currentShow.worldcat.scoresAutomatic" />
            <label for="radio2"
              >Manual Link
              <span @click="testManualScore" v-if="!currentShow.worldcat.scoresAutomatic" class="link">Test Link</span>
            </label>
            <div>
              <input
                type="text"
                placeholder="http://"
                v-if="!currentShow.worldcat.scoresAutomatic"
                v-model="currentShow.worldcat.scoresSameUrl"
                style="width:100%;"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Recording -->
      <div v-if="checkWorldCatRecordingsWarning()">
        <p>
          <b
            ><i
              >The WorldCat <u>recording</u> settings for this show are not the same for each song. If you change the settings
              here, they will overwrite the settings for individual songs.</i
            ></b
          >
        </p>
        <input type="checkbox" id="cbScoresWarningOverride" v-model="currentShow.worldcat.recordingsOverride" />
        <label for="cbScoresWarningOverride" name="ScoresWarningOverrideLbl" class="override"
          >Yes, change the settings for ALL recordings in this show.</label
        >
      </div>
      <div v-if="!checkWorldCatRecordingsWarning() || currentShow.worldcat.recordingsOverride">
        <!-- No warning, show the Automatic and Manual Radio buttons -->
        <div>
          <input
            type="checkbox"
            class="css-checkbox"
            id="checkbox3Rec"
            @click="currentShow.worldcat.recordingsAutomatic = true"
            v-model="currentShow.worldcat.recordingsEnabled"
          />
          <label for="checkbox3Rec" name="checkbox3_lbl" class="css-label lite-red-check">Link to Recordings</label>
        </div>
        <div v-if="currentShow.worldcat.recordingsEnabled" style="margin-left:25px;margin-bottom:20px;">
          <div>
            <input
              type="radio"
              id="radio1Rec"
              name="recording"
              :value="true"
              v-model="currentShow.worldcat.recordingsAutomatic"
            />
            <label for="radio1Rec"
              >Automatic Link
              <span @click="testAutoRecording" v-if="currentShow.worldcat.recordingsAutomatic" class="link">Test Link</span>
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="radio2Rec"
              name="recording"
              :value="false"
              v-model="currentShow.worldcat.recordingsAutomatic"
            />
            <label for="radio2Rec"
              >Manual Link
              <span @click="testManualRecording" v-if="!currentShow.worldcat.recordingsAutomatic" class="link">Test Link</span>
            </label>
            <div>
              <input
                type="text"
                placeholder="http://"
                v-if="!currentShow.worldcat.recordingsAutomatic"
                v-model="currentShow.worldcat.recordingsSameUrl"
                style="width:100%;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        scoresOverride: false,
        recordingsOverride: false
      }
    },
    computed: {
      ...mapState({
        currentShow: state => state.shows.currentShow
      })
    },
    methods: {
      checkWorldCatScoresWarning() {
        if (!this.currentShow.worldcat.scoresAllSame) {
          return true
        } else {
          return false
        }
      },
      checkWorldCatRecordingsWarning() {
        if (!this.currentShow.worldcat.recordingsAllSame) {
          return true
        } else {
          return false
        }
      },
      testAutoScore() {
        const url = encodeURI(`"${this.currentShow.title}"`)
        const worldcat = 'http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amsscr&q=ti%3A'
        window.open(worldcat + url, '_blank')
      },
      testManualScore() {
        window.open(this.currentShow.worldcat.scoresSameUrl, '_blank')
      },
      testAutoRecording() {
        const url = encodeURI(`"${this.currentShow.title}"`)
        const worldcat = 'http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amusic&q=ti%3A'
        window.open(worldcat + url, '_blank')
      },
      testManualRecording() {
        window.open(this.currentShow.worldcat.recordingsSameUrl, '_blank')
      }
    }
  }
</script>
