<template>
  <div class="below-menu">
    <!-- <modal name="new-question" transition="pop-out" :width="modalWidth" :height="300" :clickToClose="false">
      <h3>Enter a new question</h3>
      <textarea style="margin-left:36px;margin-top:10px;" rows="8" cols="70" v-model="question"></textarea>
      <div class="text-right" style="margin-right:40px;margin-top:10px;">
        <button @click="cancel" class="btn btn-outline-secondary btn-sm">Cancel</button>
        <button @click="saveQuestion" class="btn btn-outline-success btn-sm">Save</button>
      </div>
    </modal>   -->

    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <div class="row fixed-top page-header">
      <div class="col-9">
        <h5>FAQs</h5>
      </div>
      <div class="col-3">
        <button @click="newQuestion" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add New Question
        </button>
        <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data" class="table-block">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'question', type: 'asc' }
        }"
        :line-numbers="false"
        @on-row-click="rowClick"
        style-class="vgt-table musical bordered condensed"
      >
        <!--  v-if="$can('delete', 'faq')" -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'delete'">
            <a href="#" v-if="$can('delete', 'faq')" @click="deleteQuestion(props.row.originalIndex)">delete question</a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <div class="row button-row">
      <div class="col-10">
        <div v-if="error" class="error-message text-right">
          {{ error }}
        </div>
        <div class="loading-message text-right">
          {{ loadingMsg }}
        </div>
      </div>
      <div class="col-2">
        <div class="text-right save-btn">
          <button class="btn btn-success btn-xs" @click="saveAnswer">
            Save Q &amp; A
          </button>
        </div>
      </div>
    </div>
    <div class="row form-row" style="min-height: 300px;">
      <div class="col-1 label">
        <label class="label required" for="question">question</label>
      </div>
      <div class="col-4">
        <!-- <tinymce v-if="data" id="d1" @editorChange="editorChanged()" :other_options="tinyOptions" v-model="answer"></tinymce> -->
        <quill-editor
          id="question"
          style="height: 200px !important;"
          v-model="curFaq.question"
          ref="myQuillEditor1"
          :options="editorOption"
          @ready="onEditorReady($event)"
        />
        <div :class="[attemptSubmit && missingQuestion ? 'is-not-valid' : 'is-valid']">
          Question is required.
        </div>
      </div>
      <div class="col-1 label">
        <label class="label required" for="bio">Answer</label>
      </div>
      <div class="col-5">
        <!-- <tinymce v-if="data" id="d1" @editorChange="editorChanged()" :other_options="tinyOptions" v-model="answer"></tinymce> -->
        <quill-editor
          id="answer"
          style="height: 200px !important;"
          v-model="curFaq.answer"
          ref="myQuillEditor2"
          :options="editorOption"
        />
        <div :class="[attemptSubmit && missingAnswer ? 'is-not-valid' : 'is-valid']">
          Answer is required.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { copyTable } from '../mixins/copyTable'
  const MODAL_WIDTH = 656
  export default {
    name: 'FAQ',
    mixins: [copyTable],
    data() {
      return {
        editorOption: {
          // some quill options
          modules: {
            toolbar: [
              'bold',
              'italic',
              'underline',
              { color: [] },
              { indent: '-1' },
              { indent: '+1' },
              { list: 'ordered' },
              { list: 'bullet' },
              'link',
              'clean',
              { header: [1, 2, 3, 4, 5, 6, false] },
              { size: ['small', false, 'large', 'huge'] }
            ]
          }
        },
        editor: {},
        attemptSubmit: false,
        okToSave: true,
        curFaq: { question: '', answer: '' },
        modalWidth: MODAL_WIDTH,
        qIndex: -1,
        columns: [
          {
            label: 'Question',
            field: 'question',
            width: '90%',
            filterable: true,
            filter: this.tableSearch
          },
          {
            label: '',
            field: 'delete',
            width: '10%'
          }
        ],
        rows: []
      }
    },
    created() {
      this.$store.commit('general/setMenuHighlight', 'FAQ')
      this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH
    },
    mounted() {
      console.log('Faqs component mounted')
      this.$store
        .dispatch('faqs/getFaqs')
        .then(() => {
          this.rows = this.faqsList
        })
        .catch(ex => {
          console.error('Exception in mounted - get Faqs', ex.message)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        faqsList: state => state.faqs.faqsList
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingQuestion || this.missingAnswer
        } else {
          return false
        }
      },
      missingQuestion() {
        return this.curFaq.question === ''
      },
      missingAnswer() {
        return this.curFaq.answer === ''
      }
    },
    methods: {
      newQuestion() {
        this.curFaq = { question: '', answer: '' }
        this.editor.focus()
        this.attemptSubmit = false
        // document.getElementById('question').focus()
      },
      deleteQuestion(index) {
        const q = this.faqsList[index]
        if (confirm(`Are you sure you want to delete the question "${q.question}"?\n\nThis action cannot be undone.`)) {
          // Delete from server first
          this.$store
            .dispatch('faqs/deleteFaq', q._id)
            .then(result => {
              // Question not yet saved
              if (!q._id) {
                const qindex = this.faqsList.findIndex(f => f.question === q.question)
                this.faqsList.splice(qindex, 1)
              } else {
                this.faqsList.splice(index, 1)
              }
              setTimeout(() => {
                document.querySelector('.clickable').click()
              }, 300)
            })
            .catch(err => {
              this.$log.warn('Error in delete FAQ:', err)
              this.loadingErrorMsg = err
            })
        }
      },
      saveAnswer() {
        // Get answer and question
        const answer = document.getElementById('answer').children[1].children[0].innerHTML
        const question = document.getElementById('question').children[1].children[0].innerText
        const faq = {
          _id: this.curFaq._id,
          question: question,
          answer: answer
        }
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          console.log(`Saving question "${faq}"`)
          // Send the faq to the server
          this.$store.dispatch('faqs/saveFAQ', faq)
          this.attemptSubmit = false
        }
      },
      rowClick(params) {
        this.okToSave = false
        // Remove reference between rows and curFaq
        this.curFaq = JSON.parse(JSON.stringify(params.row))
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
          if (params.pageIndex === rowIndex) {
            a.classList.add('clicked')
          }
        })
      },
      tableSearch(data, filterString) {
        return data.toLowerCase().indexOf(filterString.toLowerCase()) > -1
      },
      onEditorReady(quill) {
        this.editor = quill
      }
    }
  }
</script>
