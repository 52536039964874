import Vue from 'vue'
import getRule from '../acl/abilities'
import { saveUser, loadUser, savePageSetting, loadPageSetting } from './persist'
import { eventbus } from '../main'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    version: '',
    apiRoot: '',
    requestedLink: null,
    modals: [],
    resources: [],
    couponsList: [],
    serverVersion: '',
    siteLink: '',
    pdfLink: '',
    loadingMsg: '',
    data: true,
    error: null,
    loading: false,
    dataLoaded: false,
    loggedinUser: undefined,
    serverError: '',
    activeMenuItem: 'Home',
    commentsList: [],
    currentComment: { text: '' },
    emailLog: []
  },
  mutations: {
    setRequestedLink: (state, payload) => {
      state.requestedLink = payload
    },
    setApiRoot: (state, payload) => {
      state.apiRoot = payload
    },
    setMenuHighlight: (state, payload) => {
      state.activeMenuItem = payload
      console.log(`Setting active menu to ${payload}`)
    },
    setLoading: (state, payload) => {
      state.loading = payload
    },
    dataLoaded: state => {
      state.dataLoaded = true
      state.data = true
      state.error = null
      state.loading = false
    },
    dataLoadedError: (state, err) => {
      state.dataLoaded = true
      state.data = true
      state.error = err
      state.loading = false
    },
    dataLoadingStart: (state, loadingTxt) => {
      debugger
      state.dataLoaded = false
      state.data = false
      state.error = null
      state.loading = true
      state.loadingMsg = loadingTxt
    },
    setVersion: state => {
      state.version = process.env.VUE_APP_VERSION
    },
    setServerError: (state, payload) => {
      state.serverError = payload
    },
    setLoadingMessage: (state, loadingTxt) => {
      console.log('Setting loading message to', loadingTxt)
      state.loadingMsg = loadingTxt
    },
    setResources: (state, payload) => {
      // payload should be role
      state.resources = getRule(payload).rules[0].subject
      console.log(`Store: Setting resources to role ${payload}: ${state.resources.toString()}`)
    },
    setUser: (state, payload) => {
      console.log('Store: Setting loggedinUser', payload)
      state.loggedinUser = payload
      // User logged out, reset all lists
      if (payload === null) {
        state.commentsList = []
        state.dataLoaded = false
      }
    },
    setCurrentComment: (state, payload) => {
      state.currentComment = payload
    },
    setCommentsList: (state, payload) => {
      state.commentsList = payload
    },
    setSettings: (state, payload) => {
      state.siteLink = payload.siteLink
      state.pdfLink = payload.pdfLink
      state.serverVersion = payload.serverVersion
    },
    setEmailsLog: (state, payload) => {
      state.emailLog = payload
    },
    setCoupons: (state, payload) => {
      state.couponsList = payload
    }
  },
  actions: {
    saveRowsPerPage: (state, payload) => {
      savePageSetting(payload)
    },
    getRowsPerPage: (state, payload) => {
      return new Promise((resolve, reject) => {
        const value = loadPageSetting(payload)
        resolve(value || 20)
      })
    },
    getCoupons: ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        state.error = ''
        state.loading = true
        state.loadingMsg = 'Loading Coupons'
        console.log('Loading Coupons')
        Vue.http.get('api/coupons').then(
          coupons => {
            state.loading = false
            state.loadingMsg = ''
            commit('setCoupons', coupons.body)
            resolve('coupons')
          },
          error => {
            state.error = error.bodyText
            reject(error.bodyText)
          }
        )
      })
    },
    deleteCoupon: ({ state, commit }, code) => {
      return new Promise((resolve, reject) => {
        Vue.http.delete('api/coupons/' + code).then(
          () => {
            console.log('Store: Got response from deleteCoupon')
            resolve('coupons')
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            state.loading = false
            reject(error.bodyText)
          }
        )
      })
    },
    saveCoupon: ({ state, commit }, code) => {
      return new Promise((resolve, reject) => {
        Vue.http.post('api/coupons', { coupon: code }).then(
          response => {
            console.log('Store: Got response from saveCoupon')
            resolve(response.body)
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            state.loading = false
            reject(error.bodyText)
          }
        )
      })
    },
    getSettings: ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        state.error = ''
        state.loading = true
        state.loadingMsg = 'Loading Settings'
        console.log('Loading Settings')
        // Get settings from server
        Vue.http.get('settings').then(
          settingsResp => {
            state.loading = false
            state.loadingMsg = ''
            commit('setSettings', settingsResp.body)
            resolve('settings')
          },
          error => {
            state.error = error.bodyText
            reject(error.bodyText)
          }
        )
      })
    },
    setUser: ({ commit, rootState }, payload) => {
      rootState.glossary.tagsList = []
      rootState.faqs.faqs = []
      rootState.creators.composersList = []
      rootState.creators.lyricistsList = []
      rootState.songs.songsList = []
      rootState.shows.showsList = []

      commit('setUser', payload)
      saveUser(payload)
    },
    getUser: ({ commit, dispatch }) => {
      const user = loadUser()
      if (user && Object.prototype.hasOwnProperty.call(user, 'expiry')) {
        console.log(`LoggedinUser ${user.email} found, checking expiry`)
        const now = moment()
        const expDate = moment(user.expiry)
        const duration = moment.duration(expDate.diff(now, 'milliseconds')).humanize()
        console.log(`Token is valid for ${duration}`)
        console.log('Token:', user.token)
        dispatch('setUser', user)
        commit('setResources', user.role)
        if (expDate.isSameOrBefore(now)) {
          console.log('Token expired')
          dispatch('setUser', null)
        }
      } else {
        console.log('No user found in local storage')
        dispatch('setUser', null)
      }
    },
    login: ({ state, commit }, payload) => {
      return new Promise((resolve, reject) => {
        Vue.http.post('login', { username: payload.username, pw: payload.pw }).then(
          response => {
            console.log('Setting loggedin user')
            commit('setUser', response.body)
            saveUser(response.body)
            eventbus.$emit('loggedIn', response.body)
            console.log('Token:', response.body.token)
            const now = moment()
            const expDate = moment(state.loggedinUser.expiry)
            const duration = moment.duration(expDate.diff(now, 'milliseconds')).humanize()
            console.log(`Token is valid for ${duration}`)
            commit('setResources', response.body.role)
            resolve()
          },
          error => {
            this.lastError = error.bodyText
            reject(this.lastError)
          }
        )
      })
    },
    newComment: ({ state, commit }, payload) => {
      Vue.http.post('api/saveComment', { comment: payload }).then(
        response => {
          console.log('Store: Got response from newComment')
          commit('setCurrentComment', response.body)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          state.loading = false
        }
      )
    },
    delComment: ({ state, commit }, compId) => {
      Vue.http.delete('api/comments/' + compId).then(
        () => {
          console.log('Store: Got response from delComment')
          state.currentComment = { text: '' }
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          state.loading = false
        }
      )
    },
    getComments: ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        if (state.commentsList.length === 0) {
          state.error = ''
          state.loading = true
          state.loadingMsg = 'Loading Comments'
          console.log('Loading Comments')
          Vue.http.get('api/comments').then(
            response => {
              commit('setCommentsList', response.body)
              state.loading = false
              state.loadingMsg = ''
              resolve('comments')
            },
            error => {
              state.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('comments')
        }
      })
    },
    getEmailLog: ({ state, commit }) => {
      return new Promise((resolve, reject) => {
        if (state.emailLog.length === 0) {
          state.error = ''
          state.loading = true
          state.loadingMsg = 'Loading Email Log'
          console.log('Loading Email Log')
          Vue.http.get('api/getemailLogforadmin').then(
            (response) => {
              for (const log of response.body) {
                if (!log.template) {
                  log.template = log.subjectCode
                }
                if (!log.ccedToAdmin) {
                  log.ccedToAdmin = false
                }
              }
              commit('setEmailsLog', response.body)
              state.loading = false
              state.loadingMsg = ''
              resolve('emails')
            },
            (error) => {
              state.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('emails')
        }
      })
    }
  },
  getters: {
    loggedinUser: state => {
      return state.loggedinUser
    },
    activeMenuItem: state => {
      return state.activeMenuItem
    },
    serverVersion: state => {
      return state.serverVersion
    },
    version: state => {
      return state.version
    }
  }
}
