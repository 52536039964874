import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'

const sortData = data => {
  console.log(`sorting`)
  // look at: https://www.jstips.co/en/javascript/map-to-the-rescue-adding-order-to-object-properties/
}

export default {
  namespaced: true,
  state: {
    tableKey: 0,
    individuals: [],
    institutions: [],
    stat: {}
  },
  mutations: {
    setStatLists: (state, payload) => {
      state.individuals = payload.individuals.sort()
      state.institutions = payload.institutions.sort()
    },
    setStat: (state, payload) => {
      state.stat = payload
    },
    resetStat: state => {
      state.stat = {}
    }
  },
  actions: {
    getStatLists: async ({ commit, rootState }) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .get(rootState.general.apiRoot + '/api/getInstitutionNamesAndEmails', {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting statLists from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting statLists from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          commit('setStatLists', result.data)
          resolve(result)
        } else {
          resolve({})
        }
      })
    },
    getStat: async ({ commit, rootState }, params) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .post(rootState.general.apiRoot + `/api/getStat`, params, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting stat from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting stat from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          const periods = result.data.periods.filter(s => s.date <= Date.now())
          result.data.periods = periods
          commit('setStat', result.data)
          resolve(result)
        } else {
          resolve({})
        }
      })
    }
  }
}
