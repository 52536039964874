<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Shows</h5>
      </div>
      <div class="col-2">
        <button @click="newShow" style="margin-left: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add Show
        </button>
        <button @click="copyContent" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div id="printable" v-if="data">
      <vue-good-table
        :key="tableKey"
        :columns="columns"
        :rows="rows"
        @on-per-page-change="onPerPageChange"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [15, 20, 25, 30, 35, 40, 50],
          position: 'top'
        }"
        :line-numbers="false"
        @on-row-click="rowClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: initialSortField, type: initialSortType }
        }"
        style-class="vgt-table musical bordered condensed"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  // import ShowEdit from '../components/ShowEdit.vue'
  import { copyTable } from '../mixins/copyTable'
  import { eventbus } from '../main'
  export default {
    // components: {
    //   ShowEdit
    // },
    mixins: [copyTable],
    name: 'Shows',
    data() {
      return {
        tableKey: 1,
        loading: true,
        loadingMsg: 'Loading Shows',
        initialSortField: 'title',
        initialSortType: 'asc',
        serverParams: {
          perPage: 20
        },
        columns: [
          {
            label: 'Show Title',
            field: 'title',
            width: '30%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by title', // placeholder for filter input
              filterFn: this.titleSearch
            },
            sortFn: this.titleSort
          },
          {
            label: 'Composer',
            field: 'composerNames',
            width: '30%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by composer', // placeholder for filter input
              filterFn: this.nameSearch
            },
            sortFn: this.nameSort
          },
          {
            label: 'Lyricist',
            field: 'lyricistNames',
            width: '30%',
            filterOptions: {
              enabled: true, // enable filter for this column
              placeholder: 'Filter by Lyricist', // placeholder for filter input
              filterFn: this.nameSearch
            },
            sortFn: this.nameSort
          },
          {
            label: 'Last Edited',
            field: 'date',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            width: '10%',
            sortable: true
          }
        ],
        rows: []
      }
    },
    created() {
      console.log('[Shows.vue] Shows component created')
      eventbus.$on('updateColumns', showListItem => {
        console.log(`[Shows.vue] Got event updateColumns with ${showListItem}`)
        this.updateColumns(showListItem)
      })
    },
    beforeDestroy() {
      console.log('[Shows.vue] Shows component beforeDestroy')
      eventbus.$off('updateColumns')
    },
    mounted() {
      console.log('Shows component mounted')
      this.$store.commit('general/setMenuHighlight', 'Shows')
      this.$store
        .dispatch('general/getRowsPerPage', 'showsPP')
        .then(value => {
          this.serverParams.perPage = value
        })
        .catch(ex => {
          console.error('Exception in mounted - getRowsPerPage', ex.message)
        })
      this.$store
        .dispatch('shows/getShows')
        .then(() => {
          console.log('Adding icons to shows list in mounted')
          for (const listItem of this.showsList) {
            const hasFemaleComposer = listItem.hasFemaleComposer ? '👩🏻 ' : ''
            const hasBIPOCComposer = listItem.hasBIPOCComposer ? '👨🏾 ' : ''
            const hasFemaleLyricist = listItem.hasFemaleLyricist ? '👩🏻 ' : ''
            const hasBIPOCLyricist = listItem.hasBIPOCLyricist ? '👨🏾 ' : ''
            if (listItem.composers && Object.keys(listItem.composers).length > 0) {
              let composerNames = ''
              for (const c of Object.values(listItem.composers)) {
                // console.log(`Composer: ${c.firstName} ${c.lastName}`)
                composerNames += `${c.firstName} ${c.lastName}, `
              }
              listItem.composerNames =
                hasFemaleComposer + hasBIPOCComposer + composerNames.trim().substr(0, composerNames.length - 2)
            } else {
              listItem.composerNames = 'Various Composers'
            }

            if (listItem.lyricists && Object.keys(listItem.lyricists).length > 0) {
              let lyricistNames = ''
              for (const l of Object.values(listItem.lyricists)) {
                lyricistNames += `${l.firstName} ${l.lastName}, `
              }
              listItem.lyricistNames =
                hasFemaleLyricist + hasBIPOCLyricist + lyricistNames.trim().substr(0, lyricistNames.length - 2)
            } else {
              listItem.lyricistNames = 'Various Lyricists'
            }
          }
          this.rows = this.showsList
          this.loading = false
        })
        .catch(ex => {
          console.error('Exception in mounted - getShows', ex.message)
          this.loading = false
        })
    },
    computed: {
      ...mapState({
        data: state => state.general.data,
        error: state => state.general.error,
        loggedinUser: state => state.general.loggedinUser,
        showsList: state => state.shows.showsList,
        currentShow: state => state.shows.currentShow
      })
    },
    methods: {
      updateColumns(listItem) {
        if (listItem) {
          console.log('Adding icons to shows list in updateColumns')
          const hasFemaleComposer = listItem.hasFemaleComposer ? '👩🏻 ' : ''
          const hasBIPOCComposer = listItem.hasBIPOCComposer ? '👨🏾 ' : ''
          const hasFemaleLyricist = listItem.hasFemaleLyricist ? '👩🏻 ' : ''
          const hasBIPOCLyricist = listItem.hasBIPOCLyricist ? '👨🏾 ' : ''
          if (listItem.composers && Object.keys(listItem.composers).length > 0) {
            let composerNames = ''
            for (const c of Object.values(listItem.composers)) {
              // console.log(`Composer: ${c.firstName} ${c.lastName}`)
              composerNames += `${c.firstName} ${c.lastName}, `
            }
            listItem.composerNames =
              hasFemaleComposer + hasBIPOCComposer + composerNames.trim().substr(0, composerNames.length - 2)
          } else {
            listItem.composerNames = 'Various Composers'
          }

          if (listItem.lyricists && Object.keys(listItem.lyricists).length > 0) {
            let lyricistNames = ''
            for (const l of Object.values(listItem.lyricists)) {
              lyricistNames += `${l.firstName} ${l.lastName}, `
            }
            listItem.lyricistNames =
              hasFemaleLyricist + hasBIPOCLyricist + lyricistNames.trim().substr(0, lyricistNames.length - 2)
          } else {
            listItem.lyricistNames = 'Various Lyricists'
          }
          // this.rows = this.showsList
          this.tableKey++
        }
      },
      copyContent() {
        var urlField = document.querySelector('table')
        var range = document.createRange()
        range.selectNode(urlField)
        window.getSelection().addRange(range)
        document.execCommand('copy')
      },
      onPerPageChange(params) {
        this.$log.info('onPerPageChange', params)
        this.$store.dispatch('general/saveRowsPerPage', { name: 'showsPP', value: params.currentPerPage })
      },
      nameSearch(data, filterString) {
        const colData = this.createSortName(data)
        if (colData) {
          return colData.indexOf(filterString.toLowerCase()) > -1
        } else {
          return false
        }
      },
      titleSearch(data, filterString) {
        const colData = this.createSearchTitle(data)
        return colData.indexOf(filterString.toLowerCase()) > -1
      },
      nameSort(x, y) {
        let xname = this.createSortName(x)
        let yname = this.createSortName(y)
        return xname < yname ? -1 : xname > yname ? 1 : 0
      },
      titleSort(x, y) {
        let xtitle = this.createSortTitle(x)
        let ytitle = this.createSortTitle(y)
        return xtitle < ytitle ? -1 : xtitle > ytitle ? 1 : 0
      },
      createSortTitle(title) {
        return title
          .trim()
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .replace(/^(a|an|the) /, '')
      },
      createSearchTitle(title) {
        return title.toLowerCase()
      },
      createSortName(name) {
        const xarray = name.split(' ')
        let result = ''
        for (const el of xarray) {
          result += el.replace(/[^\w\s]/g, '').toLowerCase()
        }
        return result
      },
      newShow() {
        this.$store.dispatch('shows/setNewShow')
        eventbus.$emit('modal', { command: 'open', name: 'show-edit' })
        // this.$modal.show('new-edit')
      },
      // Below has moved to ShowEdit eventbus event
      // onCloseModal() {
      //   // this.$modal.hide('new-edit')
      //   debugger
      //   eventbus.$emit('modal', { command: 'close', name: 'show-edit' })
      //   this.updateColumns()
      // },
      rowClick(params) {
        console.log('Shows: getting show with rowid', params.row._id)
        this.$store.dispatch('shows/getShow', params.row._id)
        eventbus.$emit('modal', { command: 'open', name: 'show-edit' })
        console.log('Done with rowClick')
        // this.$modal.show('new-edit')
      }
    }
  }
</script>

<style>
  input[type='radio'] {
    margin: 0 5px 0 5px;
  }
  /* label {
    margin-left: 5px;
  } */
</style>
