<template>
  <div>
    <modal
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="lyricist-edit"
      height="auto"
      width="1000"
      transition="pop-out"
      :click-to-close="false"
    >
      <div class="show-form">
        <h5 v-if="currentLyricist._id">
          Edit Lyricist
        </h5>
        <h5 v-else>
          New Lyricist
        </h5>
        <div class="row">
          <div class="col-12 form-block">
            <div class="row form-row">
              <!-- First Name -->
              <div class="col-2 label">
                <label class="label required" for="firstName">First Name</label>
              </div>
              <div class="col-2">
                <input
                  id="firstName"
                  name="firstName"
                  class="form-control"
                  type="text"
                  v-model.trim="currentLyricist.firstName"
                  :class="{ 'is-invalid': attemptSubmit && missingFirstName }"
                />
                <div :class="[attemptSubmit && missingFirstName ? 'is-not-valid' : 'is-valid']">
                  First Name is required.
                </div>
              </div>

              <!-- Last Name -->
              <div class="col-2 label">
                <label class="label required" for="lastName">Last Name</label>
              </div>
              <div class="col-2">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="currentLyricist.lastName"
                  id="lastName"
                  :class="{ 'is-invalid': attemptSubmit && missingLastName }"
                  name="lastName"
                />
                <div :class="[attemptSubmit && missingLastName ? 'is-not-valid' : 'is-valid']">
                  Last Name is required.
                </div>
              </div>

              <!-- Buttons -->
              <div class="offset-1 col-3">
                <button @click="cancel" class="btn btn-outline-secondary btn-xs song-button" style="margin-right: 5px;">
                  Cancel
                </button>
                <button @click="saveLyricist" :disabled="formsHasErrors" class="btn btn-outline-success btn-xs song-button">
                  Save
                </button>
                <button
                  @click="deleteLyricist"
                  v-if="$can('delete', 'lyricists') && currentLyricist._id"
                  :disabled="!canDelete"
                  class="btn btn-outline-danger btn-xs song-button"
                >
                  Delete
                </button>
              </div>
            </div>

             <!-- Female and BIPOC -->
            <div class="row form-row">
              <div class="offset-2 col-4">
                <input class="featured" type="checkbox" v-model="currentLyricist.isFemale" /><b style="padding-left:5px;"
                  >Women/Nonbinary</b
                >
              </div>
              <div class="col-4">
                <input class="featured" type="checkbox" v-model="currentLyricist.isBIPOC" /><b style="padding-left:5px;"
                  >Global Majority</b
                >
              </div>
            </div>

            <!-- Info URL -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="lastName">Info URL</label>
              </div>
              <div class="col-5">
                <input type="text" class="form-control" v-model.trim="currentLyricist.infoUrl" id="infoUrl" name="infoUrl" />
              </div>
              <div class="col-2">
                <a v-if="currentLyricist.infoUrl" :href="currentLyricist.infoUrl" target="blank">test link</a>
              </div>
            </div>

            <div class="row form-row" v-if="currentLyricist._id">
              <div class="col-2 label">
                <label class="label" for="created">Created</label>
              </div>
              <div class="col-2">
                {{ currentLyricist.dateCreated | formatDate }}
              </div>
              <div class="col-2 label">
                <label class="label" for="created">Created By</label>
              </div>
              <div class="col-4">
                {{ currentLyricist.enteredBy | emptyVal }}
              </div>
            </div>

            <div class="row form-row" v-if="currentLyricist._id">
              <div class="col-2 label">
                <label class="label" for="created">Last Updated</label>
              </div>
              <div class="col-2">
                {{ currentLyricist.lastUpdated | formatDate }}
              </div>
              <div class="col-2 label">
                <label class="label" for="created">Updated By</label>
              </div>
              <div class="col-4">
                <!-- <span v-if="!currentLyricist.updatedBy.includes('undefined')">{{currentLyricist.updatedBy}}</span> -->
                {{ currentLyricist.updatedBy | emptyVal }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  export default {
    data() {
      return {
        apiError: '',
        attemptSubmit: false
      }
    },
    computed: {
      ...mapState({
        currentLyricist: state => state.creators.currentLyricist,
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingFirstName || this.missingLastName
        } else {
          return false
        }
      },
      missingFirstName() {
        return this.currentLyricist.firstName === ''
      },
      missingLastName() {
        return this.currentLyricist.lastName === ''
      },
      canDelete() {
        return this.currentLyricist._id !== undefined
      }
    },
    methods: {
      beforeOpen() {
        // Set init values
        this.attemptSubmit = false
        document.addEventListener('keyup', this.close)
      },
      beforeClose() {
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          eventbus.$emit('modal', { command: 'close', name: 'lyricist-edit' })
        }
      },
      cancel() {
        eventbus.$emit('modal', { command: 'close', name: 'lyricist-edit' })
      },
      saveLyricist() {
        this.attemptSubmit = true
        // Give UI some time to react to attemptSubmit
        setTimeout(() => {
          if (!this.formsHasErrors) {
            this.$store.dispatch('creators/saveLyricist', this.currentLyricist).then(() => {
              this.attemptSubmit = false
              // const rows = document.querySelectorAll('.clickable')
              // rows.forEach((row, rowIndex) => {
              //   const a = rows[rowIndex]
              //   a.classList.remove('clicked')
              // })
              eventbus.$emit('modal', { command: 'close', name: 'lyricist-edit' })
            })
          }
        }, 500)
      },
      deleteLyricist() {
        if (this.currentLyricist._id) {
          if (
            confirm(
              `Are you sure you want to delete lyricist "${this.currentLyricist.lastName}"?\n\nThis action cannot be undone.`
            )
          ) {
            this.$store.dispatch('creators/deleteLyricist', this.currentLyricist._id)
            // const rows = document.querySelectorAll('.clickable')
            // rows.forEach((row, rowIndex) => {
            //   const a = rows[rowIndex]
            //   a.classList.remove('clicked')
            // })
            eventbus.$emit('modal', { command: 'close', name: 'lyricist-edit' })
          }
        }
      }
    }
  }
</script>
