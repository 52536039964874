<template>
  <div>
    <!-- <song-edit id="song-edit" :on-close="editClosed" /> -->
    <song-edit-common
      name="song-edit-partial"
      :songs="songsForEdit"
      :songs-titles="songsTitlesForEdit"
      :show-title="currentShow.title"
      :on-closed="onSongsCommonClosed"
      :all-songs="allSongsSelected"
      :show-id="currentShow._id"
    />
    <modal
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="show-edit"
      height="auto"
      width="90%"
      :scrollable="true"
      transition="pop-out"
      :click-to-close="false"
    >
      <!-- Display some nice loading animation -->
      <div class="loading" v-if="loading">
        <img src="/loading.gif" />
        <div class="loading">
          {{ loadingMsg }}
        </div>
      </div>

      <div class="show-form">
        <h4 v-if="currentShow._id">
          Edit Show
        </h4>
        <h4 v-else>
          New Show
        </h4>
        <div class="row">
          <div class="col-8 form-block">
            <!-- Show Title -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="showTitle">Show Title</label>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <input
                    id="title"
                    name="title"
                    class="form-control"
                    type="text"
                    @blur="titleEntered"
                    v-model.trim="currentShow.title"
                    :class="{ 'is-invalid': attemptSubmit && missingTitle }"
                  />
                  <div class="is-not-valid" v-if="titleCheck !== 'ok'">
                    {{ titleCheck }}
                  </div>
                  <div :class="[attemptSubmit && missingTitle ? 'is-not-valid' : 'is-valid']">
                    Title is required.
                  </div>
                </div>

                <div class="checkbox-block">
                  <input
                    type="checkbox"
                    @click="checkClicked"
                    class="css-checkbox"
                    id="checkbox1"
                    v-model="currentShow.isCompilation"
                  />
                  <label for="checkbox1" name="checkbox1_lbl" class="css-label lite-red-check"
                    >This is a compilation or jukebox show</label
                  >
                  <input
                    type="checkbox"
                    @click="checkClicked"
                    class="css-checkbox"
                    id="checkbox2"
                    v-model="currentShow.songsAreStandalone"
                  />
                  <label for="checkbox2" name="checkbox2_lbl" class="css-label lite-red-check"
                    >This is a container for stand-alone songs, not a real show</label
                  >
                  <p class="note" :class="[currentShow.songsAreStandalone ? 'show' : 'hide']">
                    <b>Note:</b> If you are entering stand-alone songs, the "Show Title" above is inconsequential. It may be
                    useful to enter the composer's name as the title.
                  </p>
                </div>
              </div>
            </div>

            <!-- Show ID -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="showId">Show ID</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control" v-model="currentShow._id" id="showId" disabled />
              </div>
            </div>

            <!-- Put composers and lyricists in a div together so they can be animated when not applicable -->
            <div :class="[currentShow.isCompilation ? 'remove' : 'add']">
              <!-- Composers -->
              <div class="row form-row">
                <div class="col-4 label">
                  <label class="label required" for="composers">Composer(s)</label>
                  <div>
                    <span v-if="currentShow.hasFemaleComposer">👩🏻 </span>
                    <span v-if="currentShow.hasBIPOCComposer">👨🏾</span>
                  </div>
                </div>
                <div class="col-6">
                  <v-select
                    multiple
                    @input="composerEntered"
                    :close-on-select="false"
                    v-model="currentShow.composers"
                    id="composers"
                    name="composers"
                    class="tooltip-target is-invalid"
                    :options="composers"
                  />
                </div>
                <div class="col-2">
                  <div class="btn btn-sm btn-outline-primary" @click="newComposer">New</div>
                </div>
              </div>
              <div class="row form-row">
                <div class="offset-4 col-8">
                  <span class="note">Format: Last name, First name</span><br />
                  <div v-for="composer of currentShow.composers" :key="composer._id">
                    <span v-if="composer.infoUrl">
                      <b
                        ><i>{{ composer.lastName }}:</i></b
                      >
                      <a :href="composer.infoUrl" target="__blank">{{ composer.infoUrl }}</a>
                    </span>
                  </div>
                  <div :class="[attemptSubmit && missingComposer ? 'is-not-valid' : 'is-valid']">
                    At least one composer is required.
                  </div>
                </div>
              </div>

              <!-- Lyricists -->
              <div class="row form-row" v-if="!currentShow.isCompilation">
                <div class="col-4 label">
                  <label class="label required" for="lyricists">Lyricist(s)</label>
                  <div>
                    <span v-if="currentShow.hasFemaleLyricist">👩🏻 </span>
                    <span v-if="currentShow.hasBIPOCLyricist">👨🏾</span>
                  </div>
                </div>
                <div class="col-6">
                  <v-select
                    multiple
                    @input="lyricistEntered"
                    :close-on-select="false"
                    v-model="currentShow.lyricists"
                    id="lyricists"
                    name="lyricists"
                    :options="lyricists"
                  />
                </div>
                <div class="col-2">
                  <div class="btn btn-sm btn-outline-primary" @click="newLyricist">New</div>
                </div>
              </div>
              <div class="row form-row">
                <div class="offset-4 col-8">
                  <span class="note">Format: Last name, First name</span><br />
                  <div v-for="lyricist of currentShow.lyricists" :key="lyricist._id">
                    <span v-if="lyricist.infoUrl">
                      <b
                        ><i>{{ lyricist.lastName }}:</i></b
                      >
                      <a :href="lyricist.infoUrl" target="__blank">{{ lyricist.infoUrl }}</a>
                    </span>
                  </div>
                  <div :class="[attemptSubmit && missingLyricist ? 'is-not-valid' : 'is-valid']">
                    At least one lyricist is required.
                  </div>
                </div>
              </div>
            </div>

            <!-- Year Written -->
            <div class="row form-row" v-if="!currentShow.songsAreStandalone">
              <div class="col-4 label">
                <label class="label required" for="showyearWritten">Year Written</label>
              </div>
              <div class="col-6">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="currentShow.yearWritten"
                  data-vv-as="year a show was written"
                  id="showyearWritten"
                  :class="{ 'is-invalid': attemptSubmit && missingYear }"
                  name="showyearWritten"
                />
                <div :class="[attemptSubmit && missingYear ? 'is-not-valid' : 'is-valid']">
                  A correct year is required.
                </div>
              </div>
            </div>

            <!-- Info / Wikipedia Link -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="showWiki">Info / Wikipedia Link</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control" v-model="currentShow.wikipediaUrl" id="showWiki" />
                <a v-if="currentShow.wikipediaUrl" :href="currentShow.wikipediaUrl" target="blank">Test link</a>
              </div>
            </div>

            <!-- WorldCat -->
            <world-cat v-if="currentShow.songs.length > 0" />

            <!-- Score link if Public Domain -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="scorePDF">Uploaded Score PDF</label>
              </div>
              <div v-if="currentShow.hasFreeScore" class="col-6">
                <span> <a :href="pdfLink + currentShow._id + '.pdf'" target="_blank">View Uploaded PDF</a></span>
              </div>
              <div v-if="!currentShow.hasFreeScore && fileUploadSuccess === true">
                <i>Please save show to display uploaded PDF</i>
              </div>
            </div>

            <!-- Upload Score PDF -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label">Upload Score <br /><span class="no-bold">(if show is public domain)</span></label>
              </div>
              <div class="col-4" v-if="!fileUploadSuccess">
                <input style="display:none;" ref="fileInput" type="file" name="score" accept=".pdf" @change="fileSelected" />
                <button @click="$refs.fileInput.click()" class="btn btn-outline-success btn-sm">
                  Select File
                </button>
                <span v-if="selectedFile">{{ selectedFile.name }}</span>
              </div>
              <div class="col-4" v-if="!fileUploadSuccess">
                <div v-if="selectedFile && !fileUploadErrorText" @click="upload" class="btn btn-outline-primary btn-sm">
                  Upload File
                </div>
                <div v-if="fileSizeWarning" style="color:red;">
                  <b>{{ fileSizeWarning }}</b>
                </div>
                <span v-if="fileUploadErrorText" style="color:red;">{{ fileUploadErrorText }}</span>
              </div>
            </div>

            <!-- Date Entered -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="showDateEntered">Date Entered</label>
              </div>
              <div class="col-6">
                <span>{{ currentShow.dateCreated | formatDate }}</span>
              </div>
            </div>

            <!-- Last Updated -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="showLastUpdated">Last Updated</label>
              </div>
              <div class="col-6">
                <span>{{ currentShow.lastUpdated | formatDate }}</span>
              </div>
            </div>

            <!-- Last Updated By -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="showLastUpdatedBy">Last Updated By</label>
              </div>
              <div class="col-6">
                <span>{{ currentShow.lastUpdatedByName | emptyVal }}</span>
              </div>
            </div>

            <hr />

            <!-- The Buttons -->
            <div v-if="apiError" class="row form-row">
              <div class="offset-sm-4 col-7">
                <span class="api-error">{{ apiError }}</span>
              </div>
            </div>
            <div class="row form-row">
              <div class="offset-sm-4 col-7">
                <button @click="cancel" class="btn btn-outline-secondary btn-sm btn-margin">
                  Cancel
                </button>
                <button @click="save" :disabled="formsHasErrors" class="btn btn-outline-success btn-sm btn-margin">
                  Save
                </button>
                <button @click="saveNew" :disabled="formsHasErrors" class="btn btn-outline-primary btn-sm btn-margin">
                  Save and New
                </button>
                <button
                  v-if="$can('delete', 'shows') && currentShow._id"
                  @click="delShow"
                  class="btn btn-outline-danger btn-sm btn-margin"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>

          <!-- The SONGS right side bar -->
          <div class="col-4">
            <h4>Songs</h4>
            <div v-if="currentShow._id">
              <button @click="newSong" class="btn btn-outline-success btn-sm song-button">
                Add New Song
              </button>
              <hr />
            </div>
            <div v-else>
              <span style="color:red">You can only add new songs after show is saved</span>
            </div>
            <div v-for="song in currentShow.songs" :key="song.title">
              <div class="row songs-in-edit">
                <input type="checkbox" class="css-checkbox" :id="song.title" v-model="song.selectedForEdit" />
                <label :for="song.title" :name="song.title" class="css-label lite-red-check">
                  {{ song.title }}
                </label>
                <a href="#" @click="editSong(song._id)">[edit]</a>
                <a v-if="$can('delete', 'songs')" href="#" @click="deleteSong(song._id, song.title)">[delete]</a>
              </div>
            </div>
            <div class="songs-edit-all">
              <a href="#" @click="editSelectedSongs">Edit selected</a> <a href="#" @click="editAllSongs">Edit all</a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import WorldCat from '../components/WorldCat'
  import SongEditCommon from '../components/SongEditCommon.vue'
  // import SongEdit from '../components/SongEdit.vue'
  import moment from 'moment'
  import { compLyrc } from '../mixins/newCompLyrc.js'
  import { eventbus } from '../main'
  export default {
    components: {
      WorldCat,
      SongEditCommon
      // SongEdit
    },
    mixins: [compLyrc],
    data() {
      return {
        apiError: '',
        attemptSubmit: false,
        songsForEdit: [],
        songsTitlesForEdit: '',
        allSongsSelected: false,
        isNewShow: false,
        saveAndNewClicked: false,
        selectedFile: null,
        fileSizeWarning: ''
      }
    },
    created() {
      eventbus.$on('ShowCreated', () => {
        // When SaveNew was used the _id property should be reset
        // when a Show was created
        if (this.saveAndNewClicked) {
          this.currentShow._id = ''
          this.$log.info('Show Id reset because saveAndNewClicked')
        }
      })
      eventbus.$on('song-added-updated', song => {
        // A new song was added or edited, add/change the side bar
        // currentShow.songs
        console.log(`Song ${song.title} was added or changed`)
        // Find the song in currentShow.songs
        const changedSong = this.currentShow.songs.find(s => s._id === song._id)
        if (changedSong) {
          // Song found so it was changed
          Object.assign(changedSong, {
            _id: song._id,
            title: song.title,
            externalLinks: song.externalLinks
          })
        } else {
          // Song not found so it's new
          this.currentShow.songs.push({
            _id: song._id,
            title: song.title,
            externalLinks: song.externalLinks
          })
        }
      })
      // Get notified when a dialog is closed, used for new creators
      eventbus.$on('modal', options => {
        if (options.command === 'close' && options.name === 'composer-edit') {
          console.log(`[ShowEdit] Modal event with command ${options.command} and modal name ${options.name}`)
          const newComposer = this.latestSavedComposer
          console.log(` Got new composer: ${newComposer.firstName} ${newComposer.lastName}`)
          // Add the new composer to the show
          if (newComposer._id) {
            this.currentShow.composers.push(newComposer)
            this.composerEntered([{ label: `${newComposer.lastName}, ${newComposer.firstName}` }])
          } else {
            console.log("Didn't get a new composer")
          }
        }
        if (options.command === 'close' && options.name === 'lyricist-edit') {
          console.log(`[ShowEdit] Modal event with command ${options.command} and modal name ${options.name}`)
          const newLyricist = this.latestSavedLyricist
          console.log(` Got new lyricist: ${newLyricist.firstName} ${newLyricist.lastName}`)
          // Add the new lyricist to the show
          if (newLyricist._id) {
            this.currentShow.lyricists.push(newLyricist)
            this.lyricistEntered([{ label: `${newLyricist.lastName}, ${newLyricist.firstName}` }])
          } else {
            console.log("Didn't get a new lyricist")
          }
        }
      })
    },
    beforeDestroy() {
      eventbus.$off('ShowCreated')
      eventbus.$off('modal')
      eventbus.$off('song-added-updated')
    },
    computed: {
      ...mapState({
        latestSavedComposer: state => state.creators.latestSavedComposer,
        latestSavedLyricist: state => state.creators.latestSavedLyricist,
        fileUploadSuccess: state => state.shows.fileUploadSuccess,
        fileUploadErrorText: state => state.shows.fileUploadErrorText,
        currentShow: state => state.shows.currentShow,
        currentSong: state => state.songs.currentSong,
        originalShowTitle: state => state.shows.originalShowTitle,
        originalWorldCat: state => state.shows.originalWorldCat,
        composers: state => state.creators.composersList,
        lyricists: state => state.creators.lyricistsList,
        songsList: state => state.songs.songsList,
        tagsList: state => state.glossary.tagsList,
        pdfLink: state => state.general.pdfLink,
        loggedinUser: state => state.general.loggedinUser,
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        titleCheck: state => state.shows.titleCheck
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingTitle || this.missingComposer || this.missingLyricist || this.missingYear
        } else {
          return false
        }
      },
      missingTitle() {
        return this.currentShow.title === ''
      },
      missingComposer() {
        return this.currentShow.composers.length === 0 && !this.currentShow.isCompilation
      },
      missingLyricist() {
        return this.currentShow.lyricists.length === 0 && !this.currentShow.isCompilation
      },
      missingYear() {
        if (this.currentShow.songsAreStandalone) {
          return false
        }
        return this.currentShow.yearWritten.length === 0
      }
    },
    props: ['onClose'],
    methods: {
      composerRightClick() {
        console.log('composerRightClick')
      },
      beforeOpen() {
        // Set init values
        // this.$store.commit('shows/setNewShow')
        this.attemptSubmit = false
        this.saveAndNewClicked = false
        document.addEventListener('keyup', this.close)
        this.selectedFile = null
      },
      beforeClose() {
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          eventbus.$emit('modal', { command: 'close', name: 'show-edit' })
          eventbus.$emit('updateColumns')
        }
      },
      editClosed() {
        eventbus.$emit('modal', { command: 'close', name: 'new-edit-song' })
        // this.$modal.hide('new-edit-song')
        // this.$modal.show('new-edit')
      },
      cancel() {
        eventbus.$emit('modal', { command: 'close', name: 'show-edit' })
        eventbus.$emit('updateColumns')
      },
      newComposer() {
        this.$store.commit('creators/setLatestSavedComposer', {})
        this.$store.commit('creators/setNewComposer')
        eventbus.$emit('modal', { command: 'open', name: 'composer-edit' })
      },
      newLyricist() {
        this.$store.commit('creators/setLatestSavedLyricist', {})
        this.$store.commit('creators/setNewLyricist')
        eventbus.$emit('modal', { command: 'open', name: 'lyricist-edit' })
      },
      newSong() {
        // Called from the sidebar new song button
        // If no songs list yet, get songs first
        // this.$store.dispatch('songs/getSongs', this.currentShow._id).then(() => {
        this.$store.dispatch('songs/setNewSong', {
          showId: this.currentShow._id,
          showTitle: this.currentShow.title,
          composers: this.currentShow.composers,
          lyricists: this.currentShow.lyricists,
          yearWritten: this.currentShow.yearWritten
        })
        eventbus.$emit('modal', { command: 'open', name: 'new-edit-song' })
        // this.$modal.hide('new-edit')
        // this.$modal.show('new-edit-song')
        // })
      },
      deleteSong(songId, songTitle) {
        if (confirm(`Are you sure you want to delete the song "${songTitle}"?\n\nThis action cannot be undone.`)) {
          this.$log.info('deleting song ' + songTitle)
          this.$store.dispatch('songs/deleteSong', songId)
        }
      },
      editSong(songId) {
        eventbus.$emit('modal', { command: 'open', name: 'new-edit-song' })
        // this.$modal.hide('new-edit')
        // this.$modal.show('new-edit-song')
        this.$store.dispatch('songs/getSong', songId)
      },
      onSongsCommonClosed() {
        // Update the date edited filed in songsList
        for (var commonSong of this.songsForEdit) {
          // No need to update date last edited if number of properties are less than 2, meaning nothing changed
          if (Object.keys(commonSong).length > 2) {
            const songListItem = this.songsList.find(sli => sli._id === commonSong._id)
            songListItem.date = moment().format('YYYY-MM-DD HH:mm')
          }
        }
        // Clear songs selected for edit of common properties
        this.songsForEdit = []
        this.songsTitlesForEdit = ''
        for (var song of this.currentShow.songs) {
          delete song.selectedForEdit
        }
        eventbus.$emit('modal', { command: 'close', name: 'song-edit-partial' })
        // this.$modal.hide('song-edit-partial')
        // this.$modal.show('new-edit')
      },
      editSelectedSongs() {
        const that = this
        this.$store
          .dispatch('songs/getSongsForShow', this.currentShow._id)
          .then(() => {
            this.allSongsSelected = false
            this.songsForEdit = []
            this.songsTitlesForEdit = ''
            for (var song of this.currentShow.songs) {
              if (song.selectedForEdit) {
                this.songsTitlesForEdit = this.songsTitlesForEdit + song.title + ', '
                this.songsForEdit.push({ _id: song._id, title: song.title })
              }
            }
            if (this.songsForEdit.length > 0) {
              this.$log.debug(`Found ${this.songsForEdit.length} songs to edit`)
              this.songsTitlesForEdit = this.songsTitlesForEdit.substr(0, this.songsTitlesForEdit.length - 2)
              eventbus.$emit('modal', { command: 'open', name: 'song-edit-partial' })
              // this.$modal.hide('new-edit')
              // this.$modal.show('song-edit-partial')
            } else {
              alert('No songs selected')
            }
          })
          .catch(ex => {
            console.error('Exception in editSelectedSongs', ex.message)
          })
      },
      editAllSongs() {
        this.$store
          .dispatch('songs/getSongsForShow', this.currentShow._id)
          .then(() => {
            this.allSongsSelected = true
            for (var song of this.currentShow.songs) {
              song.selectedForEdit = true
              this.songsForEdit.push({ _id: song._id, title: song.title })
            }
            if (this.songsForEdit.length > 0) {
              this.$log.debug(`Found ${this.songsForEdit.length} songs to edit`)
              this.songsTitlesForEdit = `All songs from "${this.currentShow.title}"`
              eventbus.$emit('modal', { command: 'open', name: 'song-edit-partial' })
              // this.$modal.hide('new-edit')
              // this.$modal.show('song-edit-partial')
            }
          })
          .catch(ex => {
            console.error('Exception in editAllSongs', ex.message)
          })
      },
      titleEntered() {
        // Check and warn if title already exists
        this.$store.dispatch('shows/checkTitle', { title: this.currentShow.title, _id: this.currentShow._id })
        // Set Wikipedia link, only if empty; don't override
        if (this.currentShow.wikipediaUrl === '' && this.currentShow.title !== '') {
          this.$el.querySelector('#showWiki').value = 'http://en.wikipedia.org/wiki/' + this.currentShow.title.replace(/ /g, '_')
          this.currentShow.wikipediaUrl = this.$el.querySelector('#showWiki').value
        }
        // Set sort title; strip punctuation and remove a, an and the
        this.currentShow.sortTitle = this.currentShow.title
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .replace(/^(a|an|the) /, '')
          .trim()
      },
      composerEntered(val) {
        if (val && val.length > 0) {
          const composer = val[0].label
          this.currentShow.sortComposer = composer.replace(/[^\w\s]/g, '')
          this.currentShow.hasBIPOCComposer = false
          this.currentShow.hasFemaleComposer = false
          for (const comp of this.currentShow.composers) {
            if (comp.isFemale) {
              this.currentShow.hasFemaleComposer = true
            }
            if (comp.isBIPOC) {
              this.currentShow.hasBIPOCComposer = true
            }
          }
        }
        // this.$validator.validateAll()
      },
      lyricistEntered(val) {
        if (val && val.length > 0) {
          const lyricist = val[0].label
          this.currentShow.sortLyricist = lyricist.replace(/[^\w\s]/g, '')
          this.currentShow.hasBIPOCLyricist = false
          this.currentShow.hasFemaleLyricist = false
          for (const lyrc of this.currentShow.lyricists) {
            if (lyrc.isFemale) {
              this.currentShow.hasFemaleLyricist = true
            }
            if (lyrc.isBIPOC) {
              this.currentShow.hasBIPOCLyricist = true
            }
          }
        }
        // this.$validator.validateAll()
      },
      fileSelected(event) {
        this.fileSizeWarning = ''
        // this.$log.debug(event)
        this.$store.commit('shows/resetFileSizeWarning')
        if (event.target.files[0].size < 60 * 1024 * 1024) {
          this.selectedFile = event.target.files[0]
        } else {
          this.fileSizeWarning = 'Please select a file smaller than 21 MB'
        }
      },
      upload() {
        const fd = new FormData()
        fd.append('image', this.selectedFile, this.currentShow._id)
        this.$store
          .dispatch('shows/uploadScoreFile', fd)
          .then(result => {
            this.$log.debug('Result from upload:', result)
          })
          .catch(ex => {
            console.error('Exception in upload', ex.message)
          })
      },
      delShow() {
        if (confirm(`Are you sure you want to delete the show "${this.currentShow.title}"?\n\nThis action cannot be undone.`)) {
          this.$log.info('deleting show ' + this.currentShow.title)
          eventbus.$emit('modal', { command: 'close', name: 'show-edit' })
          eventbus.$emit('updateColumns')
          // this.$modal.hide('new-edit')
          this.$store.dispatch('shows/deleteShow')
        }
      },
      save() {
        this.$log.info('Save Show has errors?', this.formsHasErrors)
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          this.saveAndNewClicked = false
          this.saveShow()
        }
      },
      saveNew() {
        this.$log.info('SaveNew Show has errors?', this.formsHasErrors)
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          this.saveAndNewClicked = true
          this.saveShow()
          this.attemptSubmit = false
          this.$store.commit('shows/setNewShow')
        }
      },
      saveShow() {
        this.$log.info('Saving show')
        this.isNewShow = this.currentShow._id === ''
        // If Compilation, remove composers and lyricists
        if (this.currentShow.isCompilation) {
          this.currentShow.composers = []
          this.currentShow.lyricists = []
        }
        // Check if title has changed and if so update songs with new title and new date lastUpdated
        // This is only for existing shows as new shows will have new songs updated anyway
        if (!this.isNewShow && this.currentShow.title !== this.originalShowTitle) {
          this.$log.info(`Show title changed from ${this.originalShowTitle} to ${this.currentShow.title}`)
          this.currentShow.titleChanged = true
        }
        // Check if worldcat has changed and if so update songs with date lastUpdated
        // This is only for existing shows as new shows will have new songs updated anyway
        // Convert worlcat to sting, we don't want referenced copies
        if (!this.isNewShow && JSON.stringify(this.currentShow.worldcat) !== this.originalWorldCat) {
          this.$log.info('Worldcat has changed')
          this.currentShow.worldcatHasChanged = true
        }
        this.$store.dispatch('shows/saveShow')
        if (!this.isNewShow && !this.saveAndNewClicked) {
          eventbus.$emit('modal', { command: 'close', name: 'show-edit' })
          // this.$modal.hide('new-edit')
        }
      },
      checkClicked(event) {
        // If one checkbox is clicked, unclick the other
        // Compilation or jukebox show
        if (event.currentTarget.id === 'checkbox1') {
          if (event.currentTarget.checked) {
            if (document.querySelector('#checkbox2').checked) {
              // Should uncheck Checkbox2
              document.querySelector('#checkbox2').click()
            }
            // Container show checked, should remove composers and lyricists
            this.currentShow.composers = []
            this.currentShow.composerNames = ''
            this.currentShow.lyricists = []
            this.currentShow.lyricistNames = ''
          }
        }
        // Container for stand-alone songs
        if (event.currentTarget.id === 'checkbox2') {
          if (event.currentTarget.checked) {
            if (document.querySelector('#checkbox1').checked) {
              // Should uncheck Checkbox1
              document.querySelector('#checkbox1').click()
            }
          }
        }
      }
    }
  }
</script>
