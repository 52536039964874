import Vue from 'vue'
import axios from 'axios'
import merge from 'lodash.merge'
import checkAxiosError from './helpers/checkAxiosError'

export default {
  namespaced: true,
  state: {
    reloadShows: false,
    showsList: [],
    originalShowTitle: '',
    originalWorldCat: '',
    fileUploadSuccess: false,
    fileUploadErrorText: '',
    titleCheck: 'ok',
    currentShow: {
      _id: '',
      title: '',
      composers: [],
      lyricists: [],
      yearWritten: '',
      wikipediaUrl: '',
      lastUpdated: Date.now(),
      lastUpdatedById: '',
      linkScoreType: 'auto',
      linkRecordingType: 'auto',
      dateCreated: Date.now(),
      enteredById: '',
      hasFreeScore: false,
      scoreFiles: [],
      sortLyricist: '',
      sortComposer: '',
      sortTitle: '',
      songs: [],
      worldcat: {
        scoresEnabled: false,
        scoresAutomatic: true,
        scoresSameUrl: '',
        scoresAllSame: true,
        recordingsAutomatic: true,
        recordingsEnabled: false,
        recordingsAllSame: true,
        recordingsSameUrl: ''
      }
    }
  },
  mutations: {
    setTitleCheck: (state, payload) => {
      state.titleCheck = payload
    },
    setNewShow: state => {
      const newShow = {
        _id: '',
        title: '',
        composers: [],
        lyricists: [],
        yearWritten: '',
        wikipediaUrl: '',
        lastUpdated: Date.now(),
        lastUpdatedById: '',
        linkScoreType: 'auto',
        linkRecordingType: 'auto',
        dateCreated: Date.now(),
        enteredById: '',
        hasFreeScore: false,
        scoreFiles: [],
        sortLyricist: '',
        sortComposer: '',
        sortTitle: '',
        songs: [],
        worldcat: {
          scoresEnabled: false,
          scoresAutomatic: true,
          scoresSameUrl: '',
          scoresAllSame: true,
          recordingsAutomatic: true,
          recordingsEnabled: false,
          recordingsAllSame: true,
          recordingsSameUrl: ''
        }
      }
      state.currentShow = JSON.parse(JSON.stringify(newShow))
      state.originalShowTitle = ''
      state.originalWorldCat = ''
    },
    setShowsList: (state, payload) => {
      state.showsList = payload
    },
    setCurrentShow: (state, payload) => {
      state.currentShow = payload
    },
    addShow: (state, payload) => {
      state.showsList.push(payload)
    },
    resetFileSizeWarning: state => {
      state.fileUploadSuccess = false
      state.fileUploadErrorText = ''
    }
  },
  actions: {
    setNewShow: ({ commit, dispatch, rootState }) => {
      if (rootState.creators.composersList.length === 0) {
        console.log('Getting composers')
        dispatch('creators/getComposers', null, { root: true })
          .then(result => {
            console.log(`Got ${result}`)
            dispatch('setNewShow')
          })
          .catch(ex => {
            console.error('Exception in setNewShow - getComposers', ex.message)
          })
        // If no lyricists, get them
      } else if (rootState.creators.lyricistsList.length === 0) {
        console.log('Getting lyricists')
        dispatch('creators/getLyricists', null, { root: true })
          .then(result => {
            console.log(`Got ${result}`)
            dispatch('setNewShow')
          })
          .catch(ex => {
            console.error('Exception in setNewShow - getLyricists', ex.message)
          })
      } else {
        commit('setNewShow')
      }
    },
    getShows: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.showsList.length === 0 || state.reloadShows) {
          rootState.general.error = ''
          console.log('Loading Shows')
          Vue.http.get('api/shows').then(
            response => {
              commit('setShowsList', response.body)
              // rootState.general.loading = false
              // rootState.general.loadingMsg = ''
              resolve('shows')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
          state.reloadShows = false
        } else {
          console.log('[Shows] No need to reload shows list')
          resolve('shows')
        }
      })
    },
    checkTitle: async ({ commit, rootState }, showInfo) => {
      console.log(`Checking title "${showInfo.title}" with ID: ${showInfo._id}`)
      commit('setTitleCheck', 'ok')
      const result = await axios
        .post(
          rootState.general.apiRoot + '/api/checkShowTitle',
          { title: showInfo.title, _id: showInfo._id },
          { headers: { Authorization: rootState.general.loggedinUser.token } }
        )
        .then(result => {
          commit('setTitleCheck', result.data)
        })
        .catch(error => {
          const msg = checkAxiosError.getErrorMsg(error)
          commit('sendToast', { type: 'error', content: msg }, { root: true })
          // rootState.general.loading = false
        })
    },
    getShow: ({ state, commit, dispatch, rootState }, rowId) => {
      console.log('Getting show', rowId)
      commit('setTitleCheck', 'ok')
      // If no composers, get them
      if (rootState.creators.composersList.length === 0) {
        console.log('Getting composers')
        dispatch('creators/getComposers', null, { root: true })
          .then(result => {
            console.log(`Got ${result}`)
            dispatch('getShow', rowId)
          })
          .catch(ex => {
            console.error('Exception in getShow - getComposers', ex.message)
          })
        // If no lyricists, get them
      } else if (rootState.creators.lyricistsList.length === 0) {
        console.log('Getting lyricists')
        dispatch('creators/getLyricists', null, { root: true })
          .then(result => {
            console.log(`Got ${result}`)
            dispatch('getShow', rowId)
          })
          .catch(ex => {
            console.error('Exception in getShow - getLyricists', ex.message)
          })
      } else {
        rootState.general.error = ''
        rootState.general.loading = true
        rootState.general.loadingMsg = 'Loading Show'
        state.fileUploadSuccess = false
        state.fileUploadErrorText = ''
        Vue.http.get('api/shows/' + rowId).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            const newShow = {
              _id: '',
              title: '',
              composers: [],
              lyricists: [],
              yearWritten: '',
              wikipediaUrl: '',
              lastUpdated: Date.now(),
              lastUpdatedById: '',
              linkScoreType: 'auto',
              linkRecordingType: 'auto',
              dateCreated: Date.now(),
              enteredById: '',
              hasFreeScore: false,
              scoreFiles: [],
              sortLyricist: '',
              sortComposer: '',
              sortTitle: '',
              songs: [],
              worldcat: {
                scoresEnabled: false,
                scoresAutomatic: true,
                scoresSameUrl: '',
                scoresAllSame: true,
                recordingsAutomatic: true,
                recordingsEnabled: false,
                recordingsAllSame: true,
                recordingsSameUrl: ''
              }
            }
            const currentShow = merge(newShow, response.body)
            FixComposersLyricists(currentShow)
            commit('setCurrentShow', currentShow)
            state.originalShowTitle = currentShow.title
            state.originalWorldCat = JSON.stringify(currentShow.worldcat)
            console.log('Original title is', state.originalShowTitle)
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.general.loading = false
          }
        )
      }
    },
    saveShow: async ({ state, commit, rootState }) => {
      // rootState.general.loading = true
      const result = await axios
        .post(
          rootState.general.apiRoot + '/api/shows/update',
          { show: state.currentShow },
          { headers: { Authorization: rootState.general.loggedinUser.token } }
        )
        .catch(error => {
          const msg = checkAxiosError.getErrorMsg(error)
          commit('sendToast', { type: 'error', content: msg }, { root: true })
          // rootState.general.loading = false
        })
      // rootState.general.loading = false
    },
    deleteShow: ({ state, commit }) => {
      const showTitle = state.currentShow.title
      Vue.http.delete('api/shows/' + state.currentShow._id).then(
        () => {
          // Set new show to make sure the deleted show cannot be seen
          commit('setNewShow')
          console.log(`[Show-Store] Deleted show ${showTitle}`)
        },
        error => {
          console.error(error)
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
        }
      )
    },
    uploadScoreFile: ({ state, commit, rootState }, file) => {
      return new Promise(resolve => {
        state.fileUploadSuccess = false
        rootState.general.loadingMsg = 'Uploading score file'
        rootState.general.loading = true
        console.log('Score to be uploaded', file)
        const url = Vue.http.options.root + '/api/scoreUpload'
        console.log('URL:', url)
        const config = {
          headers: {
            Authorization: rootState.loggedinUser.token
          },
          onUploadProgress: progressEvent => {
            let percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            rootState.general.loadingMsg = `Uploading score file ${percentage}%`
            // Don't wait for server to reply
            if (percentage === 100) {
              rootState.general.loading = false
            }
          }
        }
        axios.post(url, file, config).then(
          () => {
            state.fileUploadSuccess = true
            rootState.general.loading = false
            return resolve('Score uploaded')
          },
          error => {
            if (error.response.statusText === 'Payload Too Large') {
              commit('sendToast', { type: 'error', content: 'Uploaded score file too big' }, { root: true })
              state.fileUploadErrorText = 'Uploaded score file too big'
            } else {
              commit('sendToast', { type: 'error', content: error.response.statusText }, { root: true })
            }
            rootState.general.loading = false
          }
        )
      })
    }
  }
}

const FixComposersLyricists = show => {
  for (var composer of show.composers) {
    composer.label = composer.lastName + ' ' + composer.firstName
  }
  for (var lyricist of show.lyricists) {
    lyricist.label = lyricist.lastName + ' ' + lyricist.firstName
  }
}

// const FixComposersLyricists = show => {
//   const lyricistNames = []
//   const composersNames = []
//   if (show.lyricists) {
//     let lyricistsList = Object.values(show.lyricists)
//     for (var lyricist of lyricistsList) {
//       lyricistNames.push({ label: `${lyricist.lastName}, ${lyricist.firstName}`, _id: lyricist._id })
//     }
//   }
//   if (show.composers) {
//     let composersList = Object.values(show.composers)
//     for (var composer of composersList) {
//       composersNames.push({ label: `${composer.lastName}, ${composer.firstName}`, _id: composer._id })
//     }
//   }
//   show.lyricistsListNames = lyricistNames
//   show.composersListNames = composersNames
// }
