var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "lyricist-edit",
            height: "auto",
            width: "1000",
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _c("div", { staticClass: "show-form" }, [
            _vm.currentLyricist._id
              ? _c("h5", [_vm._v(" Edit Lyricist ")])
              : _c("h5", [_vm._v(" New Lyricist ")]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 form-block" }, [
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "firstName" }
                      },
                      [_vm._v("First Name")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.currentLyricist.firstName,
                          expression: "currentLyricist.firstName",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.attemptSubmit && _vm.missingFirstName
                      },
                      attrs: {
                        id: "firstName",
                        name: "firstName",
                        type: "text"
                      },
                      domProps: { value: _vm.currentLyricist.firstName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentLyricist,
                            "firstName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingFirstName
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" First Name is required. ")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "lastName" }
                      },
                      [_vm._v("Last Name")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.currentLyricist.lastName,
                          expression: "currentLyricist.lastName",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.attemptSubmit && _vm.missingLastName
                      },
                      attrs: { type: "text", id: "lastName", name: "lastName" },
                      domProps: { value: _vm.currentLyricist.lastName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentLyricist,
                            "lastName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingLastName
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" Last Name is required. ")]
                    )
                  ]),
                  _c("div", { staticClass: "offset-1 col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-secondary btn-xs song-button",
                        staticStyle: { "margin-right": "5px" },
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-success btn-xs song-button",
                        attrs: { disabled: _vm.formsHasErrors },
                        on: { click: _vm.saveLyricist }
                      },
                      [_vm._v(" Save ")]
                    ),
                    _vm.$can("delete", "lyricists") && _vm.currentLyricist._id
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-danger btn-xs song-button",
                            attrs: { disabled: !_vm.canDelete },
                            on: { click: _vm.deleteLyricist }
                          },
                          [_vm._v(" Delete ")]
                        )
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentLyricist.isFemale,
                          expression: "currentLyricist.isFemale"
                        }
                      ],
                      staticClass: "featured",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.currentLyricist.isFemale)
                          ? _vm._i(_vm.currentLyricist.isFemale, null) > -1
                          : _vm.currentLyricist.isFemale
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentLyricist.isFemale,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentLyricist,
                                  "isFemale",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentLyricist,
                                  "isFemale",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentLyricist, "isFemale", $$c)
                          }
                        }
                      }
                    }),
                    _c("b", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v("Women/Nonbinary")
                    ])
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentLyricist.isBIPOC,
                          expression: "currentLyricist.isBIPOC"
                        }
                      ],
                      staticClass: "featured",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.currentLyricist.isBIPOC)
                          ? _vm._i(_vm.currentLyricist.isBIPOC, null) > -1
                          : _vm.currentLyricist.isBIPOC
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentLyricist.isBIPOC,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentLyricist,
                                  "isBIPOC",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentLyricist,
                                  "isBIPOC",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentLyricist, "isBIPOC", $$c)
                          }
                        }
                      }
                    }),
                    _c("b", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v("Global Majority")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "lastName" } },
                      [_vm._v("Info URL")]
                    )
                  ]),
                  _c("div", { staticClass: "col-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.currentLyricist.infoUrl,
                          expression: "currentLyricist.infoUrl",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "infoUrl", name: "infoUrl" },
                      domProps: { value: _vm.currentLyricist.infoUrl },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentLyricist,
                            "infoUrl",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _vm.currentLyricist.infoUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.currentLyricist.infoUrl,
                              target: "blank"
                            }
                          },
                          [_vm._v("test link")]
                        )
                      : _vm._e()
                  ])
                ]),
                _vm.currentLyricist._id
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Created")]
                        )
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.currentLyricist.dateCreated
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Created By")]
                        )
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("emptyVal")(_vm.currentLyricist.enteredBy)
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.currentLyricist._id
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Last Updated")]
                        )
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.currentLyricist.lastUpdated
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Updated By")]
                        )
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("emptyVal")(_vm.currentLyricist.updatedBy)
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }