var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.enabled,
              expression: "enabled"
            }
          ],
          staticClass: "css-checkbox",
          attrs: {
            type: "checkbox",
            name: _vm.linkName + _vm.type,
            id: _vm.linkName + _vm.type
          },
          domProps: {
            checked: Array.isArray(_vm.enabled)
              ? _vm._i(_vm.enabled, null) > -1
              : _vm.enabled
          },
          on: {
            change: function($event) {
              var $$a = _vm.enabled,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.enabled = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.enabled = $$c
              }
            }
          }
        }),
        _c(
          "label",
          {
            staticClass: "css-label lite-red-check",
            attrs: { for: _vm.linkName + _vm.type }
          },
          [_vm._v(_vm._s(_vm.linkName))]
        )
      ])
    ]),
    _vm.enabled
      ? _c("div", { staticClass: "external-links" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.automatic,
                    expression: "automatic"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: _vm.linkName + _vm.type + "radio"
                },
                domProps: { value: true, checked: _vm._q(_vm.automatic, true) },
                on: {
                  change: function($event) {
                    _vm.automatic = true
                  }
                }
              }),
              _c(
                "label",
                { attrs: { for: _vm.linkName + _vm.type + "radio" } },
                [
                  _vm._v("Automatic Link "),
                  _vm.automatic
                    ? _c(
                        "span",
                        {
                          staticClass: "link",
                          on: { click: _vm.testAutoScore }
                        },
                        [_vm._v("Test Link")]
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm.automatic
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.includeSongTitle,
                        expression: "includeSongTitle"
                      }
                    ],
                    staticClass: "css-checkbox",
                    attrs: {
                      type: "checkbox",
                      name: _vm.linkName + _vm.type + "check",
                      id: _vm.linkName + _vm.type + "check"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.includeSongTitle)
                        ? _vm._i(_vm.includeSongTitle, null) > -1
                        : _vm.includeSongTitle
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.includeSongTitle,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.includeSongTitle = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.includeSongTitle = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.includeSongTitle = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "css-label lite-red-check external-links",
                      attrs: { for: _vm.linkName + _vm.type + "check" }
                    },
                    [
                      _vm._v(
                        " Include song title in addition to show title in automatic link "
                      )
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.automatic,
                    expression: "automatic"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: _vm.linkName + _vm.type + "radio"
                },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.automatic, false)
                },
                on: {
                  change: function($event) {
                    _vm.automatic = false
                  }
                }
              }),
              _c(
                "label",
                { attrs: { for: _vm.linkName + _vm.type + "radio" } },
                [
                  _vm._v("Manual Link "),
                  !_vm.automatic
                    ? _c(
                        "span",
                        {
                          staticClass: "link",
                          on: { click: _vm.testManualScore }
                        },
                        [_vm._v("Test Link")]
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          !_vm.automatic
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.url,
                        expression: "url"
                      }
                    ],
                    staticClass: "external-links",
                    attrs: { type: "text", placeholder: "http://" },
                    domProps: { value: _vm.url },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.url = $event.target.value
                      }
                    }
                  })
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }