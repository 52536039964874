export const saveUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const loadUser = () => {
  let user = JSON.parse(localStorage.getItem('user'))
  if (!user) {
    user = undefined
  }
  return user
}

export const savePageSetting = setting => {
  localStorage.setItem(setting.name, setting.value)
}

export const loadPageSetting = name => {
  return parseInt(localStorage.getItem(name))
}
