var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "below-menu" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [
          _c("img", { attrs: { src: "/loading.gif" } }),
          _c("div", { staticClass: "loading" }, [
            _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
          ])
        ])
      : _vm._e(),
    _vm.error
      ? _c("div", { staticClass: "server-error" }, [
          _vm._v(" " + _vm._s(_vm.error) + " ")
        ])
      : _vm._e(),
    _c("div", { staticClass: "row fixed-top page-header" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-2" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            on: { click: _vm.newLyricist }
          },
          [_vm._v(" Add Lyricist ")]
        ),
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            staticStyle: { "margin-right": "10px" },
            on: { click: _vm.copyContent }
          },
          [_vm._v(" Copy ")]
        )
      ])
    ]),
    _vm.data
      ? _c(
          "div",
          [
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.rows,
                "row-style-class": _vm.rowStyleClassFn,
                "pagination-options": { enabled: false },
                "search-options": { enabled: false },
                "line-numbers": false,
                "sort-options": {
                  enabled: true,
                  initialSortBy: {
                    field: _vm.initialSortField,
                    type: _vm.initialSortType
                  }
                },
                "style-class": "vgt-table musical bordered condensed"
              },
              on: {
                "on-page-change": _vm.pageChanged,
                "on-cell-click": _vm.cellClick
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field === "isBIPOC"
                          ? _c("span", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: props.row.isBIPOC,
                                    expression: "props.row.isBIPOC"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(props.row.isBIPOC)
                                    ? _vm._i(props.row.isBIPOC, null) > -1
                                    : props.row.isBIPOC
                                },
                                on: {
                                  click: function($event) {
                                    if (!$event.ctrlKey) {
                                      return null
                                    }
                                    _vm.copyToComposers = false
                                  },
                                  change: [
                                    function($event) {
                                      var $$a = props.row.isBIPOC,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              props.row,
                                              "isBIPOC",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              props.row,
                                              "isBIPOC",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(props.row, "isBIPOC", $$c)
                                      }
                                    },
                                    function($event) {
                                      return _vm.changeIsBipoc(
                                        $event,
                                        props.row._id
                                      )
                                    }
                                  ]
                                }
                              })
                            ])
                          : _vm._e(),
                        props.column.field === "isFemale"
                          ? _c("span", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: props.row.isFemale,
                                    expression: "props.row.isFemale"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(props.row.isFemale)
                                    ? _vm._i(props.row.isFemale, null) > -1
                                    : props.row.isFemale
                                },
                                on: {
                                  click: function($event) {
                                    if (!$event.ctrlKey) {
                                      return null
                                    }
                                    _vm.copyToComposers = false
                                  },
                                  change: [
                                    function($event) {
                                      var $$a = props.row.isFemale,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              props.row,
                                              "isFemale",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              props.row,
                                              "isFemale",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(props.row, "isFemale", $$c)
                                      }
                                    },
                                    function($event) {
                                      return _vm.changeIsfemale(
                                        $event,
                                        props.row._id
                                      )
                                    }
                                  ]
                                }
                              })
                            ])
                          : _vm._e(),
                        props.column.field !== "isBIPOC" &&
                        props.column.field !== "isFemale"
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                1543206703
              )
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-10" }, [
      _c("h5", [_vm._v("Lyricists")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }