var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.active,
              expression: "model.active"
            }
          ],
          staticClass: "css-checkbox",
          attrs: {
            type: "checkbox",
            name: _vm.linkName,
            id: _vm.type + _vm.linkName
          },
          domProps: {
            checked: Array.isArray(_vm.model.active)
              ? _vm._i(_vm.model.active, null) > -1
              : _vm.model.active
          },
          on: {
            change: function($event) {
              var $$a = _vm.model.active,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.model, "active", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.model,
                      "active",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.model, "active", $$c)
              }
            }
          }
        }),
        _c(
          "label",
          {
            staticClass: "css-label lite-red-check",
            attrs: { for: _vm.linkName }
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      ])
    ]),
    _vm.model.active
      ? _c("div", { staticClass: "external-link" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.type,
                    expression: "model.type"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: _vm.linkName + "radio",
                  value: "automatic"
                },
                domProps: { checked: _vm._q(_vm.model.type, "automatic") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.model, "type", "automatic")
                  }
                }
              }),
              _c("label", { attrs: { for: _vm.linkName + "radio" } }, [
                _vm._v("Automatic Link "),
                _vm.model.type === "automatic"
                  ? _c(
                      "span",
                      { staticClass: "link", on: { click: _vm.testAutoScore } },
                      [_vm._v("Test Link")]
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _vm.model.type === "automatic"
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.includeTitle,
                        expression: "model.includeTitle"
                      }
                    ],
                    staticClass: "css-checkbox",
                    attrs: {
                      type: "checkbox",
                      name: _vm.linkName + "check",
                      id: _vm.type + _vm.linkName + "check"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.model.includeTitle)
                        ? _vm._i(_vm.model.includeTitle, null) > -1
                        : _vm.model.includeTitle
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.model.includeTitle,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.model,
                                "includeTitle",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.model,
                                "includeTitle",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.model, "includeTitle", $$c)
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "css-label lite-red-check external-link",
                      attrs: { for: _vm.linkName + "check" }
                    },
                    [
                      _vm._v(
                        " Include song title in addition to show title in automatic link "
                      )
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.type,
                    expression: "model.type"
                  }
                ],
                attrs: {
                  type: "radio",
                  name: _vm.linkName + "radio",
                  value: "manual"
                },
                domProps: { checked: _vm._q(_vm.model.type, "manual") },
                on: {
                  change: function($event) {
                    return _vm.$set(_vm.model, "type", "manual")
                  }
                }
              }),
              _c("label", { attrs: { for: _vm.linkName + "radio" } }, [
                _vm._v("Manual Link "),
                _vm.model.type === "manual"
                  ? _c(
                      "span",
                      {
                        staticClass: "link",
                        on: { click: _vm.testManualScore }
                      },
                      [_vm._v("Test Link")]
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _vm.model.type === "manual"
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.manualLink,
                        expression: "model.manualLink"
                      }
                    ],
                    staticClass: "external-link",
                    attrs: { type: "text", placeholder: "http://" },
                    domProps: { value: _vm.model.manualLink },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.model, "manualLink", $event.target.value)
                      }
                    }
                  })
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }