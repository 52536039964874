var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row form-row" }, [
    _vm._m(0),
    _c("div", { staticClass: "col-6" }, [
      _vm.checkWorldCatScoresWarning()
        ? _c("div", [
            _vm._m(1),
            _c("br"),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentShow.worldcat.scoresOverride,
                  expression: "currentShow.worldcat.scoresOverride"
                }
              ],
              attrs: { type: "checkbox", id: "cbScoresWarningOverride" },
              domProps: {
                checked: Array.isArray(_vm.currentShow.worldcat.scoresOverride)
                  ? _vm._i(_vm.currentShow.worldcat.scoresOverride, null) > -1
                  : _vm.currentShow.worldcat.scoresOverride
              },
              on: {
                change: function($event) {
                  var $$a = _vm.currentShow.worldcat.scoresOverride,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.currentShow.worldcat,
                          "scoresOverride",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.currentShow.worldcat,
                          "scoresOverride",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.currentShow.worldcat, "scoresOverride", $$c)
                  }
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "override",
                attrs: {
                  for: "cbScoresWarningOverride",
                  name: "ScoresWarningOverrideLbl"
                }
              },
              [_vm._v("Yes, change the settings for ALL songs in this show.")]
            )
          ])
        : _vm._e(),
      !_vm.checkWorldCatScoresWarning() ||
      _vm.currentShow.worldcat.scoresOverride
        ? _c("div", [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentShow.worldcat.scoresEnabled,
                    expression: "currentShow.worldcat.scoresEnabled"
                  }
                ],
                staticClass: "css-checkbox",
                attrs: { type: "checkbox", id: "checkbox3" },
                domProps: {
                  checked: Array.isArray(_vm.currentShow.worldcat.scoresEnabled)
                    ? _vm._i(_vm.currentShow.worldcat.scoresEnabled, null) > -1
                    : _vm.currentShow.worldcat.scoresEnabled
                },
                on: {
                  click: function($event) {
                    _vm.currentShow.worldcat.scoresAutomatic = true
                  },
                  change: function($event) {
                    var $$a = _vm.currentShow.worldcat.scoresEnabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.currentShow.worldcat,
                            "scoresEnabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.currentShow.worldcat,
                            "scoresEnabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.currentShow.worldcat, "scoresEnabled", $$c)
                    }
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "css-label lite-red-check",
                  attrs: { for: "checkbox3", name: "checkbox3_lbl" }
                },
                [_vm._v("Link to Score")]
              )
            ]),
            _vm.currentShow.worldcat.scoresEnabled
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "25px",
                      "margin-bottom": "20px"
                    }
                  },
                  [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentShow.worldcat.scoresAutomatic,
                            expression: "currentShow.worldcat.scoresAutomatic"
                          }
                        ],
                        attrs: { type: "radio", id: "radio1", name: "score" },
                        domProps: {
                          value: true,
                          checked: _vm._q(
                            _vm.currentShow.worldcat.scoresAutomatic,
                            true
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.currentShow.worldcat,
                              "scoresAutomatic",
                              true
                            )
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "radio1" } }, [
                        _vm._v("Automatic Link "),
                        _vm.currentShow.worldcat.scoresAutomatic
                          ? _c(
                              "span",
                              {
                                staticClass: "link",
                                on: { click: _vm.testAutoScore }
                              },
                              [_vm._v("Test Link")]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentShow.worldcat.scoresAutomatic,
                            expression: "currentShow.worldcat.scoresAutomatic"
                          }
                        ],
                        attrs: { type: "radio", id: "radio2", name: "score" },
                        domProps: {
                          value: false,
                          checked: _vm._q(
                            _vm.currentShow.worldcat.scoresAutomatic,
                            false
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.currentShow.worldcat,
                              "scoresAutomatic",
                              false
                            )
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "radio2" } }, [
                        _vm._v("Manual Link "),
                        !_vm.currentShow.worldcat.scoresAutomatic
                          ? _c(
                              "span",
                              {
                                staticClass: "link",
                                on: { click: _vm.testManualScore }
                              },
                              [_vm._v("Test Link")]
                            )
                          : _vm._e()
                      ]),
                      _c("div", [
                        !_vm.currentShow.worldcat.scoresAutomatic
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentShow.worldcat.scoresSameUrl,
                                  expression:
                                    "currentShow.worldcat.scoresSameUrl"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { type: "text", placeholder: "http://" },
                              domProps: {
                                value: _vm.currentShow.worldcat.scoresSameUrl
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.currentShow.worldcat,
                                    "scoresSameUrl",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.checkWorldCatRecordingsWarning()
        ? _c("div", [
            _vm._m(2),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentShow.worldcat.recordingsOverride,
                  expression: "currentShow.worldcat.recordingsOverride"
                }
              ],
              attrs: { type: "checkbox", id: "cbScoresWarningOverride" },
              domProps: {
                checked: Array.isArray(
                  _vm.currentShow.worldcat.recordingsOverride
                )
                  ? _vm._i(_vm.currentShow.worldcat.recordingsOverride, null) >
                    -1
                  : _vm.currentShow.worldcat.recordingsOverride
              },
              on: {
                change: function($event) {
                  var $$a = _vm.currentShow.worldcat.recordingsOverride,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.currentShow.worldcat,
                          "recordingsOverride",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.currentShow.worldcat,
                          "recordingsOverride",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(
                      _vm.currentShow.worldcat,
                      "recordingsOverride",
                      $$c
                    )
                  }
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "override",
                attrs: {
                  for: "cbScoresWarningOverride",
                  name: "ScoresWarningOverrideLbl"
                }
              },
              [
                _vm._v(
                  "Yes, change the settings for ALL recordings in this show."
                )
              ]
            )
          ])
        : _vm._e(),
      !_vm.checkWorldCatRecordingsWarning() ||
      _vm.currentShow.worldcat.recordingsOverride
        ? _c("div", [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentShow.worldcat.recordingsEnabled,
                    expression: "currentShow.worldcat.recordingsEnabled"
                  }
                ],
                staticClass: "css-checkbox",
                attrs: { type: "checkbox", id: "checkbox3Rec" },
                domProps: {
                  checked: Array.isArray(
                    _vm.currentShow.worldcat.recordingsEnabled
                  )
                    ? _vm._i(_vm.currentShow.worldcat.recordingsEnabled, null) >
                      -1
                    : _vm.currentShow.worldcat.recordingsEnabled
                },
                on: {
                  click: function($event) {
                    _vm.currentShow.worldcat.recordingsAutomatic = true
                  },
                  change: function($event) {
                    var $$a = _vm.currentShow.worldcat.recordingsEnabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.currentShow.worldcat,
                            "recordingsEnabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.currentShow.worldcat,
                            "recordingsEnabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.currentShow.worldcat,
                        "recordingsEnabled",
                        $$c
                      )
                    }
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "css-label lite-red-check",
                  attrs: { for: "checkbox3Rec", name: "checkbox3_lbl" }
                },
                [_vm._v("Link to Recordings")]
              )
            ]),
            _vm.currentShow.worldcat.recordingsEnabled
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "25px",
                      "margin-bottom": "20px"
                    }
                  },
                  [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentShow.worldcat.recordingsAutomatic,
                            expression:
                              "currentShow.worldcat.recordingsAutomatic"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "radio1Rec",
                          name: "recording"
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(
                            _vm.currentShow.worldcat.recordingsAutomatic,
                            true
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.currentShow.worldcat,
                              "recordingsAutomatic",
                              true
                            )
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "radio1Rec" } }, [
                        _vm._v("Automatic Link "),
                        _vm.currentShow.worldcat.recordingsAutomatic
                          ? _c(
                              "span",
                              {
                                staticClass: "link",
                                on: { click: _vm.testAutoRecording }
                              },
                              [_vm._v("Test Link")]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentShow.worldcat.recordingsAutomatic,
                            expression:
                              "currentShow.worldcat.recordingsAutomatic"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "radio2Rec",
                          name: "recording"
                        },
                        domProps: {
                          value: false,
                          checked: _vm._q(
                            _vm.currentShow.worldcat.recordingsAutomatic,
                            false
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.currentShow.worldcat,
                              "recordingsAutomatic",
                              false
                            )
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "radio2Rec" } }, [
                        _vm._v("Manual Link "),
                        !_vm.currentShow.worldcat.recordingsAutomatic
                          ? _c(
                              "span",
                              {
                                staticClass: "link",
                                on: { click: _vm.testManualRecording }
                              },
                              [_vm._v("Test Link")]
                            )
                          : _vm._e()
                      ]),
                      _c("div", [
                        !_vm.currentShow.worldcat.recordingsAutomatic
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.currentShow.worldcat.recordingsSameUrl,
                                  expression:
                                    "currentShow.worldcat.recordingsSameUrl"
                                }
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { type: "text", placeholder: "http://" },
                              domProps: {
                                value:
                                  _vm.currentShow.worldcat.recordingsSameUrl
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.currentShow.worldcat,
                                    "recordingsSameUrl",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4 label" }, [
      _c("label", { staticClass: "label", attrs: { for: "showWorldCat" } }, [
        _vm._v("WorldCat")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("i", [
        _vm._v(
          "The WorldCat settings for this show are not the same for each song. If you change the settings here, they will overwrite the settings for individual songs."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _c("i", [
          _vm._v("The WorldCat "),
          _c("u", [_vm._v("recording")]),
          _vm._v(
            " settings for this show are not the same for each song. If you change the settings here, they will overwrite the settings for individual songs."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }