var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "below-menu" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [
          _c("img", { attrs: { src: "/loading.gif" } }),
          _c("div", { staticClass: "loading" }, [
            _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "row fixed-top page-header" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            on: { click: _vm.newResource }
          },
          [_vm._v(" Add New Resource ")]
        ),
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            staticStyle: { "margin-right": "10px" },
            on: { click: _vm.copyContent }
          },
          [_vm._v(" Copy ")]
        )
      ])
    ]),
    _vm.data
      ? _c(
          "div",
          { staticClass: "table-block" },
          [
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.rows,
                "pagination-options": { enabled: false },
                "search-options": { enabled: false },
                "sort-options": {
                  enabled: true,
                  initialSortBy: { field: "title", type: "asc" }
                },
                "line-numbers": false,
                "style-class": "vgt-table musical bordered condensed"
              },
              on: { "on-row-click": _vm.rowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "delete"
                          ? _c("span", [
                              _vm.$can("delete", "faq")
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteResource(
                                            props.row.originalIndex
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("delete resource")]
                                  )
                                : _vm._e()
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ],
                null,
                false,
                346032337
              )
            })
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "row button-row" }, [
      _c("div", { staticClass: "col-10" }, [
        _vm.error
          ? _c("div", { staticClass: "error-message text-right" }, [
              _vm._v(" " + _vm._s(_vm.error) + " ")
            ])
          : _vm._e(),
        _c("div", { staticClass: "loading-message text-right" }, [
          _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
        ])
      ]),
      _c("div", { staticClass: "col-2" }, [
        _c("div", { staticClass: "text-right save-btn" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-xs",
              on: { click: _vm.saveAnswer }
            },
            [_vm._v(" Save Resource ")]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row form-row" }, [
      _vm._m(1),
      _c("div", { staticClass: "col-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.curResource.title,
              expression: "curResource.title",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", id: "title", name: "title" },
          domProps: { value: _vm.curResource.title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.curResource, "title", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "row form-row" }, [
      _vm._m(2),
      _c("div", { staticClass: "col-10" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.curResource.link,
              expression: "curResource.link",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", id: "link", name: "link" },
          domProps: { value: _vm.curResource.link },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.curResource, "link", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "row form-row", staticStyle: { "min-height": "300px" } },
      [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _c("quill-editor", {
              ref: "myQuillEditor1",
              staticStyle: { height: "200px !important" },
              attrs: { id: "description", options: _vm.editorOption },
              on: {
                ready: function($event) {
                  return _vm.onEditorReady($event)
                }
              },
              model: {
                value: _vm.curResource.description,
                callback: function($$v) {
                  _vm.$set(_vm.curResource, "description", $$v)
                },
                expression: "curResource.description"
              }
            }),
            _c(
              "div",
              {
                class: [
                  _vm.attemptSubmit && _vm.missingTitle
                    ? "is-not-valid"
                    : "is-valid"
                ]
              },
              [_vm._v(" Question is required. ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-9" }, [
      _c("h5", [_vm._v("Resources")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-1 label" }, [
      _c(
        "label",
        { staticClass: "label required", attrs: { for: "question" } },
        [_vm._v("Title")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-1 label" }, [
      _c("label", { staticClass: "label required", attrs: { for: "link" } }, [
        _vm._v("Link")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-1 label" }, [
      _c(
        "label",
        { staticClass: "label required", attrs: { for: "question" } },
        [_vm._v("Description")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }