<template>
  <div class="below-menu">
    <div class="row fixed-top page-header">
      <div class="col-9">
        <h5>Users - {{ tableTitle }}</h5>
      </div>
      <div class="col-3">
        <button class="btn btn-outline-primary btn-xs float-right" @click="onNewSubscription">
          Add Subscription
        </button>
        <button class="btn btn-outline-primary btn-xs float-right" style="margin-right:10px;" @click="onNewUser">
          Add User
        </button>
          <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <!-- <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 25,
          perPageDropdown: [10, 15, 20, 25, 30, 35, 40],
          position: 'top'
        }"
        @on-page-change="pageChanged"
        :line-numbers="false"
        @on-row-click="rowClick"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: initialSortField, type: initialSortType }
        }"
        style-class="vgt-table musical bordered condensed"
      > -->

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-row-click="rowClick"
      @on-cell-click="cellClick"
      :key="tableKey"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        perPageDropdown: [15, 20, 25, 30, 35, 40, 50],
        position: 'top',
        mode: 'records'
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{ field: sortField, type: sortType }]
      }"
      :rows="rows"
      :columns="columns"
      style-class="vgt-table musical bordered condensed"
    >
      <div class="loading" slot="loadingContent">
        <div v-if="loadingErrorMsg" class="loading server-error">
          {{ loadingErrorMsg }}
        </div>
        <div v-else>
          <img src="/loading.gif" />
          {{ isLoadingMsg }}
        </div>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'delete'">
          <a href="#" v-show="props.row.planName && props.row.planName.includes('No subscription')">Delete</a>
        </span>
      </template>

      <div slot="table-actions">
        <!-- Appears above the column names -->
        <!-- Filter on Featured Composers -->
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="all"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">All Users</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="subscriber"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Subscribers</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="creator"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Creators</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="dataAssistant"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Data Assistants</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="marketingAssistant"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Markting Assistants</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="admin"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Administrators</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="active"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Users without subscription</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="groupAdmins"
          @change="usersSelectChanged"
        />
        <i style="padding-left:0px;margin-right: 10px;">Group Admins</i>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Users',
    mixins: [copyTable],
    data() {
      return {
        sortField: 'lastName',
        sortType: 'asc',
        firstNameFilter: '',
        lastNameFilter: '',
        emailFilter: '',
        planFilter: '',
        subIdFilter: '',
        tableFilter: {},
        isLoading: false,
        isLoadingMsg: '',
        loadingErrorMsg: '',
        totalRecords: 0,
        serverParams: {
          columnFilters: { role: 'all' },
          page: 1,
          perPage: 15
        },
        rows: [],
        usersSelect: 'all',
        tableTitle: 'All Users',
        columns: [
          {
            label: 'First Name',
            field: 'firstName',
            width: '12%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by first name'
            }
          },
          {
            label: 'Last Name',
            field: 'lastName',
            width: '12%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by last name'
            }
          },
          {
            label: 'Email',
            field: 'email',
            width: '17%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by email',
              filterValue: this.emailFilter
            }
          },
          {
            label: 'Subscription Type',
            field: 'planName',
            width: '17%',
            type: 'string',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by subscription type',
              filterDropdownItems: [
                'Yearly',
                'Yearly (Academic Discount)',
                'Yearly (Special Academic Discount)',
                'Monthly',
                'Institutional Free Trial - individual',
                'Institutional Free Trial - institution-wide',
                'Custom Dates - No Reminder Email',
                'Group Subscription',
                'No subscription'
              ]
            }
          },
          {
            label: 'Subsc. Expiration',
            field: 'expirationDate',
            width: '10%',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            filterOptions: {
              enabled: false
            }
          },
          // {
          //   label: 'Subscription ID',
          //   field: 'subscriptionId',
          //   width: '17%',
          //   type: 'string',
          //   filterOptions: {
          //     enabled: true
          //   },
          //   sortable: false
          // },
          {
            label: 'Role',
            field: this.showRoles,
            width: '5%',
            type: 'string',
            filterOptions: {
              enabled: false // enable filter for this column
            },
            sortable: false
          },
          {
            label: '',
            field: 'delete',
            width: '5%',
            sortable: false
          }
        ]
      }
    },
    // created() {
    //   eventbus.$on('userCreatedOrUpdated', () => {
    //     console.log('Received userCreatedOrUpdated')
    //     this.$store.commit('users/tableUpdate')
    //     // this.loadItems()
    //     // this.rows = this.usersList
    //   })
    // },
    // beforeDestroy() {
    //   eventbus.$off('userCreatedOrUpdated')
    // },
    mounted() {
      this.$store.commit('general/setMenuHighlight', 'Users')
      this.$store
        .dispatch('general/getRowsPerPage', 'usersPP')
        .then(value => {
          this.serverParams.perPage = value
          this.updateParams({ page: 1, perPage: value })
        })
        .catch(ex => {
          this.$log.info(`Exception in getRowsPerPage ${ex.message}`)
        })
    },
    computed: {
      ...mapState({
        tableKey: state => state.users.tableKey,
        loggedinUser: state => state.general.loggedinUser,
        usersList: state => state.users.usersList
      })
    },
    methods: {
      rowClick() {
        // This is only needed to get the row highlight
      },
      updateParams(newProps) {
        this.serverParams = Object.assign(this.serverParams, newProps)
        this.serverParams.columnFilters = Object.assign(this.serverParams.columnFilters, this.tableFilter.columnFilters)
        Object.keys(this.serverParams).forEach(
          key => (this.serverParams[key] === null || this.serverParams[key] === '') && delete this.serverParams[key]
        )
        if (this.serverParams.columnFilters) {
          Object.keys(this.serverParams.columnFilters).forEach(
            key =>
              (this.serverParams.columnFilters[key] === null || this.serverParams.columnFilters[key] === '') &&
              delete this.serverParams.columnFilters[key]
          )
        }

        this.$log.info(`updateParams serverParams: ${JSON.stringify(this.serverParams)}`)
      },
      onPageChange(params) {
        this.$log.info('onPageChange', params)
        this.updateParams({ page: params.currentPage })
        this.loadItems()
      },
      onPerPageChange(params) {
        this.$log.info('onPerPageChange', params)
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
        this.$store.dispatch('general/saveRowsPerPage', { name: 'usersPP', value: params.currentPerPage })
      },
      onSortChange(params) {
        this.$log.info('onSortChange', params)
        this.updateParams({
          page: 1,
          sort: [
            {
              type: params[0].type,
              field: params[0].field
            }
          ]
        })
        this.sortField = params[0].field
        this.sortType = params[0].type
        this.loadItems()
      },
      onColumnFilter(params) {
        this.$log.info('onColumnFilter', params)
        this.firstNameFilter = params.columnFilters.firstName ? params.columnFilters.firstName : ''
        this.lastNameFilter = params.columnFilters.lastName ? params.columnFilters.lastName : ''
        this.emailFilter = params.columnFilters.email ? params.columnFilters.email : ''
        this.planFilter = params.columnFilters.planName ? params.columnFilters.planName : ''
        this.subIdFilter = params.columnFilters.subscriptionId ? params.columnFilters.subscriptionId : ''
        this.updateParams(params)
        this.updateParams({ page: 1 })
        this.loadItems()
      },
      loadItems() {
        // load items is what brings back the rows from server
        this.isLoadingMsg = 'Loading Users'
        this.$log.info('loadItems')
        this.$store
          .dispatch('users/getFromServer', this.serverParams)
          .then(response => {
            this.totalRecords = response.totalRecords
            for (var user of this.usersList) {
              const isGroupSubscription = user.planName === 'Group Subscription' ? '👥 ' : ''
              user.planName =
                isGroupSubscription + user.planName
            }
            this.rows = this.usersList
            this.setColumnFilters()
          })
          .catch(err => {
            this.totalRecords = 0
            this.rows = []
            this.$log.info('Error in loadItems', err)
            this.loadingErrorMsg = err
          })
      },
      setColumnFilters() {
        this.columns[0].filterOptions.filterValue = this.firstNameFilter
        this.columns[1].filterOptions.filterValue = this.lastNameFilter
        this.columns[2].filterOptions.filterValue = this.emailFilter
        this.columns[3].filterOptions.filterValue = this.planFilter
        this.columns[5].filterOptions.filterValue = this.subIdFilter
      },
      onNewSubscription() {
        // see https://github.com/euvl/vue-js-modal#readme
        this.$store.commit('subscriptions/setNewSubscription')
        eventbus.$emit('modal', { command: 'open', name: 'subscription-edit' })
      },
      onNewUser() {
        this.$store.commit('users/setCurrentUser', {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          roles: []
        })
        eventbus.$emit('modal', { command: 'open', name: 'user-edit' })
        // this.$modal.show('user-edit')
      },
      delUser(params) {
        if (confirm(`Are you sure you want to delete user "${params.email}"?\n\nThis action cannot be undone.`)) {
          this.$store.dispatch('users/deleteUser', params._id)
          console.log(`Deleted user ${params._id}`)
        }
      },
      showRoles(robObj) {
        if (robObj.roles) {
          return robObj.roles.toString()
        } else {
          console.error(`${robObj.email} has no roles`)
        }
      },
      cellClick(params) {
        console.log('Cell clicked', params)
        if (params.column && params.column.field === 'delete' && params.event.target.innerText === 'Delete') {
          this.delUser(params.row)
          return
        }
        this.attemptSubmit = false
        console.log('Cell clicked', params.row._id, params.row.subscriptionId)
        // Wait for dispatch success
        this.$store.dispatch('users/getUser', params.row).then(
          () => {
            const rows = document.querySelectorAll('.clickable')
            rows.forEach((row, rowIndex) => {
              const a = rows[rowIndex]
              a.classList.remove('clicked')
              if (params.pageIndex === rowIndex) {
                a.classList.add('clicked')
              }
            })
            eventbus.$emit('modal', { command: 'open', name: 'user-edit', subId: params.row.subscriptionId })
          },
          error => {
            console.log(`[Users.vue] Error getting user: ${error}`)
          }
        )
      },
      usersSelectChanged() {
        switch (this.usersSelect) {
          case 'all':
            this.tableTitle = 'All users'
            this.tableFilter = { columnFilters: { role: 'all' } }
            break
          case 'subscriber':
            this.tableTitle = 'Subscribers'
            this.tableFilter = { columnFilters: { role: 'subscriber' } }
            break
          case 'creator':
            this.tableTitle = 'Composers or Lyricists'
            this.tableFilter = { columnFilters: { role: 'creator' } }
            break
          case 'dataAssistant':
            this.tableTitle = 'Data Assistants'
            this.tableFilter = { columnFilters: { role: 'dataAssistant' } }
            break
          case 'marketingAssistant':
            this.tableTitle = 'Marketing Assistants'
            this.tableFilter = { columnFilters: { role: 'marketingAssistant' } }
            break
          case 'admin':
            this.tableTitle = 'Administrators'
            this.tableFilter = { columnFilters: { role: 'admin' } }
            break
          case 'active':
            this.tableTitle = 'Without Subscriptions'
            this.tableFilter = { columnFilters: { role: 'nosub' } }
            break
          case 'groupAdmins':
            this.tableTitle = 'Group Admins'
            this.tableFilter = { columnFilters: { role: 'groupadmin' } }
            break
        }
        this.updateParams({ page: 1 })
        this.loadItems()
      }
    }
  }
</script>

<style>
  .vgt-global-search__input {
    padding-left: 0;
    flex-grow: unset;
  }
</style>
