<template>
  <div class="below-menu">
    <div class="row fixed-top page-header">
      <div class="col-9">
        <h5>{{ tableTitle }}</h5>
      </div>
      <div class="col-3">
        <button class="btn btn-outline-primary btn-xs float-right" @click="onNewSubscription">
          Add Subscription
        </button>
          <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <!-- <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 15,
          perPageDropdown: [5, 10, 15, 20, 25],
          position: 'top'
        }"
        @on-page-change="pageChanged"
        :line-numbers="false"
        :sort-options="{
          enabled: false,
          initialSortBy: { field: initialSortField, type: initialSortType }
        }"
        :search-options="{ enabled: false }"
        style-class="vgt-table musical bordered condensed"
      > -->
    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-row-click="rowClick"
      :key="tableKey"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
        position: 'top',
        mode: 'records'
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{ field: 'startDate', type: 'desc' }]
      }"
      :rows="rows"
      :columns="columns"
      style-class="vgt-table musical bordered condensed"
    >
      <div class="loading" slot="loadingContent">
        <div v-if="loadingErrorMsg" class="loading server-error">
          {{ loadingErrorMsg }}
        </div>
        <div v-else>
          <img src="/loading.gif" />
          {{ isLoadingMsg }}
        </div>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'editSub'">
          <a href="#" v-show="!props.row.planName.includes('No subscription')" @click="editSub(props.row)">Subscription</a>
        </span>
        <span v-else-if="props.column.field == 'editPurchaser'">
          <a href="#" @click="editPurchaser(props.row)">Purchaser</a>
        </span>
        <span v-else-if="props.column.field == 'extend'">
          <a href="#" v-show="props.row.planName.includes('Trial')" @click="extendTrial(props.row._id)">Extend</a>
        </span>
        <span v-else-if="props.column.field == 'planName' && props.row.isCancelled">
          <span style="font-weight: bold; color: blue;">{{ props.row.planName }} - cancelled</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="table-actions">
        <!-- Appears above the column names -->
        <!-- Filter on Institutions Composers -->
        <input type="checkbox" name="showOnlyActive" class="usersSelect" v-model="showOnlyActive" @change="activeSwitch()" />
        <i style="padding-left:0px;margin-right: 10px;">Only Active Subscriptions</i>
        <input type="radio" name="usersSelect" class="usersSelect" v-model="usersSelect" value="all" @change="filter('all')" />
        <i style="padding-left:0px;margin-right: 10px;">All Subscriptions</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="institutional"
          @change="filter('institutional')"
        />
        <i style="padding-left:0px;margin-right: 10px;">Institutional Subscribers</i>
        <input
          type="radio"
          name="usersSelect"
          class="usersSelect"
          v-model="usersSelect"
          value="individual"
          @change="filter('individual')"
        />
        <i style="padding-left:0px;margin-right: 10px;">Individual Subscribers</i>
      </div>
    </vue-good-table>
  </div>
  <!-- </div> -->
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Subscriptions',
    mixins: [copyTable],
    data() {
      return {
        showOnlyActive: false,
        isLoading: false,
        isLoadingMsg: '',
        loadingErrorMsg: '',
        totalRecords: 0,
        nameFilter: '',
        emailFilter: '',
        planFilter: '',
        institutionFilter: '',
        renewalFilter: '',
        amountFilter: '',
        subIdFilter: '',
        serverParams: {
          columnFilters: {},
          page: 1,
          perPage: 15,
          active: false,
          subType: 'all'
        },
        rows: [],
        usersSelect: 'all',
        tableTitle: 'All Subscriptions',
        columns: [
          {
            label: 'Purchaser',
            field: 'name',
            width: '7%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by purchaser'
            }
          },
          {
            label: 'Purchaser Email',
            field: 'email',
            width: '5%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by email'
            }
          },
          {
            label: 'Plan',
            field: 'planName',
            width: '16%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by plan',
              filterDropdownItems: [
                'Yearly',
                'Yearly (Academic Discount)',
                'Yearly (Special Academic Discount)',
                'Monthly',
                'Institutional Free Trial - individual',
                'Institutional Free Trial - institution-wide',
                'Custom Dates - No Reminder Email',
                'No subscription'
              ]
            }
          },
          {
            label: 'Institution',
            field: 'institutionName',
            width: '10%',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by institution'
            },
            sortable: true
          },
          {
            label: 'Start',
            field: 'startDate',
            width: '7%',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            filterOptions: {
              enabled: false
            },
            sortable: true
          },
          {
            label: 'Expiration',
            field: 'expirationDate',
            width: '7%',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy',
            filterOptions: {
              enabled: false
            },
            sortable: true
          },
          {
            label: 'Renewal',
            field: 'isRenewal',
            width: '3%',
            type: 'boolean',
            formatFn: function(value) {
              return value ? `yes` : 'no'
            },
            filterOptions: {
              enabled: true,
              placeholder: 'All subscriptions',
              filterDropdownItems: ['Renewals only', 'First time subs only']
            },
            sortable: true
          },
          {
            label: 'Amount',
            field: 'amount',
            width: '3%',
            type: 'number',
            formatFn: function(value) {
              return `$ ${Number.parseFloat(value).toFixed(2)}`
            },
            filterOptions: {
              enabled: true,
              placeholder: 'Filter by amount'
            }
          },
          // {
          //   label: 'Subscription ID',
          //   field: '_id',
          //   width: '13%',
          //   type: 'string',
          //   filterOptions: {
          //     enabled: true,
          //     placeholder: 'Filter by subscription ID'
          //   },
          //   sortable: false
          // },
          {
            label: '',
            field: 'editSub',
            width: '5%',
            sortable: false
          },
          {
            label: '',
            field: 'editPurchaser',
            width: '5%',
            sortable: false
          },
          {
            label: '',
            field: 'extend',
            width: '5%',
            sortable: false
          }
        ]
      }
    },
    created() {
      eventbus.$on('reload-subscribers', () => {
        console.log('reload-subscribers received')
        this.loadItems()
      })
    },
    mounted() {
      this.$store.commit('general/setMenuHighlight', 'Subscriptions')
      this.$store
        .dispatch('general/getRowsPerPage', 'subscriptionsPP')
        .then(value => {
          this.serverParams.perPage = value
          this.updateParams({ page: 1, perPage: value })
        })
        .catch(ex => {
          this.$log.info(`Exception in getRowsPerPage ${ex.message}`)
        })
    },
     beforeDestroy() {
      eventbus.$off('reload-subscribers')
    },
    computed: {
      ...mapState({
        tableKey: state => state.subscriptions.tableKey,
        loggedinUser: state => state.general.loggedinUser,
        subscriptionsList: state => state.subscriptions.subscriptionsList,
        currentPurchaser: state => state.subscriptions.currentPurchaser
      })
    },
    methods: {
      activeSwitch() {
        this.$log.info('onActiveChanged', this.showOnlyActive)
        this.updateParams({ active: this.showOnlyActive })
        this.loadItems()
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps)
        Object.keys(this.serverParams).forEach(
          key => (this.serverParams[key] === null || this.serverParams[key] === '') && delete this.serverParams[key]
        )
        if (this.serverParams.columnFilters) {
          Object.keys(this.serverParams.columnFilters).forEach(
            key =>
              (this.serverParams.columnFilters[key] === null || this.serverParams.columnFilters[key] === '') &&
              delete this.serverParams.columnFilters[key]
          )
        }

        this.$log.info(`updateParams serverParams: ${JSON.stringify(this.serverParams)}`)
      },
      onPageChange(params) {
        this.$log.info('onPageChange', params)
        this.updateParams({ page: params.currentPage })
        this.loadItems()
      },
      onPerPageChange(params) {
        this.$log.info('onPerPageChange', params)
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
        this.$store.dispatch('general/saveRowsPerPage', { name: 'subscriptionsPP', value: params.currentPerPage })
      },
      onSortChange(params) {
        this.updateParams({
          page: 1,
          sort: [
            {
              type: params[0].type,
              field: params[0].field
            }
          ]
        })
        this.loadItems()
      },
      onColumnFilter(params) {
        this.$log.info('onColumnFilter', params)
        this.nameFilter = params.columnFilters.name ? params.columnFilters.name : ''
        this.emailFilter = params.columnFilters.email ? params.columnFilters.email : ''
        this.planFilter = params.columnFilters.planName ? params.columnFilters.planName : ''
        this.institutionFilter = params.columnFilters.subscriptionId ? params.columnFilters.institutionName : ''
        this.renewalFilter = params.columnFilters.subscriptionId ? params.columnFilters.isRenewal : ''
        this.amountFilter = params.columnFilters.subscriptionId ? params.columnFilters.amountFilter : ''
        this.subIdFilter = params.columnFilters.subscriptionId ? params.columnFilters.subscriptionId : ''
        this.updateParams(params)
        this.updateParams({ page: 1 })
        this.loadItems()
      },
      setColumnFilters() {
        this.columns[0].filterOptions.filterValue = this.nameFilter
        this.columns[1].filterOptions.filterValue = this.emailFilter
        this.columns[2].filterOptions.filterValue = this.planFilter
        this.columns[3].filterOptions.filterValue = this.institutionFilter
        this.columns[4].filterOptions.filterValue = this.renewalFilter
        this.columns[5].filterOptions.filterValue = this.amountFilter
        this.columns[6].filterOptions.filterValue = this.subIdFilter
      },
      loadItems() {
        // load items is what brings back the rows from server
        this.isLoadingMsg = 'Loading Subscriptions'
        this.$log.info('Getting subscriptions from store')
        this.$store
          .dispatch('subscriptions/getFromServer', this.serverParams)
          .then(response => {
            this.totalRecords = response.totalRecords
            for (var sub of this.subscriptionsList) {
              const isGroupSubscription = sub.isGroupSubscription ? '👥 ' : ''
              sub.planName =
                isGroupSubscription + sub.planName
            }
            this.rows = this.subscriptionsList
            this.setColumnFilters()
          })
          .catch(err => {
            this.$log.info('Error in loadItems', err)
            this.loadingErrorMsg = err
          })
      },
      editSub(sub) {
        this.$store.dispatch('subscriptions/getSubscription', { subscriptionId: sub._id })
        eventbus.$emit('modal', { command: 'open', name: 'subscription-edit' })
      },
      editPurchaser(sub) {
        console.log('Edit Purchaser', sub.purchaserUserId)
        this.$store.dispatch('subscriptions/getPurchaser', sub.purchaserUserId).then(
          () => {
            this.$store.commit('users/setCurrentUser', this.currentPurchaser)
            eventbus.$emit('modal', { command: 'open', name: 'user-edit' })
          },
          error => {
            console.error('Something wrong returning from getPurchaser action', error)
          }
        )
      },
      extendTrial(id) {
        console.log('Extend Subscription', id)
        this.$store.dispatch('subscriptions/extendTrial', id)
      },
      deleteSub(subs) {
        console.log('Delete Subscription', subs._id)
        if (
          confirm(
            `Are you sure you want to delete the subscription for "${subs.purchaserFirstName} ${subs.purchaserLastName}"?\n\nThis action cannot be undone.`
          )
        ) {
          this.$store.dispatch('subscriptions/deleteSubscription', subs._id)
        }
      },
      pageChanged(one, two) {
        console.log('Page changed', one, two)
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
        })
        this.$store.commit('subscriptions/setNewComposer')
      },
      filter(arg) {
        switch (arg) {
          case 'all':
            this.tableTitle = this.showOnlyActive ? 'All Active Subscriptions' : 'All Subscriptions'
            this.updateParams({ subType: 'all' })
            break
          case 'institutional':
            this.tableTitle = this.showOnlyActive ? 'Active Institutional Subscriptions' : 'Institutional Subscriptions'
            this.updateParams({ subType: 'institutional' })
            break
          case 'individual':
            this.tableTitle = this.showOnlyActive ? 'Active Individual Subscriptions' : 'Individual Subscriptions'
            this.updateParams({ subType: 'individual' })
            break
          default:
            this.tableTitle = ''
        }
        this.loadItems()
      },
      onNewSubscription() {
        this.$store.commit('subscriptions/setNewSubscription')
        eventbus.$emit('modal', { command: 'open', name: 'subscription-edit' })
      },
      rowClick() {
        // This is only needed to get the row highlight
      }
    }
  }
</script>
