var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      { staticClass: "navbar navbar-expand-lg navbar-dark fixed-top bg-dark" },
      [
        !_vm.hover
          ? _c(
              "a",
              {
                staticClass: "navbar-brand",
                attrs: { href: "#" },
                on: {
                  mouseover: function($event) {
                    _vm.hover = true
                  },
                  mouseleave: function($event) {
                    _vm.hover = false
                  }
                }
              },
              [_vm._v("Administration")]
            )
          : _vm._e(),
        _vm.hover
          ? _c(
              "a",
              {
                staticClass: "navbar-text navbar-right version",
                on: {
                  mouseover: function($event) {
                    _vm.hover = true
                  },
                  mouseleave: function($event) {
                    _vm.hover = false
                  }
                }
              },
              [_vm._v("Version: " + _vm._s(_vm.version) + _vm._s(_vm.appType))]
            )
          : _vm._e(),
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "navbarNav" }
          },
          [
            _vm.loggedinUser
              ? _c("ul", { staticClass: "navbar-nav" }, [
                  _c("li", { staticClass: "nav-item" }, [
                    _vm.$can("view", "shows")
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: {
                              disabled: !_vm.loggedinUser,
                              active: _vm.activeMenuItem === "Shows"
                            },
                            attrs: { href: "#/shows" },
                            on: { click: _vm.menuClicked }
                          },
                          [_vm._v("Shows")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", { staticClass: "nav-item" }, [
                    _vm.$can("view", "songs")
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-item nav-link",
                            class: {
                              disabled: !_vm.loggedinUser,
                              active: _vm.activeMenuItem === "Songs"
                            },
                            attrs: { href: "#/songs" },
                            on: { click: _vm.menuClicked }
                          },
                          [_vm._v("Songs")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", { staticClass: "nav-item dropdown" }, [
                    _vm.$can("view", "help")
                      ? _c(
                          "a",
                          {
                            ref: "help",
                            staticClass: "nav-link dropdown-toggle",
                            class: { disabled: !_vm.loggedinUser },
                            attrs: {
                              id: "navbarDropdownMenuLinkHelp",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: {
                              click: function($event) {
                                _vm.dropdownOpenedHelp = !_vm.dropdownOpenedHelp
                              }
                            }
                          },
                          [_vm._v("Help")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        class: {
                          dropdownOpened: _vm.dropdownOpenedHelp,
                          dropdownClosed: !_vm.dropdownOpenedHelp
                        },
                        attrs: {
                          "aria-labelledby": "navbarDropdownMenuLinkHelp"
                        }
                      },
                      [
                        _vm.$can("view", "help")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "FAQ"
                                },
                                attrs: { href: "#/faq" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("FAQ")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "help")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "Glossary"
                                },
                                attrs: { href: "#/glossary" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Glossary")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "help")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "Resources"
                                },
                                attrs: { href: "#/resources" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Resources")]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "nav-item" }, [
                    _vm.$can("view", "users")
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-item nav-link",
                            class: {
                              disabled: !_vm.loggedinUser,
                              active: _vm.activeMenuItem === "Users"
                            },
                            attrs: { href: "#/users" },
                            on: { click: _vm.menuClicked }
                          },
                          [_vm._v("Users")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", { staticClass: "nav-item" }, [
                    _vm.$can("view", "subscriptions")
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-item nav-link",
                            class: {
                              disabled: !_vm.loggedinUser,
                              active: _vm.activeMenuItem === "Subscriptions"
                            },
                            attrs: { href: "#/subscriptions" },
                            on: { click: _vm.menuClicked }
                          },
                          [_vm._v("Subscriptions")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", { staticClass: "nav-item" }, [
                    _vm.$can("view", "composers")
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-item nav-link",
                            class: {
                              disabled: !_vm.loggedinUser,
                              active: _vm.activeMenuItem === "Composers"
                            },
                            attrs: { href: "#/composers" },
                            on: { click: _vm.menuClicked }
                          },
                          [_vm._v("Composers")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", { staticClass: "nav-item" }, [
                    _vm.$can("view", "lyricists")
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-item nav-link",
                            class: {
                              disabled: !_vm.loggedinUser,
                              active: _vm.activeMenuItem === "Lyricists"
                            },
                            attrs: { href: "#/lyricists" },
                            on: { click: _vm.menuClicked }
                          },
                          [_vm._v("Lyricists")]
                        )
                      : _vm._e()
                  ]),
                  _c("li", { staticClass: "nav-item dropdown" }, [
                    _vm.$can("view", "miscellaneous")
                      ? _c(
                          "a",
                          {
                            ref: "misc",
                            staticClass: "nav-link dropdown-toggle",
                            class: { disabled: !_vm.loggedinUser },
                            attrs: {
                              id: "navbarDropdownMenuLink",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: {
                              click: function($event) {
                                _vm.dropdownOpened1 = !_vm.dropdownOpened1
                              }
                            }
                          },
                          [_vm._v("Miscellaneous")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        class: {
                          dropdownOpened: _vm.dropdownOpened1,
                          dropdownClosed: !_vm.dropdownOpened1
                        },
                        attrs: { "aria-labelledby": "navbarDropdownMenuLink" }
                      },
                      [
                        _vm.$can("view", "miscellaneous")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active:
                                    _vm.activeMenuItem === "Comment Templates"
                                },
                                attrs: { href: "#/commentTemplates" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Comment Templates")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "miscellaneous")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active:
                                    _vm.activeMenuItem === "Tag Management"
                                },
                                attrs: { href: "#/tags" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Tag Management")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "miscellaneous")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active:
                                    _vm.activeMenuItem ===
                                    "Institutional Coupon Codes"
                                },
                                attrs: { href: "#/coupons" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Institutional Coupon Codes")]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _c("li", { staticClass: "nav-item dropdown" }, [
                    _vm.$can("view", "admin")
                      ? _c(
                          "a",
                          {
                            ref: "admin",
                            staticClass: "nav-link dropdown-toggle",
                            class: { disabled: !_vm.loggedinUser },
                            attrs: {
                              id: "navbarDropdownMenuLinkAdmin",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: {
                              click: function($event) {
                                _vm.dropdownOpened2 = !_vm.dropdownOpened2
                              }
                            }
                          },
                          [_vm._v("Admin")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        class: {
                          dropdownOpened: _vm.dropdownOpened2,
                          dropdownClosed: !_vm.dropdownOpened2
                        },
                        attrs: {
                          "aria-labelledby": "navbarDropdownMenuLinkAdmin"
                        }
                      },
                      [
                        _vm.$can("view", "admin")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "Logs"
                                },
                                attrs: { href: "#/logs" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Logs ")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "miscellaneous")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "Email Log"
                                },
                                attrs: { href: "#/emails" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Email Log")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "admin")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active:
                                    _vm.activeMenuItem === "Stripe Messages"
                                },
                                attrs: { href: "#/stripe-msgs" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Stripe Messages ")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "admin")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "Statistcs"
                                },
                                attrs: { href: "#/statistics" },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Statistcs ")]
                            )
                          : _vm._e(),
                        _vm.$can("view", "admin")
                          ? _c(
                              "a",
                              {
                                staticClass: "dropdown-item nav-link",
                                class: {
                                  disabled: !_vm.loggedinUser,
                                  active: _vm.activeMenuItem === "Status"
                                },
                                attrs: {
                                  href:
                                    "https://stats.uptimerobot.com/x19JNuJwZW",
                                  target: "_blank"
                                },
                                on: { click: _vm.menuClicked }
                              },
                              [_vm._v("Status ")]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm.loggedinUser
          ? _c("div", { staticClass: "navbar-nav" }, [
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link active",
                  attrs: { id: "email", href: "#" }
                },
                [_vm._v(_vm._s(_vm.loggedinUser.email))]
              ),
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link active",
                  attrs: { href: "#" },
                  on: { click: _vm.logout }
                },
                [_vm._v("Logout")]
              ),
              _c("span", {
                class: [_vm.socket.isConnected ? "green-dot" : "red-dot"]
              })
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarNav",
          "aria-controls": "navbarNav",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }