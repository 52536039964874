import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tableKey: 0,
    groupUsers: [],
    subscriptionsList: [],
    filteredSubscriptionsList: [],
    filterSetting: 'all',
    currentSubscription: { purchaser: {} },
    currentGroupSubscription: {},
    currentPurchaser: {},
    institutionalPlanTypes: ['singleLimitedAccount', 'singleFullAccount', 'onCampusOnly_loginRequired', 'multipleAccounts'],
    plans: [
      'Yearly',
      'Yearly (Academic Discount)',
      'Yearly (Special Academic Discount)',
      'Yearly (Special Academic Extra Discount)',
      'Monthly',
      'Institutional Free Trial - individual',
      'Institutional Free Trial - institution-wide',
      'Custom Dates - No Reminder Email'
    ]
  },
  mutations: {
    addToGroupUsers: (state, payload) => {
      let newOrModifiedUser = state.groupUsers.find(u => u._id === payload._id)
      if (newOrModifiedUser) {
        newOrModifiedUser = Object.assign(newOrModifiedUser, payload)
      } else {
        state.groupUsers.push(payload)
      }
    },
    setGroupUsers: (state, payload) => {
      state.groupUsers = payload
    },
    tableUpdate: state => {
      state.tableKey += 1
    },
    setSubscriptions: (state, payload) => {
      state.subscriptionsList = payload
    },
    setCurrentSubscription: (state, payload) => {
      state.currentSubscription = payload
    },
    setCurrentGroupSubscription: (state, payload) => {
      state.currentGroupSubscription = payload
    },
    setNewSubscription: state => {
      state.currentSubscription = {
        isNewUser: true,
        canEditPayments: true,
        plan: 'Institutional Free Trial - individual',
        institutionalPlanType: 'singleLimitedAccount',
        reminderEmails: true,
        isInstitutional: false,
        storeCard: false,
        recognizedIps: [],
        automaticRenewal: 0,
        startDate: new Date().valueOf(),
        expirationDate: 0,
        isNew: true,
        purchaser: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          label: 'Please select a user',
          roles: []
        }
        // stripeSubscription: {
        //   plan: {
        //     amount: 0
        //   }
        // },
        // stripeCharge: {
        //   amount: 0,
        //   created: 0,
        //   metadata: {
        //     totalBeforeCoupon: 0
        //   }
        // }
      }
    },
    // filterSubscriptions: (state, payload) => {
    //   state.filterSetting = payload
    //   if (payload === 'all') {
    //     state.filteredSubscriptionsList = state.subscriptionsList
    //   } else if (payload === 'institutional') {
    //     state.filteredSubscriptionsList = state.subscriptionsList.filter(s => {
    //       return s.isInstitutional
    //     })
    //   } else if (payload === 'individual') {
    //     state.filteredSubscriptionsList = state.subscriptionsList.filter(s => {
    //       return s.isIndividual
    //     })
    //   }
    //   console.log(`[Store.Subscriptions] Filtered on ${payload}`)
    // },
    setCurrentPurchaser: (state, payload) => {
      state.currentPurchaser = payload
    }
  },
  actions: {
    getFromServer: async ({ state, commit, rootState }, serverParams) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .put(rootState.general.apiRoot + '/api/subscriptions', serverParams, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting subscriptions from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting subscriptions from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          Vue.$log.info(`Total received: ${result.data.totalRecords}`)
          commit('setSubscriptions', result.data.subscriptionsList)
          resolve({ totalRecords: result.data.totalRecords })
        } else {
          resolve({ totalRecords: 0, rows: [] })
        }
      })
    },
    // setNewSubscription: ({ state, commit, dispatch }) => {
    //   return commit('setNewSubscription')
    // },
    extendTrial: ({ commit, rootState }, payload) => {
      Vue.http.post('api/subscriptions/extend', { subscriptionId: payload }).then(
        () => {
          console.log('Store: Got response from extendTrial')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    deleteSubscription: ({ commit, rootState }, payload) => {
      Vue.http.delete('api/subscriptions/' + payload).then(
        response => {
          conaddToGroupUserssole.log('Got reply from deleteSubscription', response)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    cancelSubscription: ({ commit, rootState }, payload) => {
      Vue.http.delete('api/subscriptions/cancel/' + payload).then(
        response => {
          console.log('Got reply from cancelSubscription', response)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    getPurchaser: ({ commit, rootState }, payload) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading Purchaser'
      return new Promise((resolve, reject) => {
        Vue.http.get('api/users/' + payload).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            const currentUser = response.body
            currentUser.hasSubscription = true
            commit('setCurrentPurchaser', currentUser)
            resolve('ok')
          },
          error => {
            rootState.general.loading = false
            reject(error)
          }
        )
      })
    },
    getSubscription: ({ state, commit, dispatch, rootState }, payload) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading Subscription'
      return new Promise((resolve, reject) => {
        Vue.http.get('api/subscriptions/' + payload.subscriptionId).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            const currentSub = response.body
            commit('setCurrentSubscription', currentSub)
            return resolve('subscription')
          },
          error => {
            rootState.general.loading = false
            return reject(error)
          }
        )
      })
    },
    saveSubscription: ({ state, commit, rootState }) => {
      if (state.currentSubscription.startDate) {
        state.currentSubscription.startDate = state.currentSubscription.startDate.valueOf()
      }
      if (state.currentSubscription.expirationDate) {
        state.currentSubscription.expirationDate = state.currentSubscription.expirationDate.valueOf()
      }
      if (state.currentSubscription.paymentDate) {
        state.currentSubscription.paymentDate = state.currentSubscription.paymentDate.valueOf()
      }
      Vue.http.post('api/subscriptions', { subscription: state.currentSubscription }).then(
        () => {
          console.log('Store: Got response from saveSubscription')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    renewSubscription: ({ commit, rootState }, payload) => {
      // Send a server request to extend subscription
      Vue.http.post('api/subscriptions/renew', { subscriptionId: payload }).then(
        () => {
          console.log('Store: Got response from renewSubscription')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    saveGroupUser: ({ commit, rootState }, payload) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Saving Group User'
      // Send a server request to extend subscription
      Vue.http.post('api/users/', payload).then(
        (result) => {
          rootState.general.loading = false
          console.log('Store: Got response from saveGroupUser', result)
          commit('addToGroupUsers', result.body)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    getGroupUsers: ({ commit, rootState }, payload) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading Group Users'
      return new Promise((resolve, reject) => {
        Vue.http.get(`api/groupUsers/${payload}`).then(
          result => {
            commit('general/dataLoaded', null, { root: true })
            // rootState.general.loading = false
            commit('setGroupUsers', result.body)
            return resolve('groupUsers')
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.general.loading = false
            return reject(error)
          }
        )
      })
    },
    subscriptionByPurchaser: ({ commit, rootState }, payload) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading Group Users'
      return new Promise((resolve, reject) => {
        Vue.http.get(`api/subscriptionByPurchaser/${payload}`).then(
          result => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            commit('setCurrentGroupSubscription', result.body)
            return resolve('subscriptionByPurchaser')
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.general.loading = false
            return reject(error)
          }
        )
      })
    }
  }
}
