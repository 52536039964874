<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <!-- If there are any errors fetching data, display the error -->
    <div v-if="error" class="server-error">
      {{ error }}
    </div>

    <div class="row fixed-top page-header">
      <div class="col-4">
        <h5>Institutional Coupon Codes</h5>
      </div>
      <div class="col-8">
        <button @click="copyContent" style="margin-left: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
        <button @click="newCoupon" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add Coupon
        </button>
        <input v-model="newCouponText" style="margin-right:5px;" class="float-right" type="text" />
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'code', type: 'asc' }
        }"
        @on-row-click="rowClick"
        :line-numbers="false"
        style-class="vgt-table musical bordered condensed"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'delete'">
            <a href="#" @click="deleteCoupon(props.row)">Delete Coupon</a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Coupons',
    mixins: [copyTable],
    data() {
      return {
        newCouponText: '',
        columns: [
          {
            label: 'Code',
            field: 'code',
            width: '20%'
          },
          {
            label: '',
            field: 'delete',
            width: '5%',
            sortable: true
          }
        ],
        rows: [],
        apiError: '',
        attemptSubmit: false
      }
    },
    created() {
      console.log('Coupons component created')
      this.$store.commit('general/setMenuHighlight', 'Institutional Coupon Codes')
    },
    mounted() {
      console.log('Coupons component mounted')
      this.$store
        .dispatch('general/getCoupons')
        .then(() => {
          console.log(`Got ${this.couponsList.length} coupons`)
          this.rows = this.couponsList
        })
        .catch(ex => {
          console.error('Exception in mounted - getCoupons', ex.message)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        couponsList: state => state.general.couponsList
      })
    },
    methods: {
      newCoupon() {
        console.log('new coupon clicked')
        this.$store
          .dispatch('general/saveCoupon', this.newCouponText)
          .then(() => {
            this.newCouponText = ''
          })
          .catch(ex => {
            console.error('Exception in newCoupon', ex.message)
          })
      },
      deleteCoupon(row) {
        console.log(`Deleting ${row.code}`)
        this.$store
          .dispatch('general/deleteCoupon', row.code)
          .then(() => {
            console.log(`Coupon ${row.code} deleted`)
          })
          .catch(ex => {
            console.error('Exception in deleteCoupon', ex.message)
          })
      },
      rowClick() {}
    }
  }
</script>
