<template>
  <div>
    <modal
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="tag-edit"
      height="400"
      width="1000"
      transition="pop-out"
      :click-to-close="false"
    >
      <div class="show-form">
        <h5 v-if="currentTag._id">
          Edit Glossary
        </h5>
        <h5 v-else>
          New Glossary
        </h5>
        <div class="col-12">
          <!-- Term -->
          <div class="row form-row">
            <div class="col-2 label">
              <label class="label required" for="term">Term</label>
            </div>
            <div class="col-3">
              <input
                id="term"
                name="term"
                class="form-control"
                type="text"
                v-model.trim="currentTag.term"
                :class="{ 'is-invalid': attemptSubmit && missingTerm }"
              />
              <div :class="[attemptSubmit && missingTerm ? 'is-not-valid' : 'is-valid']">
                Term is required.
              </div>
            </div>
            <!-- Buttons -->
            <div class="offset-3 col-3">
              <button @click="saveTag" :disabled="formsHasErrors" class="btn btn-outline-success btn-xs float-right">
                Save
              </button>
              <button
                @click="deleteTag"
                style="margin-right:5px;"
                v-if="$can('delete', 'tags')"
                :disabled="!currentTag._id"
                class="float-right btn btn-outline-danger btn-xs"
              >
                Delete
              </button>
              <button @click="cancel" style="margin-right:5px;" class="btn btn-outline-secondary btn-xs float-right">
                Cancel
              </button>
            </div>
          </div>

          <!-- Rename Tag in songs -->
          <div class="row form-row" v-if="currentTag._id">
            <div class="offset-2 col-4">
              <b>Rename tag "{{ getTagLabel() }}" in all songs where the tag is used to:</b>
            </div>
            <div class="col-2">
              <select
                style="width:200px;"
                name="comments"
                ref="tagToRename"
                id="tagToRename"
                :required="true"
                v-model="tagToRename"
                @change="setTagToRename"
              >
                <option :selected="true">
                  Select a Tag
                </option>
                <option v-for="tag in tagList" :key="tag._id" :value="tag.term">
                  {{ tag.term }}
                </option>
              </select>
            </div>
            <div class="col-3">
              <button @click="renameTag" :disabled="canRename" class="btn btn-outline-danger btn-xs float-right">
                Rename
              </button>
            </div>
          </div>

          <!-- Bio -->
          <div class="row form-row">
            <div class="col-2 label">
              <label class="label required" for="bio">Definition</label>
            </div>
            <div class="col-7">
              <quill-editor
                style="height: 200px !important;"
                v-model="currentTag.definition"
                ref="myQuillEditor"
                :options="editorOption"
                @ready="onEditorReady($event)"
              />
              <div :class="[attemptSubmit && missingDefinition ? 'is-not-valid' : 'is-valid']">
                Definition is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  export default {
    data() {
      return {
        tagToRename: 'Select a Tag',
        apiError: '',
        attemptSubmit: false,
        editor: {},
        editorOption: {
          // some quill options
          modules: {
            toolbar: [
              'bold',
              'italic',
              'underline',
              { color: [] },
              { indent: '-1' },
              { indent: '+1' },
              { list: 'ordered' },
              { list: 'bullet' },
              'link',
              'clean',
              { header: [1, 2, 3, 4, 5, 6, false] },
              { size: ['small', false, 'large', 'huge'] }
            ]
          }
        }
      }
    },
    computed: {
      ...mapState({
        currentTag: state => state.glossary.currentTag,
        tagList: state => state.glossary.tagsList,
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingTerm || this.missingDefinition
        } else {
          return false
        }
      },
      missingTerm() {
        return this.currentTag.term === ''
      },
      missingDefinition() {
        return this.currentTag.definition === ''
      },
      canRename() {
        return !(this.tagToRename !== '' && this.tagToRename !== 'Select a Tag' && this.currentTag.term !== this.tagToRename)
      }
    },
    methods: {
      beforeOpen() {
        // Set init values
        this.attemptSubmit = false
        console.log('[Tag Edit] beforeOpen called')
        document.addEventListener('keyup', this.close)
        this.$store
          .dispatch('glossary/getTags')
          .then(result => {
            console.log(`Got tags list: ${this.tagList.length}`)
          })
          .catch(ex => {
            console.error('Exception in getting Tags', ex.message)
          })
      },
      beforeClose() {
        console.log('[Tag Edit] beforeClose called')
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          console.log('[Tag Edit] escape pressed')
          eventbus.$emit('modal', { command: 'close', name: 'tag-edit' })
        }
      },
      cancel() {
        console.log('[Tag Edit] Cancel called')
        eventbus.$emit('modal', { command: 'close', name: 'tag-edit' })
      },
      setTagToRename() {
        const index = this.$refs['tagToRename'].selectedIndex
        this.tagToRename = this.$refs['tagToRename'].options[index].text
        // this.$store.commit('glossary/setRenameTag', tagToRename)
      },
      deleteTag() {
        if (this.currentTag._id) {
          if (confirm(`Are you sure you want to delete tag "${this.currentTag.term}"?\n\nThis action cannot be undone.`)) {
            console.log('deleting tag ' + this.currentTag.term)
            this.$store.dispatch('glossary/deleteTag', this.currentTag._id)
            this.cancel()
          }
        }
      },
      saveTag() {
        this.attemptSubmit = true
        // Give UI some time to react to attemptSubmit
        setTimeout(() => {
          if (!this.formsHasErrors) {
            this.$store.dispatch('glossary/saveTag', this.currentTag)
            this.attemptSubmit = false
            this.cancel()
          }
        }, 500)
      },
      renameTag() {
        if (
          confirm(
            `Are you sure you want to rename tag "${this.currentTag.term}" to "${this.tagToRename}"?\n\nThis action cannot be undone.`
          )
        ) {
          const from = this.currentTag.term.replace(':', '')
          const to = this.tagToRename.replace(':', '')
          console.log(`Renaming tag from ${from} to ${to}`)
          this.$store.dispatch('glossary/renameTag', { from, to })
        }
      },
      onEditorReady(quill) {
        console.log('Editor Ready')
        this.editor = quill
      },
      getTagLabel() {
        if (this.currentTag.term.length > 20) {
          return this.currentTag.term.substr(0, 18) + '...'
        } else {
          return this.currentTag.term
        }
      }
    }
  }
</script>
