<template>
  <div>
    <!-- <div v-if="serverError" class="server-error">{{serverError}}</div> -->
    <div class="form-login">
      <h4 style="text-align: center">
        Please Login
      </h4>
      <form id="login-form" @submit.prevent="handleSubmit">
        <input
          type="text"
          autocomplete="username"
          id="userName"
          class="form-control input-sm chat-input"
          placeholder="username"
          v-model="username"
        />
        <br />
        <input
          data-private
          autocomplete="current-password"
          type="password"
          id="userPassword"
          class="form-control input-sm chat-input"
          placeholder="password"
          v-model="pw"
        />
        <br />
        <div class="wrapper">
          <span class="group-btn">
            <button class="btn btn-success btn-md" id="btn-login" type="submit">login <i class="fa fa-sign-in" /></button>
            <div class="login-error">{{ serverError }}</div>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Login',
    data() {
      return {
        username: '',
        pw: '',
        lastError: ''
      }
    },
    created() {
      console.log(`Login created, ${JSON.stringify(this.$route.query)}`)
    },
    computed: {
      ...mapState({
        serverError: state => state.general.serverError,
        requestedLink: state => state.general.requestedLink
      })
    },
    methods: {
      handleSubmit() {
        console.log('Login clicked, sending login info to server', this.username)
        this.$store.commit('general/setServerError', '')
        this.lastError = ''
        // Post loginData to API and respect response
        this.$store
          .dispatch('general/login', { username: this.username, pw: this.pw })
          .then(() => {
            if (this.requestedLink) {
              console.log(`Login - redirecting to ${this.requestedLink}`)
              this.$router.push(this.requestedLink)
            } else {
              this.$router.push('/')
            }
          })
          .catch(err => {
            debugger
          })
      }
    }
  }
</script>
