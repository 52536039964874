var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "tag-edit",
            height: "400",
            width: "1000",
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _c("div", { staticClass: "show-form" }, [
            _vm.currentTag._id
              ? _c("h5", [_vm._v(" Edit Glossary ")])
              : _c("h5", [_vm._v(" New Glossary ")]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row form-row" }, [
                _c("div", { staticClass: "col-2 label" }, [
                  _c(
                    "label",
                    { staticClass: "label required", attrs: { for: "term" } },
                    [_vm._v("Term")]
                  )
                ]),
                _c("div", { staticClass: "col-3" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.currentTag.term,
                        expression: "currentTag.term",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-invalid": _vm.attemptSubmit && _vm.missingTerm
                    },
                    attrs: { id: "term", name: "term", type: "text" },
                    domProps: { value: _vm.currentTag.term },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.currentTag,
                          "term",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      class: [
                        _vm.attemptSubmit && _vm.missingTerm
                          ? "is-not-valid"
                          : "is-valid"
                      ]
                    },
                    [_vm._v(" Term is required. ")]
                  )
                ]),
                _c("div", { staticClass: "offset-3 col-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-success btn-xs float-right",
                      attrs: { disabled: _vm.formsHasErrors },
                      on: { click: _vm.saveTag }
                    },
                    [_vm._v(" Save ")]
                  ),
                  _vm.$can("delete", "tags")
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "float-right btn btn-outline-danger btn-xs",
                          staticStyle: { "margin-right": "5px" },
                          attrs: { disabled: !_vm.currentTag._id },
                          on: { click: _vm.deleteTag }
                        },
                        [_vm._v(" Delete ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-outline-secondary btn-xs float-right",
                      staticStyle: { "margin-right": "5px" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ])
              ]),
              _vm.currentTag._id
                ? _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "offset-2 col-4" }, [
                      _c("b", [
                        _vm._v(
                          'Rename tag "' +
                            _vm._s(_vm.getTagLabel()) +
                            '" in all songs where the tag is used to:'
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-2" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tagToRename,
                              expression: "tagToRename"
                            }
                          ],
                          ref: "tagToRename",
                          staticStyle: { width: "200px" },
                          attrs: {
                            name: "comments",
                            id: "tagToRename",
                            required: true
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.tagToRename = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.setTagToRename
                            ]
                          }
                        },
                        [
                          _c("option", { domProps: { selected: true } }, [
                            _vm._v(" Select a Tag ")
                          ]),
                          _vm._l(_vm.tagList, function(tag) {
                            return _c(
                              "option",
                              { key: tag._id, domProps: { value: tag.term } },
                              [_vm._v(" " + _vm._s(tag.term) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-danger btn-xs float-right",
                          attrs: { disabled: _vm.canRename },
                          on: { click: _vm.renameTag }
                        },
                        [_vm._v(" Rename ")]
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row form-row" }, [
                _c("div", { staticClass: "col-2 label" }, [
                  _c(
                    "label",
                    { staticClass: "label required", attrs: { for: "bio" } },
                    [_vm._v("Definition")]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-7" },
                  [
                    _c("quill-editor", {
                      ref: "myQuillEditor",
                      staticStyle: { height: "200px !important" },
                      attrs: { options: _vm.editorOption },
                      on: {
                        ready: function($event) {
                          return _vm.onEditorReady($event)
                        }
                      },
                      model: {
                        value: _vm.currentTag.definition,
                        callback: function($$v) {
                          _vm.$set(_vm.currentTag, "definition", $$v)
                        },
                        expression: "currentTag.definition"
                      }
                    }),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingDefinition
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" Definition is required. ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }