var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            id: "subscription-edit",
            name: "subscription-edit",
            height: "auto",
            width: "1000",
            scrollable: true,
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _vm.loading
            ? _c("div", { staticClass: "loading" }, [
                _vm.error
                  ? _c("div", { staticClass: "loading server-error" }, [
                      _vm._v(" " + _vm._s(_vm.loadingErrorMsg) + " ")
                    ])
                  : _c("div", [
                      _c("img", { attrs: { src: "/loading.gif" } }),
                      _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
                    ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "show-form" }, [
            _vm.currentSubscription._id
              ? _c("div", [
                  _c("h4", [_vm._v("Edit Subscription")]),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "#/statistics/" + _vm.currentSubscription._id,
                        target: "_blank"
                      }
                    },
                    [_vm._v("View usage statistics")]
                  )
                ])
              : _c("div", [
                  _c("h4", [_vm._v("New Subscription")]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.isNewUser,
                          expression: "currentSubscription.isNewUser"
                        }
                      ],
                      attrs: { type: "radio", name: "isNewUser" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.currentSubscription.isNewUser, true)
                      },
                      on: {
                        click: _vm.newUserSet,
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "isNewUser",
                            true
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style1" } }, [
                      _vm._v("New User")
                    ]),
                    _c("input", {
                      attrs: { type: "radio", name: "isNewUser" },
                      domProps: { value: false },
                      on: { click: _vm.existingUserSet }
                    }),
                    _c("label", { attrs: { for: "style2" } }, [
                      _vm._v("Existing User")
                    ])
                  ])
                ]),
            !_vm.currentSubscription.isNew
              ? _c("p", [
                  _vm._v(
                    " Purchased by: " +
                      _vm._s(_vm.currentSubscription.purchaser.firstName) +
                      " " +
                      _vm._s(_vm.currentSubscription.purchaser.lastName) +
                      ", " +
                      _vm._s(_vm.currentSubscription.purchaser.email) +
                      " "
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.editUser(
                            _vm.currentSubscription.purchaser._id
                          )
                        }
                      }
                    },
                    [_vm._v("(Edit User)")]
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm.currentSubscription.isNewUser
                  ? _c("div", [
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c("label", { staticClass: "label required" }, [
                            _vm._v("First Name")
                          ])
                        ]),
                        _c("div", { staticClass: "col-2" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSubscription.purchaser.firstName,
                                expression:
                                  "currentSubscription.purchaser.firstName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.currentSubscription.purchaser.firstName
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentSubscription.purchaser,
                                  "firstName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.hasFirstName
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" First Name is required. ")]
                          )
                        ]),
                        _c("div", { staticClass: "col-2 label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentSubscription.purchaser.roles,
                                expression:
                                  "currentSubscription.purchaser.roles"
                              }
                            ],
                            staticClass: "css-checkbox",
                            attrs: {
                              type: "checkbox",
                              name: "age",
                              id: "age6",
                              value: "groupadmin"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.currentSubscription.purchaser.roles
                              )
                                ? _vm._i(
                                    _vm.currentSubscription.purchaser.roles,
                                    "groupadmin"
                                  ) > -1
                                : _vm.currentSubscription.purchaser.roles
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a =
                                      _vm.currentSubscription.purchaser.roles,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "groupadmin",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.currentSubscription.purchaser,
                                          "roles",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.currentSubscription.purchaser,
                                          "roles",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.currentSubscription.purchaser,
                                      "roles",
                                      $$c
                                    )
                                  }
                                },
                                _vm.GroupAdminChanged
                              ]
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "css-label lite-red-check",
                              attrs: { for: "age6" }
                            },
                            [_vm._v("Group Admin")]
                          )
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "label",
                            {
                              staticClass: "label required extra-role",
                              attrs: { for: "maxGroupMembers" }
                            },
                            [_vm._v("Max Members")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSubscription.purchaser
                                    .maxGroupMembers,
                                expression:
                                  "currentSubscription.purchaser.maxGroupMembers"
                              }
                            ],
                            attrs: {
                              type: "text",
                              id: "maxGroupMembers",
                              size: "3"
                            },
                            domProps: {
                              value:
                                _vm.currentSubscription.purchaser
                                  .maxGroupMembers
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentSubscription.purchaser,
                                  "maxGroupMembers",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c("label", { staticClass: "label required" }, [
                            _vm._v("Last Name")
                          ])
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSubscription.purchaser.lastName,
                                expression:
                                  "currentSubscription.purchaser.lastName"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.currentSubscription.purchaser.lastName
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentSubscription.purchaser,
                                  "lastName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.hasLastName
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" Last Name is required. ")]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c("label", { staticClass: "label required" }, [
                            _vm._v("Email")
                          ])
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentSubscription.purchaser.email,
                                expression:
                                  "currentSubscription.purchaser.email"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.currentSubscription.purchaser.email
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentSubscription.purchaser,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.hasEmail
                            ? _c(
                                "div",
                                {
                                  class: [
                                    _vm.attemptSubmit && _vm.hasEmail
                                      ? "is-not-valid"
                                      : "is-valid"
                                  ]
                                },
                                [_vm._v(" Email is required. ")]
                              )
                            : _vm._e(),
                          _vm.existingEmail
                            ? _c(
                                "div",
                                {
                                  class: [
                                    _vm.existingEmail
                                      ? "is-not-valid"
                                      : "is-valid"
                                  ]
                                },
                                [_vm._v(" Email already exists. ")]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c("label", { staticClass: "label required" }, [
                            _vm._v("Password")
                          ])
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSubscription.purchaser.password,
                                expression:
                                  "currentSubscription.purchaser.password"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.currentSubscription.purchaser.password
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentSubscription.purchaser,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.hasPassword
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" Password is required. ")]
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.currentSubscription.isNew &&
                !_vm.currentSubscription.isNewUser
                  ? _c("div", [
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c("label", { staticClass: "label required" }, [
                            _vm._v("Existing User")
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("v-select", {
                              attrs: {
                                label: "email",
                                options: _vm.emailsList
                              },
                              on: { input: _vm.existingUserSelected },
                              model: {
                                value: _vm.selectedUser,
                                callback: function($$v) {
                                  _vm.selectedUser = $$v
                                },
                                expression: "selectedUser"
                              }
                            }),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.attemptSubmit && _vm.hasUser
                                    ? "is-not-valid"
                                    : "is-valid"
                                ]
                              },
                              [_vm._v(" User is required. ")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _vm.currentSubscription.canRenew
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary btn-sm",
                            on: {
                              click: function($event) {
                                return _vm.renewSub(_vm.currentSubscription._id)
                              }
                            }
                          },
                          [_vm._v(" Renew Subscription ")]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "col-3 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "songTitle" }
                      },
                      [_vm._v("Subscription Plan")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSubscription.plan,
                            expression: "currentSubscription.plan"
                          }
                        ],
                        attrs: { name: "plan" },
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSubscription,
                                "plan",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.planChanged
                          ]
                        }
                      },
                      _vm._l(_vm.plans, function(option) {
                        return _c(
                          "option",
                          { key: option, domProps: { value: option } },
                          [_vm._v(" " + _vm._s(option) + " ")]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingPlan
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" Plan is required. ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "startDate" } },
                      [_vm._v("Subscription ID")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", [_vm._v(_vm._s(_vm.currentSubscription._id))])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "startDate" }
                      },
                      [_vm._v("Start Date")]
                    )
                  ]),
                  _vm.currentSubscription.canEditPayments
                    ? _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("datepicker", {
                            attrs: { format: "MMM dd yyyy", name: "startDate" },
                            on: { closed: _vm.planChanged },
                            model: {
                              value: _vm.currentSubscription.startDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentSubscription,
                                  "startDate",
                                  $$v
                                )
                              },
                              expression: "currentSubscription.startDate"
                            }
                          }),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.startDateOk
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" Start date is required. ")]
                          )
                        ],
                        1
                      )
                    : _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatEpoch")(
                                _vm.currentSubscription.startDate
                              )
                            ) +
                            " "
                        )
                      ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "expData" }
                      },
                      [_vm._v("Expiration Date")]
                    )
                  ]),
                  _vm.currentSubscription.canEditPayments
                    ? _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("datepicker", {
                            staticStyle: { float: "left" },
                            attrs: {
                              format: "MMM dd yyyy",
                              name: "expirationDate"
                            },
                            model: {
                              value: _vm.currentSubscription.expirationDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentSubscription,
                                  "expirationDate",
                                  $$v
                                )
                              },
                              expression: "currentSubscription.expirationDate"
                            }
                          }),
                          _vm.expDateChanged
                            ? _c("span", [
                                _c("i", [
                                  _vm._v(
                                    " Date changed. Original " +
                                      _vm._s(_vm.oldDate)
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.expirationDateOk
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" Expiration date is required. ")]
                          )
                        ],
                        1
                      )
                    : _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatEpoch")(
                                _vm.currentSubscription.expirationDate
                              )
                            ) +
                            " "
                        )
                      ])
                ]),
                _vm.currentSubscription.isCancelled
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label",
                            attrs: { for: "cancellationDate" }
                          },
                          [_vm._v("Cancellation Date")]
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatEpoch")(
                                _vm.currentSubscription.cancellationDate
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Amount Paid")]
                    )
                  ]),
                  _vm.currentSubscription.canEditPayments
                    ? _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSubscription.amountPaid,
                              expression: "currentSubscription.amountPaid"
                            }
                          ],
                          staticStyle: { float: "left" },
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.currentSubscription.amountPaid
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentSubscription,
                                "amountPaid",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.currentSubscription.isNew &&
                        _vm.currentSubscription.amountPaid > 0
                          ? _c("div", [
                              _c("input", {
                                staticStyle: {
                                  float: "left",
                                  "margin-left": "10px",
                                  "margin-top": "5px",
                                  "margin-right": "5px"
                                },
                                attrs: { type: "checkbox" }
                              }),
                              _vm._v("Send email receipt? ")
                            ])
                          : _vm._e()
                      ])
                    : _c("div", [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm._f("roundAmount")(
                                _vm.currentSubscription.amountPaid
                              )
                            )
                        )
                      ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Payment Received")]
                    )
                  ]),
                  _vm.currentSubscription.canEditPayments
                    ? _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("datepicker", {
                            attrs: {
                              format: "MMM dd yyyy",
                              name: "paymentDate"
                            },
                            model: {
                              value: _vm.currentSubscription.paymentDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentSubscription,
                                  "paymentDate",
                                  $$v
                                )
                              },
                              expression: "currentSubscription.paymentDate"
                            }
                          })
                        ],
                        1
                      )
                    : _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatEpoch")(
                                _vm.currentSubscription.paymentDate
                              )
                            ) +
                            " "
                        )
                      ])
                ]),
                !_vm.currentSubscription.isNew &&
                _vm.currentSubscription.couponCode
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "paid" }
                          },
                          [_vm._v("Coupon Code Used")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.currentSubscription.couponCode))
                        ])
                      ])
                    ])
                  : _vm._e(),
                !_vm.currentSubscription.isNew &&
                _vm.currentSubscription.couponCode
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "paid" }
                          },
                          [_vm._v("Coupon Discount")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("span", [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm._f("roundAmount")(
                                  _vm.currentSubscription.totalBeforeCoupon
                                )
                              )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Reminder Emails")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.reminderEmails,
                          expression: "currentSubscription.reminderEmails"
                        }
                      ],
                      attrs: { type: "radio", name: "style", value: "true" },
                      domProps: {
                        checked: _vm._q(
                          _vm.currentSubscription.reminderEmails,
                          "true"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "reminderEmails",
                            "true"
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style1" } }, [
                      _vm._v("Enabled")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.reminderEmails,
                          expression: "currentSubscription.reminderEmails"
                        }
                      ],
                      attrs: { type: "radio", name: "style", value: "false" },
                      domProps: {
                        checked: _vm._q(
                          _vm.currentSubscription.reminderEmails,
                          "false"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "reminderEmails",
                            "false"
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style2" } }, [
                      _vm._v("Disabled")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Internal Notes")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.internalNotes,
                          expression: "currentSubscription.internalNotes"
                        }
                      ],
                      attrs: { cols: "50" },
                      domProps: {
                        value: _vm.currentSubscription.internalNotes
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentSubscription,
                            "internalNotes",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Automatic Renewal")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.automaticRenewal,
                          expression: "currentSubscription.automaticRenewal"
                        }
                      ],
                      attrs: { type: "radio", name: "automaticRenewal" },
                      domProps: {
                        value: 1,
                        checked: _vm._q(
                          _vm.currentSubscription.automaticRenewal,
                          1
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "automaticRenewal",
                            1
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style1" } }, [_vm._v("Yes")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.automaticRenewal,
                          expression: "currentSubscription.automaticRenewal"
                        }
                      ],
                      attrs: { type: "radio", name: "automaticRenewal" },
                      domProps: {
                        value: 0,
                        checked: _vm._q(
                          _vm.currentSubscription.automaticRenewal,
                          0
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "automaticRenewal",
                            0
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style2" } }, [_vm._v("No")])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Card stored")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.storeCard,
                          expression: "currentSubscription.storeCard"
                        }
                      ],
                      attrs: { type: "radio", name: "cardStored" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.currentSubscription.storeCard, true)
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "storeCard",
                            true
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style1" } }, [_vm._v("Yes")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSubscription.storeCard,
                          expression: "currentSubscription.storeCard"
                        }
                      ],
                      attrs: { type: "radio", name: "cardStored" },
                      domProps: {
                        value: false,
                        checked: _vm._q(
                          _vm.currentSubscription.storeCard,
                          false
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.currentSubscription,
                            "storeCard",
                            false
                          )
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "style2" } }, [_vm._v("No")])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-4 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "paid" }
                      },
                      [_vm._v("Institutional Subscription")]
                    )
                  ]),
                  !_vm.currentSubscription._id
                    ? _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSubscription.isInstitutional,
                              expression: "currentSubscription.isInstitutional"
                            }
                          ],
                          attrs: { type: "radio", name: "isInstitutional" },
                          domProps: {
                            value: true,
                            checked: _vm._q(
                              _vm.currentSubscription.isInstitutional,
                              true
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.currentSubscription,
                                "isInstitutional",
                                true
                              )
                            }
                          }
                        }),
                        _c("label", { attrs: { for: "style1" } }, [
                          _vm._v("Yes")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSubscription.isInstitutional,
                              expression: "currentSubscription.isInstitutional"
                            }
                          ],
                          attrs: { type: "radio", name: "isInstitutional" },
                          domProps: {
                            value: false,
                            checked: _vm._q(
                              _vm.currentSubscription.isInstitutional,
                              false
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.currentSubscription,
                                "isInstitutional",
                                false
                              )
                            }
                          }
                        }),
                        _c("label", { attrs: { for: "style2" } }, [
                          _vm._v("No")
                        ])
                      ])
                    : _c("div", { staticClass: "col-6" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.currentSubscription.isInstitutional
                                ? "Yes"
                                : "No"
                            )
                          )
                        ])
                      ])
                ]),
                _vm.currentSubscription.isInstitutional
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "paid" }
                          },
                          [_vm._v("Institution Name")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSubscription.institutionName,
                              expression: "currentSubscription.institutionName"
                            }
                          ],
                          attrs: { type: "text", size: "50" },
                          domProps: {
                            value: _vm.currentSubscription.institutionName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentSubscription,
                                "institutionName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _vm.currentSubscription.isInstitutional
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "paid" }
                          },
                          [_vm._v("Domain Name")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.currentSubscription.institutionDomainName,
                              expression:
                                "currentSubscription.institutionDomainName"
                            }
                          ],
                          attrs: { type: "text", size: "50" },
                          domProps: {
                            value: _vm.currentSubscription.institutionDomainName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentSubscription,
                                "institutionDomainName",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _vm.currentSubscription.isInstitutional
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "paid" }
                          },
                          [_vm._v("Library URL")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.currentSubscription.institutionLibraryUrl,
                              expression:
                                "currentSubscription.institutionLibraryUrl"
                            }
                          ],
                          attrs: { type: "text", size: "50" },
                          domProps: {
                            value: _vm.currentSubscription.institutionLibraryUrl
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.currentSubscription,
                                "institutionLibraryUrl",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _vm.currentSubscription.isInstitutional
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label required",
                            attrs: { for: "songTitle" }
                          },
                          [_vm._v("Institutional Plan Type")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSubscription.institutionalPlanType,
                                expression:
                                  "currentSubscription.institutionalPlanType"
                              }
                            ],
                            attrs: { name: "plan" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.currentSubscription,
                                  "institutionalPlanType",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.institutionalPlanTypes, function(option) {
                            return _c(
                              "option",
                              { key: option, domProps: { value: option } },
                              [_vm._v(" " + _vm._s(option) + " ")]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.missingInstitutionalPlan
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [_vm._v(" Plan is required. ")]
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.currentSubscription.isInstitutional
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "paid" }
                          },
                          [_vm._v("IP Range(s)")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("p", [
                          _vm._v(" IP ranges must be in "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "http://www.ipaddressguide.com/cidr",
                                target: "_blank_"
                              }
                            },
                            [_vm._v("CIDR")]
                          ),
                          _vm._v(
                            " format. For multiple ranges, use multiple lines. Single IP addresses are also accepted. "
                          )
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ipranges,
                              expression: "ipranges"
                            }
                          ],
                          attrs: { cols: "50", rows: "3" },
                          domProps: { value: _vm.ipranges },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.ipranges = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e(),
                _vm.cidrError
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "offset-4 col-6" }, [
                        _c(
                          "div",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.cidrRangeOk
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [
                            _vm._v(
                              " Error in CIDR range. " +
                                _vm._s(_vm.cidrError) +
                                " "
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-8 col-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary btn-sm",
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-success btn-sm",
                        attrs: { disabled: _vm.formsHasErrors },
                        on: { click: _vm.save }
                      },
                      [_vm._v(" Save ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger btn-sm",
                        attrs: { disabled: _vm.subscriptionCheck() },
                        on: { click: _vm.delSubscription }
                      },
                      [_vm._v(" Delete ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger btn-sm",
                        attrs: { disabled: _vm.cancelCheck() },
                        on: { click: _vm.cancelSubscription }
                      },
                      [_vm._v(" Cancel Subscription ")]
                    ),
                    _vm.subscriptionCheck()
                      ? _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(" Cannot delete subscription, still active. ")
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ]),
            !_vm.currentSubscription.isNew
              ? _c("div", [
                  _c("h6", [_vm._v("Users")]),
                  _c("hr"),
                  _c(
                    "ul",
                    _vm._l(_vm.currentSubscription.users, function(user) {
                      return _c("li", { key: user._id }, [
                        _vm._v(
                          " " +
                            _vm._s(user.name) +
                            ", " +
                            _vm._s(user.email) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.editUser(user._id)
                              }
                            }
                          },
                          [_vm._v("(Edit User)")]
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            !_vm.currentSubscription.isNew
              ? _c("div", [
                  _c("h6", [_vm._v("Renewal Reminders")]),
                  _c("hr"),
                  _vm.currentSubscription.emails &&
                  _vm.currentSubscription.emails.length > 0
                    ? _c(
                        "ul",
                        _vm._l(_vm.currentSubscription.emails, function(email) {
                          return _c("li", { key: email.timeSent }, [
                            _vm._v(" " + _vm._s(email) + " ")
                          ])
                        }),
                        0
                      )
                    : _c("span", { staticStyle: { "margin-left": "40px" } }, [
                        _vm._v("No emails sent for this subscription")
                      ])
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }