import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    resourcesList: [],
    currentResource: {}
  },
  mutations: {
    setResourcesList: (state, payload) => {
      state.resourcesList = payload
    },
    setCurrentResource: (state, payload) => {
      state.currentResource = payload
    }
  },
  actions: {
    getResources: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.resourcesList.length === 0) {
          rootState.general.error = ''
          rootState.general.loading = true
          rootState.general.loadingMsg = 'Loading Resources'
          console.log('Loadingresourcesources')
          Vue.http.get('api/resources').then(
            response => {
              commit('setResourcesList', response.body)
              rootState.general.loading = false
              rootState.general.loadingMsg = ''
              resolve('resources')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('resources')
        }
      })
    },
    deleteResource: ({ commit, rootState }, resourceId) => {
      return new Promise((resolve, reject) => {
        Vue.http.delete('api/resources/' + resourceId).then(
          response => {
            console.log('Got reply from deleting resource', response)
            resolve(response)
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.general.loading = false
            reject(error.bodyText)
          }
        )
      })
    },
    saveResource: ({ commit, rootState }, resource) => {
      // resource.question = resource.question
      //   .replace('<p>', '')
      //   .replace('</p>', '')
      //   .replace(/(?:\r\n|\r|\n)/g, '')
      // resource.answer = resource.answer.replace('<p>', '').replace('</p>', '')
      Vue.http.post('api/resources', { resource }).then(
        response => {
          console.log('Got reply from saving resource', response)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    }
  }
}
