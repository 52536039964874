var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "below-menu" }, [
    _c("div", { staticClass: "row fixed-top page-header" }, [
      _vm._m(0),
      _vm.hasData()
        ? _c("div", { staticClass: "col-10" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.showSubscriptions,
                    expression: "showSubscriptions"
                  }
                ],
                staticStyle: { "margin-top": "3px" },
                attrs: { type: "checkbox", name: "showSubscriptions" },
                domProps: {
                  checked: Array.isArray(_vm.showSubscriptions)
                    ? _vm._i(_vm.showSubscriptions, null) > -1
                    : _vm.showSubscriptions
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.showSubscriptions,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.showSubscriptions = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.showSubscriptions = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.showSubscriptions = $$c
                    }
                  }
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Show subscriptions in report")]
              ),
              _vm._v(" Start report from: "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.month,
                      expression: "month"
                    }
                  ],
                  attrs: { name: "months", id: "months" },
                  on: {
                    input: _vm.monthChanged,
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.month = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.months, function(month) {
                  return _c("option", { key: month }, [_vm._v(_vm._s(month))])
                }),
                0
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.year,
                      expression: "year"
                    }
                  ],
                  attrs: { name: "years", id: "years" },
                  on: {
                    input: _vm.yearChanged,
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.year = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.years, function(year) {
                  return _c("option", { key: year }, [_vm._v(_vm._s(year))])
                }),
                0
              ),
              _vm._v(" End report until: "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.monthEnd,
                      expression: "monthEnd"
                    }
                  ],
                  attrs: { name: "monthsEnd", id: "monthsEnd" },
                  on: {
                    input: _vm.monthEndChanged,
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.monthEnd = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.months, function(month) {
                  return _c("option", { key: month }, [_vm._v(_vm._s(month))])
                }),
                0
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.yearEnd,
                      expression: "yearEnd"
                    }
                  ],
                  attrs: { name: "yearsEnd", id: "yearsEnd" },
                  on: {
                    input: _vm.yearEndChanged,
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.yearEnd = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.yearsEnd, function(year) {
                  return _c("option", { key: year }, [_vm._v(_vm._s(year))])
                }),
                0
              ),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary btn-xs float-right header-btn",
                  on: {
                    click: function($event) {
                      return _vm.emailPage(true)
                    }
                  }
                },
                [_vm._v(" Email as Text ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-primary btn-xs float-right header-btn",
                  on: {
                    click: function($event) {
                      return _vm.emailPage(false)
                    }
                  }
                },
                [_vm._v(" Email as Image ")]
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._m(1),
    _c("div", { staticClass: "flex-row" }, [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c("v-select", {
            staticClass: "select-style",
            attrs: { "close-on-select": true, options: _vm.institutions },
            on: { input: _vm.institutionEntered },
            model: {
              value: _vm.institution,
              callback: function($$v) {
                _vm.institution = $$v
              },
              expression: "institution"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "or-style" }, [_vm._v("OR")]),
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c("v-select", {
            attrs: { "close-on-select": true, options: _vm.individuals },
            on: { input: _vm.individualEntered },
            model: {
              value: _vm.individual,
              callback: function($$v) {
                _vm.individual = $$v
              },
              expression: "individual"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "or-style" }, [_vm._v("OR")]),
      _c("div", { staticClass: "search-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.subId,
              expression: "subId"
            }
          ],
          attrs: { type: "text", id: "subId" },
          domProps: { value: _vm.subId },
          on: {
            keyup: _vm.subIdEntered,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.subId = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("hr"),
    _vm.stat._id
      ? _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-2" }),
            _c(
              "div",
              { staticClass: "col-sm text-center", attrs: { id: "printable" } },
              [
                _c("h1", [_vm._v(_vm._s(_vm.title))]),
                _vm.stat.institution
                  ? _c("div", [
                      _c("div", [_vm._v(_vm._s(_vm.stat.fullName))]),
                      _c("div", [_vm._v(_vm._s(_vm.stat.email))])
                    ])
                  : _c("div", [_vm._v(_vm._s(_vm.stat.email))]),
                _vm.overallLogins > 0
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-sm text-center period",
                          attrs: { id: "printable" }
                        },
                        [
                          _vm._v(
                            " Statistics from " +
                              _vm._s(_vm.month) +
                              " " +
                              _vm._s(_vm.year) +
                              " until " +
                              _vm._s(_vm.monthEnd) +
                              " " +
                              _vm._s(_vm.yearEnd) +
                              " "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.overallLogins > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "row higher",
                        staticStyle: { "line-height": "40px" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-sm offset-sm-2 text-center" },
                          [
                            _vm._v(" Overall logins: "),
                            _c("b", [_vm._v(_vm._s(this.overallLogins))])
                          ]
                        ),
                        _c("div", { staticClass: "col-sm text-center" }, [
                          _vm._v(" Overall searches: "),
                          _c("b", [_vm._v(_vm._s(this.overallSearches))])
                        ]),
                        _c("div", { staticClass: "col-sm-2" })
                      ]
                    )
                  : _vm._e(),
                _vm.overallLogins > 0
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm" }),
                      _c("span", { staticClass: "col bold" }, [_vm._v("Year")]),
                      _c("span", { staticClass: "col bold" }, [
                        _vm._v("Logins")
                      ]),
                      _c("span", { staticClass: "col bold" }, [
                        _vm._v("Searches")
                      ]),
                      _c("div", { staticClass: "col-sm" })
                    ])
                  : _vm._e(),
                _vm._l(this.totals, function(total) {
                  return _c("div", { key: total.year, staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm" }),
                    _c("span", { staticClass: "col" }, [
                      _vm._v(_vm._s(total.year))
                    ]),
                    _c("span", { staticClass: "col" }, [
                      _vm._v(_vm._s(total.logins))
                    ]),
                    _c("span", { staticClass: "col" }, [
                      _vm._v(_vm._s(total.searches))
                    ]),
                    _c("div", { staticClass: "col-sm" })
                  ])
                }),
                _vm.noStat && _vm.showSubscriptions
                  ? _c("div", { staticClass: "row higher" }, [_vm._m(2)])
                  : _vm._e(),
                _vm.noStat && _vm.showSubscriptions
                  ? _c("div", { staticClass: "row" }, [
                      _c("span", { staticClass: "col-4 text-left bold" }, [
                        _vm._v("Plan")
                      ]),
                      _c("span", { staticClass: "col bold" }, [
                        _vm._v("Start")
                      ]),
                      _c("span", { staticClass: "col bold" }, [_vm._v("End")]),
                      _c("span", { staticClass: "col-4 text-right bold" }, [
                        _vm._v("Purchaser")
                      ])
                    ])
                  : _vm._e(),
                _vm.noStat && _vm.showSubscriptions
                  ? _c(
                      "div",
                      _vm._l(this.subscriptions, function(sub) {
                        return _c("div", { key: sub._id, staticClass: "row" }, [
                          _c("span", { staticClass: "col-4 text-left" }, [
                            _vm._v(_vm._s(sub.planName))
                          ]),
                          _c("span", { staticClass: "col" }, [
                            _vm._v(_vm._s(_vm._f("formatDate")(sub.startDate)))
                          ]),
                          _c("span", { staticClass: "col" }, [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(sub.expirationDate))
                            )
                          ]),
                          _c("span", { staticClass: "col-4 text-right" }, [
                            _vm._v(_vm._s(sub.email))
                          ])
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.overallLogins > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "row",
                        staticStyle: { "margin-top": "50px" }
                      },
                      [
                        _c("span", { staticClass: "col-4 text-left bold" }, [
                          _vm._v("Year")
                        ]),
                        _c("span", { staticClass: "col text-left bold" }, [
                          _vm._v("Month")
                        ]),
                        _c("span", { staticClass: "col bold" }, [
                          _vm._v("Logins")
                        ]),
                        _c("span", { staticClass: "col-4 text-right bold" }, [
                          _vm._v("Searches")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.noStat
                  ? _c("hr", { staticStyle: { "margin-top": "0px" } })
                  : _vm._e(),
                _vm._l(this.periods, function(period) {
                  return _c(
                    "div",
                    { key: period.date, staticClass: "row border-row" },
                    [
                      _c("span", { staticClass: "col-4 text-left" }, [
                        _vm._v(_vm._s(period.year))
                      ]),
                      _c("span", { staticClass: "col text-left" }, [
                        _vm._v(_vm._s(period.month))
                      ]),
                      _c("span", { staticClass: "col" }, [
                        _vm._v(_vm._s(period.logins))
                      ]),
                      _c("span", { staticClass: "col-4 text-right" }, [
                        _vm._v(_vm._s(period.searches))
                      ])
                    ]
                  )
                })
              ],
              2
            ),
            _c("div", { staticClass: "col-2" })
          ]),
          _c("div", { ref: "tocopy" })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c("h5", [_vm._v("Statistics")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "search-title" }, [_vm._v("Institution Name")]),
      _c("div", { staticClass: "or-style white" }, [_vm._v("OR")]),
      _c("div", { staticClass: "search-title" }, [_vm._v("Email Address")]),
      _c("div", { staticClass: "or-style white" }, [_vm._v("OR")]),
      _c("div", { staticClass: "search-title" }, [_vm._v("Subscription ID")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm text-center" }, [
      _c("h4", [_vm._v("Subscriptions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }