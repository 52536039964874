var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-login" }, [
      _c("h4", { staticStyle: { "text-align": "center" } }, [
        _vm._v(" Please Login ")
      ]),
      _c(
        "form",
        {
          attrs: { id: "login-form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit($event)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.username,
                expression: "username"
              }
            ],
            staticClass: "form-control input-sm chat-input",
            attrs: {
              type: "text",
              autocomplete: "username",
              id: "userName",
              placeholder: "username"
            },
            domProps: { value: _vm.username },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.username = $event.target.value
              }
            }
          }),
          _c("br"),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.pw,
                expression: "pw"
              }
            ],
            staticClass: "form-control input-sm chat-input",
            attrs: {
              "data-private": "",
              autocomplete: "current-password",
              type: "password",
              id: "userPassword",
              placeholder: "password"
            },
            domProps: { value: _vm.pw },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.pw = $event.target.value
              }
            }
          }),
          _c("br"),
          _c("div", { staticClass: "wrapper" }, [
            _c("span", { staticClass: "group-btn" }, [
              _vm._m(0),
              _c("div", { staticClass: "login-error" }, [
                _vm._v(_vm._s(_vm.serverError))
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-success btn-md",
        attrs: { id: "btn-login", type: "submit" }
      },
      [_vm._v("login "), _c("i", { staticClass: "fa fa-sign-in" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }