import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    composersList: [],
    // featuredComposers contains only id's and custom labels
    featuredComposers: [],
    // filteredComposersList is composersList filtered by featured composers
    filteredComposersList: [],
    lyricistsList: [],
    currentComposer: {},
    currentLyricist: {},
    latestSavedComposer: {},
    latestSavedLyricist: {}
  },
  mutations: {
    setNewComposer: state => {
      state.currentComposer = {
        _id: undefined,
        firstName: '',
        lastName: '',
        infoUrl: '',
        bio: ''
      }
    },
    setNewLyricist: state => {
      state.currentLyricist = {
        _id: undefined,
        firstName: '',
        lastName: '',
        infoUrl: '',
        bio: ''
      }
    },
    setCurrentComposer: (state, payload) => {
      state.currentComposer = payload
    },
    setCurrentLyricist: (state, payload) => {
      state.currentLyricist = payload
    },
    setComposersList: (state, payload) => {
      state.composersList = payload
    },
    setFeaturedComposersList: (state, payload) => {
      state.featuredComposers = payload
    },
    setFilteredComposersList: state => {
      state.filteredComposersList = state.composersList.filter(c => {
        return state.featuredComposers._data.some(f => {
          return c._id === f.id
        })
      })
    },
    setLyricistsList: (state, payload) => {
      state.lyricistsList = payload
    },
    setLatestSavedComposer: (state, payload) => {
      state.latestSavedComposer = Object.assign({}, payload)
      if (!state.latestSavedComposer.label) {
        state.latestSavedComposer.label = `${payload.firstName} ${payload.lastName}`
      }
    },
    setLatestSavedLyricist: (state, payload) => {
      state.latestSavedLyricist = Object.assign({}, payload)
      if (!state.latestSavedLyricist.label) {
        state.latestSavedLyricist.label = `${payload.firstName} ${payload.lastName}`
      }
    }
  },
  actions: {
    getComposers: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.composersList.length === 0) {
          rootState.general.error = ''
          rootState.general.loading = true
          rootState.general.loadingMsg = 'Loading Composers'
          console.log('Loading Composers')
          Vue.http.get('api/composers').then(
            response => {
              commit('setComposersList', response.body)
              rootState.general.loading = false
              rootState.general.loadingMsg = ''
              resolve('composers')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('composers')
        }
      })
    },
    getFeaturedComposers: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.featuredComposers.length === 0) {
          rootState.general.error = ''
          rootState.general.loading = true
          rootState.general.loadingMsg = 'Loading Featured Composers'
          console.log('Loading Featured Composers')
          Vue.http.get('api/featuredcomposers').then(
            response => {
              commit('setFeaturedComposersList', response.body)
              rootState.general.loading = false
              rootState.general.loadingMsg = ''
              resolve('featured composers')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('featured composers')
        }
      })
    },
    getLyricists: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.lyricistsList.length === 0) {
          rootState.general.error = ''
          rootState.general.loading = true
          rootState.general.loadingMsg = 'Loading Lyricists'
          console.log('Loading Lyricists')
          Vue.http.get('api/lyricists').then(
            response => {
              commit('setLyricistsList', response.body)
              rootState.general.loading = false
              rootState.general.loadingMsg = ''
              resolve('lyricists')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('lyricists')
        }
      })
    },
    getComposer: ({ state, commit, rootState }, rowId) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading Composer'
      // return new Promise((resolve, reject) => {
        return Vue.http.get('api/composers/' + rowId).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            const curComp = response.body
            // Set bio or it will show undefined in HTML editor
            if (!curComp.bio) {
              curComp.bio = ''
            }
            // Set Featured Composer flag
            const isFeatured = state.featuredComposers._data.find(f => f.id === curComp._id)
            if (isFeatured) {
              curComp.isFeatured = true
              curComp.customLabel = isFeatured.customLabel
              console.log('Featured Composer', curComp.lastName)
            }
            commit('setCurrentComposer', response.body)
            console.log(`[Creators Store] Got composer ${response.body._id}`)
            // return resolve('ok')
            return Promise.resolve()
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.general.loading = false
            // return reject(error)
            return Promise.reject()
          }
        )
      // })
    },
    getLyricist: ({ commit, rootState }, rowId) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading Lyricist'
      return new Promise((resolve, reject) => {
        Vue.http.get('api/lyricists/' + rowId).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.loading = false
            const curLyrc = response.body
            // Set bio or it will show undefined in HTML editor
            if (!curLyrc.bio) {
              curLyrc.bio = ''
            }
            commit('setCurrentLyricist', curLyrc)
            resolve('ok')
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.loading = false
            reject(error)
          }
        )
      })
    },
    deleteComposer: ({ commit, rootState }, compId) => {
      Vue.http.delete('api/composers/' + compId).then(
        response => {
          console.log('Got reply from deleting composer', response)
          commit('setNewComposer')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    deleteLyricist: ({ commit, rootState }, lyrcId) => {
      Vue.http.delete('api/lyricists/' + lyrcId).then(
        response => {
          console.log('Got reply from deleting lyricist', response)
          commit('setNewLyricist')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    saveComposer: ({ commit, rootState }, composer) => {
      return Vue.http.post('api/composers', { composer }).then(
        response => {
          console.log('Got reply from saving composer', response)
          // This is mainly for use in ShowEdit and SongEdit to add new composers
          commit('setLatestSavedComposer', response.body)
          // Set current creator to empty item
          // because when on any other page then first, this creator has been moved to
          // 1st record in table when date field is the sort field (default)
          commit('setNewComposer')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    saveLyricist: ({ commit, rootState }, lyricist) => {
      return Vue.http.post('api/lyricists', { lyricist }).then(
        response => {
          console.log('Got reply from saving lyricist', response)
          // This is mainly for use in ShowEdit and SongEdit to add new composers
          commit('setLatestSavedLyricist', response.body)
          // Set current creator to empty item
          // because when on any other page then first, this creator has been moved to
          // 1st record in table when date field is the sort field (default)
          commit('setNewLyricist')
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    updateComposer: ({ commit, rootState }, composer) => {
      Vue.http.post('api/composers', { composer }).then(
        response => {},
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.loading = false
        }
      )
    },
    updateLyricist: ({ commit, rootState }, lyricist) => {
      Vue.http.post('api/lyricists', { lyricist }).then(
        response => {},
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.loading = false
        }
      )
    }
  },
  getters: {
    latestSavedComposer: state => {
      return state.latestSavedComposer
    },
    latestSavedLyricist: state => {
      return state.latestSavedLyricist
    }
  }
}
