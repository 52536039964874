<template>
  <div>
    <!-- The user edit form -->
    <!-- <user-edit :onClose="close" :currentUserProp="currentSubscription.purchaser"/> -->

    <modal
      id="subscription-edit"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="subscription-edit"
      height="auto"
      width="1000"
      :scrollable="true"
      transition="pop-out"
      :click-to-close="false"
    >
      <div class="loading" v-if="loading">
        <div v-if="error" class="loading server-error">
          {{ loadingErrorMsg }}
        </div>
        <div v-else>
          <img src="/loading.gif" />
          {{ loadingMsg }}
        </div>
      </div>
      <div class="show-form">
        <div v-if="currentSubscription._id">
          <h4>Edit Subscription</h4>
          <a :href="`#/statistics/${currentSubscription._id}`" target="_blank">View usage statistics</a>
        </div>
        <div v-else>
          <h4>New Subscription</h4>
          <div class="col-6">
            <input type="radio" name="isNewUser" :value="true" @click="newUserSet" v-model="currentSubscription.isNewUser" />
            <label for="style1">New User</label>
            <input type="radio" name="isNewUser" :value="false" @click="existingUserSet" />
            <label for="style2">Existing User</label>
          </div>
        </div>
        <p v-if="!currentSubscription.isNew">
          Purchased by: {{ currentSubscription.purchaser.firstName }} {{ currentSubscription.purchaser.lastName }},
          {{ currentSubscription.purchaser.email }}
          <a href="#" @click="editUser(currentSubscription.purchaser._id)" style="margin-left:10px;">(Edit User)</a>
        </p>
        <div class="row">
          <div class="col-12">
            <div v-if="currentSubscription.isNewUser">
              <!-- New User First Name-->
              <div class="row form-row">
                <div class="col-4 label">
                  <label class="label required">First Name</label>
                </div>
                <div class="col-2">
                  <input type="text" v-model="currentSubscription.purchaser.firstName" />
                  <div :class="[attemptSubmit && hasFirstName ? 'is-not-valid' : 'is-valid']">
                    First Name is required.
                  </div>
                </div>
              <!-- Group Admin-->
                 <div class="col-2 label">
                   <input type="checkbox" name="age" class="css-checkbox" id="age6" value="groupadmin" @change="GroupAdminChanged"  v-model="currentSubscription.purchaser.roles" />
                   <label for="age6" class="css-label lite-red-check">Group Admin</label>
                 </div>
                 <div class="col-4">
                  <label for="maxGroupMembers" class="label required extra-role">Max Members</label>
                  <input type="text" id="maxGroupMembers" size="3" v-model="currentSubscription.purchaser.maxGroupMembers">
                 </div>
              </div>

              <!-- New User Last Name-->
              <div class="row form-row">
                <div class="col-4 label">
                  <label class="label required">Last Name</label>
                </div>
                <div class="col-6">
                  <input type="text" v-model="currentSubscription.purchaser.lastName" />
                  <div :class="[attemptSubmit && hasLastName ? 'is-not-valid' : 'is-valid']">
                    Last Name is required.
                  </div>
                </div>
              </div>

              <!-- New User Email-->
              <div class="row form-row">
                <div class="col-4 label">
                  <label class="label required">Email</label>
                </div>
                <div class="col-6">
                  <input type="text" v-model="currentSubscription.purchaser.email" />
                  <div v-if="hasEmail" :class="[attemptSubmit && hasEmail ? 'is-not-valid' : 'is-valid']">
                    Email is required.
                  </div>
                  <div v-if="existingEmail" :class="[existingEmail ? 'is-not-valid' : 'is-valid']">
                    Email already exists.
                  </div>
                </div>
              </div>

              <!-- New User Password-->
              <div class="row form-row">
                <div class="col-4 label">
                  <label class="label required">Password</label>
                </div>
                <div class="col-6">
                  <input type="text" v-model="currentSubscription.purchaser.password" />
                  <div :class="[attemptSubmit && hasPassword ? 'is-not-valid' : 'is-valid']">
                    Password is required.
                  </div>
                </div>
              </div>
            </div>

            <div v-if="currentSubscription.isNew && !currentSubscription.isNewUser">
              <div class="row form-row">
                <div class="col-4 label">
                  <label class="label required">Existing User</label>
                </div>
                <div class="col-6">
                  <v-select label="email" :options="emailsList" v-model="selectedUser" @input="existingUserSelected" />
                  <div :class="[attemptSubmit && hasUser ? 'is-not-valid' : 'is-valid']">
                    User is required.
                  </div>
                </div>
              </div>
            </div>

            <!-- Subscription Plan -->
            <div class="row form-row">
              <!-- Renew Subscription button -->
              <div class="col-1">
                <button
                  v-if="currentSubscription.canRenew"
                  @click="renewSub(currentSubscription._id)"
                  class="btn btn-outline-primary btn-sm"
                >
                  Renew Subscription
                </button>
              </div>
              <div class="col-3 label">
                <label class="label required" for="songTitle">Subscription Plan</label>
              </div>
              <div class="col-6">
                <select v-model="currentSubscription.plan" name="plan" @change="planChanged">
                  <option v-for="option in plans" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
                <div :class="[attemptSubmit && missingPlan ? 'is-not-valid' : 'is-valid']">
                  Plan is required.
                </div>
              </div>
            </div>

            <!-- Subscription Id -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label" for="startDate">Subscription ID</label>
              </div>
              <div class="col-6">
                <div>{{ currentSubscription._id }}</div>
              </div>
            </div>

            <!-- Start Date -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="startDate">Start Date</label>
              </div>
              <div class="col-6" v-if="currentSubscription.canEditPayments">
                <datepicker
                  :format="'MMM dd yyyy'"
                  v-model="currentSubscription.startDate"
                  name="startDate"
                  @closed="planChanged"
                />
                <div :class="[attemptSubmit && startDateOk ? 'is-not-valid' : 'is-valid']">
                  Start date is required.
                </div>
              </div>
              <div v-else>
                {{ currentSubscription.startDate | formatEpoch }}
              </div>
            </div>

            <!-- Expiration Date -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label required" for="expData">Expiration Date</label>
              </div>
              <div class="col-8" v-if="currentSubscription.canEditPayments">
                <datepicker
                  :format="'MMM dd yyyy'"
                  v-model="currentSubscription.expirationDate"
                  name="expirationDate"
                  style="float:left;"
                />
                <span v-if="expDateChanged">
                  <i>&nbsp; Date changed. Original {{ oldDate }}</i>
                </span>
                <div :class="[attemptSubmit && expirationDateOk ? 'is-not-valid' : 'is-valid']">
                  Expiration date is required.
                </div>
              </div>
              <div v-else>
                {{ currentSubscription.expirationDate | formatEpoch }}
              </div>
            </div>

            <!-- Cancellation Date -->
            <div class="row form-row" v-if="currentSubscription.isCancelled">
              <div class="col-4 label">
                <label class="label" for="cancellationDate">Cancellation Date</label>
              </div>
              <div>
                {{ currentSubscription.cancellationDate | formatEpoch }}
              </div>
            </div>

            <!-- Amount paid -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Amount Paid</label>
              </div>
              <div class="col-6" v-if="currentSubscription.canEditPayments">
                <input type="number" style="float:left;" v-model="currentSubscription.amountPaid" />
                <div v-if="currentSubscription.isNew && currentSubscription.amountPaid > 0">
                  <input style="float:left;margin-left:10px;margin-top:5px;margin-right:5px;" type="checkbox" />Send email
                  receipt?
                </div>
              </div>
              <div v-else>${{ currentSubscription.amountPaid | roundAmount }}</div>
            </div>

            <!-- Payment received -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Payment Received</label>
              </div>
              <div class="col-8" v-if="currentSubscription.canEditPayments">
                <datepicker :format="'MMM dd yyyy'" v-model="currentSubscription.paymentDate" name="paymentDate" />
              </div>
              <div v-else>
                {{ currentSubscription.paymentDate | formatEpoch }}
              </div>
            </div>

            <!-- Coupon code -->
            <div class="row form-row" v-if="!currentSubscription.isNew && currentSubscription.couponCode">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Coupon Code Used</label>
              </div>
              <div class="col-6">
                <span>{{ currentSubscription.couponCode }}</span>
              </div>
            </div>
            <!-- totalBeforeCoupon -->
            <div class="row form-row" v-if="!currentSubscription.isNew && currentSubscription.couponCode">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Coupon Discount</label>
              </div>
              <div class="col-6">
                <span>${{ currentSubscription.totalBeforeCoupon | roundAmount }}</span>
              </div>
            </div>

            <!-- Reminder Emails -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Reminder Emails</label>
              </div>
              <div class="col-6">
                <input type="radio" name="style" value="true" v-model="currentSubscription.reminderEmails" />
                <label for="style1">Enabled</label>
                <input type="radio" name="style" value="false" v-model="currentSubscription.reminderEmails" />
                <label for="style2">Disabled</label>
              </div>
            </div>

            <!-- Internal Notes -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Internal Notes</label>
              </div>
              <div class="col-6">
                <textarea cols="50" v-model="currentSubscription.internalNotes" />
              </div>
            </div>

            <!-- Automatic Renewal -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Automatic Renewal</label>
              </div>
              <div class="col-6">
                <input type="radio" name="automaticRenewal" :value="1" v-model="currentSubscription.automaticRenewal" />
                <label for="style1">Yes</label>
                <input type="radio" name="automaticRenewal" :value="0" v-model="currentSubscription.automaticRenewal" />
                <label for="style2">No</label>
              </div>
            </div>

            <!-- Automatic Renewal -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Card stored</label>
              </div>
              <div class="col-6">
                <input type="radio" name="cardStored" :value="true" v-model="currentSubscription.storeCard" />
                <label for="style1">Yes</label>
                <input type="radio" name="cardStored" :value="false" v-model="currentSubscription.storeCard" />
                <label for="style2">No</label>
              </div>
            </div>

            <!-- Institutional Subscription -->
            <div class="row form-row">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Institutional Subscription</label>
              </div>
              <div class="col-6" v-if="!currentSubscription._id">
                <input type="radio" name="isInstitutional" :value="true" v-model="currentSubscription.isInstitutional" />
                <label for="style1">Yes</label>
                <input type="radio" name="isInstitutional" :value="false" v-model="currentSubscription.isInstitutional" />
                <label for="style2">No</label>
              </div>
              <div class="col-6" v-else>
                <b>{{ currentSubscription.isInstitutional ? 'Yes' : 'No' }}</b>
              </div>
            </div>

            <!-- Institution Name -->
            <div class="row form-row" v-if="currentSubscription.isInstitutional">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Institution Name</label>
              </div>
              <div class="col-6">
                <input type="text" size="50" v-model="currentSubscription.institutionName" />
              </div>
            </div>

            <!-- Domain Name -->
            <div class="row form-row" v-if="currentSubscription.isInstitutional">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Domain Name</label>
              </div>
              <div class="col-6">
                <input type="text" size="50" v-model="currentSubscription.institutionDomainName" />
              </div>
            </div>

            <!-- Library URL -->
            <div class="row form-row" v-if="currentSubscription.isInstitutional">
              <div class="col-4 label">
                <label class="label not-required" for="paid">Library URL</label>
              </div>
              <div class="col-6">
                <input type="text" size="50" v-model="currentSubscription.institutionLibraryUrl" />
              </div>
            </div>

            <!-- Institutional Plan Type -->
            <div class="row form-row" v-if="currentSubscription.isInstitutional">
              <div class="col-4 label">
                <label class="label required" for="songTitle">Institutional Plan Type</label>
              </div>
              <div class="col-6">
                <select v-model="currentSubscription.institutionalPlanType" name="plan">
                  <option v-for="option in institutionalPlanTypes" :value="option" :key="option">
                    {{ option }}
                  </option>
                </select>
                <div :class="[attemptSubmit && missingInstitutionalPlan ? 'is-not-valid' : 'is-valid']">
                  Plan is required.
                </div>
              </div>
            </div>

            <!-- IP Ranges -->
            <div class="row form-row" v-if="currentSubscription.isInstitutional">
              <div class="col-4 label">
                <label class="label not-required" for="paid">IP Range(s)</label>
              </div>
              <div class="col-6">
                <p>
                  IP ranges must be in
                  <a href="http://www.ipaddressguide.com/cidr" target="_blank_">CIDR</a> format. For multiple ranges, use multiple
                  lines. Single IP addresses are also accepted.
                </p>
                <textarea cols="50" rows="3" v-model="ipranges" />
              </div>
            </div>
            <div class="row form-row" v-if="cidrError">
              <div class="offset-4 col-6">
              <div :class="[attemptSubmit && cidrRangeOk ? 'is-not-valid' : 'is-valid']">
                  Error in CIDR range. {{cidrError}}
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div class="row form-row">
              <div class="offset-8 col-4">
                <button @click="cancel" class="btn btn-outline-secondary btn-sm">
                  Cancel
                </button>
                <button @click="save" :disabled="formsHasErrors" class="btn btn-outline-success btn-sm">
                  Save
                </button>
                <button @click="delSubscription" :disabled="subscriptionCheck()" class="btn btn-outline-danger btn-sm">
                  Delete
                </button>
                <button @click="cancelSubscription" :disabled="cancelCheck()" class="btn btn-outline-danger btn-sm">
                  Cancel Subscription
                </button>
                <p v-if="subscriptionCheck()" style="color:red;">
                  Cannot delete subscription, still active.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!currentSubscription.isNew">
          <h6>Users</h6>
          <hr />
          <ul>
            <li v-for="user in currentSubscription.users" :key="user._id">
              {{ user.name }}, {{ user.email }}
              <a href="#" @click="editUser(user._id)" style="margin-left:10px;">(Edit User)</a>
            </li>
          </ul>
        </div>

        <div v-if="!currentSubscription.isNew">
          <h6>Renewal Reminders</h6>
          <hr />
          <ul v-if="currentSubscription.emails && currentSubscription.emails.length > 0">
            <li v-for="email in currentSubscription.emails" :key="email.timeSent">
              {{ email }}
            </li>
          </ul>
          <span v-else style="margin-left:40px">No emails sent for this subscription</span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  import Datepicker from 'vuejs-datepicker'
  import moment from 'moment'
  import cidrRegex from 'cidr-regex'
  import isIp from 'is-ip'
  export default {
    components: { Datepicker },
    data() {
      return {
        cidrError: '',
        apiError: '',
        attemptSubmit: false,
        changePassword: false,
        expDateChanged: false,
        oldDate: 0,
        newDate: 0,
        emailExists: false,
        selectedUser: { email: null, value: null }
      }
    },
    computed: {
      ...mapState({
        currentSubscription: state => state.subscriptions.currentSubscription,
        currentPurchaser: state => state.subscriptions.currentPurchaser,
        institutionalPlanTypes: state => state.subscriptions.institutionalPlanTypes,
        plans: state => state.subscriptions.plans,
        users: state => state.users.usersList,
        siteLink: state => state.general.siteLink,
        loading: state => state.general.loading,
        loadingMsg: state => state.general.loadingMsg,
        error: state => state.general.error,
        emailsList: state => state.users.emailsList
      }),
      ipranges: {
        get() {
          if (this.currentSubscription.recognizedIps) {
            return this.currentSubscription.recognizedIps.join('\n')
          }
          return ''
        },
        set(value) {
          if (this.currentSubscription.recognizedIps) {
            this.currentSubscription.recognizedIps = value.split('\n')
          }
          return []
        }
      },
      hasUser() {
        if (this.currentSubscription.purchaser) {
          return this.currentSubscription.purchaser._id === undefined || this.currentSubscription.purchaser._id === ''
        } else {
          return true
        }
      },
      hasFirstName() {
        return this.currentSubscription.purchaser.firstName === ''
      },
      hasLastName() {
        return this.currentSubscription.purchaser.lastName === ''
      },
      hasEmail() {
        return this.currentSubscription.purchaser.email === ''
      },
      existingEmail() {
        return this.emailExists
      },
      hasPassword() {
        if (this.currentSubscription.isNewUser) {
          const hasPw =
            !Object.prototype.hasOwnProperty.call(this.currentSubscription.purchaser, 'password') ||
            this.currentSubscription.purchaser.password === ''
          return hasPw
        }
        return false
      },
      missingPlan() {
        return this.currentSubscription.plan === ''
      },
      startDateOk() {
        return this.currentSubscription.startDate === 0
      },
      expirationDateOk() {
        return this.currentSubscription.expirationDate === 0
      },
      missingInstitutionalPlan() {
        return this.currentSubscription.institutionalPlanType === '' && this.currentSubscription.isInstitutional
      },
      cidrRangeOk() {
        const result = this.checkCIDR(this.currentSubscription.recognizedIps) !== null
        console.log('Cidr result', result)
        return result
      },
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          if (this.currentSubscription.isNewUser) {
            return (
              this.missingPlan ||
              this.startDateOk ||
              this.expirationDateOk ||
              this.missingInstitutionalPlan ||
              this.hasFirstName ||
              this.hasLastName ||
              this.hasPassword ||
              this.existingEmail ||
              this.cidrRangeOk
            )
          }
          if (this.currentSubscription.purchaser !== null && this.currentSubscription.purchaser._id !== undefined) {
            return this.cidrRangeOk || this.missingPlan || this.startDateOk || this.expirationDateOk || this.missingInstitutionalPlan || this.hasUser
          } else {
            return true
          }
        }
        this.$log.info('Form has no errors')
        return false
      }
    },
    methods: {
      GroupAdminChanged (val) {
        console.log(`GroupAdmin Changed`, val.target.checked)
        if (val.target.checked) {
          this.currentSubscription.isInstitutional = true
        }
      },
      checkRange(ipRanges) {
        for (var range of ipRanges) {
          if (!cidrRegex().test(range)) {
            return range
          }
        }
        return null
      },
      checkCIDR(rangesArray) {
        this.cidrError = ''
        // Check if valid CIDR range, result !null means error
        const result = this.checkRange(rangesArray)
        if (result) {
          // Single valid ip addresses are flaged as not a CIDR range
          // So check if valid ip address
          if (!isIp(result)) {
            console.log(`Error in Ip Range ${result}`)
            this.cidrError = result
            return result
          }
        }
        return null
      },
      beforeOpen() {
        // Set init values
        this.attemptSubmit = false
        this.changePassword = false
        document.addEventListener('keyup', this.close)
        this.expDateChanged = false
        this.$store.commit('general/dataLoaded')
      },
      beforeClose() {
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e && e.keyCode === 27) {
          eventbus.$emit('modal', {
            command: 'close',
            name: 'subscription-edit'
          })
        } else if (e === undefined) {
          eventbus.$emit('modal', {
            command: 'close',
            name: 'subscription-edit'
          })
        }
      },
      cancel() {
        // Send a close event
        eventbus.$emit('modal', { command: 'close', name: 'subscription-edit' })
        // this.onClose()
      },
      delSubscription() {
        const name = this.currentSubscription.institutionName
          ? this.currentSubscription.institutionName
          : `${this.currentSubscription.purchaser.firstName} ${this.currentSubscription.purchaser.lastName}`
        if (confirm(`Are you sure you want to delete the subscription for "${name}"?\n\nThis action cannot be undone.`)) {
          this.$store.dispatch('subscriptions/deleteSubscription', this.currentSubscription._id)
          // Closing dialogs moved to store, after success message received)
        }
      },
      cancelSubscription() {
        const name = this.currentSubscription.institutionName
          ? this.currentSubscription.institutionName
          : `${this.currentSubscription.purchaser.firstName} ${this.currentSubscription.purchaser.lastName}`
        if (
          confirm(`Are you sure you want to cancel the subscription for "${name}"?
            Subscription will be cancelled per ${this.$options.filters.formatDate(this.currentSubscription.expirationDate)}
            This action cannot be undone.`)
        ) {
          this.$store.dispatch('subscriptions/cancelSubscription', this.currentSubscription._id)
        }
        return false
      },
      save() {
        this.attemptSubmit = true
        this.$log.info('Subscription has errors', this.formsHasErrors)
        if (!this.formsHasErrors) {
          this.$store.dispatch('subscriptions/saveSubscription')
          // Closing dialogs moved to store, after success message received)
        }
      },
      renewSub(id) {
        this.$log.info('Renewing subscription', id)
        this.$store.dispatch('subscriptions/renewSubscription', id)
        eventbus.$emit('modal', { command: 'close', name: 'subscription-edit' })
      },
      editUser(id) {
        this.$log.info('Edit Purchaser', id)
        this.$store.dispatch('subscriptions/getPurchaser', id).then(
          () => {
            this.$store.commit('users/setCurrentUser', this.currentPurchaser)
            eventbus.$emit('modal', { command: 'open', name: 'user-edit' })
          },
          error => {
            console.error('[SubscriptionEdit] Something wrong returning from EditUser action', error)
          }
        )
      },
      newUserSet() {
        this.$log.info('new user clicked')
        this.currentSubscription.purchaser = {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          label: '',
          roles: []
        }
      },
      existingUserSet() {
        this.currentSubscription.purchaser = { _id: undefined }
        this.$store
          .dispatch('users/getEmailsList')
          .then(() => {
            this.currentSubscription.isNewUser = false
          })
          .catch(ex => {
            console.error('Exception in existingUserSet', ex.message)
          })
      },
      existingUserSelected(val) {
        this.currentSubscription.purchaser = this.selectedUser
      },
      planChanged() {
        this.oldDate = moment(this.currentSubscription.expirationDate).format('YYYY-MM-DD')
        this.$log.info(
          'Plan changed to',
          this.currentSubscription.plan,
          moment(this.currentSubscription.startDate).format('YYYY-MM-DD')
        )
        if (this.currentSubscription.plan === 'Monthly') {
          this.currentSubscription.expirationDate = moment(this.currentSubscription.startDate)
            .add(1, 'months')
            .add(1, 'days')
            .valueOf()
        } else {
          this.currentSubscription.expirationDate = moment(this.currentSubscription.startDate)
            .add(1, 'years')
            .add(1, 'days')
            .valueOf()
        }
        this.newDate = moment(this.currentSubscription.expirationDate).format('YYYY-MM-DD')
        this.$log.info(`Old date ${this.oldDate}, new date ${this.newDate}`)
        if (this.oldDate !== this.newDate) {
          this.expDateChanged = true
        }
      },
      cancelCheck() {
        const allowCancel =
          this.currentSubscription.expirationDate > Date.now() &&
          !this.currentSubscription.isCancelled &&
          this.currentSubscription.automaticRenewal === 1
        return !allowCancel
      },
      subscriptionCheck() {
        if (!this.currentSubscription._id) {
          return true
        }
        if (this.currentSubscription.expirationDate > Date.now() && !this.currentSubscription.isCancelled) {
          return true
        }
        return false
      }
    }
  }
</script>

<style scoped>
  .extra-role {
    margin-right: 10px;
    padding-left: 20px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 12px;
    vertical-align: middle;
  }
</style>
