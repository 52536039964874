var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "below-menu" }, [
    _vm.loading
      ? _c("div", { staticClass: "loading" }, [
          _c("img", { attrs: { src: "/loading.gif" } }),
          _c("div", { staticClass: "loading" }, [
            _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "row fixed-top page-header" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-3" }, [
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            on: { click: _vm.newTemplate }
          },
          [_vm._v(" Add Comment Template ")]
        ),
        _c(
          "button",
          {
            staticClass:
              "btn btn-outline-primary btn-xs float-right header-btn",
            staticStyle: { "margin-right": "10px" },
            on: { click: _vm.copyContent }
          },
          [_vm._v(" Copy ")]
        )
      ])
    ]),
    _vm.data
      ? _c(
          "div",
          { staticClass: "table-block" },
          [
            _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.rows,
                "pagination-options": { enabled: false },
                "search-options": { enabled: false },
                "sort-options": {
                  enabled: true,
                  initialSortBy: {
                    field: _vm.initialSortField,
                    type: _vm.initialSortType
                  }
                },
                "style-class": "vgt-table musical bordered condensed"
              },
              on: { "on-row-click": _vm.rowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "delete"
                          ? _c("span", [
                              _vm.$can("delete", "comment")
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteQuestion(
                                            props.row._id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("delete comment")]
                                  )
                                : _vm._e()
                            ])
                          : _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.formattedRow[props.column.field]
                                  ) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ],
                null,
                false,
                814147964
              )
            })
          ],
          1
        )
      : _vm._e(),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "show-form" }, [
      _vm.currentComment._id
        ? _c("h5", [_vm._v(" Edit Comment ")])
        : _c("h5", [_vm._v(" New Comment ")]),
      _c("div", { staticClass: "col-12 form-block" }, [
        _c("div", { staticClass: "row form-row" }, [
          _vm._m(1),
          _c("div", { staticClass: "col-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.currentComment.text,
                  expression: "currentComment.text",
                  modifiers: { trim: true }
                }
              ],
              ref: "comment-input",
              staticClass: "form-control",
              class: { "is-invalid": _vm.attemptSubmit && _vm.missingComment },
              attrs: {
                type: "text",
                "data-vv-as": "Enter a comment",
                id: "comment",
                name: "comment"
              },
              domProps: { value: _vm.currentComment.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.currentComment,
                    "text",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c(
              "div",
              {
                class: [
                  _vm.attemptSubmit && _vm.missingComment
                    ? "is-not-valid"
                    : "is-valid"
                ]
              },
              [_vm._v(" You need to enter a comment. ")]
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "offset-4 col-6" }, [
            _vm.formErrorText
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.formErrorText)),
                  _c("br")
                ])
              : _vm._e(),
            _vm.error
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                  _c("br")
                ])
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-success btn-xs float-right",
                staticStyle: { "margin-left": "5px", "margin-right": "-5px" },
                attrs: { disabled: _vm.formsHasErrors },
                on: { click: _vm.save }
              },
              [_vm._v(" Save ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-xs float-right",
                on: { click: _vm.cancel }
              },
              [_vm._v(" Cancel ")]
            )
          ])
        ])
      ]),
      _c("hr")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-9" }, [
      _c("h5", [_vm._v("Comment Templates")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4 label" }, [
      _c(
        "label",
        { staticClass: "label required", attrs: { for: "comment" } },
        [_vm._v("Comment")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }