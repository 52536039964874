var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "composer-edit",
            height: "550",
            width: "1000",
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _c("div", { staticClass: "show-form" }, [
            _vm.currentComposer._id
              ? _c("h5", [_vm._v(" Edit Composer ")])
              : _c("h5", [_vm._v(" New Composer ")]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 form-block" }, [
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "firstName" }
                      },
                      [_vm._v("First Name")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.currentComposer.firstName,
                          expression: "currentComposer.firstName",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.attemptSubmit && _vm.missingFirstName
                      },
                      attrs: {
                        id: "firstName",
                        name: "firstName",
                        type: "text"
                      },
                      domProps: { value: _vm.currentComposer.firstName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentComposer,
                            "firstName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingFirstName
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" First Name is required. ")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "lastName" }
                      },
                      [_vm._v("Last Name")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.currentComposer.lastName,
                          expression: "currentComposer.lastName",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.attemptSubmit && _vm.missingLastName
                      },
                      attrs: { type: "text", id: "lastName", name: "lastName" },
                      domProps: { value: _vm.currentComposer.lastName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentComposer,
                            "lastName",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingLastName
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" Last Name is required. ")]
                    )
                  ]),
                  _c("div", { staticClass: "offset-1 col-3" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-secondary btn-xs song-button",
                        staticStyle: { "margin-right": "5px" },
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(" Cancel ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline-success btn-xs song-button",
                        attrs: { disabled: _vm.formsHasErrors },
                        on: { click: _vm.saveComposer }
                      },
                      [_vm._v(" Save ")]
                    ),
                    _vm.$can("delete", "composers") && _vm.currentComposer._id
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-danger btn-xs song-button",
                            attrs: { disabled: !_vm.canDelete },
                            on: { click: _vm.deleteComposer }
                          },
                          [_vm._v(" Delete ")]
                        )
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentComposer.isFemale,
                          expression: "currentComposer.isFemale"
                        }
                      ],
                      staticClass: "featured",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.currentComposer.isFemale)
                          ? _vm._i(_vm.currentComposer.isFemale, null) > -1
                          : _vm.currentComposer.isFemale
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentComposer.isFemale,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentComposer,
                                  "isFemale",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentComposer,
                                  "isFemale",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentComposer, "isFemale", $$c)
                          }
                        }
                      }
                    }),
                    _c("b", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v("Women/Nonbinary")
                    ])
                  ]),
                  _c("div", { staticClass: "col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentComposer.isBIPOC,
                          expression: "currentComposer.isBIPOC"
                        }
                      ],
                      staticClass: "featured",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.currentComposer.isBIPOC)
                          ? _vm._i(_vm.currentComposer.isBIPOC, null) > -1
                          : _vm.currentComposer.isBIPOC
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentComposer.isBIPOC,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentComposer,
                                  "isBIPOC",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentComposer,
                                  "isBIPOC",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentComposer, "isBIPOC", $$c)
                          }
                        }
                      }
                    }),
                    _c("b", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v("Global Majority")
                    ])
                  ])
                ]),
                _vm.loggedinUser && _vm.loggedinUser.role === "admin"
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "offset-2 col-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentComposer.isFeatured,
                              expression: "currentComposer.isFeatured"
                            }
                          ],
                          staticClass: "featured",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.currentComposer.isFeatured
                            )
                              ? _vm._i(_vm.currentComposer.isFeatured, null) >
                                -1
                              : _vm.currentComposer.isFeatured
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.currentComposer.isFeatured,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.currentComposer,
                                      "isFeatured",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.currentComposer,
                                      "isFeatured",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.currentComposer, "isFeatured", $$c)
                              }
                            }
                          }
                        }),
                        _c("b", { staticStyle: { "padding-left": "5px" } }, [
                          _vm._v("Featured Composer")
                        ])
                      ]),
                      _c("div", { staticClass: "col-2 label" }, [
                        _vm.currentComposer.isFeatured
                          ? _c(
                              "label",
                              {
                                staticClass: "label",
                                attrs: { for: "custLabel" }
                              },
                              [_vm._v("Custom Label")]
                            )
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _vm.currentComposer.isFeatured
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.currentComposer.customLabel,
                                  expression: "currentComposer.customLabel",
                                  modifiers: { trim: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "custLabel",
                                name: "custLabel"
                              },
                              domProps: {
                                value: _vm.currentComposer.customLabel
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.currentComposer,
                                    "customLabel",
                                    $event.target.value.trim()
                                  )
                                },
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      { staticClass: "label", attrs: { for: "lastName" } },
                      [_vm._v("Info URL")]
                    )
                  ]),
                  _c("div", { staticClass: "col-5" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.currentComposer.infoUrl,
                          expression: "currentComposer.infoUrl",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "infoUrl", name: "infoUrl" },
                      domProps: { value: _vm.currentComposer.infoUrl },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentComposer,
                            "infoUrl",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _vm.currentComposer.infoUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.currentComposer.infoUrl,
                              target: "blank"
                            }
                          },
                          [_vm._v("test link")]
                        )
                      : _vm._e()
                  ])
                ]),
                _vm.currentComposer._id
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Created")]
                        )
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.currentComposer.dateCreated
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Created By")]
                        )
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("emptyVal")(_vm.currentComposer.enteredBy)
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.currentComposer._id
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Last Updated")]
                        )
                      ]),
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.currentComposer.lastUpdated
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "created" } },
                          [_vm._v("Updated By")]
                        )
                      ]),
                      _c("div", { staticClass: "col-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("emptyVal")(_vm.currentComposer.updatedBy)
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("hr"),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-1" }, [
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _c("b", [
                        _vm._v(
                          "Note: This is the bio for the homepage, and will only appear when this composer is one of the featured composers of the month."
                        )
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label not-required",
                        attrs: { for: "bio" }
                      },
                      [_vm._v("Bio")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-7" },
                    [
                      _c("quill-editor", {
                        ref: "myQuillEditor",
                        staticStyle: { height: "200px !important" },
                        attrs: { options: _vm.editorOption },
                        on: {
                          ready: function($event) {
                            return _vm.onEditorReady($event)
                          }
                        },
                        model: {
                          value: _vm.currentComposer.bio,
                          callback: function($$v) {
                            _vm.$set(_vm.currentComposer, "bio", $$v)
                          },
                          expression: "currentComposer.bio"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }