/* global  */
import Vue from 'vue'
import Vuex from 'vuex'
import { eventbus } from '../main'

// Modules
import general from './general'
import shows from './shows'
import songs from './songs'
import faqs from './faqs'
import creators from './creators'
import glossary from './glossary'
import users from './users'
import subscriptions from './subscriptions'
import logs from './logs'
import stripe from './stripe'
import statistics from './statistics'
import resources from './resources'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    general,
    shows,
    songs,
    faqs,
    creators,
    glossary,
    users,
    subscriptions,
    logs,
    stripe,
    statistics,
    resources
  },
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
      socketReport: ''
    }
  },
  mutations: {
    SOCKET_ONOPEN(state) {
      console.log('Socket opened')
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(event) {
      console.error('Socket error', event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      console.log('Socket message recieved', message)
      state.message = message
      handleMessage(state, message)
    },
    SOCKET_RECONNECT(count) {
      console.info('Socket reconnect', count)
    },
    WS_RECONNECT_ERROR(state) {
      console.info('Socket reconnect error')
      state.socket.reconnectError = true
    },
    sendToast: ({ state }, payload) => {
      handleMessage(state, payload)
    }
  },
  actions: {}
})

const isEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const handleMessage = (state, message) => {
  let cmp,
    lyr,
    songListItem,
    showListItem,
    newShow,
    newSong,
    index3,
    index4,
    showTitle,
    index,
    index2,
    isCommonEdit,
    songsListItem,
    faqToDeleteIndex,
    updatedFaq,
    composerListItemIndex,
    composerListItem,
    composerNewListItem,
    lyricistListItemIndex,
    lyricistListItem,
    lyricistNewListItem,
    tagsListItemIndex,
    tagsListItem,
    tagListItem,
    commentItem,
    commentNewListItem,
    commentsListItemIndex,
    userListItem,
    userItem,
    subscriptionItem,
    currSubPurchaser,
    currSubUser,
    userItemIndex,
    userItemIndex2,
    subscription,
    subscrIndex,
    subscrIndex2,
    subscriptionUpdate,
    couponIndex

  console.log('Handling message', message.type)
  switch (message.type) {
    case 'error':
      if (message.content === '' || isEmptyObject(message.content) || message.content === '{}') {
        message.content = 'Unknow error ocurred'
      }
      if (message.content === 'jwt expired') {
        message.content = 'Session expired, please login'
      }
      eventbus.$emit('toast', { theme: 'error', closeBtn: true, message: message.content })
      console.log('Sent toast because of error')
      break
    case 'reload-subscribers':
      eventbus.$emit('reload-subscribers')
      break
    case 'score-uploaded':
      state.shows.currentShow.hasFreeScore = true
      state.shows.fileUploadSuccess = true
      eventbus.$emit('toast', {
        theme: 'success',
        message: `Score ${message.content}`
      })
      break
    case 'new composer':
      cmp = {
        _id: message.content._id,
        label: message.content.lastName + ', ' + message.content.firstName,
        firstName: message.content.firstName,
        lastName: message.content.lastName,
        infoUrl: message.content.infoUrl,
        date: message.content.date
      }
      state.creators.composersList.push(cmp)
      console.log('Added new composer to list', cmp)
      eventbus.$emit('toast', {
        theme: 'success',
        message: `Added new composer ${message.content.firstName} ${message.content.lastName}`
      })
      state.songs.tableKey += 1
      break
    case 'new lyricist':
      lyr = {
        _id: message.content._id,
        label: message.content.lastName + ', ' + message.content.firstName,
        firstName: message.content.firstName,
        lastName: message.content.lastName,
        infoUrl: message.content.infoUrl,
        date: message.content.date
      }
      state.creators.lyricistsList.push(lyr)
      console.log('Added new lyricist to list', lyr)
      eventbus.$emit('toast', {
        theme: 'success',
        message: `Added new lyricist ${message.content.firstName} ${message.content.lastName}`
      })
      state.songs.tableKey += 1
      break
    case 'show updated':
      showListItem = state.shows.showsList.find(s => s._id === message.content._id)
      showListItem.title = message.content.title
      showListItem.date = message.content.date
      showListItem.composers = message.content.composers
      showListItem.lyricists = message.content.lyricists
      showListItem.composerNames = message.content.composerNames
      showListItem.lyricistNames = message.content.lyricistNames
      showListItem.hasFemaleComposer = message.content.hasFemaleComposer
      showListItem.hasBIPOCComposer = message.content.hasBIPOCComposer
      showListItem.hasFemaleLyricist = message.content.hasFemaleLyricist
      showListItem.hasBIPOCLyricist = message.content.hasBIPOCLyricist
      console.log('Got reply show updated', showListItem)
      eventbus.$emit('toast', { theme: 'success', message: `Show "${showListItem.title}" updated` })
      eventbus.$emit('updateColumns', showListItem)
      break
    case 'show created':
      newShow = {
        _id: message.content._id,
        title: message.content.title,
        date: message.content.date,
        composerNames: message.content.composerNames,
        lyricistNames: message.content.lyricistNames
      }
      state.shows.showsList.push(newShow)
      console.log('Got reply show created', newShow)
      eventbus.$emit('toast', { theme: 'success', message: `Show "${newShow.title}" created` })
      // Set the _id of the current show
      state.shows.currentShow._id = message.content._id
      // Let the ShowEdit form know the currenShow._id has been changed
      eventbus.$emit('ShowCreated')
      break
    case 'song updated':
      // Because of server tables we need to add a table key or the table will not be updated
      // Don't forget to add :tableKey to the table component
      state.songs.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Song "${message.content.title}" updated` })
      // Send event to new-edit-song dialog, a new song might have been added to a show or a song was changed
      eventbus.$emit('song-added-updated', message.content)
      break
    case 'bulk-songs-updated':
      state.songs.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `${message.content} songs updated` })
      break
    case 'song created':
      // Because of server tables we need to add a table key or the table will not be updated
      // Don't forget to add :key="tableKey" to the table component
      state.songs.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Song "${message.content.title}" added` })
      // Send event to new-edit-song dialog, a new song might have been added to a show or a song was changed
      eventbus.$emit('song-added-updated', message.content)
      break
    case 'song deleted':
      state.songs.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Deleted song "${message.content}"` })
      break
    case 'show deleted':
      showTitle = state.shows.currentShow.title
      // First delete all songs for this show from the songs list
      for (var song of state.shows.currentShow.songs) {
        index = state.songs.songsList.findIndex(s => s._id === song._id)
        if (index > -1) {
          eventbus.$emit('toast', { theme: 'success', message: `Deleted song "${state.songs.songsList[index].title}"` })
          state.songs.songsList.splice(index, 1)
        } else {
          console.log(`Song ${song.title} not found in songs list`)
        }
      }
      // Update showList (for Shows table)
      index2 = state.shows.showsList.findIndex(s => s._id === state.shows.currentShow._id)
      if (index2 > -1) {
        state.shows.showsList.splice(index2, 1)
      } else {
        console.log(`Show ${state.shows.currentShow._d} not found`)
      }
      eventbus.$emit('toast', { theme: 'success', message: `Deleted show "${showTitle}"` })
      break
    case 'song updated common':
      eventbus.$emit('toast', { theme: 'success', message: `Updated common properties of ${message.content} songs` })
      break
    // case 'song-list-item':
    //   isCommonEdit = message.content.commonEdited
    //   delete message.content.commonEdited
    //   songsListItem = state.songs.songsList.find(i => i._id === message.content._id)
    //   if (songsListItem) {
    //     songsListItem.title = message.content.title
    //     songsListItem.showTitle = message.content.showTitle
    //     songsListItem.date = message.content.date
    //     songsListItem.composerNames = message.content.composerNames
    //     songsListItem.lyricistNames = message.content.lyricistNames
    //   }
    //   if (isCommonEdit) {
    //     eventbus.$emit('toast', {
    //       theme: 'success',
    //       message: `Changed song ${message.content.title} because of common properties edit`
    //     })
    //   } else {
    //     eventbus.$emit('toast', {
    //       theme: 'success',
    //       message: `Changed song ${message.content.title} because of title or Wordlcat changes`
    //     })
    //   }
    //   break
    case 'resource deleted':
      const resourceToDeleteIndex = state.resources.resourcesList.findIndex(i => i._id === message.content._id)
      if (resourceToDeleteIndex > -1) {
        state.resources.resourcesList.splice(resourceToDeleteIndex, 1)
        eventbus.$emit('toast', { theme: 'success', message: `Resource deleted` })
      }
      break
    case 'resource updated':
      eventbus.$emit('toast', { theme: 'success', message: `Resource updated` })
      const updatedResource = state.resources.resourcesList.find(i => i._id === message.content._id)
      updatedResource.title = message.content.title
      updatedResource.link = message.content.link
      updatedResource.description = message.content.description
      break
    case 'resource created':
      state.resources.resourcesList.push(message.content)
      eventbus.$emit('toast', { theme: 'success', message: `Resource created` })
      break
    
    case 'faq deleted':
      faqToDeleteIndex = state.faqs.faqsList.findIndex(i => i._id === message.content._id)
      state.faqs.faqsList.splice(faqToDeleteIndex, 1)
      eventbus.$emit('toast', { theme: 'success', message: `FAQ deleted` })
      break
    case 'faq updated':
      eventbus.$emit('toast', { theme: 'success', message: `FAQ updated` })
      updatedFaq = state.faqs.faqsList.find(i => i._id === message.content._id)
      updatedFaq.question = message.content.question
      updatedFaq.answer = message.content.answer
      break
    case 'faq created':
      state.faqs.faqsList.push(message.content)
      eventbus.$emit('toast', { theme: 'success', message: `FAQ created` })
      break
    case 'composer deleted':
      composerListItemIndex = state.creators.composersList.findIndex(s => s._id === message.content)
      state.creators.composersList.splice(composerListItemIndex, 1)
      eventbus.$emit('toast', { theme: 'success', message: `Composer deleted` })
      break
    case 'composer updated':
      composerListItem = state.creators.composersList.find(s => s._id === message.content._id)
      if (composerListItem) {
        composerListItem.firstName = message.content.firstName
        composerListItem.lastName = message.content.lastName
        composerListItem.infoUrl = message.content.infoUrl
        composerListItem.label = `${message.content.lastName}, ${message.content.firstName}`
        composerListItem.date = message.content.date
        composerListItem.isFemale = message.content.isFemale || false
        composerListItem.isBIPOC = message.content.isBIPOC || false
        eventbus.$emit('toast', { theme: 'success', message: `Composer updated` })
        state.shows.reloadShows = true
      } else {
        console.log('Could not find composer with id', message.content._id)
      }
      break
    case 'composer created':
      composerNewListItem = {}
      composerNewListItem.date = message.content.date
      composerNewListItem._id = message.content._id
      composerNewListItem.firstName = message.content.firstName
      composerNewListItem.lastName = message.content.lastName
      composerNewListItem.infoUrl = message.content.infoUrl
      composerNewListItem.label = `${message.content.lastName}, ${message.content.firstName}`
      state.creators.composersList.push(composerNewListItem)
      eventbus.$emit('toast', { theme: 'success', message: `Composer created` })
      break
    case 'lyricist deleted':
      lyricistListItemIndex = state.creators.lyricistsList.findIndex(s => s._id === message.content)
      state.creators.lyricistsList.splice(lyricistListItemIndex, 1)
      eventbus.$emit('toast', { theme: 'success', message: `Lyricist deleted` })
      break
    case 'lyricist updated':
      lyricistListItem = state.creators.lyricistsList.find(s => s._id === message.content._id)
      if (lyricistListItem) {
        lyricistListItem.firstName = message.content.firstName
        lyricistListItem.lastName = message.content.lastName
        lyricistListItem.infoUrl = message.content.infoUrl
        lyricistListItem.label = `${message.content.lastName}, ${message.content.firstName}`
        lyricistListItem.date = message.content.date
        lyricistListItem.isFemale = message.content.isFemale || false
        lyricistListItem.isBIPOC = message.content.isBIPOC || false
        eventbus.$emit('toast', { theme: 'success', message: `Lyricist updated` })
      } else {
        console.log('Could not find lyricist with id', message.content._id)
      }
      break
    case 'lyricist created':
      lyricistNewListItem = {}
      lyricistNewListItem.date = message.content.date
      lyricistNewListItem._id = message.content._id
      lyricistNewListItem.firstName = message.content.firstName
      lyricistNewListItem.lastName = message.content.lastName
      lyricistNewListItem.infoUrl = message.content.infoUrl
      lyricistNewListItem.label = `${message.content.lastName}, ${message.content.firstName}`
      state.creators.lyricistsList.push(lyricistNewListItem)
      eventbus.$emit('toast', { theme: 'success', message: `Lyricist created` })
      break
    case 'tag deleted':
      tagsListItemIndex = state.glossary.tagsList.findIndex(s => s._id === message.content)
      state.glossary.tagsList.splice(tagsListItemIndex, 1)
      eventbus.$emit('toast', { theme: 'success', message: `Tag deleted` })
      break
    case 'tag updated':
      tagsListItem = state.glossary.tagsList.find(s => s._id === message.content._id)
      if (tagsListItem) {
        tagsListItem.term = message.content.term
        tagsListItem.definition = message.content.definition
        tagsListItem.label = message.content.term
        eventbus.$emit('toast', { theme: 'success', message: `Tag/Glossary updated` })
      } else {
        console.log('Could not find Tag/Glossary with id', message.content._id)
      }
      break
    case 'tag created':
      tagListItem = {}
      tagListItem._id = message.content._id
      tagListItem.label = message.content.term
      tagListItem.term = message.content.term
      tagListItem.definition = message.content.definition
      state.glossary.tagsList.push(tagListItem)
      eventbus.$emit('toast', { theme: 'success', message: `Tag/Glossary created` })
      break
    case 'tag renamed':
      eventbus.$emit('toast', { theme: 'success', message: message.content })
      break
    case 'featuredComposersList updated':
      state.creators.featuredComposers = message.content
      console.log('featuredComposersList updated')
      break
    case 'comment updated':
      commentItem = state.general.commentsList.find(s => s._id === message.content._id)
      if (commentItem) {
        commentItem.text = message.content.text
        eventbus.$emit('toast', { theme: 'success', message: `Comment updated` })
      } else {
        console.log('Could not find Comment with id', message.content._id)
      }
      break
    case 'comment created':
      commentNewListItem = {}
      commentNewListItem._id = message.content._id
      commentNewListItem.text = message.content.text
      state.general.commentsList.push(commentNewListItem)
      eventbus.$emit('toast', { theme: 'success', message: `Comment created` })
      break
    case 'comment deleted':
      commentsListItemIndex = state.general.commentsList.findIndex(s => s._id === message.content)
      if (commentsListItemIndex > -1) {
        state.general.commentsList.splice(commentsListItemIndex, 1)
        eventbus.$emit('toast', { theme: 'success', message: `Comment deleted` })
      } else {
        console.log('Could not find Comment with id for deletion', message.content._id)
      }
      break
    case 'user created':
      state.users.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `User ${message.content.email} created` })
      // Send message to close dialog if successful
      eventbus.$emit('modal', { command: 'close', name: 'user-edit' })
      break
    case 'user updated':
      // We have 2 lists, update user in both
      state.subscriptions.tableKey += 1
      state.users.tableKey += 1
      eventbus.$emit('modal', { command: 'close', name: 'user-edit' })
      eventbus.$emit('toast', { theme: 'success', message: `User ${message.content.email} updated` })
      break
    case 'user deleted':
      // We have 2 lists, remove user from both
      state.subscriptions.tableKey += 1
      state.users.tableKey += 1
      eventbus.$emit('modal', { command: 'close', name: 'user-edit' })
      eventbus.$emit('toast', { theme: 'success', message: `User deleted` })
      break
    case 'subscription extended':
      state.subscriptions.tableKey += 1
      eventbus.$emit('toast', {
        theme: 'success',
        message: `Subscription for ${message.content.institutionName} extended`
      })
      break
    case 'subscription cancelled':
      subscription = state.subscriptions.subscriptionsList.find(s => s._id === message.content._id)
      if (subscription) {
        debugger
        state.subscriptions.tableKey += 1
        state.users.tableKey += 1
        eventbus.$emit('toast', {
          theme: 'success',
          message: `Subscription for ${subscription.email} cancelled.`
        })
        eventbus.$emit('modal', { command: 'close', name: 'subscription-edit' })
      } else {
        console.log('Could not find subscription with id for cancellation', message.content._id)
      }
      break
    case 'subscription deleted':
      // Remove subscription from lists
      state.subscriptions.tableKey += 1
      state.users.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Subscription deleted for ${message.content}` })
      eventbus.$emit('modal', { command: 'close', name: 'subscription-edit' })
      break
    case 'subscription renewed':
      state.subscriptions.tableKey += 1
      state.users.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Subscription renewed for: ${message.content}` })
      break
    case 'subscription updated':
      // Update subscription list
      state.subscriptions.tableKey += 1
      state.users.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Subscription updated for: ${message.content}` })
      eventbus.$emit('modal', { command: 'close', name: 'subscription-edit' })
      break
    case 'subscription created':
      state.subscriptions.tableKey += 1
      state.users.tableKey += 1
      eventbus.$emit('toast', { theme: 'success', message: `Subscription created for: ${message.content}` })
      eventbus.$emit('modal', { command: 'close', name: 'subscription-edit' })
      break
    case 'coupon deleted':
      couponIndex = state.general.couponsList.findIndex(c => c.code === message.content)
      state.general.couponsList.splice(couponIndex, 1)
      eventbus.$emit('toast', { theme: 'success', message: `Coupon ${message.content} deleted` })
      break
    case 'coupon created':
      state.general.couponsList.push({ id: state.general.couponsList.length + 1, code: message.content })
      eventbus.$emit('toast', { theme: 'success', message: `Coupon ${message.content} added` })
      break
    default:
      console.log('Unhandled message type', message.type)
  }
}
