var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            id: "user-edit",
            name: "user-edit",
            height: "auto",
            width: "800",
            scrollable: true,
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _c("div", { staticClass: "show-form" }, [
            _vm.currentUser._id
              ? _c("h4", [_vm._v(" Edit User ")])
              : _c("h4", [_vm._v(" New User ")]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 form-block" }, [
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "firstName" }
                      },
                      [_vm._v("First Name")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentUser.firstName,
                            expression: "currentUser.firstName",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.attemptSubmit && _vm.missingFirstName
                        },
                        attrs: {
                          id: "firstName",
                          name: "firstName",
                          type: "text"
                        },
                        domProps: { value: _vm.currentUser.firstName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentUser,
                              "firstName",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingFirstName
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v(" First Name is required. ")]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "lastName" }
                      },
                      [_vm._v("Last Name")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentUser.lastName,
                            expression: "currentUser.lastName",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.attemptSubmit && _vm.missingLastName
                        },
                        attrs: {
                          id: "lastName",
                          name: "lastName",
                          type: "text"
                        },
                        domProps: { value: _vm.currentUser.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentUser,
                              "lastName",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingLastName
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v(" Last Name is required. ")]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "email" }
                      },
                      [_vm._v("Email")]
                    )
                  ]),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentUser.email,
                            expression: "currentUser.email",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.attemptSubmit && _vm.missingEmail
                        },
                        attrs: { id: "email", name: "email", type: "text" },
                        domProps: { value: _vm.currentUser.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentUser,
                              "email",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm.missingEmail
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.missingEmail
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" Email is required. ")]
                          )
                        : _vm._e(),
                      _vm.existingEmail
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.existingEmail ? "is-not-valid" : "is-valid"
                              ]
                            },
                            [_vm._v(" Email already exists. ")]
                          )
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm.currentUser._id
                  ? _c("div", { staticStyle: { "margin-bottom": "15px" } }, [
                      _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-2 label" }, [
                          _c(
                            "label",
                            { staticClass: "label", attrs: { for: "roles" } },
                            [_vm._v("Change password")]
                          )
                        ]),
                        _c("div", { staticClass: "col-2" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.changePassword,
                                expression: "changePassword"
                              }
                            ],
                            staticClass: "css-checkbox",
                            attrs: {
                              type: "checkbox",
                              name: "chkpw",
                              id: "chkpw"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.changePassword)
                                ? _vm._i(_vm.changePassword, null) > -1
                                : _vm.changePassword
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.changePassword,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.changePassword = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.changePassword = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.changePassword = $$c
                                }
                              }
                            }
                          }),
                          _c("label", {
                            staticClass: "css-label lite-red-check",
                            attrs: { for: "chkpw" }
                          })
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.changePassword || !_vm.currentUser._id
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label required",
                            attrs: { for: "password" }
                          },
                          [_vm._v("Password")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.currentUser.password,
                                expression: "currentUser.password",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.attemptSubmit && _vm.missingPassword
                            },
                            attrs: {
                              id: "password",
                              name: "password",
                              type: "text"
                            },
                            domProps: { value: _vm.currentUser.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentUser,
                                  "password",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              class: [
                                _vm.attemptSubmit && _vm.missingPassword
                                  ? "is-not-valid"
                                  : "is-valid"
                              ]
                            },
                            [_vm._v(" Password is required. ")]
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "col-2 label" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label required",
                        attrs: { for: "roles" }
                      },
                      [_vm._v("Roles")]
                    )
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentUser.roles,
                          expression: "currentUser.roles"
                        }
                      ],
                      staticClass: "css-checkbox",
                      attrs: {
                        type: "checkbox",
                        name: "age",
                        id: "age1",
                        value: "subscriber"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.currentUser.roles)
                          ? _vm._i(_vm.currentUser.roles, "subscriber") > -1
                          : _vm.currentUser.roles
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentUser.roles,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "subscriber",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentUser, "roles", $$c)
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "css-label lite-red-check",
                        attrs: { for: "age1" }
                      },
                      [_vm._v("Subscriber")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentUser.roles,
                          expression: "currentUser.roles"
                        }
                      ],
                      staticClass: "css-checkbox",
                      attrs: {
                        type: "checkbox",
                        name: "age",
                        id: "age2",
                        value: "creator"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.currentUser.roles)
                          ? _vm._i(_vm.currentUser.roles, "creator") > -1
                          : _vm.currentUser.roles
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentUser.roles,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "creator",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentUser, "roles", $$c)
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "css-label lite-red-check",
                        attrs: { for: "age2" }
                      },
                      [_vm._v("Creator")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentUser.roles,
                          expression: "currentUser.roles"
                        }
                      ],
                      staticClass: "css-checkbox",
                      attrs: {
                        type: "checkbox",
                        name: "age",
                        id: "age3",
                        value: "dataAssistant"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.currentUser.roles)
                          ? _vm._i(_vm.currentUser.roles, "dataAssistant") > -1
                          : _vm.currentUser.roles
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentUser.roles,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "dataAssistant",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentUser, "roles", $$c)
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "css-label lite-red-check",
                        attrs: { for: "age3" }
                      },
                      [_vm._v("Data Assistant")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentUser.roles,
                          expression: "currentUser.roles"
                        }
                      ],
                      staticClass: "css-checkbox",
                      attrs: {
                        type: "checkbox",
                        name: "age",
                        id: "age4",
                        value: "marketingAssistant"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.currentUser.roles)
                          ? _vm._i(
                              _vm.currentUser.roles,
                              "marketingAssistant"
                            ) > -1
                          : _vm.currentUser.roles
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentUser.roles,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "marketingAssistant",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentUser, "roles", $$c)
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "css-label lite-red-check",
                        attrs: { for: "age4" }
                      },
                      [_vm._v("Marketing Assistant")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-4" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentUser.roles,
                          expression: "currentUser.roles"
                        }
                      ],
                      staticClass: "css-checkbox",
                      attrs: {
                        type: "checkbox",
                        name: "age",
                        id: "age5",
                        value: "admin"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.currentUser.roles)
                          ? _vm._i(_vm.currentUser.roles, "admin") > -1
                          : _vm.currentUser.roles
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentUser.roles,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "admin",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentUser, "roles", $$c)
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "css-label lite-red-check",
                        attrs: { for: "age5" }
                      },
                      [_vm._v("Admin")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-2 col-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentUser.roles,
                          expression: "currentUser.roles"
                        }
                      ],
                      staticClass: "css-checkbox",
                      attrs: {
                        type: "checkbox",
                        name: "age",
                        id: "age6",
                        value: "groupadmin",
                        disabled: ""
                      },
                      domProps: {
                        checked: Array.isArray(_vm.currentUser.roles)
                          ? _vm._i(_vm.currentUser.roles, "groupadmin") > -1
                          : _vm.currentUser.roles
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.currentUser.roles,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "groupadmin",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentUser,
                                  "roles",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.currentUser, "roles", $$c)
                          }
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "css-label lite-red-check",
                        attrs: { for: "age6" }
                      },
                      [_vm._v("Group Admin (read only)")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row form-row" }, [
                  _c("div", { staticClass: "offset-4 col-4" }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.attemptSubmit && _vm.missingRole
                            ? "is-not-valid"
                            : "is-valid"
                        ]
                      },
                      [_vm._v(" At least one role is required. ")]
                    )
                  ])
                ]),
                _vm.currentUser.timestamps &&
                _vm.currentUser.timestamps.registered
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "songDateEntered" }
                          },
                          [_vm._v("Date Registred")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.currentUser.timestamps.registered
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.currentUser.timestamps &&
                _vm.currentUser.timestamps.loggedin
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "songLastUpdated" }
                          },
                          [_vm._v("Last Logged-in")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.currentUser.timestamps.loggedin
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.currentUser.lastUpdatedByName
                  ? _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label not-required",
                            attrs: { for: "songLastUpdatedBy" }
                          },
                          [_vm._v("Last Updated By")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("emptyVal")(
                                _vm.currentUser.lastUpdatedByName
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "row form-row" }, [
              _c("div", { staticClass: "offset-8 col-4" }, [
                _vm.currentUser.hasSubscription
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("User cannot be deleted;"),
                      _c("br"),
                      _vm._v("associated subscription"),
                      _c("br")
                    ])
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary btn-sm",
                    on: { click: _vm.cancel }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-success btn-sm",
                    attrs: { disabled: _vm.formsHasErrors },
                    on: { click: _vm.save }
                  },
                  [_vm._v(" Save ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-danger btn-sm",
                    attrs: { disabled: !_vm.canBeDeleted },
                    on: { click: _vm.delUser }
                  },
                  [_vm._v(" Delete ")]
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }