var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.u13,
              expression: "currentSong.u13"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age1" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.u13)
              ? _vm._i(_vm.currentSong.u13, null) > -1
              : _vm.currentSong.u13
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.u13,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "u13", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "u13",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "u13", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age1" } },
          [_vm._v("Under 13")]
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.a2532,
              expression: "currentSong.a2532"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age2" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.a2532)
              ? _vm._i(_vm.currentSong.a2532, null) > -1
              : _vm.currentSong.a2532
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.a2532,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "a2532", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "a2532",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "a2532", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age2" } },
          [_vm._v("25-32")]
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.a60,
              expression: "currentSong.a60"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age3" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.a60)
              ? _vm._i(_vm.currentSong.a60, null) > -1
              : _vm.currentSong.a60
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.a60,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "a60", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "a60",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "a60", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age3" } },
          [_vm._v("60+")]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.a1417,
              expression: "currentSong.a1417"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age4" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.a1417)
              ? _vm._i(_vm.currentSong.a1417, null) > -1
              : _vm.currentSong.a1417
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.a1417,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "a1417", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "a1417",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "a1417", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age4" } },
          [_vm._v("14-17")]
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.a3339,
              expression: "currentSong.a3339"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age5" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.a3339)
              ? _vm._i(_vm.currentSong.a3339, null) > -1
              : _vm.currentSong.a3339
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.a3339,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "a3339", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "a3339",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "a3339", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age5" } },
          [_vm._v("33-39")]
        )
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.a1824,
              expression: "currentSong.a1824"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age6" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.a1824)
              ? _vm._i(_vm.currentSong.a1824, null) > -1
              : _vm.currentSong.a1824
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.a1824,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "a1824", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "a1824",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "a1824", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age6" } },
          [_vm._v("18-24")]
        )
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentSong.a4059,
              expression: "currentSong.a4059"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: "age", id: "age7" },
          domProps: {
            checked: Array.isArray(_vm.currentSong.a4059)
              ? _vm._i(_vm.currentSong.a4059, null) > -1
              : _vm.currentSong.a4059
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.currentSong.a4059,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.currentSong, "a4059", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.currentSong,
                        "a4059",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.currentSong, "a4059", $$c)
                }
              },
              _vm.ageClicked
            ]
          }
        }),
        _c(
          "label",
          { staticClass: "css-label lite-red-check", attrs: { for: "age7" } },
          [_vm._v("40-59")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }