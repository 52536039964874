var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("img", { attrs: { src: "/loading.gif" } }),
            _c("div", { staticClass: "loading" }, [
              _vm._v(" " + _vm._s("") + " ")
            ])
          ])
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: {
            name: "song-edit-partial",
            height: "auto",
            width: "1200",
            scrollable: true,
            transition: "pop-out",
            "click-to-close": false
          },
          on: {
            opened: function($event) {
              return _vm.checkErrors()
            },
            "before-open": _vm.beforeOpen,
            "before-close": _vm.beforeClose
          }
        },
        [
          _c(
            "div",
            { staticClass: "show-form", staticStyle: { margin: "30px" } },
            [
              _c("h4", [
                _vm._v(
                  'Edit Song common properties for show "' +
                    _vm._s(_vm.showTitle) +
                    '"'
                )
              ]),
              _c("div", { staticClass: "important" }, [
                _c("p", [
                  _vm._v(
                    " Any changes made on this form will permanently overwrite the data for the selected songs. Leave fields blank if you do not want to update them for all of the selected songs. "
                  )
                ])
              ]),
              _c("div", { staticClass: "songs-to-edit" }, [
                _vm._v(" Editing the following songs: ")
              ]),
              _c("p", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(" " + _vm._s(_vm.songsTitles) + " ")
              ]),
              _c("hr"),
              _c(
                "div",
                { staticClass: "row", staticStyle: { "margin-top": "30px" } },
                [
                  _c("div", { staticClass: "offset-sm-1 col-10" }, [
                    _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "composers" } },
                          [_vm._v("Popularity")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "composers",
                              name: "composers",
                              options: [
                                { label: "Very popular", value: 1 },
                                { label: "Somewhat popular", value: 2 },
                                { label: "Heard occassionally", value: 3 },
                                { label: "Not heard a lot", value: 4 },
                                { label: "Rare find", value: 5 }
                              ]
                            },
                            model: {
                              value: _vm.popularity,
                              callback: function($$v) {
                                _vm.popularity = $$v
                              },
                              expression: "popularity"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "composers" } },
                          [_vm._v("Composer(s)")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              multiple: "",
                              id: "composers",
                              name: "composers",
                              options: _vm.composers
                            },
                            model: {
                              value: _vm.selectedComposers,
                              callback: function($$v) {
                                _vm.selectedComposers = $$v
                              },
                              expression: "selectedComposers"
                            }
                          }),
                          _c("p", { staticClass: "note-song-edit" }, [
                            _vm._v(" Format: Last name, First name ")
                          ])
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "lyricists" } },
                          [_vm._v("Lyricist(s)")]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("v-select", {
                            attrs: {
                              multiple: "",
                              id: "lyricists",
                              name: "lyricists",
                              options: _vm.lyricists
                            },
                            model: {
                              value: _vm.selectedLyricists,
                              callback: function($$v) {
                                _vm.selectedLyricists = $$v
                              },
                              expression: "selectedLyricists"
                            }
                          }),
                          _c("p", { staticClass: "note-song-edit" }, [
                            _vm._v(" Format: Last name, First name ")
                          ])
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label",
                            attrs: { for: "showyearWritten" }
                          },
                          [_vm._v("Year written")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.yearWritten,
                              expression: "yearWritten"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "showyearWritten",
                            name: "showyearWritten"
                          },
                          domProps: { value: _vm.yearWritten },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.yearWritten = $event.target.value
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "row form-row" }, [
                      _c("div", { staticClass: "col-2 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label",
                            attrs: { for: "foundersPick" }
                          },
                          [_vm._v("Founders pick")]
                        )
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.foudersPick,
                              expression: "foudersPick"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "foundersPick",
                            value: "true",
                            checked: "false"
                          },
                          domProps: {
                            checked: _vm._q(_vm.foudersPick, "true")
                          },
                          on: {
                            change: function($event) {
                              _vm.foudersPick = "true"
                            }
                          }
                        }),
                        _c("label", { attrs: { for: "radio1" } }, [
                          _vm._v("Yes ")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.foudersPick,
                              expression: "foudersPick"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            name: "foundersPick",
                            value: "false",
                            checked: "false"
                          },
                          domProps: {
                            checked: _vm._q(_vm.foudersPick, "false")
                          },
                          on: {
                            change: function($event) {
                              _vm.foudersPick = "false"
                            }
                          }
                        }),
                        _c("label", { attrs: { for: "radio1" } }, [
                          _vm._v("No ")
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _c("h6", { staticClass: "song-edit" }, [
                _vm._v(" External links ")
              ]),
              _c("hr"),
              _c("p", [
                _c("b", [_vm._v("NOTE:")]),
                _vm._v(
                  " This form cannot be used to disable external links, only to change them. Links for which you leave the checkbox unchecked will simply be left as they are. "
                )
              ]),
              _c(
                "div",
                [
                  _c("div", { staticClass: "row" }, [
                    _c("h6", [_vm._v("Scores")])
                  ]),
                  _c("external-links", {
                    attrs: {
                      label: "MusicNotes.com",
                      "link-name": "MusicNotes.com",
                      "base-url": "https://www.musicnotes.com/search/go?w=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelScoresMusicNotes
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "SheetMusicPlus.com",
                      "link-name": "SheetMusicPlus",
                      "base-url": "https://www.sheetmusicplus.com/search?Ntt=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelScoresSheetMusic
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "NewMusicalTheatre.com",
                      "link-name": "NewMusicalTheatre",
                      "base-url":
                        "http://newmusicaltheatre.com/catalogsearch/result/?q=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelScoresNewMusicalTheatre
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "Amazon.com",
                      "link-name": "Amazon",
                      "base-url":
                        "https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=%22sheet+music%22+",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelScoresAmazon
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "WorldCat (library holdings)",
                      "link-name": "WorldCat",
                      "base-url":
                        "http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amsscr&q=ti%3A",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelScoresWorldCat
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "ContemporaryMusicalTheatre.com",
                      "link-name": "ContemporaryMusicalTheatre",
                      "base-url": "https://www.contemporarymusicaltheatre.com/",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelScoresContemporaryMusicalTheatre
                    }
                  }),
                  _c("custom-link", {
                    attrs: {
                      label: "Custom Link",
                      "link-name": "Custom",
                      songs: _vm.songs,
                      model: _vm.modelScoresCustom
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("div", { staticClass: "row" }, [
                    _c("h6", [_vm._v("Recordings")])
                  ]),
                  _c("external-links", {
                    attrs: {
                      label: "Amazon.com",
                      "link-name": "Amazon-rec",
                      "base-url":
                        "https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=music+",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsAmazon
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "iTunes",
                      "link-name": "itunes-rec",
                      "base-url":
                        "https://fnd.io/#/us/search?mediaType=music&term=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsiTunes
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "Spotify",
                      "link-name": "spotify-rec",
                      "base-url": "https://open.spotify.com/search/",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsSpotify
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "Soundcloud",
                      "link-name": "soundcloud-rec",
                      "base-url": "https://soundcloud.com/search?q=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsSoundcLoud
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "NewMusicalTheatre.com",
                      "link-name": "NewMusicalTheatre-rec",
                      "base-url":
                        "http://newmusicaltheatre.com/catalogsearch/result/?q=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsNwMusicalTheatre
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "WorldCat (library holdings)",
                      "link-name": "WorldCat-rec",
                      "base-url":
                        "http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amusic&q=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsWorldcat
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "ContemporaryMusicalTheatre.com",
                      "link-name": "ContemporaryMusicalTheatre-rec",
                      "base-url": "https://www.contemporarymusicaltheatre.com/",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsContemporaryMusicalTheatre
                    }
                  }),
                  _c("external-links", {
                    attrs: {
                      label: "Gilbert and Sullivan Archive",
                      "link-name": "GilbertandSullivanArchive-rec",
                      "base-url":
                        "http://search.freefind.com/find.html?id=2743017&pid=r&mode=ALL&n=0&submit=+Find%21+&query=",
                      songs: _vm.songs,
                      "show-title": _vm.showTitle,
                      model: _vm.modelRecordingsGilbert
                    }
                  }),
                  _c("custom-link", {
                    attrs: {
                      label: "Custom Link",
                      "link-name": "Custom-rec",
                      songs: _vm.songs,
                      model: _vm.modelRecordingsCustom
                    }
                  })
                ],
                1
              ),
              _c("hr"),
              _vm.apiError
                ? _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "offset-sm-4 col-7" }, [
                      _c("span", { staticClass: "api-error" }, [
                        _vm._v(_vm._s(_vm.apiError))
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row form-row" }, [
                _c("div", { staticClass: "offset-sm-4 col-7" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-secondary btn-sm",
                      on: { click: _vm.closeForm }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-success btn-sm",
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }