var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "below-menu" },
    [
      _c("div", { staticClass: "row fixed-top page-header" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "button",
            {
              staticClass:
                "btn btn-outline-primary btn-xs float-right header-btn",
              on: { click: _vm.refresh }
            },
            [_vm._v(" Refresh ")]
          ),
          _c(
            "button",
            {
              staticClass:
                "btn btn-outline-primary btn-xs float-right header-btn",
              staticStyle: { "margin-right": "10px" },
              on: { click: _vm.copyContent }
            },
            [_vm._v(" Copy ")]
          )
        ])
      ]),
      _c(
        "vue-good-table",
        {
          attrs: {
            mode: "remote",
            totalRows: _vm.totalRecords,
            isLoading: _vm.isLoading,
            "pagination-options": {
              enabled: true,
              perPage: _vm.serverParams.perPage,
              perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
              position: "top",
              mode: "records"
            },
            "sort-options": {
              enabled: true,
              initialSortBy: [{ field: "time", type: "desc" }]
            },
            rows: _vm.rows,
            columns: _vm.columns,
            "style-class": "vgt-table musical bordered condensed"
          },
          on: {
            "on-page-change": _vm.onPageChange,
            "on-sort-change": _vm.onSortChange,
            "on-column-filter": _vm.onColumnFilter,
            "on-per-page-change": _vm.onPerPageChange,
            "on-row-click": _vm.rowClick,
            "update:isLoading": function($event) {
              _vm.isLoading = $event
            },
            "update:is-loading": function($event) {
              _vm.isLoading = $event
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "table-actions" }, slot: "table-actions" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    width: "95vw"
                  }
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "filterBar" },
                      [
                        _c(
                          "div",
                          { staticClass: "filterBar-item filterBar-label" },
                          [_c("b", [_vm._v("Module:")])]
                        ),
                        _vm._l(_vm.modulesList, function(mod) {
                          return _c(
                            "div",
                            { key: mod, staticClass: "filterBar-item" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.logFilter,
                                    expression: "logFilter"
                                  }
                                ],
                                staticClass: "usersSelect",
                                attrs: { type: "checkbox", name: mod },
                                domProps: {
                                  value: mod,
                                  checked: Array.isArray(_vm.logFilter)
                                    ? _vm._i(_vm.logFilter, mod) > -1
                                    : _vm.logFilter
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.logFilter,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = mod,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.logFilter = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.logFilter = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.logFilter = $$c
                                      }
                                    },
                                    _vm.logFilterChanged
                                  ]
                                }
                              }),
                              _c(
                                "i",
                                {
                                  staticStyle: {
                                    "padding-left": "0px",
                                    "margin-right": "10px"
                                  }
                                },
                                [_vm._v(_vm._s(mod))]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c("div", { staticClass: "filterBar" }, [
                      _c(
                        "div",
                        { staticClass: "filterBar-item filterBar-label" },
                        [_c("b", [_vm._v("Level:")])]
                      ),
                      _c("div", { staticClass: "filterBar-item" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.logLevel,
                              expression: "logLevel"
                            }
                          ],
                          staticClass: "usersSelect",
                          attrs: {
                            type: "checkbox",
                            name: "debug",
                            value: "debug"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.logLevel)
                              ? _vm._i(_vm.logLevel, "debug") > -1
                              : _vm.logLevel
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.logLevel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "debug",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.logLevel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.logLevel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.logLevel = $$c
                                }
                              },
                              _vm.logFilterChanged
                            ]
                          }
                        }),
                        _c(
                          "i",
                          {
                            staticStyle: {
                              "padding-left": "0px",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("Debug")]
                        )
                      ]),
                      _c("div", { staticClass: "filterBar-item" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.logLevel,
                              expression: "logLevel"
                            }
                          ],
                          staticClass: "usersSelect",
                          attrs: {
                            type: "checkbox",
                            name: "verbose",
                            value: "verbose"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.logLevel)
                              ? _vm._i(_vm.logLevel, "verbose") > -1
                              : _vm.logLevel
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.logLevel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "verbose",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.logLevel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.logLevel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.logLevel = $$c
                                }
                              },
                              _vm.logFilterChanged
                            ]
                          }
                        }),
                        _c(
                          "i",
                          {
                            staticStyle: {
                              "padding-left": "0px",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("Verbose")]
                        )
                      ]),
                      _c("div", { staticClass: "filterBar-item" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.logLevel,
                              expression: "logLevel"
                            }
                          ],
                          staticClass: "usersSelect",
                          attrs: {
                            type: "checkbox",
                            name: "info",
                            value: "info"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.logLevel)
                              ? _vm._i(_vm.logLevel, "info") > -1
                              : _vm.logLevel
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.logLevel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "info",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.logLevel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.logLevel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.logLevel = $$c
                                }
                              },
                              _vm.logFilterChanged
                            ]
                          }
                        }),
                        _c(
                          "i",
                          {
                            staticStyle: {
                              "padding-left": "0px",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("Info")]
                        )
                      ]),
                      _c("div", { staticClass: "filterBar-item" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.logLevel,
                              expression: "logLevel"
                            }
                          ],
                          staticClass: "usersSelect",
                          attrs: {
                            type: "checkbox",
                            name: "warn",
                            value: "warn"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.logLevel)
                              ? _vm._i(_vm.logLevel, "warn") > -1
                              : _vm.logLevel
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.logLevel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "warn",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.logLevel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.logLevel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.logLevel = $$c
                                }
                              },
                              _vm.logFilterChanged
                            ]
                          }
                        }),
                        _c(
                          "i",
                          {
                            staticStyle: {
                              "padding-left": "0px",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("Warn")]
                        )
                      ]),
                      _c("div", { staticClass: "filterBar-item" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.logLevel,
                              expression: "logLevel"
                            }
                          ],
                          staticClass: "usersSelect",
                          attrs: {
                            type: "checkbox",
                            name: "error",
                            value: "error"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.logLevel)
                              ? _vm._i(_vm.logLevel, "error") > -1
                              : _vm.logLevel
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.logLevel,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "error",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.logLevel = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.logLevel = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.logLevel = $$c
                                }
                              },
                              _vm.logFilterChanged
                            ]
                          }
                        }),
                        _c(
                          "i",
                          {
                            staticStyle: {
                              "padding-left": "0px",
                              "margin-right": "10px"
                            }
                          },
                          [_vm._v("Error")]
                        )
                      ])
                    ])
                  ]),
                  _c("input", {
                    ref: "search",
                    staticClass: "rounded",
                    attrs: { type: "text", placeholder: "Search log messages" },
                    on: { change: _vm.searchLogs }
                  })
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "loading",
              attrs: { slot: "loadingContent" },
              slot: "loadingContent"
            },
            [
              _vm.loadingErrorMsg
                ? _c("div", { staticClass: "loading server-error" }, [
                    _vm._v(" " + _vm._s(_vm.loadingErrorMsg) + " ")
                  ])
                : _vm._e(),
              _vm.isLoading
                ? _c("div", [
                    _c("img", { attrs: { src: "/loading.gif" } }),
                    _vm._v(" " + _vm._s(_vm.isLoadingMsg) + " ")
                  ])
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-10" }, [_c("h5", [_vm._v("Logs")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }