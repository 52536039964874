<template>
  <div id="app">
    <!-- <div v-if="!loggedinUser" class="my-login">
      <login />
    </div> -->
    <div class="content-class">
      <main-menu />
      <!-- The modal edit form -->
      <user-edit />
      <!-- Subscription Edit form -->
      <subscription-edit />
      <!-- Composers Edit form -->
      <composer-edit />
      <!-- Lyricist Edit form -->
      <lyricist-edit />
      <!-- Tag Edit form -->
      <tag-edit />
      <!-- Show Edit form -->
      <show-edit />
      <!-- Song Edit form -->
      <song-edit />
      <modal name="socket-warning" height="auto" width="500" transition="pop-out" :click-to-close="false">
        Connection with server is interrupted, please wait or refresh page.
      </modal>

      <!-- <keep-alive> -->
      <router-view :key="$route.fullPath" class="below-menu" />
      <!-- </keep-alive> -->
    </div>
    <!-- <vue-toast ref="toast" /> -->
  </div>
</template>

<script>
  import TagEdit from './components/TagEdit'
  import SubscriptionEdit from './components/SubscriptionEdit'
  import UserEdit from './components/UserEdit.vue'
  import ComposerEdit from './components/ComposerEdit.vue'
  import LyricistEdit from './components/LyricistEdit.vue'
  import MainMenu from './pages/MainMenu.vue'
  import Login from './pages/Login.vue'
  import ShowEdit from './components/ShowEdit'
  import SongEdit from './components/SongEdit'
  // import 'vue-toast/dist/vue-toast.min.css'
  // import VueToast from 'vue-toast'
  import { eventbus } from './main'
  export default {
    name: 'App',
    components: {
      MainMenu,
      Login,
      SubscriptionEdit,
      UserEdit,
      ComposerEdit,
      LyricistEdit,
      TagEdit,
      ShowEdit,
      SongEdit
    },
    created() {
      console.log(`[App.vue] App Created: Requested route: ${this.$route.fullPath}`)
      // Get settings for app
      this.$store.dispatch('general/getSettings')
      eventbus.$on('toast', toast => {
        this.displayToast(toast)
      })
      // Centralized handling of closing and opening modals
      console.log(`[App.vue] creating modal listener`)
      eventbus.$on('modal', options => {
        console.log(`[App.vue] Modal event with command ${options.command} and modal name ${options.name}`)
        for (const modal of this.$store.state.general.modals) {
          console.log(`[App.vue] Modal ${modal} in modals array`)
        }
        if (options.command === 'open') {
          console.log(`[App.vue] Trying to open modal ${options.name}`)
          // Push modal name to array
          this.$store.state.general.modals.push(options.name)
          // Close previous opened modal
          if (this.$store.state.general.modals.length > 1) {
            this.$modal.hide(this.$store.state.general.modals[this.$store.state.general.modals.length - 2])
          }
          this.$modal.show(options.name)
        }
        if (options.command === 'close') {
          console.log(`[App.vue] Trying to close modal ${options.name}`)
          // Close latest modal
          this.$modal.hide(options.name)
          this.$store.state.general.modals.pop()
          // If any modals left, open the last one
          if (this.$store.state.general.modals.length > 0) {
            console.log(`[App.vue] Trying to reopen last modal ${this.$store.state.general.modals[this.$store.state.general.modals.length - 1]}`)
            this.$modal.show(this.$store.state.general.modals[this.$store.state.general.modals.length - 1])
          }
        }
        if (options.command === 'close-all') {
          console.log(`[App.vue] Trying to close all modals. Found ${this.$store.state.general.modals.length} modals`)
          // this.$modal.hideAll()
          // this.$store.state.general.modals = []
          let modals = this.$store.state.general.modals
          const modalDialog = this.$modal
          if (modals && modals.length > 0) {
            for (const modal of modals) {
              console.log(`[App.vue] Closing dialog ${modal}`)
              modalDialog.hide(modal)
            }
          }
          this.$store.state.general.modals = []
        }
      })
      this.$modal.show('socket-warning')
    },
    beforeDestroy() {
      console.log('[App.vue] App destroyed')
      eventbus.$off('toast')
      eventbus.$off('modal')
    },
    // mounted() {
    //   this.$refs.toast.setOptions({ position: 'left top' })
    // },
    computed: {
      loggedinUser() {
        return this.$store.state.general.loggedinUser
      }
    },
    methods: {
      displayToast(toast) {
        console.log(`Displayed toast`)
        this.$toasted.show(toast.message, {
          position: 'top-center',
          duration: toast.theme === 'error' ? 0 : 3000,
          theme: 'outline',
          type: toast.theme,
          action:
            toast.theme === 'error'
              ? {
                  text: 'X',
                  onClick: (e, toastObject) => {
                    toastObject.goAway(0)
                  }
                }
              : {}
        })
        // if (this.$refs.toast) {
        //   this.$refs.toast.showToast(toast.message, {
        //     theme: toast.theme,
        //     timeLife: 6000,
        //     closeBtn: toast.closeBtn
        //   })
        // }
      }
    }
  }
</script>

<style>
  .vue-toast-manager_container {
    /* margin: 60px auto !important; */
    /* top: 60px !important; */
    z-index: 1000;
  }
  .error {
    margin: 20px;
  }
  .my-login {
    margin-top: 200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .form-login {
    width: 250px;
    margin: auto;
    background-color: #ededed;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    border-color: #d2d2d2;
    border-width: 5px;
    box-shadow: 0 1px 0 #cfcfcf;
  }
</style>
