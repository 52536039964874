<template>
  <div class="below-menu">
    <div class="row fixed-top page-header">
      <div class="col-2">
        <h5>Statistics</h5>
      </div>
      <div class="col-10" v-if="hasData()">
        <div class="flex-row">
          <input type="checkbox" name="showSubscriptions" style="margin-top:3px;" v-model="showSubscriptions" />
          <i style="padding-left:0px;margin-right: 10px;">Show subscriptions in report</i>
          Start report from:
          <select name="months" id="months" v-model="month" @input="monthChanged">
            <option v-for="month of months" :key="month">{{ month }}</option>
          </select>
          <select name="years" id="years" v-model="year" @input="yearChanged">
            <option v-for="year of years" :key="year">{{ year }}</option>
          </select>
          End report until:
          <select name="monthsEnd" id="monthsEnd" v-model="monthEnd" @input="monthEndChanged">
            <option v-for="month of months" :key="month">{{ month }}</option>
          </select>
          <select name="yearsEnd" id="yearsEnd" v-model="yearEnd" @input="yearEndChanged">
            <option v-for="year of yearsEnd" :key="year">{{ year }}</option>
          </select>
          <button @click="emailPage(true)" class="btn btn-outline-primary btn-xs float-right header-btn">
            Email as Text
          </button>
          <button @click="emailPage(false)" class="btn btn-outline-primary btn-xs float-right header-btn">
            Email as Image
          </button>
        </div>
      </div>
    </div>
    <div class="flex-row">
      <div class="search-title">Institution Name</div>
      <div class="or-style white">OR</div>
      <div class="search-title">Email Address</div>
      <div class="or-style white">OR</div>
      <div class="search-title">Subscription ID</div>
    </div>
    <div class="flex-row">
      <div class="search-box">
        <v-select
          class="select-style"
          @input="institutionEntered"
          :close-on-select="true"
          v-model="institution"
          :options="institutions"
        />
      </div>
      <div class="or-style">OR</div>
      <div class="search-box">
        <v-select @input="individualEntered" :close-on-select="true" v-model="individual" :options="individuals" />
      </div>
      <div class="or-style">OR</div>
      <div class="search-box">
        <input type="text" id="subId" v-model="subId" @keyup="subIdEntered" />
      </div>
    </div>
    <!-- <div class="flex-row button">
      <button @click="search" class="btn btn-outline-primary btn-sm">SEARCH</button>
    </div> -->
    <hr />
    <div v-if="stat._id" class="container-fluid">
      <div class="row">
        <div class="col-2"></div>
        <div id="printable" class="col-sm text-center">
          <h1>{{ title }}</h1>
          <div v-if="stat.institution">
            <div>{{ stat.fullName }}</div>
            <div>{{ stat.email }}</div>
          </div>
          <div v-else>{{ stat.email }}</div>

          <div class="row" v-if="overallLogins > 0">
            <div id="printable" class="col-sm text-center period">
              Statistics from {{ month }} {{ year }} until {{ monthEnd }} {{ yearEnd }}
            </div>
          </div>

          <div class="row higher" style="line-height:40px;" v-if="overallLogins > 0">
            <div class="col-sm offset-sm-2 text-center">
              Overall logins: <b>{{ this.overallLogins }}</b>
            </div>
            <div class="col-sm text-center">
              Overall searches: <b>{{ this.overallSearches }}</b>
            </div>
            <div class="col-sm-2"></div>
          </div>
          <div class="row" v-if="overallLogins > 0">
            <div class="col-sm"></div>
            <span class="col bold">Year</span>
            <span class="col bold">Logins</span>
            <span class="col bold">Searches</span>
            <div class="col-sm"></div>
          </div>
          <div class="row" v-for="total of this.totals" :key="total.year">
            <div class="col-sm"></div>
            <span class="col">{{ total.year }}</span>
            <span class="col">{{ total.logins }}</span>
            <span class="col">{{ total.searches }}</span>
            <div class="col-sm"></div>
          </div>
          <div class="row higher" v-if="noStat && showSubscriptions">
            <div class="col-sm text-center">
              <h4>Subscriptions</h4>
            </div>
          </div>
          <div class="row" v-if="noStat && showSubscriptions">
            <span class="col-4 text-left bold">Plan</span>
            <span class="col bold">Start</span>
            <span class="col bold">End</span>
            <span class="col-4 text-right bold">Purchaser</span>
          </div>
          <div v-if="noStat && showSubscriptions">
            <div class="row" v-for="sub of this.subscriptions" :key="sub._id">
              <span class="col-4 text-left">{{ sub.planName }}</span>
              <span class="col">{{ sub.startDate | formatDate }}</span>
              <span class="col">{{ sub.expirationDate | formatDate }}</span>
              <span class="col-4 text-right">{{ sub.email }}</span>
            </div>
          </div>
          <div class="row" style="margin-top: 50px" v-if="overallLogins > 0">
            <span class="col-4 text-left bold">Year</span>
            <span class="col text-left bold">Month</span>
            <span class="col bold">Logins</span>
            <span class="col-4 text-right bold">Searches</span>
          </div>
          <hr style="margin-top: 0px;" v-if="noStat" />
          <div class="row border-row" v-for="period of this.periods" :key="period.date">
            <span class="col-4 text-left">{{ period.year }}</span>
            <span class="col text-left">{{ period.month }}</span>
            <span class="col">{{ period.logins }}</span>
            <span class="col-4 text-right">{{ period.searches }}</span>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <div ref="tocopy"></div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  import html2canvas from 'html2canvas'
  export default {
    props: ['subscriptionId'],
    data: () => ({
      loaded: false,
      isLoadingMsg: '',
      loadingErrorMsg: '',
      institution: '',
      individual: '',
      subId: '',
      title: '',
      year: '',
      yearEnd: '',
      month: '',
      monthEnd: '',
      years: [],
      yearsEnd: [],
      totals: [],
      subscriptions: [],
      periods: [],
      overallLogins: 0,
      overallSearches: 0,
      showSubscriptions: true,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    }),
    async created() {
      // load items is what brings back the rows from server
      this.isLoadingMsg = 'Loading Statistics Data'
      this.$log.info('Getting statistics from store')
      this.loaded = false
      this.$store.commit('statistics/resetStat')
      this.$store
        .dispatch('statistics/getStatLists')
        .then(response => {
          this.loaded = true
        })
        .catch(err => {
          this.$log.info('Error in Getting stats from store', err)
          this.loadingErrorMsg = err
        })
      if (this.$route.params.subscriptionId) {
        this.subId = this.$route.params.subscriptionId
        this.search()
      }
    },
    computed: {
      ...mapState({
        loggedinUser: state => state.general.loggedinUser,
        individuals: state => state.statistics.individuals,
        institutions: state => state.statistics.institutions,
        stat: state => state.statistics.stat
      }),
      noStat() {
        return !(Object.keys(this.stat).length === 0 && this.stat.constructor === Object)
      }
    },
    filters: {
      formatDate: function(value) {
        return moment(value).format('YYYY/MM/DD')
      },
      formatMonth: function(value) {
        return moment(value).format('MMMM')
      }
    },
    methods: {
      hasData() {
        return this.overallLogins > 0 || this.overallSearches > 0
      },
      calculate() {
        let monthNumber = `0${this.months.indexOf(this.month) + 1}`
        let dateString = `${this.year}-${monthNumber.substr(monthNumber.length > 2 ? 1 : 0)}-01`
        const startDate = moment(dateString)
        monthNumber = `0${this.months.indexOf(this.monthEnd) + 1}`
        dateString = `${this.yearEnd}-${monthNumber.substr(monthNumber.length > 2 ? 1 : 0)}-01`
        const endDate = moment(dateString)

        console.log(`Start date is ${startDate}`)
        console.log(`End date is ${endDate}`)
        this.periods = this.stat.periods.filter(p => p.date >= startDate.valueOf() && p.date <= endDate.valueOf())
        const totals = {}
        for (var period of this.periods) {
          if (totals[period.year]) {
            totals[period.year] = {
              logins: totals[period.year].logins + period.logins,
              searches: totals[period.year].searches + period.searches
            }
          } else {
            totals[period.year] = {
              logins: period.logins,
              searches: period.searches
            }
          }
        }
        this.totals = []
        for (const year in totals) {
          this.totals.push({ year: year, searches: totals[year].searches, logins: totals[year].logins })
        }
        this.overallLogins = 0
        this.overallSearches = 0
        for (var total of this.stat.totals) {
          this.overallLogins += total.logins
          this.overallSearches += total.searches
        }
        this.subscriptions = this.stat.subscriptions
      },
      monthChanged(val) {
        console.log(`Month changed from ${this.month} to ${val.target.value}`)
        this.month = val.target.value
        this.calculate()
      },
      monthEndChanged(val) {
        console.log(`Month End changed from ${this.monthEnd} to ${val.target.value}`)
        this.monthEnd = val.target.value
        this.calculate()
      },
      yearChanged(val) {
        console.log(`Year changed from ${this.year} to ${val.target.value}`)
        this.year = val.target.value
        this.calculate()
        // this.totals = this.stat.totals.filter(y => y.year >= parseInt(val.target.value) && y.year <= parseInt(this.yearEnd))
        // this.subscriptions = this.stat.subscriptions.filter(
        //   (y => moment(y.expirationDate).year() >= parseInt(val.target.value)) &&
        //     (y => moment(y.expirationDate).year() <= parseInt(this.yearEnd))
        // )
        // this.periods = this.stat.periods.filter(
        //   (p => moment(p.date).year() >= parseInt(val.target.value)) && (p => moment(p.date).year() <= parseInt(this.yearEnd))
        // )
        // this.overallLogins = 0
        // this.overallSearches = 0
        // for (var total of this.totals) {
        //   this.overallLogins += total.logins
        //   this.overallSearches += total.searches
        // }
      },
      yearEndChanged(val) {
        console.log(`Year End changed from ${this.yearEnd} to ${val.target.value}`)
        this.yearEnd = val.target.value
        this.calculate()
        // this.totals = this.stat.totals.filter(y => y.year >= parseInt(this.year) && y.year <= parseInt(val.target.value))
        // this.subscriptions = this.stat.subscriptions.filter(
        //   (y => moment(y.expirationDate).year() >= parseInt(this.year)) &&
        //     (y => moment(y.expirationDate).year() <= parseInt(val.target.value))
        // )
        // this.periods = this.stat.periods.filter(
        //   (p => moment(p.date).year() >= parseInt(this.year)) && (p => moment(p.date).year() <= parseInt(val.target.value))
        // )
        // this.overallLogins = 0
        // this.overallSearches = 0
        // for (var total of this.totals) {
        //   this.overallLogins += total.logins
        //   this.overallSearches += total.searches
        // }
      },
      institutionEntered() {
        this.subId = ''
        this.individual = ''
        this.search()
      },
      individualEntered() {
        this.subId = ''
        this.institution = ''
        this.search()
      },
      subIdEntered(e) {
        if (e.keyCode === 13) {
          this.institution = ''
          this.individual = ''
          this.search()
        }
      },
      search() {
        // TODO this can be a lot shorter, only set the payload per type
        this.$store.commit('statistics/resetStat')
        if (this.institution) {
          console.log(`Search institution ${this.institution}`)
          this.$store
            .dispatch('statistics/getStat', { type: 'institution', val: this.institution })
            .then(data => {
              this.processData(data)
            })
            .catch(ex => {
              console.error(`Exception in getting stat: ${ex.message}`)
            })
        } else if (this.individual) {
          console.log(`Search individual ${this.individual}`)
          this.$store
            .dispatch('statistics/getStat', { type: 'individual', val: this.individual })
            .then(data => {
              this.processData(data)
            })
            .catch(ex => {
              console.error(`Exception in getting stat: ${ex.message}`)
            })
        } else if (this.subId) {
          console.log(`Search subId ${this.subId}`)
          this.$store
            .dispatch('statistics/getStat', { type: 'subId', val: this.subId })
            .then(data => {
              this.processData(data)
            })
            .catch(ex => {
              console.error(`Exception in getting stat: ${ex.message}`)
            })
        }
      },
      processData(data) {
        if (data && data.data) {
          this.years = []
          if (data.data.overallLogins > 0) {
            for (let [key, value] of Object.entries(data.data.totals)) {
              this.years.push(value.year)
            }
            this.years.sort()
            // Set the year value to the lowest year as default
            this.year = this.years[0]
            // Table doesn't like it when two fields point to the same date property
            for (var period of data.data.periods) {
              // period.month = period.date
            }

            // TODO below doesn't seem right.
            for (var row of data.data.periods) {
              if (!row.searches) {
                row.searches = 0
              }
            }
            for (var row of data.data.totals) {
              if (!row.searches) {
                row.searches = 0
              }
            }
          }
          this.yearsEnd = this.years.map(y => y)
          this.yearsEnd.sort((a, b) => b - a)
          this.yearEnd = this.yearsEnd[0]
          this.month = this.months[0]
          this.monthEnd = this.months[11]
          this.totals = data.data.totals
          this.subscriptions = data.data.subscriptions
          this.periods = data.data.periods
          const fullName = this.stat.fullName.includes('undefined') ? this.stat.email : this.stat.fullName
          this.title = this.stat.institution ? this.stat.institution : fullName
          this.overallLogins = data.data.overallLogins
          this.overallSearches = data.data.overallSearches
        }
      },
      emailPage(asText) {
        if (
          confirm(
            `The report has been copied to the clipboard.\nIf you continue, Gmail will be opened in a new tab.\n\nPlease paste the report into the email.`
          )
        ) {
          const url = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${this.stat.email}&su=Status Report musicaltheatersongs.com&tf=1`
          if (asText) {
            this.selectText(document.getElementById('printable'))
            document.execCommand('copy')
            window.getSelection().removeAllRanges()
            window.open(url, '_blank')
          } else {
            html2canvas(document.querySelector('#printable'))
              .then(canvas => {
                var img = document.createElement('img')
                img.src = canvas.toDataURL()
                var div = document.createElement('div')
                div.contentEditable = true
                div.appendChild(img)
                document.body.appendChild(div)
                this.selectText(div)
                document.execCommand('Copy')
                document.body.removeChild(div)
                window.open(url, '_blank')
              })
              .catch(ex => {
                console.error(ex)
              })
          }
        }
      },
      selectText(element) {
        var doc = document
        if (doc.body.createTextRange) {
          var range = document.body.createTextRange()
          range.moveToElementText(element)
          range.select()
        } else if (window.getSelection) {
          var selection = window.getSelection()
          var range = document.createRange()
          range.selectNodeContents(element)
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    }
  }
</script>

<style>
  .select-style .vs__search::placeholder,
  .select-style .vs__dropdown-toggle {
    height: 40px;
  }
  .or-style {
    font-weight: 300;
    font-size: 18px;
    margin-top: 10px;
  }
  .or-style.white {
    color: white;
  }
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    max-height: 40px;
  }
  .flex-row.button {
    justify-content: flex-end;
    margin-top: 10px;
  }
  .period {
    margin-top: 30px;
    font-weight: 600;
    font-size: 18px;
  }
  .search-title {
    font-weight: 300;
    font-size: 18px;
  }
  .search-box {
    width: 30%;
    font-size: 14px;
    max-height: 40px !important;
  }
  #subId {
    width: 100%;
    height: 40px;
  }
  .search-box input {
    width: 350px;
    font-size: 14px;
  }
  .vgt-wrap {
    width: 100%;
  }
  .bold {
    font-weight: 600;
  }

  /* .chart {
    max-height: 300px;
    max-width: 800px;
  }
  #bar-chart {
    max-height: 300px;
    max-width: 800px;
  }
  .loading-chart {
    z-index: 20000;
    color: red;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  
  .container-fluid {
    font-size: 14px;
  }
  .higher {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .border-row {
    border-bottom: 1px solid #ccc;
  }
</style>
