var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "below-menu" },
    [
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [
            _c("img", { attrs: { src: "/loading.gif" } }),
            _c("div", { staticClass: "loading" }, [
              _vm._v(" " + _vm._s(_vm.loadingMsg) + " ")
            ])
          ])
        : _vm._e(),
      _vm.error
        ? _c("div", { staticClass: "server-error" }, [
            _vm._v(" " + _vm._s(_vm.error) + " ")
          ])
        : _vm._e(),
      _c("div", { staticClass: "row fixed-top page-header" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-xs float-right header-btn",
              staticStyle: { "margin-left": "10px" },
              on: { click: _vm.newGroupUser }
            },
            [_vm._v(" Add Group User ")]
          )
        ])
      ]),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "justify-content": "space-between"
          }
        },
        [
          _c("div", { staticClass: "label group-members" }, [
            _vm._v(
              "Institution: " + _vm._s(_vm.loggedinUser.account.institutionName)
            )
          ]),
          _c("div", { staticClass: "label group-members " }, [
            _vm._v(
              "Start: " +
                _vm._s(_vm._f("formatDate")(_vm.loggedinUser.account.startDate))
            )
          ]),
          _c("div", { staticClass: "label group-members " }, [
            _vm._v(
              "Expiration: " +
                _vm._s(
                  _vm._f("formatDate")(_vm.loggedinUser.account.expirationDate)
                )
            )
          ]),
          _c("div", { staticClass: "label group-members " }, [
            _vm._v(
              "Maximum users allowed: " +
                _vm._s(_vm.loggedinUser.maxGroupMembers)
            )
          ])
        ]
      ),
      _c("hr"),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-10 form-block" }, [
          _c("div", { staticClass: "row form-row" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-3" }, [
              _c("div", {}, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.groupUser.firstName,
                      expression: "groupUser.firstName",
                      modifiers: { trim: true }
                    }
                  ],
                  ref: "firstName",
                  staticClass: "form-control",
                  attrs: {
                    id: "firstName",
                    name: "firstName",
                    type: "text",
                    disabled: _vm.disableInput
                  },
                  domProps: { value: _vm.groupUser.firstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.groupUser,
                        "firstName",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ]),
            _vm._m(2),
            _c("div", { staticClass: "col-3" }, [
              _c("div", {}, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.groupUser.lastName,
                      expression: "groupUser.lastName",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "firstName",
                    name: "firstName",
                    type: "text",
                    disabled: _vm.disableInput
                  },
                  domProps: { value: _vm.groupUser.lastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.groupUser,
                        "lastName",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "offset-2 col-4" }, [
              _c(
                "div",
                {
                  class: [
                    _vm.attemptSubmit && _vm.missingFirstName
                      ? "is-not-valid"
                      : "is-valid"
                  ]
                },
                [_vm._v(" First Name is required. ")]
              )
            ]),
            _c("div", { staticClass: "offset-1 col-4" }, [
              _c(
                "div",
                {
                  class: [
                    _vm.attemptSubmit && _vm.missingLastName
                      ? "is-not-valid"
                      : "is-valid"
                  ]
                },
                [_vm._v(" Last Name is required. ")]
              )
            ])
          ]),
          _c("div", { staticClass: "row form-row" }, [
            _vm._m(3),
            _c("div", { staticClass: "col-3" }, [
              _c("div", {}, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.groupUser.email,
                      expression: "groupUser.email",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "firstName",
                    name: "firstName",
                    type: "text",
                    disabled: _vm.disableInput
                  },
                  domProps: { value: _vm.groupUser.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.groupUser,
                        "email",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ])
            ]),
            _vm._m(4),
            _c("div", { staticClass: "col-3" }, [
              _c("div", {}, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.groupUser.password,
                      expression: "groupUser.password"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "firstName",
                    name: "firstName",
                    type: "text",
                    disabled: _vm.disableInput
                  },
                  domProps: { value: _vm.groupUser.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.groupUser, "password", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "offset-2 col-4" }, [
              _c(
                "div",
                {
                  class: [
                    _vm.attemptSubmit && _vm.missingEmail
                      ? "is-not-valid"
                      : "is-valid"
                  ]
                },
                [_vm._v(" Email is required. ")]
              ),
              _vm.existingEmail
                ? _c(
                    "div",
                    {
                      class: [_vm.existingEmail ? "is-not-valid" : "is-valid"]
                    },
                    [_vm._v(" Account already exists. ")]
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "offset-1 col-4" }, [
              _c(
                "div",
                {
                  class: [
                    _vm.attemptSubmit && _vm.missingPassword
                      ? "is-not-valid"
                      : "is-valid"
                  ]
                },
                [_vm._v(" Password is required. ")]
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row form-row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-secondary btn-sm",
                staticStyle: { "margin-right": "10px" },
                attrs: { disabled: _vm.disableInput },
                on: { click: _vm.cancel }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-success btn-sm",
                attrs: { disabled: _vm.formsHasErrors || _vm.disableInput },
                on: { click: _vm.save }
              },
              [_vm._v(" Save ")]
            )
          ])
        ]),
        _c("div", { staticClass: "col-10 form-block" }, [
          _c("p", [
            _vm._v(" To add a "),
            _c("strong", [_vm._v("new")]),
            _vm._v(
              " group user, click on the Add Group User button in the top right of the screen or click "
            ),
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.newGroupUser($event)
                  }
                }
              },
              [_vm._v("here")]
            ),
            _vm._v(". "),
            _c("br"),
            _vm._v(" To "),
            _c("strong", [_vm._v("edit")]),
            _vm._v(
              " an existing user, click on the row below with the user you want to edit. Don't forget to click "
            ),
            _c("strong", [_vm._v("Save")]),
            _vm._v(" to keep you changes. ")
          ])
        ])
      ]),
      _c("vue-good-table", {
        key: _vm.tableKey,
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          "pagination-options": {
            enabled: true,
            perPage: 15,
            perPageDropdown: [15, 20, 25, 30, 35, 40, 50],
            position: "top"
          },
          "style-class": "vgt-table musical bordered condensed"
        },
        on: { "on-row-click": _vm.rowClick }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-10" }, [
      _c("h5", [_vm._v("Group Users")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 label" }, [
      _c(
        "label",
        { staticClass: "label required", attrs: { for: "firstName" } },
        [_vm._v("First Name")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 label" }, [
      _c(
        "label",
        { staticClass: "label required", attrs: { for: "firstName" } },
        [_vm._v("Last Name")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 label" }, [
      _c(
        "label",
        { staticClass: "label required", attrs: { for: "firstName" } },
        [_vm._v("Email")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2 label" }, [
      _c("label", { staticClass: "label", attrs: { for: "firstName" } }, [
        _vm._v("Password")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }