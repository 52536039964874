var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.active,
              expression: "model.active"
            }
          ],
          staticClass: "css-checkbox",
          attrs: { type: "checkbox", name: _vm.linkName, id: _vm.linkName },
          domProps: {
            checked: Array.isArray(_vm.model.active)
              ? _vm._i(_vm.model.active, null) > -1
              : _vm.model.active
          },
          on: {
            change: function($event) {
              var $$a = _vm.model.active,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.model, "active", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.model,
                      "active",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.model, "active", $$c)
              }
            }
          }
        }),
        _c(
          "label",
          {
            staticClass: "css-label lite-red-check",
            attrs: { for: _vm.linkName }
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      ])
    ]),
    _vm.model.active
      ? _c("div", { staticClass: "custom-link" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("label", { attrs: { for: _vm.linkName + "radio" } }, [
                _vm._v("Site Name ")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.siteName,
                    expression: "model.siteName"
                  }
                ],
                attrs: { type: "text", placeholder: "" },
                domProps: { value: _vm.model.siteName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "siteName", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("label", { attrs: { for: _vm.linkName + "radio" } }, [
                _vm._v("URL")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.manualLink,
                    expression: "model.manualLink"
                  }
                ],
                attrs: { type: "text", placeholder: "http://" },
                domProps: { value: _vm.model.manualLink },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model, "manualLink", $event.target.value)
                  }
                }
              }),
              _vm.model.manualLink
                ? _c(
                    "span",
                    { staticClass: "link", on: { click: _vm.testManualScore } },
                    [_vm._v("Test Link")]
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }