var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "below-menu" },
    [
      _c("div", { staticClass: "row fixed-top page-header" }, [
        _c("div", { staticClass: "col-9" }, [
          _c("h5", [_vm._v("Users - " + _vm._s(_vm.tableTitle))])
        ]),
        _c("div", { staticClass: "col-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-xs float-right",
              on: { click: _vm.onNewSubscription }
            },
            [_vm._v(" Add Subscription ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-xs float-right",
              staticStyle: { "margin-right": "10px" },
              on: { click: _vm.onNewUser }
            },
            [_vm._v(" Add User ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-xs float-right",
              staticStyle: { "margin-right": "10px" },
              on: { click: _vm.copyContent }
            },
            [_vm._v(" Copy ")]
          )
        ])
      ]),
      _c(
        "vue-good-table",
        {
          key: _vm.tableKey,
          attrs: {
            mode: "remote",
            totalRows: _vm.totalRecords,
            isLoading: _vm.isLoading,
            "pagination-options": {
              enabled: true,
              perPage: _vm.serverParams.perPage,
              perPageDropdown: [15, 20, 25, 30, 35, 40, 50],
              position: "top",
              mode: "records"
            },
            "sort-options": {
              enabled: true,
              initialSortBy: [{ field: _vm.sortField, type: _vm.sortType }]
            },
            rows: _vm.rows,
            columns: _vm.columns,
            "style-class": "vgt-table musical bordered condensed"
          },
          on: {
            "on-page-change": _vm.onPageChange,
            "on-sort-change": _vm.onSortChange,
            "on-column-filter": _vm.onColumnFilter,
            "on-per-page-change": _vm.onPerPageChange,
            "on-row-click": _vm.rowClick,
            "on-cell-click": _vm.cellClick,
            "update:isLoading": function($event) {
              _vm.isLoading = $event
            },
            "update:is-loading": function($event) {
              _vm.isLoading = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "table-row",
              fn: function(props) {
                return [
                  props.column.field == "delete"
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  props.row.planName &&
                                  props.row.planName.includes(
                                    "No subscription"
                                  ),
                                expression:
                                  "props.row.planName && props.row.planName.includes('No subscription')"
                              }
                            ],
                            attrs: { href: "#" }
                          },
                          [_vm._v("Delete")]
                        )
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            {
              staticClass: "loading",
              attrs: { slot: "loadingContent" },
              slot: "loadingContent"
            },
            [
              _vm.loadingErrorMsg
                ? _c("div", { staticClass: "loading server-error" }, [
                    _vm._v(" " + _vm._s(_vm.loadingErrorMsg) + " ")
                  ])
                : _c("div", [
                    _c("img", { attrs: { src: "/loading.gif" } }),
                    _vm._v(" " + _vm._s(_vm.isLoadingMsg) + " ")
                  ])
            ]
          ),
          _c(
            "div",
            { attrs: { slot: "table-actions" }, slot: "table-actions" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: { type: "radio", name: "usersSelect", value: "all" },
                domProps: { checked: _vm._q(_vm.usersSelect, "all") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "all"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("All Users")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: {
                  type: "radio",
                  name: "usersSelect",
                  value: "subscriber"
                },
                domProps: { checked: _vm._q(_vm.usersSelect, "subscriber") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "subscriber"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Subscribers")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: { type: "radio", name: "usersSelect", value: "creator" },
                domProps: { checked: _vm._q(_vm.usersSelect, "creator") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "creator"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Creators")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: {
                  type: "radio",
                  name: "usersSelect",
                  value: "dataAssistant"
                },
                domProps: { checked: _vm._q(_vm.usersSelect, "dataAssistant") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "dataAssistant"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Data Assistants")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: {
                  type: "radio",
                  name: "usersSelect",
                  value: "marketingAssistant"
                },
                domProps: {
                  checked: _vm._q(_vm.usersSelect, "marketingAssistant")
                },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "marketingAssistant"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Markting Assistants")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: { type: "radio", name: "usersSelect", value: "admin" },
                domProps: { checked: _vm._q(_vm.usersSelect, "admin") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "admin"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Administrators")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: { type: "radio", name: "usersSelect", value: "active" },
                domProps: { checked: _vm._q(_vm.usersSelect, "active") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "active"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Users without subscription")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: {
                  type: "radio",
                  name: "usersSelect",
                  value: "groupAdmins"
                },
                domProps: { checked: _vm._q(_vm.usersSelect, "groupAdmins") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "groupAdmins"
                    },
                    _vm.usersSelectChanged
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Group Admins")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }