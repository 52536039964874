import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    faqsList: [],
    currentFaq: {}
  },
  mutations: {
    setFaqsList: (state, payload) => {
      state.faqsList = payload
    },
    setCurrentFaq: (state, payload) => {
      state.currentFaq = payload
    }
  },
  actions: {
    getFaqs: ({ state, commit, rootState }) => {
      return new Promise((resolve, reject) => {
        if (state.faqsList.length === 0) {
          rootState.general.error = ''
          rootState.general.loading = true
          rootState.general.loadingMsg = 'Loading Faqs'
          console.log('Loading Faqs')
          Vue.http.get('api/faqs').then(
            response => {
              commit('setFaqsList', response.body)
              rootState.general.loading = false
              rootState.general.loadingMsg = ''
              resolve('faqs')
            },
            error => {
              rootState.general.error = error.bodyText
              reject(error.bodyText)
            }
          )
        } else {
          resolve('faqs')
        }
      })
    },
    deleteFaq: ({ commit, rootState }, faqId) => {
      return new Promise((resolve, reject) => {
        Vue.http.delete('api/faqs/' + faqId).then(
          response => {
            console.log('Got reply from deleting faq', response)
            resolve(response)
          },
          error => {
            commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
            rootState.general.loading = false
            reject(error.bodyText)
          }
        )
      })
    },
    saveFAQ: ({ commit, rootState }, faq) => {
      faq.question = faq.question
        .replace('<p>', '')
        .replace('</p>', '')
        .replace(/(?:\r\n|\r|\n)/g, '')
      faq.answer = faq.answer.replace('<p>', '').replace('</p>', '')
      Vue.http.post('api/faqs', { faq }).then(
        response => {
          console.log('Got reply from saving faq', response)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    }
  }
}
