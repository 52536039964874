<template>
  <div>
    <modal
      @before-open="beforeOpen"
      @before-close="beforeClose"
      name="composer-edit"
      height="550"
      width="1000"
      transition="pop-out"
      :click-to-close="false"
    >
      <div class="show-form">
        <h5 v-if="currentComposer._id">
          Edit Composer
        </h5>
        <h5 v-else>
          New Composer
        </h5>
        <div class="row">
          <div class="col-12 form-block">
            <div class="row form-row">
              <!-- First Name -->
              <div class="col-2 label">
                <label class="label required" for="firstName">First Name</label>
              </div>
              <div class="col-2">
                <input
                  id="firstName"
                  name="firstName"
                  class="form-control"
                  type="text"
                  v-model.trim="currentComposer.firstName"
                  :class="{ 'is-invalid': attemptSubmit && missingFirstName }"
                />
                <div :class="[attemptSubmit && missingFirstName ? 'is-not-valid' : 'is-valid']">
                  First Name is required.
                </div>
              </div>

              <!-- Last Name -->
              <div class="col-2 label">
                <label class="label required" for="lastName">Last Name</label>
              </div>
              <div class="col-2">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="currentComposer.lastName"
                  id="lastName"
                  :class="{ 'is-invalid': attemptSubmit && missingLastName }"
                  name="lastName"
                />
                <div :class="[attemptSubmit && missingLastName ? 'is-not-valid' : 'is-valid']">
                  Last Name is required.
                </div>
              </div>

              <!-- Buttons -->
              <div class="offset-1 col-3">
                <button @click="cancel" class="btn btn-outline-secondary btn-xs song-button" style="margin-right: 5px;">
                  Cancel
                </button>
                <button @click="saveComposer" :disabled="formsHasErrors" class="btn btn-outline-success btn-xs song-button">
                  Save
                </button>
                <button
                  @click="deleteComposer"
                  v-if="$can('delete', 'composers') && currentComposer._id"
                  :disabled="!canDelete"
                  class="btn btn-outline-danger btn-xs song-button"
                >
                  Delete
                </button>
              </div>
            </div>

            <!-- Female and BIPOC -->
            <div class="row form-row">
              <div class="offset-2 col-4">
                <input class="featured" type="checkbox" v-model="currentComposer.isFemale" /><b style="padding-left:5px;"
                  >Women/Nonbinary</b
                >
              </div>
              <div class="col-4">
                <input class="featured" type="checkbox" v-model="currentComposer.isBIPOC" /><b style="padding-left:5px;"
                  >Global Majority</b
                >
              </div>
            </div>

            <!-- Featured composer -->
            <div class="row form-row" v-if="loggedinUser && loggedinUser.role === 'admin'">
              <div class="offset-2 col-2">
                <input class="featured" type="checkbox" v-model="currentComposer.isFeatured" /><b style="padding-left:5px;"
                  >Featured Composer</b
                >
              </div>
              <div class="col-2 label">
                <label v-if="currentComposer.isFeatured" class="label" for="custLabel">Custom Label</label>
              </div>
              <div class="col-2">
                <input
                  v-if="currentComposer.isFeatured"
                  type="text"
                  class="form-control"
                  v-model.trim="currentComposer.customLabel"
                  id="custLabel"
                  name="custLabel"
                />
              </div>
            </div>

            <!-- Info URL -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label" for="lastName">Info URL</label>
              </div>
              <div class="col-5">
                <input type="text" class="form-control" v-model.trim="currentComposer.infoUrl" id="infoUrl" name="infoUrl" />
              </div>
              <div class="col-2">
                <a v-if="currentComposer.infoUrl" :href="currentComposer.infoUrl" target="blank">test link</a>
              </div>
            </div>

            <div class="row form-row" v-if="currentComposer._id">
              <div class="col-2 label">
                <label class="label" for="created">Created</label>
              </div>
              <div class="col-2">
                {{ currentComposer.dateCreated | formatDate }}
              </div>
              <div class="col-2 label">
                <label class="label" for="created">Created By</label>
              </div>
              <div class="col-4">
                {{ currentComposer.enteredBy | emptyVal }}
              </div>
            </div>

            <div class="row form-row" v-if="currentComposer._id">
              <div class="col-2 label">
                <label class="label" for="created">Last Updated</label>
              </div>
              <div class="col-2">
                {{ currentComposer.lastUpdated | formatDate }}
              </div>
              <div class="col-2 label">
                <label class="label" for="created">Updated By</label>
              </div>
              <div class="col-4">
                <!-- <span v-if="!currentComposer.updatedBy.includes('undefined')">{{currentComposer.updatedBy}}</span> -->
                {{ currentComposer.updatedBy | emptyVal }}
              </div>
            </div>

            <hr />
            <div class="row form-row">
              <div class="offset-1">
                <span style="margin-left:10px;"
                  ><b
                    >Note: This is the bio for the homepage, and will only appear when this composer is one of the featured
                    composers of the month.</b
                  ></span
                >
              </div>
            </div>

            <!-- Bio -->
            <div class="row form-row">
              <div class="col-2 label">
                <label class="label not-required" for="bio">Bio</label>
              </div>
              <div class="col-7">
                <quill-editor
                  style="height: 200px !important;"
                  v-model="currentComposer.bio"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @ready="onEditorReady($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { eventbus } from '../main'
  export default {
    data() {
      return {
        apiError: '',
        attemptSubmit: false,
        editor: {},
        editorOption: {
          // some quill options
          modules: {
            toolbar: [
              'bold',
              'italic',
              'underline',
              { color: [] },
              { indent: '-1' },
              { indent: '+1' },
              { list: 'ordered' },
              { list: 'bullet' },
              'link',
              'clean',
              { header: [1, 2, 3, 4, 5, 6, false] },
              { size: ['small', false, 'large', 'huge'] }
            ]
          }
        }
      }
    },
    computed: {
      ...mapState({
        loggedinUser: state => state.general.loggedinUser,
        currentComposer: state => state.creators.currentComposer,
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingFirstName || this.missingLastName
        } else {
          return false
        }
      },
      missingFirstName() {
        return this.currentComposer.firstName === ''
      },
      missingLastName() {
        return this.currentComposer.lastName === ''
      },
      canDelete() {
        return this.currentComposer._id !== undefined
      }
    },
    methods: {
      beforeOpen() {
        // Set init values
        this.attemptSubmit = false
        document.addEventListener('keyup', this.close)
      },
      beforeClose() {
        document.removeEventListener('keyup', this.close)
      },
      close(e) {
        if (e.keyCode === 27) {
          eventbus.$emit('modal', { command: 'close', name: 'composer-edit' })
        }
      },
      cancel() {
        eventbus.$emit('modal', { command: 'close', name: 'composer-edit' })
      },
      saveComposer() {
        this.attemptSubmit = true
        // Give UI some time to react to attemptSubmit
        setTimeout(() => {
          if (!this.formsHasErrors) {
            this.$store.dispatch('creators/saveComposer', this.currentComposer)
            .then(() => {
              this.attemptSubmit = false
              // const rows = document.querySelectorAll('.clickable')
              // rows.forEach((row, rowIndex) => {
              //   const a = rows[rowIndex]
              //   a.classList.remove('clicked')
              // })
              eventbus.$emit('modal', { command: 'close', name: 'composer-edit' })
            })
          }
        }, 500)
      },
      deleteComposer() {
        if (this.currentComposer._id) {
          if (
            confirm(
              `Are you sure you want to delete composer "${this.currentComposer.lastName}"?\n\nThis action cannot be undone.`
            )
          ) {
            this.$store.dispatch('creators/deleteComposer', this.currentComposer._id)
            eventbus.$emit('modal', { command: 'close', name: 'composer-edit' })
          }
        }
      },
      onEditorReady(quill) {
        this.editor = quill
      }
    }
  }
</script>
