<template>
  <div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" :name="linkName + type" :id="linkName + type" class="css-checkbox" v-model="enabled" />
        <label :for="linkName + type" class="css-label lite-red-check">{{ linkName }}</label>
      </div>
    </div>
    <div v-if="enabled" class="external-links">
      <div class="row">
        <div class="col-12">
          <input type="radio" :name="linkName + type + 'radio'" v-model="automatic" :value="true" />
          <label :for="linkName + type + 'radio'"
            >Automatic Link
            <span @click="testAutoScore" v-if="automatic" class="link">Test Link</span>
          </label>
        </div>
      </div>
      <div class="row" v-if="automatic">
        <div class="col-8">
          <input
            type="checkbox"
            :name="linkName + type + 'check'"
            :id="linkName + type + 'check'"
            class="css-checkbox"
            v-model="includeSongTitle"
          />
          <label :for="linkName + type + 'check'" class="css-label lite-red-check external-links">
            Include song title in addition to show title in automatic link
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <input type="radio" :name="linkName + type + 'radio'" v-model="automatic" :value="false" />
          <label :for="linkName + type + 'radio'"
            >Manual Link
            <span @click="testManualScore" v-if="!automatic" class="link">Test Link</span>
          </label>
        </div>
      </div>
      <div class="row" v-if="!automatic">
        <div class="col-12">
          <input type="text" class="external-links" v-model="url" placeholder="http://" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['model', 'name', 'type', 'baseUrl'],
    computed: {
      linkName: function() {
        return this.model.externalLinks[this.type][this.name].siteName
      },
      enabled: {
        get: function() {
          return this.model.externalLinks[this.type][this.name].enabled
        },
        set: function(val) {
          this.model.externalLinks[this.type][this.name].enabled = val
        }
      },
      automatic: {
        get: function() {
          return this.model.externalLinks[this.type][this.name].automatic
        },
        set: function(val) {
          this.model.externalLinks[this.type][this.name].automatic = val
        }
      },
      includeSongTitle: {
        get: function() {
          return this.model.externalLinks[this.type][this.name].includeSongTitle
        },
        set: function(val) {
          this.model.externalLinks[this.type][this.name].includeSongTitle = val
        }
      },
      url: {
        get: function() {
          return this.model.externalLinks[this.type][this.name].url
        },
        set: function(val) {
          this.model.externalLinks[this.type][this.name].url = val
        }
      }
    },
    methods: {
      testAutoScore() {
        let url
        if (this.model.externalLinks[this.type][this.name].includeSongTitle) {
          url = encodeURI(`${this.model.showTitle} ${this.model.title}`)
        } else {
          url = encodeURI(`${this.model.showTitle}`)
        }
        window.open(this.baseUrl + url, '_blank')
      },
      testManualScore() {
        window.open(this.model.externalLinks[this.type][this.name].url, '_blank')
      }
    }
  }
</script>
