var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "new-edit-song",
            height: "auto",
            width: "90%",
            scrollable: true,
            transition: "pop-out",
            "click-to-close": false
          },
          on: { "before-open": _vm.beforeOpen, "before-close": _vm.beforeClose }
        },
        [
          _c(
            "form",
            {
              staticClass: "show-form",
              attrs: { autocomplete: "off" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("input", {
                staticStyle: { display: "none" },
                attrs: { autocomplete: "false", name: "hidden", type: "text" }
              }),
              _vm.currentSong._id
                ? _c("h4", [_vm._v(" Edit Song ")])
                : _c("h4", [_vm._v(" New Song ")]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "songTitle" }
                        },
                        [_vm._v("Song Title")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentSong.title,
                            expression: "currentSong.title",
                            modifiers: { trim: true }
                          }
                        ],
                        ref: "songTitle",
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.attemptSubmit && _vm.missingTitle
                        },
                        attrs: {
                          type: "text",
                          id: "songTitle",
                          name: "songTitle",
                          placeholder: "Enter Song Title"
                        },
                        domProps: { value: _vm.currentSong.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong,
                              "title",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingTitle
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v(" Title is required. ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label not-required",
                          attrs: { for: "songId" }
                        },
                        [_vm._v("Song ID")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong._id,
                            expression: "currentSong._id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "songId", disabled: "" },
                        domProps: { value: _vm.currentSong._id },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong,
                              "_id",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-2" }, [
                      _vm.currentSong._id
                        ? _c(
                            "span",
                            {
                              staticClass: "link",
                              on: { click: _vm.testDetailPage }
                            },
                            [_vm._v("song detail page")]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label not-required",
                          attrs: { for: "showTitle" }
                        },
                        [_vm._v("Show Title")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentSong.showTitle,
                            expression: "currentSong.showTitle",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "showTitle", disabled: "" },
                        domProps: { value: _vm.currentSong.showTitle },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong,
                              "showTitle",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _vm.currentSong._id
                    ? _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "label not-required",
                              attrs: { for: "songDateEntered" }
                            },
                            [_vm._v("Date Entered")]
                          )
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDate")(
                                  _vm.currentSong.dateCreated
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.currentSong._id
                    ? _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "label not-required",
                              attrs: { for: "songLastUpdated" }
                            },
                            [_vm._v("Last Updated")]
                          )
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDate")(
                                  _vm.currentSong.lastUpdated
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.currentSong._id
                    ? _c("div", { staticClass: "row form-row" }, [
                        _c("div", { staticClass: "col-4 label" }, [
                          _c(
                            "label",
                            {
                              staticClass: "label not-required",
                              attrs: { for: "songLastUpdatedBy" }
                            },
                            [_vm._v("Last Updated By")]
                          )
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("emptyVal")(
                                  _vm.currentSong.lastUpdatedByName
                                )
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "composers" }
                        },
                        [_vm._v("Composer(s)")]
                      ),
                      _c("div", [
                        _vm.currentSong.hasFemaleComposer
                          ? _c("span", [_vm._v("👩🏻 ")])
                          : _vm._e(),
                        _vm.currentSong.hasBIPOCComposer
                          ? _c("span", [_vm._v("👨🏾")])
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("v-select", {
                          ref: "composer",
                          attrs: {
                            multiple: "",
                            "close-on-select": true,
                            id: "composers",
                            name: "composers",
                            options: _vm.composers
                          },
                          on: { input: _vm.composerEntered },
                          model: {
                            value: _vm.currentSong.composers,
                            callback: function($$v) {
                              _vm.$set(_vm.currentSong, "composers", $$v)
                            },
                            expression: "currentSong.composers"
                          }
                        }),
                        _c("span", { staticClass: "note" }, [
                          _vm._v("Format: Last name, First name")
                        ]),
                        _vm._l(_vm.currentSong.composers, function(composer) {
                          return _c("div", { key: composer._id }, [
                            composer.infoUrl
                              ? _c("span", [
                                  _c("b", [
                                    _c("i", [
                                      _vm._v(_vm._s(composer.lastName) + ":")
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: composer.infoUrl,
                                        target: "__blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(composer.infoUrl))]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.missingComposer
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [_vm._v(" At least one composer is required. ")]
                        )
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "lyricists" }
                        },
                        [_vm._v("Lyricist(s)")]
                      ),
                      _c("div", [
                        _vm.currentSong.hasFemaleLyricist
                          ? _c("span", [_vm._v("👩🏻 ")])
                          : _vm._e(),
                        _vm.currentSong.hasBIPOCLyricist
                          ? _c("span", [_vm._v("👨🏾")])
                          : _vm._e()
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("v-select", {
                          attrs: {
                            multiple: "",
                            "close-on-select": true,
                            id: "lyricists",
                            name: "lyricists",
                            options: _vm.lyricists
                          },
                          on: { input: _vm.lyricistEntered },
                          model: {
                            value: _vm.currentSong.lyricists,
                            callback: function($$v) {
                              _vm.$set(_vm.currentSong, "lyricists", $$v)
                            },
                            expression: "currentSong.lyricists"
                          }
                        }),
                        _c("span", { staticClass: "note" }, [
                          _vm._v("Format: Last name, First name")
                        ]),
                        _vm._l(_vm.currentSong.lyricists, function(lyricist) {
                          return _c("div", { key: lyricist._id }, [
                            lyricist.infoUrl
                              ? _c("span", [
                                  _c("b", [
                                    _c("i", [
                                      _vm._v(_vm._s(lyricist.lastName) + ":")
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: lyricist.infoUrl,
                                        target: "__blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(lyricist.infoUrl))]
                                  )
                                ])
                              : _vm._e()
                          ])
                        }),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.missingLyricist
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [_vm._v(" At least one lyricist is required. ")]
                        )
                      ],
                      2
                    )
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "age" }
                        },
                        [_vm._v("Character's Age")]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("age", {
                          attrs: {
                            "current-song": _vm.currentSong,
                            name: "age"
                          }
                        }),
                        _c(
                          "div",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.missingAge
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [_vm._v(" At least one age group is required. ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label not-required",
                          attrs: { for: "tags" }
                        },
                        [_vm._v("Tags (keywords)")]
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("v-select", {
                          attrs: {
                            multiple: "",
                            "close-on-select": false,
                            id: "tags",
                            name: "tags",
                            options: _vm.tagsList
                          },
                          model: {
                            value: _vm.currentSong.tags,
                            callback: function($$v) {
                              _vm.$set(_vm.currentSong, "tags", $$v)
                            },
                            expression: "currentSong.tags"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "year" }
                        },
                        [_vm._v("Year Written")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentSong.yearWritten,
                            expression: "currentSong.yearWritten",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.attemptSubmit && _vm.missingYear
                        },
                        attrs: {
                          type: "text",
                          id: "year",
                          name: "year",
                          placeholder: "Enter Year Written"
                        },
                        domProps: { value: _vm.currentSong.yearWritten },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong,
                              "yearWritten",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingYear
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v(" A valid year is required. ")]
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("div", { staticClass: "col-5" }, [
                  _c("h6", { staticStyle: { "padding-top": "11px" } }, [
                    _vm._v(" Musical Parameters ")
                  ])
                ]),
                _c("div", { staticClass: "col-5" }, [
                  _vm.formErrorText
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.formErrorText)),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm.error
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.error)),
                        _c("br")
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-margin btn btn-outline-secondary btn-xs",
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-margin btn btn-outline-success btn-xs",
                      attrs: { disabled: _vm.formsHasErrors },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  ),
                  _vm.loggedinUser && _vm.loggedinUser.role !== "creator"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-margin btn btn-outline-primary btn-xs",
                          attrs: { disabled: _vm.formsHasErrors },
                          on: { click: _vm.saveNew }
                        },
                        [_vm._v(" Save and New ")]
                      )
                    : _vm._e(),
                  _vm.$can("delete", "songs") && _vm.currentSong._id
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-margin btn btn-outline-danger btn-xs",
                          on: { click: _vm.delSong }
                        },
                        [_vm._v(" Delete ")]
                      )
                    : _vm._e()
                ])
              ]),
              _c("hr", { staticStyle: { "margin-top": "0px" } }),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "style" }
                        },
                        [_vm._v("Style")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong.isComic,
                            expression: "currentSong.isComic"
                          }
                        ],
                        attrs: { type: "radio", name: "style", value: "true" },
                        domProps: {
                          checked: _vm._q(_vm.currentSong.isComic, "true")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.currentSong, "isComic", "true")
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "style1" } }, [
                        _vm._v("Comedic")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong.isComic,
                            expression: "currentSong.isComic"
                          }
                        ],
                        attrs: { type: "radio", name: "style", value: "false" },
                        domProps: {
                          checked: _vm._q(_vm.currentSong.isComic, "false")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.currentSong, "isComic", "false")
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "style2" } }, [
                        _vm._v("Dramatic")
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "voice" }
                        },
                        [_vm._v("Voice Type")]
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSong.voiceType,
                              expression: "currentSong.voiceType"
                            }
                          ],
                          attrs: { name: "voice-type" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong,
                                "voiceType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("Soprano")]),
                          _c("option", [_vm._v("Mezzo/Belt/Alto")]),
                          _c("option", [_vm._v("Tenor")]),
                          _c("option", [_vm._v("Baritone/Bass")])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingVoiceType
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v("Voice Type is required.")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "tempo" }
                        },
                        [_vm._v("Tempo")]
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSong.tempo,
                              expression: "currentSong.tempo"
                            }
                          ],
                          attrs: { name: "tempo" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong,
                                "tempo",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("Easy Ballad")]),
                          _c("option", [_vm._v("Power Ballad")]),
                          _c("option", [_vm._v("Medium/Moderate")]),
                          _c("option", [_vm._v("Up-Tempo")])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingTempo
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v("Tempo is required.")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "key" }
                        },
                        [_vm._v("Original Key")]
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSong.originalKey.pitch,
                              expression: "currentSong.originalKey.pitch"
                            }
                          ],
                          attrs: { name: "pitch" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong.originalKey,
                                "pitch",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("A")]),
                          _c("option", [_vm._v("A♯")]),
                          _c("option", [_vm._v("A♭")]),
                          _c("option", [_vm._v("B")]),
                          _c("option", [_vm._v("B♭")]),
                          _c("option", [_vm._v("C")]),
                          _c("option", [_vm._v("C♯")]),
                          _c("option", [_vm._v("D")]),
                          _c("option", [_vm._v("D♯")]),
                          _c("option", [_vm._v("D♭")]),
                          _c("option", [_vm._v("E")]),
                          _c("option", [_vm._v("E♭")]),
                          _c("option", [_vm._v("F")]),
                          _c("option", [_vm._v("F♯")]),
                          _c("option", [_vm._v("G")]),
                          _c("option", [_vm._v("G♯")]),
                          _c("option", [_vm._v("G♭")])
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong.originalKey.majorMinor,
                            expression: "currentSong.originalKey.majorMinor"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "key1",
                          name: "pitch",
                          value: "major"
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.currentSong.originalKey.majorMinor,
                            "major"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.currentSong.originalKey,
                              "majorMinor",
                              "major"
                            )
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "key1" } }, [
                        _vm._v("Major")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong.originalKey.majorMinor,
                            expression: "currentSong.originalKey.majorMinor"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: "key2",
                          name: "pitch",
                          value: "minor"
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.currentSong.originalKey.majorMinor,
                            "minor"
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.currentSong.originalKey,
                              "majorMinor",
                              "minor"
                            )
                          }
                        }
                      }),
                      _c("label", { attrs: { for: "ke21" } }, [_vm._v("Minor")])
                    ]),
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingKey
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v("Key is required.")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "difficulty" }
                        },
                        [_vm._v("Difficulty for accompanist")]
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSong.accompanistDifficulty,
                              expression: "currentSong.accompanistDifficulty"
                            }
                          ],
                          attrs: { name: "difficulty" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong,
                                "accompanistDifficulty",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("1")]),
                          _c("option", [_vm._v("2")]),
                          _c("option", [_vm._v("3")]),
                          _c("option", [_vm._v("4")]),
                          _c("option", [_vm._v("5")])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingDifficulty
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v("Difficulty is required.")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "timesignature" }
                        },
                        [_vm._v("Time Signature")]
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.currentSong.timeSignature1,
                              expression: "currentSong.timeSignature1",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: { name: "timesignature" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong,
                                "timeSignature1",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("2")]),
                          _c("option", [_vm._v("3")]),
                          _c("option", [_vm._v("4")]),
                          _c("option", [_vm._v("5")]),
                          _c("option", [_vm._v("6")]),
                          _c("option", [_vm._v("7")]),
                          _c("option", [_vm._v("9")]),
                          _c("option", [_vm._v("12")])
                        ]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.currentSong.timeSignature2,
                              expression: "currentSong.timeSignature2",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: { name: "timesignature" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong,
                                "timeSignature2",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", [_vm._v("2")]),
                          _c("option", [_vm._v("4")]),
                          _c("option", [_vm._v("8")])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingTimeSignature
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v("Time Signature is required.")]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row form-row",
                      staticStyle: { "margin-bottom": "0px" }
                    },
                    [
                      _c("div", { staticClass: "col-4 label" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label required",
                            attrs: { for: "voicerange" }
                          },
                          [_vm._v("Voice Range")]
                        )
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSong.voiceRange.lowestNote.note,
                                expression:
                                  "currentSong.voiceRange.lowestNote.note"
                              }
                            ],
                            attrs: { name: "voicerange" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.currentSong.voiceRange.lowestNote,
                                  "note",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("A")]),
                            _c("option", [_vm._v("A♯")]),
                            _c("option", [_vm._v("A♭")]),
                            _c("option", [_vm._v("B")]),
                            _c("option", [_vm._v("B♭")]),
                            _c("option", [_vm._v("C")]),
                            _c("option", [_vm._v("C♯")]),
                            _c("option", [_vm._v("D")]),
                            _c("option", [_vm._v("D♯")]),
                            _c("option", [_vm._v("D♭")]),
                            _c("option", [_vm._v("E")]),
                            _c("option", [_vm._v("E♭")]),
                            _c("option", [_vm._v("F")]),
                            _c("option", [_vm._v("F♯")]),
                            _c("option", [_vm._v("G")]),
                            _c("option", [_vm._v("G♯")]),
                            _c("option", [_vm._v("G♭")])
                          ]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSong.voiceRange.lowestNote.octave,
                                expression:
                                  "currentSong.voiceRange.lowestNote.octave"
                              }
                            ],
                            attrs: { name: "voicerange" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.currentSong.voiceRange.lowestNote,
                                  "octave",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("2")]),
                            _c("option", [_vm._v("3")]),
                            _c("option", [_vm._v("4")]),
                            _c("option", [_vm._v("5")]),
                            _c("option", [_vm._v("6")])
                          ]
                        ),
                        _vm._v(" to "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSong.voiceRange.highestNote.note,
                                expression:
                                  "currentSong.voiceRange.highestNote.note"
                              }
                            ],
                            attrs: { name: "voicerange" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.currentSong.voiceRange.highestNote,
                                  "note",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("A")]),
                            _c("option", [_vm._v("A♯")]),
                            _c("option", [_vm._v("A♭")]),
                            _c("option", [_vm._v("B")]),
                            _c("option", [_vm._v("B♭")]),
                            _c("option", [_vm._v("C")]),
                            _c("option", [_vm._v("C♯")]),
                            _c("option", [_vm._v("D")]),
                            _c("option", [_vm._v("D♯")]),
                            _c("option", [_vm._v("D♭")]),
                            _c("option", [_vm._v("E")]),
                            _c("option", [_vm._v("E♭")]),
                            _c("option", [_vm._v("F")]),
                            _c("option", [_vm._v("F♯")]),
                            _c("option", [_vm._v("G")]),
                            _c("option", [_vm._v("G♯")]),
                            _c("option", [_vm._v("G♭")])
                          ]
                        ),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentSong.voiceRange.highestNote.octave,
                                expression:
                                  "currentSong.voiceRange.highestNote.octave"
                              }
                            ],
                            attrs: { name: "voicerange" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.currentSong.voiceRange.highestNote,
                                  "octave",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("2")]),
                            _c("option", [_vm._v("3")]),
                            _c("option", [_vm._v("4")]),
                            _c("option", [_vm._v("5")]),
                            _c("option", [_vm._v("6")])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col-5" }, [
                        _c(
                          "span",
                          {
                            class: [
                              _vm.attemptSubmit && _vm.missingVoiceRange
                                ? "is-not-valid"
                                : "is-valid"
                            ]
                          },
                          [_vm._v("Voice Range is required.")]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row form-row",
                      staticStyle: { "margin-bottom": "10px" }
                    },
                    [
                      _c("div", { staticClass: "col-4 label" }),
                      _c("div", { staticClass: "col-6" }, [
                        _c("i", [_vm._v("Middle C = C4")])
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label required",
                          attrs: { for: "pages" }
                        },
                        [_vm._v("Page Numbers in the Score")]
                      )
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _vm._v(" from "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentSong.pageNumbers.start,
                            expression: "currentSong.pageNumbers.start",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "70px", display: "inline" },
                        attrs: {
                          type: "text",
                          id: "pages-start",
                          name: "pages-start",
                          placeholder: "Start Page"
                        },
                        domProps: { value: _vm.currentSong.pageNumbers.start },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong.pageNumbers,
                              "start",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" to "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentSong.pageNumbers.end,
                            expression: "currentSong.pageNumbers.end",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "70px", display: "inline" },
                        attrs: {
                          type: "text",
                          id: "pages-end",
                          name: "pages-end",
                          placeholder: "End Page"
                        },
                        domProps: { value: _vm.currentSong.pageNumbers.end },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong.pageNumbers,
                              "end",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-5" }, [
                      _c(
                        "span",
                        {
                          class: [
                            _vm.attemptSubmit && _vm.missingPages
                              ? "is-not-valid"
                              : "is-valid"
                          ]
                        },
                        [_vm._v("Page Numbers are required.")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label not-required",
                          attrs: { for: "comments" }
                        },
                        [_vm._v("Comments")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.selectComment,
                              expression: "selectComment",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "comments-select",
                          attrs: {
                            name: "comments",
                            id: "comments",
                            required: true
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectComment = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.addComment
                            ]
                          }
                        },
                        [
                          _c("option", { domProps: { selected: true } }, [
                            _vm._v(" (Optional) Select Template ")
                          ]),
                          _vm._l(_vm.comments, function(comment) {
                            return _c(
                              "option",
                              {
                                key: comment._id,
                                domProps: { value: comment.text }
                              },
                              [_vm._v(" " + _vm._s(comment.text) + " ")]
                            )
                          })
                        ],
                        2
                      ),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.currentSong.comments,
                            expression: "currentSong.comments",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "comments-text",
                        domProps: { value: _vm.currentSong.comments },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.currentSong,
                              "comments",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label not-required",
                          attrs: { for: "foundersPick" }
                        },
                        [_vm._v("Founders pick")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong.isRecommended,
                            expression: "currentSong.isRecommended"
                          }
                        ],
                        staticClass: "css-checkbox",
                        attrs: { type: "checkbox", id: "founders" },
                        domProps: {
                          checked: Array.isArray(_vm.currentSong.isRecommended)
                            ? _vm._i(_vm.currentSong.isRecommended, null) > -1
                            : _vm.currentSong.isRecommended
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.currentSong.isRecommended,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.currentSong,
                                    "isRecommended",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.currentSong,
                                    "isRecommended",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.currentSong, "isRecommended", $$c)
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "css-label lite-red-check",
                          attrs: { for: "founders", name: "founders_lbl" }
                        },
                        [_vm._v("Yes")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label not-required",
                          attrs: { for: "popularity" }
                        },
                        [_vm._v("Popularity")]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.currentSong.popularity,
                              expression: "currentSong.popularity"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.currentSong,
                                "popularity",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v(" Very popular ")
                          ]),
                          _c("option", { attrs: { value: "2" } }, [
                            _vm._v(" Somewhat popular ")
                          ]),
                          _c("option", { attrs: { value: "3" } }, [
                            _vm._v(" Heard occassionally ")
                          ]),
                          _c("option", { attrs: { value: "4" } }, [
                            _vm._v(" Not heard a lot ")
                          ]),
                          _c("option", { attrs: { value: "5" } }, [
                            _vm._v(" Rare find ")
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row form-row" }, [
                    _c("div", { staticClass: "col-4 label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label mult-lines",
                          attrs: { for: "rarefind" }
                        },
                        [
                          _vm._v(
                            '[OLD - For new songs or updates, use "Popularity" field] Rare Find'
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentSong.isRareFind,
                            expression: "currentSong.isRareFind"
                          }
                        ],
                        staticClass: "css-checkbox",
                        attrs: { type: "checkbox", id: "rarefind" },
                        domProps: {
                          checked: Array.isArray(_vm.currentSong.isRareFind)
                            ? _vm._i(_vm.currentSong.isRareFind, null) > -1
                            : _vm.currentSong.isRareFind
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.currentSong.isRareFind,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.currentSong,
                                    "isRareFind",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.currentSong,
                                    "isRareFind",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.currentSong, "isRareFind", $$c)
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "css-label lite-red-check",
                          attrs: { for: "rarefind", name: "rarefind_lbl" }
                        },
                        [_vm._v("Yes")]
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("div", { staticClass: "col-5" }, [
                  _c("h6", { staticStyle: { "padding-top": "11px" } }, [
                    _vm._v(" External Links ")
                  ])
                ]),
                _c("div", { staticClass: "col-5" }, [
                  _vm.formErrorText
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.formErrorText)),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm.error
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.error)),
                        _c("br")
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-margin btn btn-outline-secondary btn-xs",
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-margin btn btn-outline-success btn-xs",
                      attrs: { disabled: _vm.formsHasErrors },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  ),
                  _vm.loggedinUser && _vm.loggedinUser.role !== "creator"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-margin btn btn-outline-primary btn-xs",
                          attrs: { disabled: _vm.formsHasErrors },
                          on: { click: _vm.saveNew }
                        },
                        [_vm._v(" Save and New ")]
                      )
                    : _vm._e(),
                  _vm.$can("delete", "songs") && _vm.currentSong._id
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-margin btn btn-outline-danger btn-xs",
                          on: { click: _vm.delSong }
                        },
                        [_vm._v(" Delete ")]
                      )
                    : _vm._e()
                ])
              ]),
              _c("hr", { staticStyle: { "margin-top": "0px" } }),
              _c("div", [
                _c("div", { staticClass: "row external-links" }, [
                  _c("h6", [_vm._v("Info / Wikipedia Link")])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("p", { staticClass: "small-text" }, [
                      _vm._v(
                        " Use this field only if the link for the song is different from the link for the show. "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentSong.wikipediaUrl,
                          expression: "currentSong.wikipediaUrl"
                        }
                      ],
                      staticClass: "row external-links wiki-link",
                      attrs: { type: "text", placeholder: "http://" },
                      domProps: { value: _vm.currentSong.wikipediaUrl },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.currentSong,
                            "wikipediaUrl",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "col-4 wiki-link" }, [
                    _c(
                      "span",
                      {
                        staticClass: "link",
                        staticStyle: { "margin-left": "-75px" },
                        on: { click: _vm.testWikiLink }
                      },
                      [_vm._v("Test Link")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row external-links" }, [
                  _c("h6", [_vm._v("Scores")])
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "scores",
                          name: "musicNotes",
                          "base-url": "https://www.musicnotes.com/landing?w="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "scores",
                          name: "sheetMusicPlus",
                          "base-url":
                            "https://www.sheetmusicplus.com/search?Ntt="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "scores",
                          name: "newMusicalTheatre",
                          "base-url":
                            "http://newmusicaltheatre.com/catalogsearch/result/?q="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "scores",
                          name: "amazon",
                          "base-url":
                            "https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=+sheet+music+"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "scores",
                          name: "worldcat",
                          "base-url":
                            "http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amsscr&q=ti%3A"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "scores",
                          name: "contemporaryMusicalTheatre",
                          "base-url":
                            "https://www.contemporarymusicaltheatre.com/"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("custom-link1", {
                        attrs: { model: _vm.currentSong, type: "scores" }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links" }, [
                  _c("h6", [_vm._v("Recordings")])
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "amazon",
                          "base-url":
                            "https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=music+"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "iTunes",
                          "base-url":
                            "https://fnd.io/#/us/search?mediaType=music&term="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "spotify",
                          "base-url": "https://open.spotify.com/search/"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "soundCloud",
                          "base-url": "https://soundcloud.com/search?q="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "worldcat",
                          "base-url":
                            "http://www.worldcat.org/search?qt=affiliate&ai=Musical_musicaltheatersongs&fq=x0%3Amusic&q="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "contemporaryMusicalTheatre",
                          "base-url":
                            "https://www.contemporarymusicaltheatre.com/"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "newMusicalTheatre",
                          "base-url":
                            "http://newmusicaltheatre.com/catalogsearch/result/?q="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("external-links1", {
                        attrs: {
                          model: _vm.currentSong,
                          type: "recordings",
                          name: "gsarchive",
                          "base-url":
                            "http://search.freefind.com/find.html?id=2743017&pid=r&mode=ALL&n=0&submit=+Find%21+&query="
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row external-links-link" }, [
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _c("custom-link1", {
                        attrs: { model: _vm.currentSong, type: "recordings" }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "row justify-content-between" }, [
                _c("div", { staticClass: "col-5" }),
                _c("div", { staticClass: "col-5" }, [
                  _c("p"),
                  _vm.formErrorText
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.formErrorText)),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm.error
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.error)),
                        _c("br")
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-margin btn btn-outline-secondary btn-xs",
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-margin btn btn-outline-success btn-xs",
                      attrs: { disabled: _vm.formsHasErrors },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  ),
                  _vm.loggedinUser && _vm.loggedinUser.role !== "creator"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-margin btn btn-outline-primary btn-xs",
                          attrs: { disabled: _vm.formsHasErrors },
                          on: { click: _vm.saveNew }
                        },
                        [_vm._v(" Save and New ")]
                      )
                    : _vm._e(),
                  _vm.$can("delete", "songs") && _vm.currentSong._id
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-margin btn btn-outline-danger btn-xs",
                          on: { click: _vm.delSong }
                        },
                        [_vm._v(" Delete ")]
                      )
                    : _vm._e()
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }