<template>
  <div class="below-menu">
    <div class="row fixed-top page-header">
      <div class="col-10">
        <h5>Logs</h5>
      </div>
      <div class="col-2">
        <button @click="refresh" class="btn btn-outline-primary btn-xs float-right header-btn">
          Refresh
        </button>
          <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-row-click="rowClick"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
        position: 'top',
        mode: 'records'
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{ field: 'time', type: 'desc' }]
      }"
      :rows="rows"
      :columns="columns"
      style-class="vgt-table musical bordered condensed"
    >
      <div slot="table-actions">
        <!-- Appears above the column names -->
        <div style="display:flex;justify-content:space-between;width:95vw">
          <div>
            <div class="filterBar">
              <div class="filterBar-item filterBar-label">
                <b>Module:</b>
              </div>
              <div v-for="mod in modulesList" :key="mod" class="filterBar-item">
                <input
                  type="checkbox"
                  :name="mod"
                  class="usersSelect"
                  :value="mod"
                  @change="logFilterChanged"
                  v-model="logFilter"
                />
                <i style="padding-left:0px;margin-right: 10px;">{{ mod }}</i>
              </div>
            </div>
            <div class="filterBar">
              <div class="filterBar-item filterBar-label">
                <b>Level:</b>
              </div>
              <div class="filterBar-item">
                <input
                  type="checkbox"
                  name="debug"
                  class="usersSelect"
                  value="debug"
                  @change="logFilterChanged"
                  v-model="logLevel"
                />
                <i style="padding-left:0px;margin-right: 10px;">Debug</i>
              </div>
              <div class="filterBar-item">
                <input
                  type="checkbox"
                  name="verbose"
                  class="usersSelect"
                  value="verbose"
                  @change="logFilterChanged"
                  v-model="logLevel"
                />
                <i style="padding-left:0px;margin-right: 10px;">Verbose</i>
              </div>
              <div class="filterBar-item">
                <input
                  type="checkbox"
                  name="info"
                  class="usersSelect"
                  value="info"
                  @change="logFilterChanged"
                  v-model="logLevel"
                />
                <i style="padding-left:0px;margin-right: 10px;">Info</i>
              </div>
              <div class="filterBar-item">
                <input
                  type="checkbox"
                  name="warn"
                  class="usersSelect"
                  value="warn"
                  @change="logFilterChanged"
                  v-model="logLevel"
                />
                <i style="padding-left:0px;margin-right: 10px;">Warn</i>
              </div>
              <div class="filterBar-item">
                <input
                  type="checkbox"
                  name="error"
                  class="usersSelect"
                  value="error"
                  @change="logFilterChanged"
                  v-model="logLevel"
                />
                <i style="padding-left:0px;margin-right: 10px;">Error</i>
              </div>
            </div>
          </div>
          <input type="text" class="rounded" placeholder="Search log messages" @change="searchLogs" ref="search" />
        </div>
      </div>

      <div class="loading" slot="loadingContent">
        <div v-if="loadingErrorMsg" class="loading server-error">
          {{ loadingErrorMsg }}
        </div>
        <div v-if="isLoading">
          <img src="/loading.gif" />
          {{ isLoadingMsg }}
        </div>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Logs',
    mixins: [copyTable],
    data() {
      return {
        isLoading: false,
        logFilter: [],
        logLevel: ['warn', 'error'],
        isLoadingMsg: '',
        loadingErrorMsg: '',
        totalRecords: 0,
        tableFilter: { columnFilters: { levels: ['warn', 'error'] } },
        serverParams: {
          columnFilters: {},
          page: 1,
          perPage: 20
        },
        rows: [],
        columns: [
          {
            label: 'Date',
            field: 'time',
            sortable: false,
            width: '150px',
            type: 'date',
            dateInputFormat: 'T',
            dateOutputFormat: 'MMM dd yyyy hh:mm'
          },
          {
            label: 'Module',
            field: 'module',
            sortable: false,
            width: '150px'
          },
          {
            label: 'Level',
            field: 'level',
            sortable: false,
            width: '150px'
          },
          {
            label: 'Message',
            field: 'message',
            sortable: false
          }
        ]
      }
    },
    mounted() {
      this.$log.info('mounted called')
      this.$store.commit('general/setMenuHighlight', 'Logs')
      this.$store.dispatch('logs/getModules')
      this.$store
        .dispatch('general/getRowsPerPage', 'logsPP')
        .then(value => {
          this.serverParams.perPage = value
          // this.updateParams({ page: 1, perPage: value})
        })
        .catch(ex => {
          this.$log.error(` Error getting logs from store: ${ex.message}`)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        logsList: state => state.logs.logsList,
        modulesList: state => state.logs.modulesList,
        filteredLogsList: state => state.logs.filteredLogsList
      })
    },
    methods: {
      searchLogs() {
        this.serverParams = Object.assign(this.serverParams, { search: this.$refs.search.value })
        this.loadItems()
      },
      refresh() {
        this.loadItems()
      },
      updateParams(newProps) {
        this.serverParams = Object.assign(this.serverParams, newProps)
        this.serverParams.columnFilters = Object.assign(this.serverParams.columnFilters, this.tableFilter.columnFilters)
        Object.keys(this.serverParams).forEach(
          key => (this.serverParams[key] === null || this.serverParams[key] === '') && delete this.serverParams[key]
        )
        if (this.serverParams.columnFilters) {
          Object.keys(this.serverParams.columnFilters).forEach(
            key =>
              (this.serverParams.columnFilters[key] === null || this.serverParams.columnFilters[key] === '') &&
              delete this.serverParams.columnFilters[key]
          )
        }

        this.$log.info(`updateParams serverParams: ${JSON.stringify(this.serverParams)}`)
      },
      onPageChange(params) {
        this.$log.info('onPageChange called', params)
        this.updateParams({ page: params.currentPage })
        this.loadItems()
      },
      onPerPageChange(params) {
        this.$log.info('onPerPageChange called', params)
        this.updateParams({ perPage: params.currentPerPage })
        this.loadItems()
        this.$store.dispatch('general/saveRowsPerPage', { name: 'logsPP', value: params.currentPerPage })
      },
      onSortChange(params) {
        this.$log.info('onSortChange called', params)
        this.updateParams({
          sort: [
            {
              type: params[0].type,
              field: params[0].field
            }
          ]
        })
        this.loadItems()
      },
      onColumnFilter(params) {
        this.$log.info('onColumnFilter called', params)
        this.updateParams(params)
        this.updateParams({ page: 1 })
        this.loadItems()
      },
      loadItems() {
        // load items is what brings back the rows from server
        this.isLoading = true
        this.isLoadingMsg = 'Loading Logs'
        this.$store
          .dispatch('logs/getLogs', this.serverParams)
          .then(response => {
            this.totalRecords = response.totalRecords
            this.rows = this.logsList
            this.isLoading = false
          })
          .catch(err => {
            this.$log.info('Error in loading logs', err)
            this.loadingErrorMsg = err
          })
      },
      logFilterChanged() {
        this.$log.info('logFilterChanged called')
        this.tableFilter = { columnFilters: { modules: this.logFilter, levels: this.logLevel } }
        this.updateParams({ page: 1 })
        this.loadItems()
      },
      rowClick() {
        // This is only needed to get the row highlight
      }
    }
  }
</script>

<style scoped>
  .filterBar {
    display: flex;
    flex-flow: row nowrap;
  }
  .filterBar-label {
    max-width: 60px;
  }
  .filterBar-item {
    width: 120px;
  }
  .rounded {
    border-radius: 1rem !important;
    width: 150px;
  }
  input:focus {
    outline: none;
  }
  .right-button {
  }
</style>
