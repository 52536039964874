import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    tableKey: 0,
    currentUser: {},
    usersList: [],
    emailsList: []
  },
  mutations: {
    tableUpdate: state => {
      state.tableKey += 1
    },
    setUsers: (state, payload) => {
      state.usersList = payload
    },
    setCurrentUser: (state, payload) => {
      state.currentUser = payload
    },
    setEmailsList: (state, payload) => {
      state.emailsList = payload
    }
  },
  actions: {
    getEmailsList: async ({ commit, rootState }) => {
      return new Promise(async (resolve, reject) => {
        rootState.general.error = ''
        rootState.general.loading = true
        rootState.general.loadingMsg = 'Loading users list'
        const result = await axios
          .get(rootState.general.apiRoot + '/api/getEmailsList', {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            rootState.general.error = err
            rootState.general.loading = false
            if (err.response) {
              Vue.$log.error(`Error getting emailsList from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting emailsList from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          commit('setEmailsList', result.data)
          rootState.general.loading = false
          rootState.general.loadingMsg = ''
          resolve()
        }
      })
    },
    getFromServer: async ({ commit, rootState }, serverParams) => {
      return new Promise(async (resolve, reject) => {
        const result = await axios
          .put(rootState.general.apiRoot + '/api/users', serverParams, {
            headers: { Authorization: rootState.general.loggedinUser.token }
          })
          .catch(err => {
            if (err.response) {
              Vue.$log.error(`Error getting users from server: ${err.response.data}`)
              return reject(err.response.data)
            } else {
              Vue.$log.error(`Error getting users from server: ${err}`)
              return reject(err)
            }
          })
        if (result) {
          Vue.$log.info(`Total received: ${result.data.totalRecords} / ${result.data.usersList.length}`)
          commit('setUsers', result.data.usersList)
          resolve({ totalRecords: result.data.totalRecords })
        } else {
          resolve({ totalRecords: 0, rows: [] })
        }
      })
    },
    getUser: ({ commit, rootState }, userListItem) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Loading User'
      return new Promise((resolve, reject) => {
        Vue.http.get('api/users/' + userListItem._id).then(
          response => {
            commit('general/dataLoaded', null, { root: true })
            rootState.general.loading = false
            const currentUser = response.body
            currentUser.hasSubscription =
              Object.prototype.hasOwnProperty.call(userListItem, 'subscriptionId') && userListItem.subscriptionId !== ''
            commit('setCurrentUser', currentUser)
            resolve('ok')
          },
          error => {
            rootState.general.loading = false
            reject(error)
          }
        )
      })
    },
    saveUser: ({ commit, rootState }, user) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Saving User'
      Vue.http.post('api/users/', { user }).then(
        response => {
          commit('general/dataLoaded', null, { root: true })
          rootState.general.loading = false
          commit('setCurrentUser', response.body)
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    },
    deleteUser: ({ commit, rootState }, rowId) => {
      rootState.general.error = ''
      rootState.general.loading = true
      rootState.general.loadingMsg = 'Deleting User'
      Vue.http.delete('api/users/' + rowId).then(
        () => {
          commit('general/dataLoaded', null, { root: true })
          rootState.general.loading = false
          commit('setCurrentUser', {})
        },
        error => {
          commit('sendToast', { type: 'error', content: error.bodyText }, { root: true })
          rootState.general.loading = false
        }
      )
    }
  }
}
