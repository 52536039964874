import Shows from '@/pages/Shows'
import Songs from '@/pages/Songs'
import FAQ from '@/pages/FAQ'
import Glossary from '@/pages/Glossary'
import Users from '@/pages/Users'
import Subscriptions from '@/pages/Subscriptions'
import Composers from '@/pages/Composers'
import Lyricists from '@/pages/Lyricists'
import CommentTemplates from '@/pages/CommentTemplates'
import Tags from '@/pages/Tags'
import EmailLog from '@/pages/EmailLog'
import Coupons from '@/pages/Coupons'
import Logs from '@/pages/Logs'
import StripeMsgs from '@/pages/StripeMsgs'
import Statistics from '@/pages/Statistics'
import Login from '@/pages/Login'
import Resources from '@/pages/Resources'
import Groupusers from '@/pages/Groupusers'

export const routes = [
  {
    path: '/',
    redirect: '/shows'
  },
  {
    path: '/groupusers',
    name: 'groupusers',
    component: Groupusers
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/shows',
    name: 'Shows',
    component: Shows
  },
  {
    path: '/songs',
    name: 'Songs',
    component: Songs
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/glossary',
    name: 'Glossary',
    component: Glossary
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions
  },
  {
    path: '/Composers',
    name: 'Composers',
    component: Composers
  },
  {
    path: '/lyricists',
    name: 'Lyricists',
    component: Lyricists
  },
  {
    path: '/commentTemplates',
    name: 'CommentTemplates',
    component: CommentTemplates
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/emails',
    name: 'EmailLog',
    component: EmailLog
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: Coupons
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/stripe-msgs',
    name: 'StripeMsgs',
    component: StripeMsgs
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/statistics/:subscriptionId',
    name: 'StatisticsWithId',
    component: Statistics
  }
]
