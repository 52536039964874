var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "below-menu" },
    [
      _c("div", { staticClass: "row fixed-top page-header" }, [
        _c("div", { staticClass: "col-9" }, [
          _c("h5", [_vm._v(_vm._s(_vm.tableTitle))])
        ]),
        _c("div", { staticClass: "col-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-xs float-right",
              on: { click: _vm.onNewSubscription }
            },
            [_vm._v(" Add Subscription ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-xs float-right",
              staticStyle: { "margin-right": "10px" },
              on: { click: _vm.copyContent }
            },
            [_vm._v(" Copy ")]
          )
        ])
      ]),
      _c(
        "vue-good-table",
        {
          key: _vm.tableKey,
          attrs: {
            mode: "remote",
            totalRows: _vm.totalRecords,
            isLoading: _vm.isLoading,
            "pagination-options": {
              enabled: true,
              perPage: _vm.serverParams.perPage,
              perPageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 50],
              position: "top",
              mode: "records"
            },
            "sort-options": {
              enabled: true,
              initialSortBy: [{ field: "startDate", type: "desc" }]
            },
            rows: _vm.rows,
            columns: _vm.columns,
            "style-class": "vgt-table musical bordered condensed"
          },
          on: {
            "on-page-change": _vm.onPageChange,
            "on-sort-change": _vm.onSortChange,
            "on-column-filter": _vm.onColumnFilter,
            "on-per-page-change": _vm.onPerPageChange,
            "on-row-click": _vm.rowClick,
            "update:isLoading": function($event) {
              _vm.isLoading = $event
            },
            "update:is-loading": function($event) {
              _vm.isLoading = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "table-row",
              fn: function(props) {
                return [
                  props.column.field == "editSub"
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !props.row.planName.includes(
                                  "No subscription"
                                ),
                                expression:
                                  "!props.row.planName.includes('No subscription')"
                              }
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.editSub(props.row)
                              }
                            }
                          },
                          [_vm._v("Subscription")]
                        )
                      ])
                    : props.column.field == "editPurchaser"
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.editPurchaser(props.row)
                              }
                            }
                          },
                          [_vm._v("Purchaser")]
                        )
                      ])
                    : props.column.field == "extend"
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: props.row.planName.includes("Trial"),
                                expression:
                                  "props.row.planName.includes('Trial')"
                              }
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.extendTrial(props.row._id)
                              }
                            }
                          },
                          [_vm._v("Extend")]
                        )
                      ])
                    : props.column.field == "planName" && props.row.isCancelled
                    ? _c("span", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "blue"
                            }
                          },
                          [_vm._v(_vm._s(props.row.planName) + " - cancelled")]
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(props.formattedRow[props.column.field]) +
                            " "
                        )
                      ])
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            {
              staticClass: "loading",
              attrs: { slot: "loadingContent" },
              slot: "loadingContent"
            },
            [
              _vm.loadingErrorMsg
                ? _c("div", { staticClass: "loading server-error" }, [
                    _vm._v(" " + _vm._s(_vm.loadingErrorMsg) + " ")
                  ])
                : _c("div", [
                    _c("img", { attrs: { src: "/loading.gif" } }),
                    _vm._v(" " + _vm._s(_vm.isLoadingMsg) + " ")
                  ])
            ]
          ),
          _c(
            "div",
            { attrs: { slot: "table-actions" }, slot: "table-actions" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.showOnlyActive,
                    expression: "showOnlyActive"
                  }
                ],
                staticClass: "usersSelect",
                attrs: { type: "checkbox", name: "showOnlyActive" },
                domProps: {
                  checked: Array.isArray(_vm.showOnlyActive)
                    ? _vm._i(_vm.showOnlyActive, null) > -1
                    : _vm.showOnlyActive
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.showOnlyActive,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.showOnlyActive = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.showOnlyActive = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.showOnlyActive = $$c
                      }
                    },
                    function($event) {
                      return _vm.activeSwitch()
                    }
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Only Active Subscriptions")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: { type: "radio", name: "usersSelect", value: "all" },
                domProps: { checked: _vm._q(_vm.usersSelect, "all") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "all"
                    },
                    function($event) {
                      return _vm.filter("all")
                    }
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("All Subscriptions")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: {
                  type: "radio",
                  name: "usersSelect",
                  value: "institutional"
                },
                domProps: { checked: _vm._q(_vm.usersSelect, "institutional") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "institutional"
                    },
                    function($event) {
                      return _vm.filter("institutional")
                    }
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Institutional Subscribers")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.usersSelect,
                    expression: "usersSelect"
                  }
                ],
                staticClass: "usersSelect",
                attrs: {
                  type: "radio",
                  name: "usersSelect",
                  value: "individual"
                },
                domProps: { checked: _vm._q(_vm.usersSelect, "individual") },
                on: {
                  change: [
                    function($event) {
                      _vm.usersSelect = "individual"
                    },
                    function($event) {
                      return _vm.filter("individual")
                    }
                  ]
                }
              }),
              _c(
                "i",
                {
                  staticStyle: { "padding-left": "0px", "margin-right": "10px" }
                },
                [_vm._v("Individual Subscribers")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }