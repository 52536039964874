<template>
  <div class="below-menu">
    <!-- Display some nice loading animation -->
    <div class="loading" v-if="loading">
      <img src="/loading.gif" />
      <div class="loading">
        {{ loadingMsg }}
      </div>
    </div>

    <div class="row fixed-top page-header">
      <div class="col-9">
        <h5>Resources</h5>
      </div>
      <div class="col-3">
        <button @click="newResource" class="btn btn-outline-primary btn-xs float-right header-btn">
          Add New Resource
        </button>
        <button @click="copyContent" style="margin-right: 10px;" class="btn btn-outline-primary btn-xs float-right header-btn">
          Copy
        </button>
      </div>
    </div>

    <!-- Data Table. If there is data, display the table -->
    <div v-if="data" class="table-block">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{ enabled: false }"
        :search-options="{ enabled: false }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'title', type: 'asc' }
        }"
        :line-numbers="false"
        @on-row-click="rowClick"
        style-class="vgt-table musical bordered condensed"
      >
        <!--  v-if="$can('delete', 'faq')" -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'delete'">
            <a href="#" v-if="$can('delete', 'faq')" @click="deleteResource(props.row.originalIndex)">delete resource</a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <div class="row button-row">
      <div class="col-10">
        <div v-if="error" class="error-message text-right">
          {{ error }}
        </div>
        <div class="loading-message text-right">
          {{ loadingMsg }}
        </div>
      </div>
      <div class="col-2">
        <div class="text-right save-btn">
          <button class="btn btn-success btn-xs" @click="saveAnswer">
            Save Resource
          </button>
        </div>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-1 label">
        <label class="label required" for="question">Title</label>
      </div>
      <div class="col-10">
         <input type="text" class="form-control" v-model.trim="curResource.title" id="title" name="title" />
      </div>
    </div>

    <div class="row form-row">
      <div class="col-1 label">
        <label class="label required" for="link">Link</label>
      </div>
      <div class="col-10">
         <input type="text" class="form-control" v-model.trim="curResource.link" id="link" name="link" />
      </div>
    </div>

    <div class="row form-row" style="min-height: 300px;">
      <div class="col-1 label">
        <label class="label required" for="question">Description</label>
      </div>
      <div class="col-10">
        <!-- <tinymce v-if="data" id="d1" @editorChange="editorChanged()" :other_options="tinyOptions" v-model="answer"></tinymce> -->
        <quill-editor
          id="description"
          style="height: 200px !important;"
          v-model="curResource.description"
          ref="myQuillEditor1"
          :options="editorOption"
          @ready="onEditorReady($event)"
        />
        <div :class="[attemptSubmit && missingTitle ? 'is-not-valid' : 'is-valid']">
          Question is required.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { copyTable } from '../mixins/copyTable'
  export default {
    name: 'Resources',
    mixins: [copyTable],
    data() {
      return {
        editorOption: {
          // some quill options
          modules: {
            toolbar: [
              'bold',
              'italic',
              'underline',
              { color: [] },
              { indent: '-1' },
              { indent: '+1' },
              { list: 'ordered' },
              { list: 'bullet' },
              'link',
              'clean',
              { header: [1, 2, 3, 4, 5, 6, false] },
              { size: ['small', false, 'large', 'huge'] }
            ]
          }
        },
        editor: {},
        attemptSubmit: false,
        okToSave: true,
        curResource: { title: '', link: '', description: '' },
        qIndex: -1,
        columns: [
          {
            label: 'Title',
            field: 'title',
            width: '20%'
          },
          {
            label: 'Link Address',
            field: 'link',
            width: '70%'
          },
          {
            label: '',
            field: 'delete',
            width: '10%'
          }
        ],
        rows: []
      }
    },
    created() {
      this.$store.commit('general/setMenuHighlight', 'Resources')
    },
    mounted() {
      console.log('Resources component mounted')
      this.$store
        .dispatch('resources/getResources')
        .then(() => {
          this.rows = this.resourcesList
        })
        .catch(ex => {
          console.error('Exception in mounted - get Resources', ex.message)
        })
    },
    computed: {
      ...mapState({
        loadingMsg: state => state.general.loadingMsg,
        data: state => state.general.data,
        error: state => state.general.error,
        loading: state => state.general.loading,
        loggedinUser: state => state.general.loggedinUser,
        resourcesList: state => state.resources.resourcesList
      }),
      formErrorText() {
        return this.formsHasErrors ? 'Please correct one or more errors' : ''
      },
      formsHasErrors() {
        if (this.attemptSubmit) {
          return this.missingTitle || this.missingLink || this.missingDescription
        } else {
          return false
        }
      },
      missingTitle() {
        return this.curResource.title === ''
      },
      missingLink() {
        return this.curResource.answer === ''
      },
      missingDescription() {
        return this.curResource.description === ''
      }
    },
    methods: {
      newResource() {
        this.curResource = { title: '', link: '', description: '' }
        this.editor.focus()
        this.attemptSubmit = false
      },
      deleteResource(index) {
        const q = this.resourcesList[index]
        if (confirm(`Are you sure you want to delete the resource "${q.title}"?\n\nThis action cannot be undone.`)) {
          // Delete from server first
          this.$store
            .dispatch('resources/deleteResource', q._id)
            .then(result => {
              this.curResource = { title: '', link: '', description: '' }
              // Resource not yet saved
              if (!q._id) {
                const qindex = this.resourcesList.findIndex(f => f.title === q.title)
                this.resourcesList.splice(qindex, 1)
              } else {
                this.resourcesList.splice(index, 1)
              }
              setTimeout(() => {
                document.querySelector('.clickable').click()
              }, 300)
            })
            .catch(err => {
              this.$log.warn('Error in delete Resource:', err)
              this.loadingErrorMsg = err
            })
        }
      },
      saveAnswer() {
        // Get answer and question
        const description = document.getElementById('description').children[1].children[0].innerHTML
        const resource = {
          _id: this.curResource._id,
          title: this.curResource.title,
          link: this.curResource.link,
          description
        }
        this.attemptSubmit = true
        if (!this.formsHasErrors) {
          console.log(`Saving resource "${resource}"`)
          // Send the resource to the server
          this.$store.dispatch('resources/saveResource', resource)
          this.attemptSubmit = false
          this.curResource = { title: '', link: '', description: '' }
        }
      },
      rowClick(params) {
        this.okToSave = false
        // Remove reference between rows and curResource
        this.curResource = JSON.parse(JSON.stringify(params.row))
        const rows = document.querySelectorAll('.clickable')
        rows.forEach((row, rowIndex) => {
          const a = rows[rowIndex]
          a.classList.remove('clicked')
          if (params.pageIndex === rowIndex) {
            a.classList.add('clicked')
          }
        })
      },
      onEditorReady(quill) {
        this.editor = quill
      }
    }
  }
</script>
