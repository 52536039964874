<template>
  <div>
    <div class="row">
      <div class="col-12">
        <input type="checkbox" :name="linkName" :id="linkName" class="css-checkbox" v-model="model.active" />
        <label :for="linkName" class="css-label lite-red-check">{{ label }}</label>
      </div>
    </div>
    <div class="custom-link" v-if="model.active">
      <div class="row">
        <div class="col-12">
          <label :for="linkName + 'radio'">Site Name </label>
          <input type="text" v-model="model.siteName" placeholder="" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label :for="linkName + 'radio'">URL</label>
          <input type="text" v-model="model.manualLink" placeholder="http://" />
          <span @click="testManualScore" v-if="model.manualLink" class="link">Test Link</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ['songs', 'linkName', 'label', 'model'],
    methods: {
      testManualScore() {
        window.open(this.model.manualLink, '_blank')
      }
    }
  }
</script>
